const Processing = () =>
  import(/* webpackChunkName: "processing" */ '../processing')
const Sidebar = () =>
  import(
    /* webpackChunkName: "processing" */ '@/app/old/desktop/shared/components/sidebar.vue'
  )
const Navbar = () =>
  import(
    /* webpackChunkName: "processing" */ '@/app/old/desktop/shared/components/navbar/navbar.vue'
  )
const ProcessingList = () =>
  import(
    /* webpackChunkName: "processing" */ '../components/processing-list/processing-list'
  )
const ProcessingDetails = () =>
  import(
    /* webpackChunkName: "processing" */ '../components/processing-details'
  )
const ProcessingConfig = () =>
  import(
    /* webpackChunkName: "processing" */ '../components/processing-config/processing-config.vue'
  )
const ProcessingImages = () =>
  import(
    /* webpackChunkName: "processing" */ '../components/processing-images/processing-images'
  )
const GCP = () =>
  import(
    /* webpackChunkName: "processing" */ '../components/processing-gcp/gcp'
  )
const Events = () =>
  import(
    /* webpackChunkName: "processing" */ '../components/processing-events/events'
  )

const Routes = [
  {
    path: '/process',
    components: {
      default: Processing,
      left: Sidebar,
      top: Navbar
    },
    meta: {
      title: 'Processing Management',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'process-list',
        component: ProcessingList
      },
      {
        path: ':id/details',
        component: ProcessingDetails,
        children: [
          // {
          //     path: "",
          //     redirect: { name: "processing-config" }
          // },
          {
            path: '',
            name: 'processing-config',
            component: ProcessingConfig
          },
          {
            path: 'images',
            name: 'processing-images',
            component: ProcessingImages
          },
          {
            path: 'gcp',
            name: 'processing-gcp',
            component: GCP
          },
          {
            path: 'events',
            name: 'processing-events',
            component: Events
          }
        ]
      }
    ]
  }
]

export default Routes

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"mentions",staticClass:"sh-comment-action",class:_vm.isFocused ? 'focused' : ''},[(_vm.attachment_files && _vm.attachment_files.length > 0)?_c('attachment-list',{staticClass:"task-list-tab",attrs:{"id":"task-tab","existing_files":_vm.attachment_files,"service":_vm.service,"deselect":false,"ticket":_vm.ticket},on:{"delete_attachment":function($event){return _vm.delete_attachment($event)},"refresh_attachments":_vm.getFiles}}):_vm._e(),_c('textarea',{ref:"editor",attrs:{"placeholder":_vm.localization(
        'app-type-something-to-comment',
        'Type something to comment'
      )}}),_c('p',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.emoji_keyboard = false)),expression:"() => (emoji_keyboard = false)"}],staticClass:"is-flex is-vcenter sh-comment-action-bar",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('sh-icon',{staticClass:"is-pointer is-24x24 mr-10 comment-smiley",attrs:{"name":'smiley'},nativeOn:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();_vm.emoji_keyboard = !_vm.emoji_keyboard}}}),_c('v-emoji-picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.emoji_keyboard),expression:"emoji_keyboard"}],staticStyle:{"position":"absolute","bottom":"10px","right":"0","z-index":"1"},attrs:{"labelSearch":"Search","lang":"pt-BR","emojiSize":30},on:{"select":_vm.select_emoji}}),(_vm.showAttachments && !_vm.offline)?_c('upload-file',{attrs:{"service":"tickets","name":_vm.ticket.name,"type":"comments","uploadMethod":_vm.uploadMethod,"uid":_vm.ticket.uid,"isModal":"true","id":`ticket_attachments_comments`},on:{"initialised":function($event){_vm.uppy = $event},"uploaded_success":_vm.upload_completed,"uploaded-files-details":attachments => {
          _vm.track_file_uploads(attachments, null, 'comment');
        }}},[_c('div',{staticClass:"sh-attachment-btn",staticStyle:{"position":"relative","margin":"0px 10px"}},[_c('sh-icon',{staticClass:"is-pointer is-20x20 mr-10",attrs:{"name":'link'}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachments.length),expression:"attachments.length"}],staticClass:"badge",domProps:{"textContent":_vm._s(_vm.attachments.length)}})],1)]):_vm._e(),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization(
          'app-tooltip-enter-to-add-comment',
          `Ctrl + Enter to add the comment`
        ),
        html: false
      }),expression:"{\n        content: localization(\n          'app-tooltip-enter-to-add-comment',\n          `Ctrl + Enter to add the comment`\n        ),\n        html: false\n      }"}],staticClass:"sh-button sh-comment-send",class:_vm.editorValue.length > 0 || _vm.attachments.length ? '' : 'disabled',on:{"click":_vm.sendComment}},[_c('span',[(_vm.editorValue.length > 0 || _vm.attachments.length)?_c('i',{class:_vm.action
              ? 'fa fa-spinner fa-spin is-size-4 mx-5'
              : 'far fa-paper-plane is-size-4 mr-5'}):_c('i',{class:'fas fa-paper-plane is-size-4  mr-5'})])])],1),_c('div',{staticClass:"sh-comment-typing-event"},[_c('div',[(_vm.typing && _vm.typeID !== _vm.$store.state.claims.user_id)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.typing))]),_vm._v(" started typing ... ")]):_vm._e()]),(_vm.isMobile)?_c('user-tag',{attrs:{"custom_class":'tiny-user-avatar ',"custom_size":18,"users":_vm.viewingMembers,"tagsCountToShow":3,"no_access":true,"is_precense_component":true}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
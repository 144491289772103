var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-list"},[_c('div',{staticClass:"list-top-section"},[_c('div',{staticClass:"list-top-section--search is-flex align-center"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}}),_c('div',{staticClass:"ml-15"},[(_vm.selected.length > 1)?_c('project-actions',{attrs:{"selected":_vm.selected,"data":_vm.data,"group_id":_vm.$route.params.id},on:{"update_selection":_vm.select_all,"delete_selected":_vm.delete_selected}}):_vm._e()],1)],1),_c('div',{staticClass:"list-top-section--create sh-buttons-group"},[(_vm.$store.getters.is_internal_user)?_c('a',{on:{"click":function($event){_vm.show_deleted = !_vm.show_deleted;
          _vm.getData();}}},[_c('button',{staticClass:"sh-button--delete"},[_c('span',{staticClass:"icon"},[(_vm.show_deleted)?_c('span',[_vm._v(_vm._s(_vm.localization("app-all", "All")))]):_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":'trash'}})],1)])]):_vm._e(),(_vm.access('core', 'create_projects'))?_c('a',{on:{"click":function($event){_vm.open_form(_vm.ProjectFormComponent, {
            header: _vm.localization(
              'app-create-project-for',
              'Create Project for'
            ),
            group_id: _vm.$route.params.id
          })}}},[_c('button',{staticClass:"sh-button sh-button--create sh-button--inverted",class:{ 'is-disabled': _vm.show_deleted }},[_vm._v(" "+_vm._s(_vm.localization("app-new-project", "New Project"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('core', 'create_projects')}}},[_c('button',{staticClass:"sh-button sh-button--create disabled-link sh-button--inverted"},[_vm._v(" "+_vm._s(_vm.localization("app-new-project", "New Project"))+" ")])])])]),_c('div',{staticClass:"groups-header columns is-regular-14-00-17 bb-1 is-marginless"},[_c('div',{staticClass:"column is-4 is-flex align-center"},[_c('div',{staticClass:"b-checkbox is-flex mr-15"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.all_projects),expression:"all_projects"}],staticClass:"sh-custom-checkbox is-thinner fill",attrs:{"type":"checkbox","id":"selection"},domProps:{"checked":Array.isArray(_vm.all_projects)?_vm._i(_vm.all_projects,null)>-1:(_vm.all_projects)},on:{"input":function($event){_vm.all_projects
              ? (_vm.selected = [])
              : (_vm.selected = _vm.data.map(item => item.uid))},"change":function($event){var $$a=_vm.all_projects,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.all_projects=$$a.concat([$$v]))}else{$$i>-1&&(_vm.all_projects=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.all_projects=$$c}}}})]),_vm._v(" "+_vm._s(_vm.localization("app-projects", "Projects"))+" ")]),_c('div',{staticClass:"column",class:[_vm.$route.name == 'group-list' ? 'is-3' : 'is-4']},[_vm._v(" "+_vm._s(_vm.localization("app-date", "Date"))+" ")]),_c('div',{staticClass:"column is-2"},[_vm._v(" "+_vm._s(_vm.localization("app-processing", "Processing"))+" ")])]),(_vm.data && _vm.data.length)?_c('div',{staticClass:"bg-white"},_vm._l((_vm.data),function(item){return _c('div',{key:item.uid,staticClass:"px-20 py-20 bb-1",on:{"mouseenter":function($event){_vm.hover = item.uid},"mouseleave":function($event){_vm.hover = -1}}},[(item)?_c('project-list-item',{attrs:{"show_title":true,"item":item,"hover":_vm.hover,"selected":_vm.selected,"asset_id":_vm.asset_id},on:{"edit":function($event){return _vm.open_form(
            _vm.ProjectFormComponent,
            {
              header: 'Edit Project for',
              subheader: item.group.name,
              group_id: _vm.$route.params.id
            },
            $event
          )},"add_to_selection":_vm.update_selection}}):_vm._e()],1)}),0):_c('div',[(_vm.isLoading)?_c('div',[_c('list-skeleton',{attrs:{"options":{ row_count: 5, col_count: 4, text: 4 }}})],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/events-illustration.svg"),"alt":""},slot:"image"}),(!_vm.show_deleted)?_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('a',{on:{"click":function($event){_vm.open_form(_vm.ProjectFormComponent, {
              header: _vm.localization(
                'app-create-project-for',
                'Create Project for'
              ),
              group_id: _vm.$route.params.id
            })}}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-project", "Create New Project"))+" ")])])]):_vm._e()])],1),_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="therm-tickets-sidebar">
    <i
      class="fas is-24x24 therm-chevron"
      :class="[mini_ticket_sidebar ? 'fa-chevron-left' : 'fa-chevron-right']"
      @click="SET_TICKET_SIDEBAR(!mini_ticket_sidebar)"
      :style="[mini_ticket_sidebar ? { right: '4rem' } : { right: '29rem' }]"
    ></i>

    <div v-if="mini_ticket_sidebar" class="mini-sidebar">
      <div class="collapsed-therm-tickets">
        <span class="text">
          {{ localization("app-defects-list", "Defects List") }}
        </span>
      </div>
    </div>

    <div class="therm-tickets" v-else>
      <div v-if="!selected_feature_id">
        <div class="padding-20 is-medium-16-500-19">
          <span class="has-text-white">
            {{ localization("app-defects-list", "Defects List") }}
          </span>
        </div>
        <div class="search-box has-dark-theme-background">
          <div class="search-box-searchicon">
            <m-icon
              :name="'search'"
              class="is-16x16"
              :file="'tickets-sprite'"
            ></m-icon>
          </div>
          <div class="search-box-input">
            <input
              @input="search()"
              class="has-dark-theme-background"
              autocomplete="off"
              type="text"
              v-model="search_by"
              :placeholder="
                localization('app-search-defects', 'Search Defects')
              "
            />
          </div>
        </div>

        <div v-if="loading">
          <mobile-list-skeleton />
        </div>
        <div
          v-else-if="!loading && (!defects || !defects.length)"
          style="height: 100vh; margin-top: 40vh; text-align: center"
        >
          {{ localization("app-no-defects-found", "NO DEFECTS FOUND") }}
        </div>

        <div v-else>
          <div
            class="m-ticket__ticketlist has-slimscroll-xs pb-30"
            style="height: 88vh"
          >
            <div
              v-for="item in search_filtered_defects"
              :key="item.uid"
              class="m-ticket-list__item is-flex dark-hover-background"
            >
              <div>
                <mobile-tickets-list-item
                  v-if="item.uid"
                  @show-defect-details="open_ticket_details"
                  :ticket="item"
                  :tags="
                    TAGS
                      ? (item.tags || []).map(t => (TAGS[t] ? TAGS[t] : null))
                      : null
                  "
                  class="ml-10"
                  :has_dark_theme="true"
                  :isThermTicket="true"
                ></mobile-tickets-list-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ticket-details v-else @reset_list="get_data()"></ticket-details>
    </div>
  </div>
</template>

<script>
  import TicketDetails from "./therm-v2-ticket-details";
  import { mapState, mapMutations, mapGetters } from "vuex";
  import MobileTicketsListItem from "@/app/old/mobile/modules/tickets/components/tickets-list/components/mobile-list-item.vue";
  import SearchBar from "@/app/old/mobile/shared/components/searchbar.vue";
  import MobileListSkeleton from "@/app/old/mobile/shared/components/list-skeleton.vue";
  import DefectsMixin from "@/app/old/desktop/therm-v2/components/defects.mixin.vue";

  export default {
    mixins: [DefectsMixin],
    components: {
      TicketDetails,
      SearchBar,
      MobileListSkeleton,
      MobileTicketsListItem
    },
    data() {
      return {
        allFiltersData: {},
        show_details: false,
        properties: "",
        search_by: null,
        loading: false
      };
    },

    computed: {
      ...mapState(["TAGS"]),
      ...mapState("thermv2", [
        "mini_ticket_sidebar",
        "projects",
        "defects",
        "active_class_ids",
        "active_filters",
        "map",
        "selected_feature_id",
        "active_projects"
      ]),
      ...mapGetters("thermv2", ["filtered_defects"]),
      search_filtered_defects() {
        return !this.search_by
          ? this.filtered_defects
          : this.filtered_defects.filter(t => t.name.includes(this.search_by));
      }
    },

    beforeDestroy() {
      this.$store.dispatch("thermv2/select_ticket");
    },
    methods: {
      ...mapMutations("thermv2", [
        "SET_TICKET_SIDEBAR",
        "SET_DEFECTS",
        "SET_SELECTED_FEATURE_ID"
      ]),
      open_ticket_details(uid) {
        let ticket = this.filtered_defects.find(t => t.uid === uid);

        this.SET_SELECTED_FEATURE_ID(
          ticket && ticket.properties ? ticket.properties.issueUid : null
        );
      }
    }
  };
</script>

<style lang="scss">
  .therm-tickets {
    width: 30rem;
    position: fixed;
    right: 0;
    top: 0;
    background: #1e2328;
    color: #bbbdbe;
    .search-box-filter {
      display: none;
    }

    // filter: invert(1) hue-rotate(180deg);
  }

  .mini-sidebar {
    position: fixed;
    right: 0;
    top: 0;
  }
  .collapsed-therm-tickets {
    top: 0px;
    width: 5rem;
    height: 100vh;
    background: #1e2328 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #171e481a;

    .text {
      font-family: Barlow-Regular;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #ffffff;
      position: absolute;
      top: 50%;

      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);

      margin: auto;
      white-space: nowrap;
      right: -1rem;
    }
  }

  .therm-chevron {
    position: absolute;

    color: #5b607e;
    border-radius: 100%;
    width: 2.3rem;
    height: 2.3rem;
    background: #fff 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 2px 5px #323c464d;
    box-shadow: 0px 2px 5px #323c464d;
    font-size: 1.4rem;
    padding: 0.6rem 0.6em;
    top: 1rem;
    cursor: pointer;
    z-index: 1;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .therm-tickets-sidebar {
    transition: width 0.2s linear;
  }
  .dark-hover-background {
    &:hover {
      background: #000;
    }
  }
</style>

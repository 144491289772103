const SystemModel = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/system-model/system-model.vue'
  )
const SystemModelTemplate = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/system-model/components/system-model-template/system-model-template.vue'
  )
const SystemModelComponents = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/system-model/components/system-model-component/system-model-component.vue'
  )
const SystemModelComponentInstances = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/system-model/components/system-model-component/components/component-instances/component-instances.vue'
  )
const SystemModelComponentConnections = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/system-model/components/system-model-component/components/component-connections.vue'
  )
const Sidebar = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/shared/components/sidebar.vue'
  )
const Navbar = () =>
  import(
    /* webpackChunkName: "system-model" */ '@/app/old/desktop/shared/components/navbar/navbar.vue'
  )

// import SystemModel from '@/app/old/desktop/system-model/system-model.vue';
// import SystemModelTemplate from '@/app/old/desktop/system-model/components/system-model-template/system-model-template.vue';
// import SystemModelComponents from '@/app/old/desktop/system-model/components/system-model-component/system-model-component.vue';
// import SystemModelComponentInstances from '@/app/old/desktop/system-model/components/system-model-component/components/component-instances/component-instances.vue';
// import SystemModelComponentConnections from '@/app/old/desktop/system-model/components/system-model-component/components/component-connections.vue';

// import Sidebar from '@/app/old/desktop/shared/components/sidebar.vue';
// import Navbar from '@/app/old/desktop/shared/components/navbar/navbar.vue';

const Routes = [
  {
    path: '/sm/:asset/templates',
    name: 'systemModel',
    components: {
      default: SystemModel,
      left: Sidebar,
      top: Navbar
    },
    meta: {
      title: 'System Model',
      requiresAuth: true,
      mini_sidebar: true
    },
    children: [
      {
        path: '',
        name: 'sm-template',
        component: SystemModelTemplate,
        meta: {
          title: 'System Model - Templates',
          mini_sidebar: true
        }
      },
      {
        path: ':templateUid/components/',
        name: 'sm-components',
        component: SystemModelComponents,
        meta: {
          title: 'System Model - Components',
          mini_sidebar: true
        }
      },
      {
        path: ':templateUid/components/:component/instances/',
        name: 'sm-component-instances',
        component: SystemModelComponentInstances,
        meta: {
          title: 'System Model - Component Instances',
          mini_sidebar: true
        }
      },
      {
        path:
          ':templateUid/components/:component/parent/:parent_component/instances/',
        name: 'sm-component-connections',
        component: SystemModelComponentConnections,
        meta: {
          title: 'System Model - Component Connections',
          mini_sidebar: true
        }
      }
    ]
  }
]

export default Routes

const get_cookies_host_name = () => {
  if (window.location.hostname === "localhost") return "localhost";

  return `.${location.host
    .split(".")
    .splice(1)
    .join(".")}`; // .ril.solar, .sensehawk.com, .nextracker.com
};

export { get_cookies_host_name };

<template>
  <div>
    <div v-if="view_type == 'list'">
      <div v-if="!is_loading">
        <template v-for="(item, i) in data">
          <list-item-folder
            v-if="item.hasOwnProperty('parent')"
            :class="{ 'is-hidden': show_filter.filter_key == 'files' }"
            :key="i"
            :is_viewer_small="is_viewer_small"
            :item="item"
            :i="i"
          />
          <list-item-file
            v-else
            :class="{ 'is-hidden': show_filter.filter_key == 'folders' }"
            :key="i"
            :is_viewer_small="is_viewer_small"
            :item="item"
            :i="i"
          />
        </template>
      </div>
      <template v-else>
        <mobile-list-skeleton v-if="is_viewer_small" />
        <vault-list-skeleton v-else></vault-list-skeleton>
      </template>
    </div>
    <div v-else>
      <div v-if="!is_loading">
        <drag-select
          @change="DRAG_SELECT($event)"
          class="columns is-multiline is-marginless padding-20"
          selectorClass="dragitem"
        >
          <template v-for="(folder, i) in data">
            <div
              v-if="folder.hasOwnProperty('parent')"
              :key="folder.uid"
              :class="{ 'is-hidden': show_filter.filter_key == 'files' }"
              class="column is-3 is-centered is-flex is-center"
            >
              <grid-item-folder
                @toggle_selection="TOGGLE_SELECTION($event)"
                @shift_click="
                  $store.dispatch('vault/SHIFT_SELECTION', {
                    item: $event,
                    all_items: data
                  })
                "
                class="is-pointer "
                :data-file="folder.uid"
                :item="folder"
                :i="i"
              />
            </div>
            <div
              v-else
              :key="folder.uid"
              :class="{ 'is-hidden': show_filter.filter_key == 'folders' }"
              class="column is-3 is-centered is-flex is-center"
            >
              <grid-item-file
                @toggle_selection="TOGGLE_SELECTION($event)"
                @shift_click="
                  $store.dispatch('vault/SHIFT_SELECTION', {
                    item: $event,
                    all_items: data
                  })
                "
                class="is-pointer"
                :data-file="folder.uid"
                :is_viewer_small="is_viewer_small"
                :item="folder"
                :i="i"
              />
            </div>
          </template>
        </drag-select>
      </div>
      <template v-else>
        <div class="columns is-gapless" v-for="i in 2" :key="i">
          <attachment-skeleton
            v-for="i in 4"
            :key="i"
            class="column is-3"
          ></attachment-skeleton>
        </div>
      </template>
    </div>
    <template v-if="!data.length && !is_loading">
      <vault-noresults></vault-noresults>
    </template>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapGetters } from "vuex";
  import AttachmentSkeleton from "@/app/old/desktop/tickets/components/ticket-details/components/attachments/components/attachment-skeleton";
  import VaultListSkeleton from "@/app/old/desktop/vault/components/vault-list/components/vault-list-skeleton.vue";
  import VaultHeaders from "@/app/old/desktop/vault/components/vault-list/components/vault-headers";
  import ListItemFile from "@/app/old/desktop/vault/components/vault-list/components/vault-files/file-list-item";
  import ListItemFolder from "@/app/old/desktop/vault/components/vault-list/components/vault-folders/folder-list-item";
  import GridItemFile from "@/app/old/desktop/vault/components/vault-list/components/vault-files/file-grid-item";
  import GridItemFolder from "@/app/old/desktop/vault/components/vault-list/components/vault-folders/folder-grid-item";
  import DragSelect from "vue-drag-select/src/DragSelect.vue";
  import MobileListSkeleton from "@/app/old/mobile/shared/components/list-skeleton.vue";
  import VaultNoresults from "@/app/old/desktop/vault/components/vault-list/components/vault-noresults";
  export default {
    components: {
      AttachmentSkeleton,
      VaultListSkeleton,
      ListItemFile,
      ListItemFolder,
      GridItemFile,
      GridItemFolder,
      VaultHeaders,
      DragSelect,
      MobileListSkeleton,
      VaultNoresults
    },
    props: ["data", "is_viewer_small", "is_loading", "index"],
    computed: {
      ...mapState("vault", [
        "view_type",
        "group_by",
        "show_filter",
        "selected_files",
        "selected_folders",
        "last_selected",
        "vault_files",
        "vault_folders",
        "current_folder_uid"
      ])
    },
    methods: {
      ...mapMutations("vault", [
        "SET_SELECTED_FILES",
        "SET_SELECTED_FOLDERS",
        "SET_LAST_SELECTED",
        "DRAG_SELECT",
        "TOGGLE_SELECTION"
      ])
    }
  };
</script>

<style></style>

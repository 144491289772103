<template>
  <div class="column is-6">
    <div class="header issue-status-header">
      <h2 class="is-semiBold-18-600-22 pb-15">
        {{ localization("app-tickets-progress", "Tickets Progress") }}
      </h2>
    </div>

    <div class="box card-height-500">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{ localization("app-project", "Project") }}</th>
            <th>{{ localization("app-progress", "Progress") }}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr v-for="skeleton in 10" :key="skeleton">
            <td><b-skeleton width="100%"></b-skeleton></td>
            <td><b-skeleton width="100%"></b-skeleton></td>
            <td><b-skeleton width="100%"></b-skeleton></td>
            <td><b-skeleton width="100%"></b-skeleton></td>
          </tr>
        </tbody>
        <tbody class="is-medium-16-500-50" v-else>
          <tr v-for="(project, i) of data" :key="i">
            <td>
              {{
                projects_dict[project.x]
                  ? projects_dict[project.x].name
                  : project.x
              }}
            </td>
            <td
              colspan="100"
              class="has-text-centered"
              style="padding-top: 20px"
            >
              <ticket-multiple-progress
                :values="project.y"
              ></ticket-multiple-progress>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import TicketMultipleProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-multiple-progress";

  export default {
    props: ["details"],
    components: {
      TicketMultipleProgress
    },
    data() {
      return {
        data: [],
        projects_dict: {},
        isLoading: false
      };
    },
    async created() {
      this.isLoading = true;

      let projects = [];
      if (this.details && this.details.groups) {
        for (let group of this.details.groups) {
          for (let project of group.projects) {
            this.projects_dict[project.uid] = project;
            projects.push(project.uid);
          }
        }
      }

      this.data = await ticketService.get_report({
        body: {
          filters: {
            organization: this.$store.state.current_organization.uid,
            assetUid: [],
            properties: btoa(`projectUid=${projects.join(",")}`)
          },
          x: { key: "properties.projectUid" },
          y: { key: "status" }
        }
      });
      this.isLoading = false;
    }
  };
</script>

<style>
  .issue-status-header {
    height: 36px !important;
  }
  .card-height-500 {
    min-height: 503px;
    max-height: 503px;
    overflow-y: auto;
  }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.users && _vm.users.length)?_c('div',{staticClass:"is-flex align-center"},[_vm._l((_vm.users.slice(0, _vm.tagsCountToShow)),function(user,i){return _c('div',{key:i},[_c('div',{on:{"click":function($event){_vm.no_access
          ? _vm.blur
            ? _vm.$emit('remove_user', user)
            : _vm.$emit('handle_click')
          : _vm.$router.push({ name: 'user-details', params: { id: user.uid } })}}},[_c('user-avatar',{staticClass:"mr-3",attrs:{"blur":_vm.blur,"user":user,"custom_class":_vm.custom_class,"show_tooltip":true,"pxsize":_vm.custom_size,"not_state":_vm.not_state}}),(_vm.show_presence)?_c('div',{staticClass:"user-tag-status"}):_vm._e()],1)])}),(_vm.users.length > _vm.tagsCountToShow)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.remaining_users,
      html: false,
      trigger: _vm.isMobile ? 'click' : 'hover'
    }),expression:"{\n      content: remaining_users,\n      html: false,\n      trigger: isMobile ? 'click' : 'hover'\n    }"}],staticClass:"tiny-user-avatar--text",class:`${_vm.custom_class} is-${_vm.custom_size}x${_vm.custom_size}`},[_vm._v(" +"+_vm._s(_vm.users.length - _vm.tagsCountToShow)+" ")]):_vm._e()],2):(!_vm.is_precense_component)?_c('not-assigned',{staticClass:"mt-10",attrs:{"type":'user'}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
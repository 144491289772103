var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(
        _vm.access('core', 'can_upload_images') &&
          _vm.project_details &&
          !_vm.project_details.properties.upload_completed
      )?_c('p',{staticClass:"image-block--upload is-pointer",on:{"click":function($event){_vm.popup_active = true}}},[_c('sh-icon',{staticClass:"is-24x24 pt-5",attrs:{"name":'upload',"file":'helper_sprite'}}),_c('span',{staticClass:"is-medium-12-500-14 has-opacity-8"},[_vm._v(_vm._s(_vm.localization("app-upload", "Upload")))])],1):_c('p',{staticClass:"disabled-link is-not-allowed image-block--upload is-pointer",on:{"click":function($event){return _vm.no_access_popup('core', 'can_upload_images')}}},[_c('sh-icon',{staticClass:"is-24x24 pt-5",attrs:{"name":'upload',"file":'helper_sprite'}}),_c('span',{staticClass:"is-medium-12-500-14 has-opacity-8"},[_vm._v(_vm._s(_vm.localization("app-upload", "Upload")))])],1)]),_c('b-modal',{attrs:{"active":_vm.popup_active,"width":640,"can-cancel":_vm.uploading ? [] : ['escape', 'x', 'outside'],"scroll":"clip"},on:{"close":function($event){_vm.popup_active = false}}},[_c('div',{staticClass:"card has-slimscroll-xs",staticStyle:{"max-height":"100vh","overflow":"scroll"}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title is-uppercase padding-20"},[_vm._v(" "+_vm._s(_vm.localization("app-upload-images", "Upload Images"))+" ")]),_c('a',{staticClass:"card-header-icon",attrs:{"aria-label":"more options"},on:{"click":function($event){!_vm.uploading ? (_vm.popup_active = false) : (_vm.popup_active = true)}}},[_c('sh-icon',{staticClass:"is-16x16 mr-20",attrs:{"name":'close',"file":'helper_sprite'}})],1)]),_c('div',{staticClass:"card-content has-padding-x-huge"},[_c('div',{staticClass:"form-content"},[_c('report-form',{attrs:{"project_report":_vm.project_report},on:{"input":function($event){_vm.form = $event;
              _vm.uppy1 = false;
              _vm.open_popup();}}})],1),_c('br'),_c('div',{staticStyle:{"height":"300px"}},[(_vm.uppy1)?_c('div',{staticClass:"DashboardContainer",attrs:{"id":"RawImagesUploadDashboard"}}):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
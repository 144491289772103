<template>
  <div
    class="sh-list-header bb-1 has-text-theme-grey has-background-white columns is-gapless is-regular-14-00-17 "
  >
    <div class="column is-4">{{ localization("app-users", "Users") }}</div>
    <div class="column is-3">{{ localization("app-teams", "Teams") }}</div>
    <div class="column is-2">{{ localization("app-role", "Role") }}</div>
    <div
      class="column is-flex is-center"
      :class="$route.name === 'admin-user-list' ? 'is-2' : 'is-3'"
    >
      <span>{{ localization("app-status", "Status") }}</span>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"},attrs:{"id":"ebs-modal"}},[_c('div',{class:{ 'is-vhidden': !_vm.is_active || !_vm.element },staticStyle:{"width":"36rem","min-height":"400px","right":"1rem","top":"1rem","position":"absolute"}},[_c('div',{staticClass:"is-relative"},[_c('div',{staticClass:"ebs-quick-list"},[(!_vm.info_tab.active)?_c('div',{staticClass:"px-10 pt-10 is-flex is-vcenter has-space-between"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.active = false)),expression:"() => (active = false)"}],staticClass:"is-sh-dropdown"},[_c('div',{staticClass:"is-pointer",on:{"click":function($event){_vm.active = !_vm.active}}},[_c('div',{staticClass:"is-selector",class:[_vm.active ? 'is-open is-11-rem' : 'is-close ']},[_c('p',{staticClass:"is-flex align-center"},[_c('span',{staticClass:"is-semiBold-16-600-19 is-flex is-vcenter is-capitalized"},[_vm._v(_vm._s(_vm.active_view))]),_c('sh-icon',{staticClass:"is-16x16 ml-10",attrs:{"name":!_vm.active ? 'collapse' : 'expand'}})],1)])]),(_vm.active)?_c('ul',{staticClass:"is-options-list is-11-rem"},_vm._l((_vm.$store.getters.forms
                  ? ['tickets', 'vault', 'forms']
                  : ['tickets', 'vault']),function(option){return _c('li',{key:option,staticClass:"item is-flex is-between pl-17",on:{"click":function($event){_vm.active_view = option;
                  _vm.active = false;}}},[_c('p',{staticClass:"item--text is-capitalized is-flex align-center"},[_c('span',{staticClass:"is-capitalised"},[_vm._v(_vm._s(option))]),(_vm.active_view == option)?_c('sh-icon',{staticClass:"is-12x12 ml-10",attrs:{"name":'checkmark'}}):_vm._e()],1)])}),0):_vm._e()])]):_vm._e(),_c('div',{staticClass:"is-pointer is-absolute mr-20",staticStyle:{"top":"1.5rem","right":"1rem"},attrs:{"slot":"top-right"},slot:"top-right"},[_c('p',{on:{"click":function($event){_vm.is_active = null;
              _vm.active_view = '';
              _vm.$store.commit('vault/SET_INFO_TAB', { active: false });
              _vm.$modal.hide('ebs-modal');}}},[_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}})],1)]),(_vm.active_view === 'tickets')?_c('div',{staticClass:"ebs-quick-list__items has-slimscroll"},[(_vm.element)?_c('tickets',{attrs:{"customclass":'is-relative',"is_vertical_window":true,"element":_vm.element}}):_vm._e()],1):(_vm.active_view === 'forms')?_c('div',{staticClass:"ebs-quick-list__items has-slimscroll",staticStyle:{"min-height":"50rem"}},[(_vm.element)?_c('form-dashboard',{attrs:{"element":_vm.element,"isShare":true,"is_vertical_window":true}}):_vm._e()],1):(_vm.active_view === 'vault')?_c('div',{staticClass:"ebs-quick-list__items has-slimscroll"},[(_vm.element)?_c('vault',{attrs:{"is_viewer_small":true,"element":_vm.element,"folder":_vm.element && _vm.element.is_folder_details ? _vm.element.folder : null}}):_vm._e()],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_SYSTEM_MODEL_API_HOST

const URL = 'fieldvalues'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }

  bulk_update_field_values(req = {}) {
    return httpClient({
      url: `${this.url}/create-update/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
}

export const customFieldValuesService = new API()

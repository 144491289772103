import { formsService } from "@/app/old/desktop/shared/services";

const forms = {
  strict: true,
  namespaced: true,
  state: {
    element: null,
    selected_asset: null,
    selected_assets: [],
    forms: {
      shared: [],
      published: [],
      draft: []
    },
    sharedForms: [],
    lastAccessForms: "published",
    lastAccessSubmission: "share",
    lastAccessInTerra: "share",
    field_value_ID: {},
    selectedForm: {},
    ticketForms: [],
    ticketPayload: {},
    submissions: [],
    submissionForm: null,
    field_values: {},
    section_template: [],
    field_template: [],
    form_id: 0,
    selectedIndex: 0,
    formLoading: false,
    sharedQuery: "",
    metrics: { forms: {}, submission: {} },
    fields: [
      {
        name: "Text Field",
        type: "short_text",
        dataType: "short_text",
        component: "text-field",
        filter: { type: "string" },
        config: { name: "text" }
      },
      {
        name: "Rich Text Field",
        type: "long_text",
        dataType: "long_text",
        component: "rich-text-field",
        filter: {
          type: "string",
          input: "textarea",
          operators: [
            "equal",
            "not_equal",
            "in",
            "not_in",
            "is_null",
            "is_not_null"
          ]
        },
        config: { name: "Long text" }
      },
      {
        name: "Checkbox",
        type: "checkbox",
        dataType: "label",
        component: "checkbox-field",
        filter: {
          type: "string",
          multiple: true,
          input: "checkbox",
          hasConfig: true,
          operators: [
            "equal",
            "not_equal",
            "in",
            "not_in",
            "is_null",
            "is_not_null"
          ]
        },
        config: [{ name: "Option-1" }]
      },
      {
        name: "Dropdown",
        type: "dropdown",
        dataType: "dropdown",
        component: "dropdown-field",
        filter: {
          type: "string",
          input: "select",
          hasConfig: true,
          operators: [
            "equal",
            "not_equal",
            "in",
            "not_in",
            "is_null",
            "is_not_null"
          ]
        },
        config: [{ name: "Option-1" }]
      },
      {
        name: "Radio",
        type: "radio",
        dataType: "dropdown",
        filter: { type: "string", input: "radio", hasConfig: true },
        component: "radio-field",
        config: [{ name: "Option-1" }]
      },
      {
        name: "Number",
        type: "number",
        dataType: "number",
        filter: { type: "integer" },
        component: "number-field",
        config: { name: "number" }
      },
      {
        name: "Date & Time",
        type: "date_time",
        dataType: "date_time",
        filter: {
          type: "datetime",
          plugin: "datetimepicker",
          plugin_config: {
            format: "YYYY-MM-DDTHH:mm:00.000[Z]" //2021-04-30T03:57:00.000Z
          }
        },
        component: "date-time-field",
        config: { name: "date time" }
      },
      {
        name: "Email",
        type: "email",
        dataType: "email",
        component: "email-field",
        filter: { type: "string" },
        config: { name: "email" }
      },
      {
        name: "Money",
        type: "money",
        dataType: "decimal",
        filter: { type: "integer" },
        component: "money-field",
        config: { name: "money" }
      },
      {
        name: "Phone number",
        type: "phone",
        dataType: "phone_number",
        filter: { type: "string" },
        component: "phone-field",
        config: { name: "phone" }
      },
      {
        name: "Website",
        type: "url",
        dataType: "url",
        component: "website-field",
        filter: { type: "string" },
        config: { name: "website" }
      },
      {
        name: "File Upload",
        type: "attachment",
        dataType: "attachment",
        component: "upload-field",
        config: {
          type: ["Video", "Document", "Spreadsheet", "Image", "Audio"],
          custom: "all",
          option: "10"
        }
      }
    ]
  },
  getters: {
    selectedForm(state) {
      return state.selectedForm;
    },
    fieldType(state) {
      return state.fields.reduce((acc, cur) => {
        acc[cur.type] = cur;
        return acc;
      }, {});
    }
  },
  mutations: {
    SET_SELECTED_ASSET(state, val) {
      state.selected_asset = val;
    },
    SET_SELECTED_ASSETS(state, val) {
      state.selected_assets = [...(val || [])];
    },
    RESET_FORMS(state) {
      state.element = null;
      state.forms = {
        shared: [],
        published: [],
        draft: []
      };
      state.field_value_ID = {};
      state.selectedForm = {};
      state.submissions = [];
      state.sharedForms = [];
      state.submissionForm = null;
      state.field_values = {};
      state.section_template = [];
      state.field_template = [];
      state.form_id = 0;
      state.selectedIndex = 0;
      state.formLoading = false;
      state.metrics = { forms: {}, submission: {} };
    },
    SET_ELEMENT(state, value) {
      if (value) {
        state.element = { ...value };
      } else {
        state.element = null;
      }
    },
    SET_LAST_ACCESS_FORM(state, value) {
      state.lastAccessForms = value;
    },
    SET_LAST_ACCESS_SUBMISSION(state, value) {
      state.lastAccessSubmission = value;
    },
    SET_LAST_IN_TERRA(state, value) {
      state.lastAccessInTerra = value;
    },

    SET_FORMID(state, value) {
      state.form_id = value;
    },
    SET_FORMS(state, value) {
      state.forms = value;
    },
    SET_TICKET_FORMS(state, value) {
      state.ticketForms = value;
    },
    SET_FORM(state, value) {
      state.selectedForm = value;
    },
    SET_FIELD_VALUES(state, value) {
      state.field_values = value;
    },
    SET_FIELD_VALUE_ID(state, value) {
      state.field_value_ID = value;
    },
    SET_SECTION_TEMPLATE(state, value = []) {
      state.section_template = value ? value : [];
    },
    SET_FIELD_TEMPLATE(state, value = []) {
      state.field_template = value ? value : [];
    },
    SET_SUBMISSIONS(state, value = []) {
      state.submissions = value;
    },
    SET_SHARED_FORMS(state, value = []) {
      state.sharedForms = value;
    },
    SET_LOADING(state, value = []) {
      state.formLoading = value;
    },
    SET_SHARED_FORMS_QUERY(state, value) {
      state.sharedQuery = value;
    },
    RESET_STORE(state) {
      state.forms = [];
    },
    SET_METRICS(state, val) {
      state.metrics = val;
    }
  },

  actions: {
    async get_ticket_forms({ commit, state }, payload) {
      if (payload) state.ticketPayload = payload;
      let { data } = await formsService.forms.get_filterForms(
        payload ? payload : state.ticketPayload
      );
      commit("SET_TICKET_FORMS", data);
      return data;
    },
    async get_submissions({ commit, state }, payload) {
      let data = await formsService.submissions.getAll(payload);
      commit("SET_SUBMISSIONS", data && data.data);
      return data;
    },
    async getall_submissions({ commit, state }, payload) {
      let res = await formsService.submissions.getAllSubmissions(payload);
      let data = res.data;
      commit("SET_SUBMISSIONS", data && data.data);
      // return data;
      return res;
    },
    async filter_submissions({ commit, state }, payload) {
      let data = await formsService.submissions.filter(payload);
      commit("SET_SUBMISSIONS", data && data.data);
      return data;
    },
    async get_shared_forms({ commit, state }) {
      let res = await formsService.forms.getAll({
        query: state.sharedQuery
      });
      let data = res.data;
      commit("SET_SHARED_FORMS", data && data.data);
      return res;
    },
    async get_submission({ commit }, payload) {
      let data = await formsService.submissions.getAll(payload);
      return data;
    },
    async update_submission({ state, dispatch }, payload) {
      await formsService.submissions.update(payload);
      dispatch("get_submissions");
    },
    async get_field_values({ commit }, payload) {
      let fv = await formsService.fieldValues.getAll(payload);
      let fvMap = {};
      let fvIDmap = {};
      if (fv && fv.data) {
        fv.data.forEach(d => {
          if (d.field && d.field.multiValue) {
            if (fvMap[d.field.uid]) {
              fvMap[d.field.uid].push(d.value);
              fvIDmap[d.field.uid].push(d.uid);
            } else {
              fvMap[d.field.uid] = [d.value];
              fvIDmap[d.field.uid] = [d.uid];
            }
          } else if (d.field) {
            fvMap[d.field.uid] = d.value;
            fvIDmap[d.field.uid] = d.uid;
          }
        });
      }
      commit("SET_FIELD_VALUES", fvMap);
      commit("SET_FIELD_VALUE_ID", fvIDmap);
      return fv && fv.data && fv.data;
    },
    async add_form({ state, dispatch }, payload) {
      let form = await formsService.forms.post(payload);
      return form;
    },
    async update_form({ state, dispatch }, payload) {
      await formsService.forms.update(payload);
      dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async get_forms({ commit }, payload) {
      commit("SET_LOADING", true);
      let data = await formsService.forms.get(payload);
      try {
        commit("SET_FORM", data && data.data);
        // if (data) {
        commit("SET_LOADING", false);
        // }
      } catch (error) {
        commit("SET_LOADING", false);
      }
    },
    async get_all_forms({ commit }, payload) {
      let data = await formsService.forms.getAll(payload);
      // commit("SET_FORMS", data && data.data && data.data.data); TODO Remoeve
    },
    async delete_form({ state, dispatch }, payload) {
      await formsService.forms.delete(payload);
    },
    async delete_section({ state, dispatch }, payload) {
      await formsService.sections.delete(payload);
      dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async add_section({ state, dispatch }, payload) {
      await formsService.sections.post(payload);
      dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async update_section({ state, dispatch }, payload) {
      await formsService.sections
        .update(payload)
        .catch(e => dispatch("get_forms", { id: state.selectedForm.uid }));
    },
    async get_section_template({ commit }) {
      commit("SET_LOADING", true);
      let data = await formsService.sections.getAll({
        query: "isTemplate=true"
      });
      commit("SET_SECTION_TEMPLATE", data && data.data);
      commit("SET_LOADING", false);
    },
    async add_section_template({ dispatch }, payload) {
      let template = await formsService.sections.saveTemplate(payload);
      await dispatch("get_section_template");
      return template;
    },
    async load_section_template({ state, dispatch }, payload) {
      await formsService.sections.loadTemplate(payload);
      await dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async add_field({ state, dispatch }, payload) {
      await formsService.fields.post(payload);
      dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async update_field({ state, dispatch }, payload) {
      await formsService.fields
        .update(payload)
        .catch(e => dispatch("get_forms", { id: state.selectedForm.uid }));
    },
    async delete_field({ state, dispatch }, payload) {
      await formsService.fields.delete(payload);
      dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async get_field_template({ commit }) {
      let data = await formsService.fields.getAll({ query: "isTemplate=true" });
      commit("SET_FIELD_TEMPLATE", data && data.data);
    },
    async add_field_template({ dispatch }, payload) {
      await formsService.fields.saveTemplate(payload);
      await dispatch("get_field_template");
    },
    async load_field_template({ state, dispatch }, payload) {
      await formsService.fields.loadTemplate(payload);
      dispatch("get_forms", { id: state.selectedForm.uid });
    },
    async get_metrics({ state, commit }) {
      let { data } = await formsService.forms.get_metrcis({
        query: state.selected_asset ? `assetUid=${state.selected_asset}` : ""
      });

      commit("SET_METRICS", data);
    }
  }
};

export default forms;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('skeleton-loading',_vm._l((3),function(item){return _c('row',{key:item,attrs:{"gutter":{ top: '40px' }}},[_c('column',{attrs:{"span":12}},[_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '10px',
            width: '30%',
            height: '20px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '10px',
            width: '75%',
            height: '15px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '10px',
            width: '75%',
            height: '15px'
          }}})],1),_c('column',{attrs:{"span":12,"gutter":10}},[_c('row',{attrs:{"gutter":{ top: '15px' }}},[_c('column',{attrs:{"span":4}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":20,"gutter":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
                top: '5px',
                width: '70px',
                height: '10px'
              }}}),_c('square-skeleton',{attrs:{"boxProperties":{
                width: '100%',
                height: '10px',
                top: '10px'
              }}})],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
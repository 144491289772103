var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'activity-block': !_vm.mobile_view,
    'activity-block__mobile-view': _vm.mobile_view
  }},[_c('div',{staticClass:"is-flex has-space-between align-center",class:{ 'mobile-view has-slimscroll-xs': _vm.mobile_view }},[_c('div',{staticClass:"is-flex align-center",style:(`flex-wrap: ${_vm.mobile_view ? 'wrap' : 'nowrap'}`)},[_c('user-avatar',{attrs:{"user":{ ..._vm.activity.actor.data, uid: _vm.activity.actor.id },"pxsize":24,"custom_class":'tiny-user-avatar'}}),(_vm.activity.actor.id === _vm.$store.state.claims.user_id)?_c('span',{staticClass:"is-medium-14-500-17 mx-5"},[_vm._v(" You ")]):_c('span',{directives:[{name:"username",rawName:"v-username",value:(
          _vm.$store.getters.state_users[_vm.activity.actor.id]
            ? _vm.$store.getters.state_users[_vm.activity.actor.id]
            : _vm.activity.actor.id
        ),expression:"\n          $store.getters.state_users[activity.actor.id]\n            ? $store.getters.state_users[activity.actor.id]\n            : activity.actor.id\n        "}],staticClass:"is-medium-14-500-17 mx-5"}),_c('span',{staticClass:"is-medium-14-500-17 has-text-grey is-inline-flex align-center"},[_vm._t("activity-description")],2)],1),(_vm.display_time && !_vm.mobile_view)?_c('p',{staticClass:"is-medium-14-500-17 has-text-grey mt-5 pl-5"},[_vm._v(" "+_vm._s(_vm.time)+" ")]):_vm._e()]),_c('div',{staticClass:"is-medium-14-500-17 mt-5 pl-5 is-inline-flex align-center",class:{ 'ml-30': !_vm.mobile_view }},[_vm._t("activity-details")],2),(_vm.display_time && _vm.mobile_view)?_c('p',{staticClass:"is-medium-14-500-17 has-text-grey mt-5 pl-5"},[_vm._v(" "+_vm._s(_vm._f("sinceDays")(_vm.time))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
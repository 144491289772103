var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-group-list-items",on:{"mouseleave":function($event){return _vm.close_dropdown(_vm.item.uid)}}},[_c('div',{staticClass:"show-project-icon",on:{"click":function($event){_vm.show_projects = !_vm.show_projects}}},[(!_vm.show_projects)?_c('sh-icon',{staticClass:"is-15x15",attrs:{"name":'group-right-arrow'}}):_c('sh-icon',{staticClass:"is-15x15",attrs:{"name":'group-right-arrow-expand'}})],1),_c('div',{staticClass:"columns is-flex align-center"},[_c('div',{staticClass:"column is-3"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'asset-details', params: { id: _vm.item.uid } }}},[_c('main-details-media',{attrs:{"info":{
            name: _vm.item.name,
            subtext: _vm.item.project_counts + ' projects',
            name_class: 'is-medium-16-500-19 has-primary-text',
            subtext_class:
              'is-medium-14-500-17 has-primary-text has-opacity-7',
            properties: _vm.item.properties
          },"hideImage":false}})],1)],1),_c('div',{staticClass:"column is-3"},[_c('primary-tag',{attrs:{"tags":_vm.item.asset ? [_vm.item.asset] : null,"type":'asset',"noTopMargin":true,"truncate_length":5}})],1),_c('div',{staticClass:"column is-3"},[_c('primary-tag',{attrs:{"tags":_vm.item.container ? [_vm.item.container] : null,"type":'container',"noTopMargin":true,"truncate_length":5}})],1),_c('div',{staticClass:"column is-flex align-center is-between is-3"},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"mr-10 is-flex is-vcenter"},[_c('span',{staticClass:"sh-access-tag",class:{
              'sh-access-tag--read':
                _vm.item.access === 'read' || _vm.item.access === 'write'
            }},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"ml-5 is-regular-12-00-14 has-text-theme-black has-opacity-7"},[_vm._v(_vm._s(_vm.localization("app-read", "Read")))])]),_c('div',{staticClass:"is-flex is-vcenter"},[_c('span',{staticClass:"sh-access-tag",class:{ 'sh-access-tag--write': _vm.item.access === 'write' }},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"ml-5 is-regular-12-00-14 has-text-theme-black has-opacity-7"},[_vm._v(_vm._s(_vm.localization("app-write", "Write")))])])]),(!_vm.$store.getters.is_org_user)?_c('div',{staticClass:"user--grouplist--drop"},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-remove-group', 'Remove group'),
            html: false
          }),expression:"{\n            content: localization('app-remove-group', 'Remove group'),\n            html: false\n          }"}],staticClass:"is-16x16 mr-10 is-pointer",attrs:{"name":'close',"file":'helper_sprite'},nativeOn:{"click":function($event){return _vm.drop_group(_vm.item)}}})],1):_vm._e()])]),_c('keep-alive',[(_vm.show_projects)?_c('group-project-list',{attrs:{"group_id":_vm.item.uid}}):_vm._e()],1),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.item.uid,"resourceName":_vm.item.name,"resourceType":'group',"resourceService":_vm.groupService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){return _vm.$emit('refresh-group-list')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details--section"},[(_vm.details)?_c('div',{staticClass:"pin"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.details.first_name
          ? _vm.details.first_name + '' + _vm.details.last_name
          : _vm.details.email,
        html: false
      }),expression:"{\n        content: details.first_name\n          ? details.first_name + '' + details.last_name\n          : details.email,\n        html: false\n      }"}],staticClass:"pin-text is-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'user-list' })}}},[_c('i',{staticClass:"fas fa-chevron-left mr-10"}),_vm._v(" "+_vm._s(_vm.details.first_name ? _vm.details.first_name + " " + _vm.details.last_name : _vm.details.email)+" ")])]):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[(_vm.is_loading)?_c('details-skeleton'):_c('details-sidecard',[_c('template',{slot:"header"},[_c('div',{staticClass:"is-block"},[(_vm.details.display_picture || _vm.details.profile_picture)?_c('img',{staticClass:"image is-rounded is-48x48",attrs:{"src":_vm.details.display_picture || _vm.details.profile_picture}}):_c('div',{staticClass:"is-icon is-30x30 user-style",style:(`background-color: ${_vm.hashed_color(
                  _vm.details.first_name
                    ? _vm.details.first_name + ' ' + _vm.details.last_name
                    : _vm.details.email,
                  'userManagement'
                )};`)},[_vm._v(" "+_vm._s(_vm.details && _vm.details.first_name ? _vm.details.first_name.charAt(0) : _vm.details.email.charAt(0))+" ")]),(
                _vm.$store.state.online_users &&
                  _vm.$store.state.online_users
                    .map(el => el.id)
                    .includes(_vm.details.uid)
              )?_c('div',{staticClass:"online-status"}):_vm._e()]),(
              !_vm.$store.getters.is_org_user &&
                (_vm.$route.name === 'user-details' ||
                  _vm.$route.name === 'user-details-containers' ||
                  _vm.$route.name === 'user-details-groups') &&
                _vm.$store.state.claims.app_permissions.core.includes(
                  'modify_users'
                )
            )?_c('user-dropdown',{attrs:{"user":_vm.details}}):_vm._e()],1),_c('template',{slot:"main"},[_c('div',{staticClass:"is-flex is-vcenter"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.details.first_name
                  ? _vm.details.first_name + '' + _vm.details.last_name
                  : _vm.details.email,
                html: false
              }),expression:"{\n                content: details.first_name\n                  ? details.first_name + '' + details.last_name\n                  : details.email,\n                html: false\n              }"}],staticClass:"is-semiBold-16-600-19 name-highlights-on-hover mr-10"},[_vm._v(" "+_vm._s(_vm._f("truncate")((_vm.details.first_name ? _vm.details.first_name + " " + _vm.details.last_name : _vm.details.email ? _vm.details.email : "Unknown"),20))+" ")]),_c('div',{staticClass:"badge-container"},[(_vm.details.is_owner)?_c('div',{staticClass:"badge is-owner"},[_vm._v(" "+_vm._s(_vm.localization("app-owner", "Owner"))+" ")]):(_vm.details.is_manager)?_c('div',{staticClass:"badge is-manager"},[_vm._v(" "+_vm._s(_vm.localization("app-manager", "Manager"))+" ")]):(_vm.details.is_guest)?_c('div',{staticClass:"badge is-guest"},[_vm._v(" "+_vm._s(_vm.localization("app-guest", "Guest"))+" ")]):_vm._e()])]),_c('div',{staticClass:"is-medium-14-500-17 has-opacity-7 pb-30"},[_vm._v(" "+_vm._s(_vm.details.role || "")+" ")]),_c('div',{staticClass:"details-card__content"},[_c('div',{staticClass:"details-card__content-heading"},[_vm._v(" "+_vm._s(_vm.localization("app-last-seen", "Last Seen"))+": ")]),_c('p',{staticClass:"details-card__content-description is-uppercase"},[_vm._v(" "+_vm._s(_vm._f("prettyDate")(_vm.details.updated_at))+" ")])]),_c('div',{staticClass:"details-card__content"},[_c('div',{staticClass:"details-card__content-heading"},[_vm._v(" "+_vm._s(_vm.localization("app-added-on", "Added on"))+": ")]),_c('p',{staticClass:"details-card__content-description is-uppercase"},[_vm._v(" "+_vm._s(_vm._f("prettyDate")(_vm.details.created_at))+" ")])]),_c('div',{staticClass:"details-card__content"},[_c('div',{staticClass:"details-card__content-heading mb-5"},[_vm._v(" "+_vm._s(_vm.localization("app-status", "Status"))+" ")]),_c('div',{staticClass:"user-details__body__main__entry__status sh-user-status-tag ml-5",class:[
                _vm.details.status === 'active'
                  ? 'sh-user-status-tag--active'
                  : 'sh-user-status-tag--inactive'
              ]},[_vm._v(" "+_vm._s(_vm.details.status)+" ")])]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"details-card__content"},[_c('div',{staticClass:"details-card__content-heading"},[_vm._v(" "+_vm._s(_vm.localization("app-email-id", "Email ID"))+" ")]),_c('p',{staticClass:"details-card__content-description"},[_vm._v(" "+_vm._s(_vm.details.email)+" ")])]),_c('div',{staticClass:"details-card__content"},[_c('div',{staticClass:"details-card__content-heading"},[_vm._v(" "+_vm._s(_vm.localization("app-phone-number", "Phone number"))+" ")]),_c('p',{staticClass:"details-card__content-description"},[_vm._v(" "+_vm._s(_vm.details.phone_number || _vm.localization("app-not-found", "*Not found"))+" ")])]),_c('div',{staticClass:"details-card__content"},[_c('div',{staticClass:"details-card__content-heading"},[_vm._v(" "+_vm._s(_vm.localization("app-teams", "Teams"))+" "),(_vm.access('core', 'modify_teams'))?_c('div',{staticClass:"details-card__content__plus is-pointer",on:{"click":function($event){_vm.add_teams({
                    teams: _vm.details.labels,
                    service: _vm.userService,
                    item_uid: _vm.details.uid
                  });
                  _vm.getDetails();}}},[_vm._v(" + ")]):_c('div',{staticClass:"role-details__body__users__heading__icon is-not-allowed disabled-link",on:{"click":function($event){return _vm.no_access_popup('core', 'modify_teams')}}},[_vm._v(" + ")])]),_c('p',{staticClass:"details-card__content-description"},[_c('primary-tag',{attrs:{"tags":_vm.details.labels || [],"tagsCountToShow":3,"type":'team'}})],1)])])],2)],1),_c('div',{staticClass:"column is-9"},[(_vm.details)?_c('div',{staticClass:"sh-tabs"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"exact-active-class":"is-active","to":{ name: 'user-details' }}},[_vm._v(" "+_vm._s(_vm.localization("app-assets", "Assets"))+" "),_c('span',[_vm._v(_vm._s(_vm.details.assets.length || 0))])])],1),_c('li',[_c('router-link',{attrs:{"exact-active-class":"is-active","to":{ name: 'user-details-groups' }}},[_vm._v(" "+_vm._s(_vm.localization("app-groups", "Groups"))+" "),_c('span',[_vm._v(_vm._s(_vm.details.groups.length || 0))])])],1),_c('li',[_c('router-link',{attrs:{"exact-active-class":"is-active","to":{ name: 'user-details-containers' }}},[_vm._v(" "+_vm._s(_vm.localization("app-containers", "Containers"))+" "),_c('span',[_vm._v(_vm._s(_vm.details.containers.length || 0))])])],1),_c('li',{staticClass:"sh-tabs__right"},[_c('router-link',{attrs:{"exact-active-class":"is-active","to":{
                name: 'user-permissions',
                params: { type: _vm.details.is_guest ? 'guest' : 'user' }
              }}},[_vm._v(_vm._s(_vm.localization("app-permissions", "Permissions")))])],1)])]):_vm._e(),_c('router-view',{staticClass:"router-asset-details",attrs:{"details":_vm.details,"tag":"div"},on:{"updateDetails":function($event){return _vm.getDetails()}}})],1)]),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.details.uid,"resourceName":_vm.details ? _vm.details.name : '',"resourceType":'user',"resourceService":_vm.userService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){_vm.show_success('Resource successfully deleted');
      _vm.$router.push({ name: 'asset-list' });
      _vm.getData();}}}):_vm._e(),(_vm.showPinModal)?_c('pin-modal',{attrs:{"resourceName":_vm.details ? _vm.details.name : '',"resourceType":'asset'},on:{"closed":function($event){_vm.showPinModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div></div>
</template>

<script>
  export default {
    name: "WebViewer",
    props: {
      path: {
        default: `${process.env.BASE_URL}webviewer`,
        type: String
      },
      name: String,
      url: String,
      icon: String,
      bookmark: {
        default: false,
        type: Boolean
      },
      targetElement: Object,
      uid: String,
      hasNavigation: {
        type: Boolean,
        default: false
      },
      navigate: {
        type: Function
      },
      hasNavIcon: {
        type: Object,
        default: { left: false, right: false }
      },
      info: {
        type: Object
      }
    },
    mounted() {
      this.$eventHub.$emit("show-webviewer", this.$props);
      this.$emit("close");
    }
  };
</script>

import { httpClient } from "@/app/old/desktop/shared/services/";
const API_HOST = process.env.VUE_APP_CORE_API_HOST;

class API {
  constructor() {
    this.baseUrl = API_HOST;
  }
  async get_element_hierarchy(req = {}) {
    let hosts = {
      core: process.env.VUE_APP_CORE_API_HOST,
      therm: process.env.VUE_APP_THERM_API_HOST,
      terra: process.env.VUE_APP_TERRA_API_HOST,
      vault: process.env.VUE_APP_VAULT_API_HOST,
      forms: process.env.VUE_APP_VAULT_API_HOST,
      system_model: process.env.VUE_APP_SYSTEM_MODEL_API_HOST
    };
    if (!req.stage || !hosts[req.stage.toLocaleLowerCase()])
      return Promise.reject(new Error("Invalid stage"));

    return httpClient({
      url: `/element/${req.id}/hierarchy/`,
      baseURL: hosts[req.stage.toLocaleLowerCase()],
      headers: req.headers
    }).then(res => res.data);
  }

  async get_strategix4_data(req = {}) {
    return httpClient({
      url: `https://sensehawk-api.strategix4.com/api/streams/getstream/${
        req.query ? `?${req.query}` : ""
      }`,
      data: req.body,
      method: "POST"
    }).then(res => res.data);
  }
  async get_strategix4_token(req = {}) {
    return httpClient({
      url: `external/strategix4/auth/`,
      data: req.body,
      method: "POST",
      baseURL: process.env.VUE_APP_CORE_API_HOST
    }).then(res => res.data);
  }

  get_all_instances(req = {}) {
    let hosts = {
      core: process.env.VUE_APP_CORE_API_HOST,
      therm: process.env.VUE_APP_THERM_API_HOST,
      terra: process.env.VUE_APP_TERRA_API_HOST,
      vault: process.env.VUE_APP_VAULT_API_HOST,
      forms: process.env.VUE_APP_VAULT_API_HOST,
      system_model: process.env.VUE_APP_SYSTEM_MODEL_API_HOST
    };
    return httpClient({
      url: `/instances/asset/${req.asset_uid}`,
      method: "GET",
      baseURL: hosts[req.stage.toLocaleLowerCase()]
    });
  }
  get_reference_vectors(req = {}) {
    return httpClient({
      url: `features/asset/${req.asset_id}/reference-vectors/`,
      baseURL: process.env.VUE_APP_TERRA_API_HOST,
      method: "POST",
      data: req.body,
      headers: req.headers
    });
  }
  get_element_for_instance(req = {}) {
    let hosts = {
      core: process.env.VUE_APP_CORE_API_HOST,
      therm: process.env.VUE_APP_THERM_API_HOST,
      terra: process.env.VUE_APP_TERRA_API_HOST,
      vault: process.env.VUE_APP_VAULT_API_HOST,
      forms: process.env.VUE_APP_VAULT_API_HOST,
      system_model: process.env.VUE_APP_SYSTEM_MODEL_API_HOST
    };
    return httpClient({
      url: `/elements/instance/${req.instance_uid}`,
      method: "GET",
      baseURL: hosts[req.stage.toLocaleLowerCase()]
    });
  }
  async get(req = {}) {
    return httpClient({
      url: req.url,
      headers: req.headers
    });
  }
  async get_strategix4_token(req = {}) {
    console.log(req);
    return httpClient({
      url: `external/strategix4/auth/`,
      data: req.body,
      method: "POST",
      baseURL: this.baseURL
    }).then(res => res.data);
  }
  async getTags(req = {}) {
    return httpClient({
      url: `${API_HOST}/tags/`,
      baseURL: this.baseURL,
      headers: req.headers
    }).then(res => res.data);
  }
  async post(req = {}) {
    return httpClient({
      url: req.url,
      data: req.body,
      headers: req.headers
    });
  }

  get_lang(req = {}) {
    return httpClient({
      url: `https://sensehawk.github.io/${req.lang ||
        "en"}.json?no_org&no_token`,
      method: "GET"
    });
  }
}
export const apiService = new API();

import { BaseService } from "@/app/old/desktop/shared/services/";
const API_HOST = process.env.VUE_APP_VAULT_API_HOST;
import { httpClient } from "@/app/old/desktop/shared/services/";
import store from "@/app/old/store";
class API extends BaseService {
  constructor() {
    super(API_HOST, "folders");
  }
  folders_bulk_patch(req = {}) {
    return httpClient({
      url: `${this.url}/bulk`,
      baseURL: this.baseURL,
      method: "patch",
      data: req.body,
      headers: req.headers
    });
  }
  async bulk_delete(req = {}) {
    return httpClient({
      url: `${this.url}/delete/?no_org`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    }).then(res => res.data);
  }
  get_files_download_url(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/generate-presigned-url/${
        req.query ? `?${req.query}` : ""
      }`,
      baseURL: this.baseURL,
      headers: req.headers
    });
  }
  get_activities(req = {}) {
    if (!req.id)
      return httpClient({
        url: `stream/user-token/vault_user/${store.state.current_organization.uid}-${store.state.claims.user_id}`,
        baseURL: this.baseURL
      });
    return httpClient({
      url: `stream/user-token/folder/${req.id}`,
      baseURL: this.baseURL
    });
  }
  patch_(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}${req.attribute ? `/${req.attribute}` : ""}`,
      baseURL: this.baseURL,
      method: "post",
      data: req.body,
      headers: req.headers
    });
  }
  folder_files(req = {}) {
    return httpClient({
      url: `files/folder/${req.id}/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers
    }).then(res => res.data);
  }
  get_sub_folders(req = {}) {
    return httpClient({
      url: `folders/${req.id}/descendants/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers
    }).then(res => res.data);
  }
  create_sub_folder(req = {}) {
    return httpClient({
      url: `folders/${req.id}/create?no_org`,
      baseURL: this.baseURL,
      headers: req.headers,
      data: req.body,
      method: "post"
    }).then(res => res.data);
  }
  // Password
  async set_password(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/password/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    });
  }
  async remove_password(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/password/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "delete"
    });
  }
  // users

  // bookmark
  async toggle_bookmark(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/bookmark/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    }).then(res => res.data);
  }
  async toggle_bulk_bookmark(req = {}) {
    return httpClient({
      url: `${this.url}/bookmark/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    }).then(res => res.data);
  }
  // archive
  async toggle_archive(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/archive/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    });
  }
  async move(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/move/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    });
  }
  async toggle_bulk_archive(req = {}) {
    return httpClient({
      url: `${this.url}/archive/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    }).then(res => res.data);
  }
  // Bulk delete
  bulk_delete(req = {}) {
    return httpClient({
      url: `${this.url}/delete/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    }).then(res => res.data);
  }

  async bulk_user_add(req = {}) {
    await set_headers(req);
    HEADERS.headers = { ...HEADERS.headers, ...(req.headers || {}) };
    return httpClient({
      url: `${this.url}/users/add/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "post",
      data: req.body
    }).then(res => res.data);
  }
}
export const folderService = new API();

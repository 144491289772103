var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-list__item padding-20"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-narrow is-pointer",on:{"click":function($event){_vm.active = !_vm.active}}},[_c('i',{staticClass:"fas fa-chevron-right"})]),_c('div',{staticClass:"column",class:[_vm.$route.name == 'group-details-processing' ? 'is-2' : 'is-3']},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'processing-config', params: { id: _vm.item.uid } }}},[_c('info-block',{attrs:{"info":{
            type: 'projectManagement',

            name: _vm.item.name.split('$$')[1],
            name_class: 'is-semiBold-16-600-19 ',
            bottom_class:
              'is-medium-14-500-17 has-text-theme-grey mt-5 has-opacity-7',
            bottom: _vm.item.no_of_images + ' images'
          },"hideImage":true}})],1)],1),(_vm.$route.name != 'group-details-processing')?_c('div',{staticClass:"column is-1"},[_c('info-block',{attrs:{"info":{
          name: 'Group',
          top: 'Group',
          top_class: 'mb-5',
          type: 'projectManagement'
        },"hideImage":true}})],1):_vm._e(),_c('div',{staticClass:"column is-2"},[_c('p',{staticClass:"is-regular-14-00-17 has-text-theme-grey mb-10"},[_vm._v(" Project Type ")]),_c('p',{staticClass:"is-medium-16-500-19 has-text-theme-black is-marginless is-capitalized"},[_vm._v(" "+_vm._s(_vm.item.project_type)+" ")])]),_c('div',{staticClass:"column",class:[_vm.$route.name == 'group-details-processing' ? 'is-3' : 'is-2']},[(_vm.$store.getters.state_users[_vm.item.owner.uid])?_c('info-block',{attrs:{"info":{
          name: `${_vm.$store.getters.state_users[_vm.item.owner.uid].first_name} ${
            _vm.$store.getters.state_users[_vm.item.owner.uid].last_name
          }`,
          top: _vm.localization('app-created-by', 'Created by'),
          top_class: 'mb-5',
          name_class: 'is-medium-16-500-19 has-text-theme-black',
          image: _vm.$store.getters.state_users[_vm.item.owner.uid].display_picture,
          type: 'userManagement'
        }}}):(_vm.item.owner && _vm.item.owner.uid)?_c('info-block',{attrs:{"unknown":true,"info":{
          name: _vm.item.owner.email.includes('@sensehawk')
            ? 'Sensehawk'
            : 'Unknown',
          top: _vm.localization('app-created-by', 'Created by'),
          top_class: 'mb-5',
          name_class: 'is-medium-16-500-19 has-text-theme-black',
          type: 'userManagement'
        }}}):_vm._e()],1),_c('div',{staticClass:"column",class:[_vm.$route.name == 'group-details-processing' ? 'is-3' : 'is-2']},[_c('p',{staticClass:"is-regular-14-00-17 has-text-theme-grey mb-10"},[_vm._v(" "+_vm._s(_vm.localization("app-created-on", "Created on"))+" ")]),_c('p',{staticClass:"is-medium-16-500-19 has-text-theme-black is-marginless is-capitalized no-whitespace-wrap is-uppercase"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.item.created_at))+" ")])]),_c('div',{staticClass:"column is-1"},[_c('p',{staticClass:"is-regular-14-00-17 has-text-theme-grey mb-10 mr-5"},[_vm._v(" "+_vm._s(_vm.localization("app-status", "Status"))+" ")]),_c('p',{staticClass:"is-medium-16-500-19 has-text-theme-black processing",class:'processing--' + _vm.item.status},[_vm._v(" "+_vm._s(_vm.item && _vm.item.status)+" ")])]),_c('div',{staticClass:"column is-narrow margin-auto"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
          () => (_vm.dropdown == _vm.item.uid ? (_vm.dropdown = -1) : null)
        ),expression:"\n          () => (dropdown == item.uid ? (dropdown = -1) : null)\n        "}],staticClass:"asset-dropdown"},[_c('div',{staticClass:"asset-dropdown-selection sh-list__item--icon",on:{"click":function($event){_vm.dropdown == -1 ? (_vm.dropdown = _vm.item.uid) : (_vm.dropdown = -1)}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1),(_vm.dropdown == _vm.item.uid)?_c('div',{staticClass:"asset-dropdown-content"},[_c('router-link',{staticClass:"is-flex",attrs:{"to":{ name: 'processing-config', params: { id: _vm.item.uid } },"tag":"p"}},[_c('sh-icon',{staticClass:"is-16x16 mr-10",attrs:{"name":'expand-leftside'}}),_vm._v(" Go to Process ")],1),_c('p',{on:{"click":function($event){_vm.showDeleteModal = true}}},[_c('sh-icon',{staticClass:"is-16x16 mr-10",attrs:{"name":'trash'}}),_vm._v(" Delete Process ")],1)],1):_vm._e()])])]),(_vm.active)?_c('div',{staticClass:"details"},[_c('keep-alive',[_c('processing-list-details',{attrs:{"process":_vm.item.uid}})],1)],1):_vm._e(),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.item.uid,"resourceName":_vm.item.name,"resourceType":'processing',"resourceService":_vm.processingService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){return _vm.$emit('refresh-processing-list')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('skeleton-loading',{staticClass:"sh-skeleton"},_vm._l((6),function(item){return _c('row',{key:item,attrs:{"gutter":{ top: '15px', bottom: '10px' }}},[_c('column',{attrs:{"span":20,"gutter":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '100%',
            height: '15px'
          }}})],1),_c('column',{attrs:{"span":4,"gutter":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '100%',
            height: '15px'
          }}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
// import auth from "../auth.vue";
import login from '@/app/shared/auth-old/components/login.vue'
import handleLogin from '@/app/shared/auth-old/components/handle-auth.vue'
import signup from '@/app/shared/auth-old/components/register.vue'

const authRoutes = [
  {
    path: '/signup',
    name: 'signup',
    component: signup
  },
  {
    path: '/auth',
    name: 'auth',
    component: login
  },
  {
    path: '/auth/callback',
    name: 'handle-login',
    component: handleLogin
  }
]

export default authRoutes

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user && _vm.person)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.show_tooltip
      ? _vm.person.first_name
        ? _vm.person.first_name + ' ' + _vm.person.last_name
        : _vm.person.email
      : null,
    html: false
  }),expression:"{\n    content: show_tooltip\n      ? person.first_name\n        ? person.first_name + ' ' + person.last_name\n        : person.email\n      : null,\n    html: false\n  }"}],staticClass:"is-flex align-center",class:[_vm.blur ? 'filter_blur' : null]},[(_vm.blur)?_c('div',{staticClass:"inline_remove_user"},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),(
      (_vm.person.display_picture || _vm.person.profile_picture) && !_vm.default_to_avatar
    )?_c('div',{class:[
      [_vm.custom_class ? _vm.custom_class : 'user-style'],
      [_vm.pxsize ? `is-${_vm.pxsize}x${_vm.pxsize}` : 'is-40x40']
    ]},[_c('img',{staticClass:"image is-rounded",staticStyle:{"height":"100%","width":"100%"},attrs:{"src":_vm.person.display_picture || _vm.person.profile_picture},on:{"error":function($event){_vm.default_to_avatar = true}}})]):_c('div',{staticClass:"is-icon",class:[
      [_vm.custom_class ? _vm.custom_class : 'user-style'],
      [_vm.pxsize ? `is-${_vm.pxsize}x${_vm.pxsize}` : 'is-40x40']
    ],style:(`background-color: ${_vm.hashed_color(
        _vm.person.first_name
          ? _vm.person.first_name + ' ' + _vm.person.last_name
          : _vm.person.email,
        'userManagement'
      )};`)},[_vm._v(" "+_vm._s(_vm.person.first_name ? _vm.person.first_name.charAt(0) : _vm.person.email.charAt(0))+" ")]),(_vm.not_state)?_c('span',{staticClass:"is-medium-14-500-17 mx-5"},[_vm._v(_vm._s(_vm.$store.state.claims.user_id == _vm.person.uid ? _vm.localization("app-you", "You") : _vm.person.first_name ? _vm.person.first_name + " " + _vm.person.last_name : _vm.person.email))]):_vm._e()]):(_vm.user && !_vm.not_state && !_vm.$store.getters.state_users[_vm.user.uid])?_c('div',[_c('div',{class:[
      [_vm.custom_class ? _vm.custom_class : 'user-style'],
      [_vm.pxsize ? `is-${_vm.pxsize}x${_vm.pxsize}` : 'is-40x40']
    ]},[(
        (_vm.user.email && _vm.user.email.includes('@sensehawk')) ||
          _vm.$store.state.internal_users_uids.includes(_vm.user.uid)
      )?_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.show_tooltip ? 'SenseHawk' : null }),expression:"{ content: show_tooltip ? 'SenseHawk' : null }"}],staticClass:"is-rounded is-36x36",attrs:{"file":'users_sprite',"name":'sensehawk-created-36px'}}):_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.show_tooltip ? 'Unknown' : null }),expression:"{ content: show_tooltip ? 'Unknown' : null }"}],staticClass:"is-rounded is-36x36",attrs:{"file":'users_sprite',"name":'unknown-user-36px'}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
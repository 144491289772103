<template>
  <div v-if="ticket_id && detailed_ticket">
    <mobile-ticket-details
      :ticket="detailed_ticket"
      @exit="close_popup()"
      v-if="$route.meta.screen == 'mobile'"
    ></mobile-ticket-details>
    <div
      v-else
      v-click-outside="() => close_popup()"
      class="new-ticket-details has-slimscroll-xs"
      id="scroll"
    >
      <div class="columns padding-20">
        <div class="column is-paddingless">
          <div class="bb-1 pb-20 is-flex is-vcenter has-space-between br-1">
            <div class="is-flex has-space-between pr-10" style="width:100%">
              <div class="is-flex align-center">
                <p
                  v-tooltip="
                    detailed_ticket.name.length > 42
                      ? detailed_ticket.name
                      : null
                  "
                  v-if="edit_ticket_name == -1"
                  @click="
                    detailed_ticket.writeAccess
                      ? (edit_ticket_name = detailed_ticket.uid)
                      : null
                  "
                  class="mr-10 is-medium-18-500-22"
                  :class="[
                    !detailed_ticket.writeAccess ? 'is-not-allowed' : null
                  ]"
                >
                  {{ detailed_ticket.name | truncate(42) }}
                </p>
                <input
                  v-click-outside="
                    () => update_ticket_name(detailed_ticket.name)
                  "
                  v-if="edit_ticket_name == detailed_ticket.uid"
                  type="text"
                  name="name"
                  id="ticket"
                  class="is-inline-input is-medium-18-500-22"
                  :placeholder="detailed_ticket.name"
                  v-focus
                  style="width: 60rem; height: 29px"
                  v-model="detailed_ticket.name"
                  @keyup.esc="edit_ticket_name = -1"
                  @keyup.enter="update_ticket_name(detailed_ticket.name)"
                />
                <div
                  @click="
                    update_ticket({ bookmark: !detailed_ticket.bookmark })
                  "
                >
                  <sh-icon
                    :name="'bookmarked-highlight'"
                    class="is-18x18 mr-10 is-pointer"
                    v-if="detailed_ticket.bookmark"
                  />
                  <sh-icon
                    :name="'bookmark'"
                    class="is-18x18 mr-10 is-pointer"
                    v-else
                  />
                </div>
              </div>

              <div
                class="is-flex is-vcenter"
                v-if="currently_viewing && currently_viewing.length"
              >
                <div
                  class="has-text-theme-black is-regular-12-00-14 is-flex is-vcenter"
                >
                  {{ localization("app-viewing", "Viewing") }}
                  <sh-icon
                    :name="'info-grey'"
                    class="is-14x14 mr-10 ml-5 mt-2"
                  ></sh-icon>
                </div>
                <user-tag
                  :users="currently_viewing"
                  :tagsCountToShow="3"
                  :is_precense_component="true"
                  :custom_class="'tiny-user-avatar'"
                  :custom_size="24"
                  :no_access="true"
                ></user-tag>
              </div>
            </div>
            <div
              class="is-flex align-center has-space-around pr-20"
              v-if="
                detailed_ticket.properties &&
                  detailed_ticket.properties.issueUid
              "
            >
              <div
                v-tooltip="{
                  content: `${localization(
                    'app-previous-defect',
                    'Previous Defect'
                  )}`
                }"
                :class="{ 'is-disabled': index == 0 }"
                class="is-pointer"
                @click="previous_ticket"
              >
                <i class="fas fa-chevron-left  pr-10"></i>
              </div>
              <div
                v-tooltip="{
                  content: `${localization('app-next-defect', 'Next Defect')}`
                }"
                :class="{ 'is-disabled': index == filtered_defects.length - 1 }"
                class="is-pointer"
                @click="next_ticket"
              >
                <i class="fas fa-chevron-right  pl-10"></i>
              </div>
            </div>
          </div>

          <div class="new-ticket-details__content" v-if="detailed_ticket">
            <div>
              <div class=" br-1">
                <task-list
                  :service="apiService"
                  :channel="channel"
                  :ticket="detailed_ticket"
                  :component="'ticketDetails'"
                ></task-list>
                <attachments
                  :ticket="detailed_ticket"
                  :component="'ticketDetails'"
                  :service="apiService"
                ></attachments>

                <comments
                  v-if="ckeditorLoaded"
                  :commentsUnread="(commentsUnread = 0)"
                  :presence_members="viewingMembers"
                  :ticket="detailed_ticket"
                  :service="apiService"
                  :channelName="'ticket-' + detailed_ticket.uid"
                ></comments>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3 is-paddingless">
          <right-section
            :is_loading="is_loading"
            :ticket="detailed_ticket"
            :presence_members="currently_viewing"
            @overlay_popup="has_overlay = $event"
            @close="close_popup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DescriptionSection from "@/app/old/desktop/tickets/components/ticket-details/components/description-section.vue";
  import TaskList from "@/app/old/desktop/tickets/components/ticket-details/components/task-list/task-list-section";
  import Attachments from "@/app/old/desktop/tickets/components/ticket-details/components/attachments/attachments.vue";
  import FormsSection from "@/app/old/desktop/tickets/components/ticket-details/components/forms/forms-section.vue";
  import Comments from "@/app/old/desktop/tickets/components/ticket-details/new-ticket-details/comments/comments-section.vue";
  import ActionsDropdown from "@/app/old/desktop/tickets/shared/components/actions-dropdown";
  import MobileTicketDetails from "@/app/old/mobile/modules/tickets/components/ticket-details/mobile-ticket-details.vue";
  import TagsDropdown from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import WatcherDropdown from "@/app/old/desktop/tickets/shared/components/watchers-dropdown.vue";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import { loadScript } from "@/utils/loadScript.js";
  import { mapState, mapGetters } from "vuex";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import RightSection from "@/app/old/desktop/therm-v2/components/defect-details-popup/right-therm-details.vue";
  import BrowserBackModalClose from "@/app/old/desktop/shared/mixins/browser-back-modal-close.mixin.vue";
  export default {
    mixins: [BrowserBackModalClose],
    props: ["ticket_id"],
    data() {
      return {
        apiService: ticketService,
        has_overlay: false,
        ticket: null,
        channel: null,
        is_loading: false,
        viewingMembers: null,
        presence_members: [],
        commentsUnread: 0,
        ckeditorLoaded: false,
        edit_ticket_name: -1,
        current_id: null,
        index: -1
      };
    },
    components: {
      DescriptionSection,
      TaskList,
      Attachments,
      ActionsDropdown,
      FormsSection,
      Comments,
      MobileTicketDetails,
      RightSection,
      TagsDropdown,
      WatcherDropdown,
      UserTag
    },
    mounted() {
      loadScript(
        "https://cdn.jsdelivr.net/gh/sensehawk/cdn/ckeditor/ckeditor-classic.js",
        this.loadCkEditor,
        "ckeditor"
      );

      this.channel = this.$store.state.PUSHER.subscribe(
        `presence-tickets-ticket_${this.current_id}`
      );
      this.channel.bind("pusher:subscription_succeeded", e => {
        this.update_members();
        this.add_presence_members(e);
      });

      this.channel.bind("pusher:member_added", member => {
        this.update_members();
        this.presence_members = [...this.presence_members, member];
      });

      this.channel.bind("pusher:member_removed", member => {
        this.update_members();
        this.presence_members = _.remove(
          this.presence_members,
          presence_member => {
            return presence_member == member.id;
          }
        );
        this.presence_members = Object.assign([], this.presence_members);
      });
    },

    created() {
      if (this.ticket_id) {
        this.current_id = this.ticket_id;
        this.index = this.filtered_defects.findIndex(
          t => t.uid === this.current_id
        );
        this.get_details(true, this.ticket_id);

        this.$store.state.chat.client.on(
          "notification.message_new",
          this.chatStreamMessage
        );
      }
    },

    beforeDestroy() {
      this.$store.state.chat.client.off(
        "notification.message_new",
        this.chatStreamMessage
      );
      this.$store.state.PUSHER.unsubscribe(
        `presence-tickets-ticket_${this.ticket_id}`
      );
      this.channel = null;
      this.ticket_id = null;
    },
    computed: {
      ...mapState("tickets", ["tickets"]),
      ...mapState("thermv2", ["defects"]),
      ...mapState(["users"]),
      ...mapGetters("thermv2", ["filtered_defects", "project_defects"]),

      detailed_ticket() {
        return this.index >= 0 && this.filtered_defects[this.index]
          ? this.filtered_defects[this.index]
          : this.project_defects.find(t => t.uid == this.ticket_id)
          ? this.project_defects.find(t => t.uid == this.ticket_id)
          : {};
      },
      currently_viewing() {
        return this.viewingMembers
          ? this.viewingMembers.filter(
              user => user.uid != this.$store.state.claims.user_id
            )
          : [];
      },
      channel_members() {
        return this.channel.members;
      }
    },

    methods: {
      loadCkEditor() {
        this.ckeditorLoaded = true;
      },
      update_ticket(value) {
        this.$store.dispatch("thermv2/update_ticket_value", {
          id: this.ticket.uid,
          value: value
        });
      },

      async update_ticket_name(name) {
        this.update_ticket({ name: name });
        this.edit_ticket_name = -1;
      },

      update_members() {
        this.viewingMembers = this.channel_members
          ? Object.values(this.channel_members.members)
          : [];
      },
      chatStreamMessage(e) {
        if (e.channel_id === "ticket-" + this.ticket.uid) {
          this.commentsUnread = e.unread_count;
        }
      },
      remove_overlay() {
        this.has_overlay = false;
      },
      add_presence_members() {
        this.channel.members.each(member => {
          this.presence_members.push(member);
        });
      },
      async get_details(loading = false, id) {
        this.is_loading = loading;

        let res = await ticketService
          .get({
            id: id,
            query: "tags=true"
          })
          .catch(err => {
            console.log(err);
          });

        this.ticket = res;

        this.is_loading = false;
        if (this.ticket) {
          this.$store.commit(
            "tickets/SET_CURRENT_OPEN_TICKET_ID",
            this.ticket.uid
          );
          this.$store.commit("thermv2/ADD_TICKET_DETAILS", this.ticket);
        }
      },
      next_ticket() {
        if (!this.filtered_defects[this.index + 1]) return;
        this.index = this.index + 1;
        this.current_id = this.filtered_defects[this.index].uid;
        this.get_details(true, this.ticket_id);
      },
      previous_ticket() {
        if (!this.filtered_defects[this.index - 1]) return;
        this.index = this.index - 1;

        this.current_id = this.filtered_defects[this.index].uid;
        this.get_details(true, this.ticket_id);
      },

      close_popup() {
        if (this.$route.name == "ticket-details") {
          this.$router.go(-1);
        } else if (
          (this.$route.name == "schedules" ||
            this.$route.name == "schedule-details") &&
          !this.detailed_ticket.schedule
        ) {
          this.$toast.show(
            "Please add schedule",
            "",
            this.$store.state.izitoast_options.appWarning
          );
        } else {
          this.$emit("close");
        }
      }
    }
  };
</script>

<template>
  <div class="sh-list-header columns is-regular-14-00-17 is-marginless bb-1">
    <div class="column is-4">{{ localization("app-groups", "Groups") }}</div>
    <div class="column" v-if="$route.name !== 'asset-details-groups'">
      {{ localization("app-assets", "Assets") }}
    </div>
    <div class="column" v-if="$route.name !== 'container-details'">
      {{ localization("app-container", "Container") }}
    </div>
    <div class="column ml-20">{{ localization("app-date", "Date") }}</div>
  </div>
</template>

<script>
  export default {};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-skeleton"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"sidebar column is-3"},[_c('skeleton-loading',_vm._l((8),function(i){return _c('row',{key:i,staticClass:"sskeleton",attrs:{"gutter":{ top: '10px' }}},_vm._l((1),function(i){return _c('column',{key:i,attrs:{"span":100,"gutter":10}},[_c('square-skeleton',{attrs:{"count":1,"boxProperties":{
                top: '12px',
                bottom: '12px',
                width: '100%',
                height: '15px'
              }}})],1)}),1)}),1)],1),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-paddingless"},[_c('div',{staticClass:"navbar"}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"one"}),_c('div',{staticClass:"two"})]),_c('div',{staticClass:"bottom-section"})])])
}]

export { render, staticRenderFns }
import { httpClient } from "@/app/old/desktop/shared/services/";
import { BaseService } from "@/app/old/desktop/shared/services/";
const API_HOST = process.env.VUE_APP_SYSTEM_MODEL_API_HOST;

const URL = "instances";

class API extends BaseService {
  constructor() {
    super(API_HOST, URL);
  }

  bulk_update_parents_for_component(req = {}) {
    return httpClient({
      url: `${this.url}/child-component/${req.component}/parent-component/${req.parent_component}/parents/`,
      baseURL: this.baseURL,
      method: "post",
      data: req.body,
      header: req.headers
    }).then(res => res.data);
  }

  get_connections_for_parent(req = {}) {
    return httpClient({
      url: `components/${req.component}/related-component/${
        req.parent_component
      }/instances/${req.query ? `?&${req.query}` : ""}`,
      baseURL: this.baseURL,
      method: "get",
      header: req.headers
    }).then(res => res);
  }

  get_all_instances(req = {}) {
    return httpClient({
      url: `${this.url}${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      method: "get",
      header: req.headers
    }).then(res => res);
  }

  bulk_delete_instances(req = {}) {
    return httpClient({
      url: `${this.url}`,
      baseURL: this.baseURL,
      headers: req.headers,
      method: "delete",
      data: req.body
    }).then(res => res.data);
  }

  postAliases(template, req = {}) {
    return httpClient({
      url: `csv/template/${template}/alias`,
      baseURL: this.baseURL,
      method: "post",
      headers: req.headers,
      data: req.body
    }).then(res => res);
  }

  patchAliases(instance, req = {}) {
    return httpClient({
      url: `instances/${instance}`,
      baseURL: this.baseURL,
      method: "patch",
      headers: req.headers,
      data: req.body
    }).then(res => res);
  }

  buildTemplate(template, req = {}) {
    return httpClient({
      url: `instances/template/${template}/build`,
      baseURL: this.baseURL,
      method: "get",
      headers: req.headers,
      data: req.body
    }).then(res => res);
  }
}

export const instanceService = new API();

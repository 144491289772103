var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.active = false)),expression:"() => (active = false)"}],staticClass:"is-sh-dropdown is-relative is-duedate"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.tooltip
    }),expression:"{\n      content: tooltip\n    }"}],staticClass:"is-close is-pointer is-relative",class:[
      { 'is-danger': _vm.is_overdue },
      { 'is-selector': !_vm.bulk_selection && !_vm.custom_text }
    ],on:{"click":function($event){_vm.active = !_vm.active;
      _vm.create_cal();}}},[(_vm.due && !_vm.bulk_selection)?_c('div',{staticClass:"is-flex is-vcenter"},[_c('p',{staticClass:"is-flex"},[_c('sh-icon',{staticClass:"is-18x18 mr-5",attrs:{"name":_vm.is_overdue ? 'due-date-danger' : 'due-date-dark'}}),(!_vm.bulk_selection)?_c('span',{class:{ 'has-text-danger': _vm.is_overdue }},[_vm._v(_vm._s(_vm._f("sinceDays")(new Date(_vm.due).toISOString())))]):_vm._e()],1),_c('div',{staticClass:"is-pointer ml-10 close-mark is-24x24 is-flex align-center is-center",on:{"click":function($event){return _vm.change_duedate(null)}}},[_c('sh-icon',{staticClass:"is-8x8",attrs:{"name":'ticket-priority-close'}})],1)]):_c('div',{staticClass:"is-flex is-vcenter is-pointer"},[_c('sh-icon',{class:[_vm.icon_class ? _vm.icon_class : 'is-24x24 mr-7'],attrs:{"name":_vm.icon ? _vm.icon : 'select-date'}}),(!_vm.bulk_selection)?_c('span',{staticClass:"has-text-theme-black",class:{ ' has-opacity-5': !_vm.custom_text }},[_vm._v(_vm._s(_vm.custom_text ? _vm.localization( `app-${_vm.custom_text .split(" ") .join("-") .toLowerCase()}`, _vm.custom_text ) : _vm.localization("app-select-date", "Select Date")))]):_vm._e()],1)]),(_vm.active && _vm.isMobile)?_c('div',{staticClass:"mobile-background-new",on:{"click":function($event){_vm.active = !_vm.active}}}):_vm._e(),_c('div',{class:[
      {
        'is-hidden':
          !_vm.active ||
          !`calendar_container${_vm.ticket ? _vm.ticket.uid : 'new'}${
            _vm.in_details ? '-details' : null
          }`
      },
      [_vm.isMobile ? 'mobile-cal-container' : 'dueDate-calendar']
    ],style:([_vm.is_bulk ? { left: '0rem', 'z-index': 0 } : null])},[_c('div',{attrs:{"id":`calendar_container${
          _vm.bulk_selection
            ? 'bulk_calendar_container'
            : _vm.ticket
            ? _vm.ticket.uid
            : 'new'
        }${_vm.in_details ? '-details' : null}`}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div
      v-if="is_loading"
      class="card padding-20 margin-auto"
      style="width: 100%"
    >
      <b-skeleton width="40%" :animated="animated"></b-skeleton>
      <b-skeleton width="90%" :animated="animated"></b-skeleton>
      <section
        class="is-flex mt-20 align-center has-text-centered has-space-around"
      >
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
      </section>
    </div>
    <div
      class="card margin-auto py-10"
      style="width: 100%; min-width: 900px"
      :class="{
        'is-vhidden': check_condition
      }"
    >
      <div class="padding-20">
        <div class="is-flex is-between pl-40">
          <h2 class="is-semiBold-18-600-22 ">
            {{
              localization("app-temperature-histogram", "Temperature Histogram")
            }}
          </h2>
          <div class="is-flex">
            <sh-icon
              @click.native="export_png"
              :name="'ticket-download'"
              class="is-24x24 mr-15 is-pointer"
            />
            <sh-icon
              @click.native="openFullscreen"
              :name="'ticket-expand-maximize'"
              class="is-24x24 is-pointer"
            />
          </div>
        </div>
        <!-- style="max-width: 95%" class="margin-auto" -->
        <div :class="{ invert_colors: false }">
          <div id="temperatureGraph"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { loadScript } from "@/utils/loadScript.js";
  import { viewService } from "@/app/old/desktop/shared/services/";
  import { mapState, mapGetters } from "vuex";

  export default {
    props: ["has_dark_theme", "metrics", "details", "use_metrics"],
    mounted() {
      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/plotly.js/1.51.1/plotly.min.js",
        this.getData,
        "plotly-cdn2"
      );
      let ele = document.getElementById("temperatureGraph");
      if (!ele) return;
      else {
        ele.addEventListener("fullscreenchange", e => {
          if (document.fullscreenElement) {
            console.log("fullscreen");
          } else {
            this.getData();
          }
        });
      }
      // was already loaded
    },
    data() {
      return {
        is_loading: false,
        data: null,
        chartData: [
          {
            x: [],
            type: "histogram",
            marker: {
              color: "#5B70FA",
              opacity: 1
            },
            xbins: {
              size: 1,
              start: 0
            }
          }
        ]
      };
    },
    computed: {
      ...mapGetters("thermv2", ["filtered_defects"]),
      check_condition() {
        if (!this.use_metrics) {
          return (
            !this.chartData[0].x.length ||
            this.is_loading ||
            !this.filtered_defects.length
          );
        } else {
          return !this.chartData[0].x.length || this.is_loading;
        }
      }
    },
    methods: {
      openFullscreen() {
        let elem = document.getElementById("temperatureGraph");
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      },
      async export_png(gd) {
        Plotly.downloadImage(document.getElementById("temperatureGraph"), {
          format: "png",
          height: 500,
          width: 800,
          filename: this.localization(
            "app-temperature-histogram",
            "Temperature Histogram"
          )
        });
      },
      async getData(projects) {
        this.is_loading = true;
        if (!this.use_metrics) {
          if (!this.filtered_defects.length) return;
          this.chartData[0].x = this.filtered_defects.map(
            defect => defect.properties.temperatureDifference
          );
        } else {
          if (!projects) {
            if (!this.data)
              this.data = await viewService.get_view_temperature_data({
                id: this.$route.params.id
              });
            if (this.data && this.data.length) {
              this.data.splice(0, 1);
            }
            this.chartData[0].x = this.data;
          } else if (!projects.length) {
            this.chartData[0].x = [];
          } else {
            let temperatures = [];
            projects.forEach(project => {
              if (
                this.metrics[project.uid].temperatures.length &&
                this.metrics[project.uid].temperatures.length > 1
              )
                temperatures.push(
                  ...(this.metrics[project.uid].temperatures || [])
                );
            });
            this.chartData[0].x = temperatures.length ? temperatures : [0];

            // this.chartData[0].x = await viewService.get_view_project_temperature_data(
            //   {
            //     id: this.$route.params.id,
            //     project_id: project,
            //   }
            // );
          }
        }

        var chart_options = {
          height: 220,
          // plot_bgcolor: "#d8dde2",
          // paper_bgcolor: "#d8dde2",
          // width: 1050,
          // autosize: true,
          xaxis: {
            title: {
              text: this.localization(
                "app-temperature-difference",
                "Temperature Difference"
              ),
              font: {
                family: "Barlow-Medium",
                size: 15,
                color: "#171e48"
              }
            }
          },
          yaxis: {
            title: {
              text: this.localization("app-no-of-defects", "No. of Defects"),
              font: {
                family: "Barlow-Medium",
                size: 15,
                color: "#171e48"
              }
            }
          },
          margin: {
            l: 50,
            r: 20,
            b: 40,
            t: 20,
            pad: 10
          }
        };
        Plotly.newPlot(
          document.getElementById("temperatureGraph"),
          this.chartData,
          chart_options,
          {
            displayModeBar: false,
            responsive: true
          }
        );
        this.is_loading = false;
      }
    },
    watch: {
      filtered_defects: {
        deep: true,
        handler(val) {
          this.getData();
        }
      }
    }
  };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageCount > 1 || _vm.$route.name == 'therm-v2-tickets')?_c('div',{staticClass:"pagination-box"},[(_vm.page_sizes && _vm.page_sizes.length)?_c('section',[_c('div',{staticClass:"pagination-text is-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.localization("app-show", "Show")))]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.is_active = false)),expression:"() => (is_active = false)"}],staticClass:"page-size-dropdown"},[_c('div',{staticClass:"dropdown",class:{ 'is-active': _vm.is_active }},[_c('div',{staticClass:"dropdown-trigger b-1"},[_c('button',{staticClass:"button is-medium-14-500-17 mx-5 is-pointer no-box-shadow",attrs:{"aria-haspopup":"true","aria-controls":"dropdown-menu"},on:{"click":function($event){_vm.is_active = !_vm.is_active}}},[_c('span',[_vm._v(_vm._s(_vm.default_page_size))]),_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas",class:{
                    ' fa-angle-down': _vm.is_active,
                    ' fa-angle-up': !_vm.is_active
                  },attrs:{"aria-hidden":"true"}})])])]),_c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdown-menu","role":"menu"}},[_c('div',{staticClass:"dropdown-content",staticStyle:{"width":"6rem"}},_vm._l((_vm.page_sizes),function(s,i){return _c('div',{key:s,staticClass:"hover-background",class:{ 'bb-1': i != _vm.page_sizes.length - 1 },on:{"click":function($event){_vm.default_page_size = s;
                  _vm.$emit('default-page-size', _vm.default_page_size);
                  _vm.is_active = false;}}},[_c('p',{staticClass:"dropdown-item py-5 is-pointer"},[_c('span',{staticClass:"is-medium-16-500-19"},[_vm._v(" "+_vm._s(s)+" ")])])])}),0)])])]),_c('span',[_vm._v(_vm._s(_vm.localization("app-per-page", "per page")))])])]):_c('section',[_c('p',{staticClass:"pagination-text"},[_vm._v(" Showing "),(
          _vm.currentPage * _vm.default_page_size - _vm.default_page_size + 1 ==
            _vm.totalRecords
        )?_c('span',[_vm._v("last")]):_c('span',[_vm._v(_vm._s(_vm.currentPage * _vm.default_page_size - _vm.default_page_size + 1)+" to "+_vm._s(_vm.currentPage * _vm.default_page_size >= _vm.totalRecords ? _vm.totalRecords : _vm.currentPage * _vm.default_page_size))]),_vm._v(" of "+_vm._s(_vm.totalRecords)+" entries ")])]),_c('nav',{staticClass:"pagination",attrs:{"role":"navigation","aria-label":"pagination"}},[_c('a',{staticClass:"pagination-previous",attrs:{"disabled":_vm.isPreviousButtonDisabled},on:{"click":_vm.previousPage}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('ul',{staticClass:"pagination-list"},_vm._l((_vm.paginationTriggers),function(paginationTrigger,i){return _c('li',{key:i},[(!paginationTrigger)?_c('a',{on:{"click":function($event){return _vm.onLoadPage(_vm.paginationTriggers[i - 1] + 1)}}},[_c('span',{staticClass:"pagination-ellipsis",staticStyle:{"width":"0","height":"0"}},[_vm._v("…")])]):_c('a',{staticClass:"pagination-link",class:{ 'is-current': paginationTrigger == _vm.currentPage },on:{"click":function($event){return _vm.onLoadPage(paginationTrigger)}}},[_vm._v(_vm._s(paginationTrigger))])])}),0),_c('a',{staticClass:"pagination-next",attrs:{"disabled":_vm.isNextButtonDisabled},on:{"click":_vm.nextPage}},[_c('i',{staticClass:"fas fa-chevron-right"})])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-relative mt-10"},[(!_vm.$store.state.is_offline)?_c('div',{staticClass:"mobileattachment--favorite is-absolute is-pointer",on:{"click":function($event){$event.stopPropagation();!_vm.$store.state.is_offline ? _vm.toggle_favorite(_vm.file) : ''}}},[(_vm.file.pinned)?_c('sh-icon',{class:'is-18x18',attrs:{"file":'tickets_sprite',"name":'bookmark-attachment'}}):_vm._e(),(!_vm.file.pinned)?_c('sh-icon',{class:'is-18x18',attrs:{"file":'tickets_sprite',"name":'unmarked-attachment'}}):_vm._e()],1):_vm._e(),(_vm.selected_attachments.includes(_vm.file.uid))?_c('div',{staticClass:"mobileattachment--selection is-absolute is-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.longtapHandler.apply(null, arguments)}}},[_c('sh-icon',{class:'is-16x16',attrs:{"file":'tickets_sprite',"name":'selection-circle'}})],1):_vm._e(),(
      !_vm.selected_attachments.includes(_vm.file.uid) && _vm.selected_attachments.length
    )?_c('div',{staticClass:"mobileattachment--selection is-absolute is-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.longtapHandler.apply(null, arguments)}}},[_c('div',{staticClass:"mobileattachment-select-circle"})]):_vm._e(),_c('div',{directives:[{name:"touch",rawName:"v-touch:longtap.stop",value:(_vm.longtapHandler),expression:"longtapHandler",arg:"longtap",modifiers:{"stop":true}},{name:"touch",rawName:"v-touch:tap.stop",value:(_vm.touchHandler),expression:"touchHandler",arg:"tap",modifiers:{"stop":true}}],staticClass:"mobileattachment card",class:{ ripple: _vm.selected_attachments.includes(_vm.file.uid) },style:({ 'border-radius': '2px', 'box-shadow': 'none' }),attrs:{"id":'attachment' + _vm.file.uid}},[_c('div',[(_vm.file)?_c('div',{staticClass:"mobileattachment--thumbnail is-flex align-center is-center",class:{
          'thumbnail-background': _vm.selected_attachments.includes(_vm.file.uid)
        }},[(_vm.file.thumbnails && _vm.file.thumbnails.small)?_c('div',{class:{
            'has-opacity-8': _vm.selected_attachments.includes(_vm.file.uid)
          }},[_c('img',{staticClass:"image thumbnail-image",attrs:{"src":_vm.file.thumbnails.small,"alt":_vm.file.url}})]):_c('div',[_c('img',{staticClass:"image is-36x36 mb-10",attrs:{"src":require('@/assets/file-type-icons/' +
                _vm.prettyFileIcons.getIcon(_vm.file.fileName) +
                '.svg')}})])]):_vm._e(),_c('figure',{staticClass:"image is-absolute profile-icon"},[_c('user-avatar',{staticClass:"user_avatar_icon",attrs:{"user":_vm.file.owner,"pxsize":20,"custom_class":'margin-x-auto tiny-user-avatar'}})],1)]),_c('div',{staticClass:"card-content is-relative",class:'is-paddingless'},[_c('div',{staticClass:"is-flex align-center mt-10",staticStyle:{"justify-content":"center"}},[(_vm.file.foreignObjectType !== 'ticket')?_c('svg',{staticClass:"is-10x10 mr-5",attrs:{"viewBox":"0 0 10 10"}},[(_vm.hover)?_c('use',{attrs:{"xlink:href":_vm.foreign_icon + '#foreign-active-' + _vm.file.foreignObjectType}}):_c('use',{attrs:{"xlink:href":_vm.foreign_icon + '#foreign-' + _vm.file.foreignObjectType}})]):_vm._e(),_c('p',{staticClass:"is-medium-14-500-17 filename"},[_vm._v(" "+_vm._s(_vm._f("truncateMiddle")(_vm.file.fileName,11))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    v-if="$store.state.app_loading"
    style="
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <img style="width: 100px" src="@/assets/icons/app-loader.svg" alt />
  </div>
  <div id="app" class="is-marginless columns is-mobile" v-else>
    <div v-if="$store.state.is_loading" class="translucent-loading-overlay">
      <img style="width: 100px" src="@/assets/icons/app-loader.svg" alt />
    </div>
    <div
      style="flex-shrink: 0; min-height: 100vh"
      :style="{ width: $route.meta.mini_sidebar ? '5rem' : '22rem' }"
      v-if="!exclude_sidebar.includes($route.name)"
    >
      <keep-alive>
        <router-view name="left"></router-view>
      </keep-alive>
    </div>
    <div class="column is-paddingless">
      <div class="sticky-navbar">
        <router-view name="top"></router-view>
      </div>
      <div>
        <router-view class="router-root"></router-view>
      </div>
    </div>
    <router-view name="tickets"></router-view>
    <OneSignalPopup />
    <viewerActions v-if="$route.name && $route.name.includes('viewer')" />
    <ZipDownloadProgress v-if="$store.getters.download_files_zip" />
    <webviewer-component
      v-show="showViewer"
      v-bind="webviewerData"
      :showViewer="showViewer"
      @close="showViewer = false"
      @delete="showViewer = false"
    ></webviewer-component>
  </div>
</template>
<script>
  import AuthMixin from "@/app/shared/auth-old/shared/auth-mixin.js";
  import ZipDownloadProgress from "@/app/old/desktop/shared/components/zip-download-progress/zip-download-progress";
  import { assetService } from "@/app/old/desktop/shared/services/";
  import DashboardSkeleton from "@/app/old/desktop/shared/components/skeleton/dashboard-skeleton";
  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";
  import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  import TicketsRealTimeUpdatesMixin from "@/app/old/desktop/tickets/shared/mixin/real-time-updates.mixin.vue";
  import jwt_decode from "jwt-decode";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import WebviewerComponent from "@/app/old/desktop/shared/components/pdftron/components/webviewer-component.vue";

  export default {
    mixins: [AuthMixin, TicketDetailsMixin, TicketsRealTimeUpdatesMixin],
    components: {
      DashboardSkeleton,
      WebviewerComponent,
      OneSignalPopup: () =>
        import(
          /* webpackChunkName: "one-signal" */ "@/app/old/desktop/shared/components/one-signal-popup"
        ),
      ViewerCreateTicket: () =>
        import(
          /* webpackChunkName: "viewer" */ "@/app/old/desktop/tickets/shared/components/viewer-create-ticket"
        ),
      ZipDownloadProgress,
      viewerActions: () =>
        import(
          /* webpackChunkName: "viewer" */ "@/app/old/desktop/shared/components/viewer-actions/viewer-actions.vue"
        ),
    },
    data() {
      return {
        webviewerData: {},
        showViewer: false,
        exclude_sidebar: [
          "therm-v2-reports",
          "therm-v2-compare",
          "therm-v2-tickets",
          "therm-v2",
          "handle-login",
          "auth",
          "signup",
          "download-shared-file",
          "redirect",
        ],
      };
    },

    computed: {
      ...mapState([
        "claims",
        "TAGS",
        "current_organization",
        "LAST_VISITED_PAGE",
      ]),
      hasClaimsAndOrganization() {
        return !!this.claims && !!this.current_organization;
      },
    },

    watch: {
      $route(to, from) {
        if (
          (this.$route.name && this.$route.name.includes("viewer")) ||
          this.$route.name.includes("therm-v2")
        )
          Intercom("update", {
            hide_default_launcher: true,
          });
        else
          Intercom("update", {
            hide_default_launcher: false,
          });
        if (to.name !== from.name) analytics.page(to.name);
      },
      hasClaimsAndOrganization() {
        this.initialize_split();
        // this.SET_TAGS();

        // Do not enable debug mode for Analytics.js in production
        if (process.env.NODE_ENV !== "production") {
          analytics.debug();
        }
        //Set user identity
        analytics.identify(this.$store.state.claims.user_id, {
          internal_user: this.$store.state.claims.user_role ? true : false,
          is_manager: this.$store.state.claims.is_manager,
          is_owner: this.$store.state.claims.is_owner,
        });
        //Set the organization name as the group identifier
        analytics.group(
          this.$store.state.claims.user_organization
            ? this.$store.state.claims.user_organization.uid
            : "Sensehawk",
          {
            organization: this.$store.state.claims.user_organization
              ? this.$store.state.claims.user_organization.name
              : "Sensehawk",
          }
        );
      },
      current_organization() {},
    },
    created() {
      console.log("route", this.$route);
      this.add_mapbox_token();
      this.event_from_iframe();
      if (
        !window.$cookies.get("access_token") &&
        !this.$route.query.code &&
        this.$route.name !== "signup"
      )
        this.$router.push({ name: "handle-login" });
      this.$eventHub.$on("show-webviewer", this.set_webviewer_data);
      this.$eventHub.$on("open_form_submission", data => {
        this.open_form_submission(
          data.formUid,
          data.submissionUid,
          data.preview,
          data.ticketUid
        );
      });
    },
    beforeDestroy() {
      this.$eventHub.$off("show-webviewer", this.set_webviewer_data);
      this.chatClient.disconnect();

      if (window.addEventListener) {
        window.removeEventListener("message", this.message_event_handler);
      } else {
        window.detachEvent("onmessage", this.message_event_handler);
      }
    },
    methods: {
      ...mapMutations([
        "SET_COOKIE_TOKEN",
        "SET_TEAMS_AND_USERS",
        "SET_TAGS",
        "SET_SPLIT_CLIENT",
      ]),
      set_webviewer_data(data) {
        this.showViewer = true;
        this.webviewerData = Object.assign({}, data);
      },
      add_teams(asset_id) {
        this.$modal.show(
          AddTeams,
          {
            asset_id,
            complete: e => {
              if (e.length) {
                let data = {};
                let read = e
                  .filter(obj => obj.access === "read")
                  .map(item => item.uid);
                let write = e
                  .filter(obj => obj.access === "write")
                  .map(item => item.uid);
                if (read.length) data["readLabels"] = read;
                if (write.length) data["writeLabels"] = write;
                this.post_teams_to_viewer("add_teams", data);
              }
            },
          },
          { height: "80%", width: "40%" }
        );
      },
      async post_teams_to_viewer(name, data) {
        if (this.$route.name.includes("viewer")) {
          let el = document.getElementById(this.$route.name);
          if (el) {
            let wn = el.contentWindow;
            wn.postMessage(
              {
                name,
                data,
              },
              this.$route.name === "terra-viewer"
                ? process.env.VUE_APP_TERRA_VIEWER
                : process.env.VUE_APP_THERM_VIEWER
            );
          }
        }
      },
      initialize_split() {
        let decodedJwt = jwt_decode(window.$cookies.get("access_token"));

        // Instantiate the SDK. CDN will expose splitio globally
        var factory = splitio({
          core: {
            authorizationKey: process.env.VUE_APP_SPLIT_KEY,
            // your internal user id, or the account id that
            // the user belongs to.
            // This coudld also be a cookie you generate
            // for anonymous users
            key: decodedJwt.sub || decodedJwt.email || this.claims.email,
            // an OPTIONAL traffic type, if provided will be
            // used for event tracking with the SDK client.
            trafficType: "A_TRAFFIC_TYPE",
          },
        });
        // And get the client instance you'll use
        var client = factory.client();
        client.on(client.Event.SDK_READY, () => {
          this.SET_SPLIT_CLIENT(client);
        });
      },
      message_event_handler(e) {
        if (_.isObject(e.data) && e.data.name === "show_ticket_details") {
          this.open_ticket_details(e.data.ticket.uid);
        } else if (
          _.isObject(e.data) &&
          e.data.name === "show_form_submission"
        ) {
          this.open_form_submission(
            e.data.form.uid,
            e.data.form.currentSubmission.length
              ? e.data.form.currentSubmission[0].uid
              : null
          );
        } else if (_.isObject(e.data) && e.data.name === "show_teams_popup") {
          this.add_teams(e.data.asset_id);
        } else if (_.isObject(e.data) && e.data.name === "redirect_to_login") {
          this.logout();
        }
      },
      event_from_iframe() {
        var eventMethod = window.addEventListener
          ? "addEventListener"
          : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent =
          eventMethod === "attachEvent" ? "onmessage" : "message";
        eventer(messageEvent, this.message_event_handler);
      },

      async add_mapbox_token() {
        let res, token;
        try {
          res = await axios.get(
            "https://sensehawk-config.s3.ap-south-1.amazonaws.com/mapbox-gl-token.json"
          );
          token = res.data.token;
        } catch (err) {
          token = null;
        }
        if (token) mapboxgl.accessToken = token;
      },
      trigger_event() {
        // Create the event
        var event = new CustomEvent("app-updated", {
          action: "refresh application",
        });
        // Dispatch/Trigger/Fire the event
        document.dispatchEvent(event);
      },
    },
  };
</script>
<style lang="scss">
  @import "@/assets/style/core.scss";
  #app {
    font-family: "Barlow-Regular";
    background-color: $primary-background;
    font-size: 1.6rem;
    color: #171e48;
    .root-right {
      width: calc(100% - 22rem);
    }
  }

  .iziToast {
    min-height: 40px;
    padding-top: 3px;
    padding-bottom: 0;
    &.-close {
      height: 37px;
    }
  }
  .v--modal-overlay {
    background: rgba(50, 60, 70, 0.8);
  }
  .sticky-navbar {
    position: sticky;
    top: 0;
    z-index: 15;
  }
  input {
    outline-color: white;
    :focus {
      outline-color: white;
    }
    :active {
      outline-color: white;
    }
    caret-color: $primary;
  }
  .pinned-tab {
    color: $primary;
  }

  .tooltip {
    font-family: Barlow-Medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.06px;
    font-size: 13px;
    display: block !important;
    z-index: 10000;
    .tooltip-inner {
      background: #171e48;
      color: white;
      border-radius: 4px;
      padding: 4px 7px 8px;
    }
    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;
    }
    &[x-placement^="top"] {
      margin-bottom: 5px;
      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &[x-placement^="bottom"] {
      margin-top: 5px;
      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &[x-placement^="right"] {
      margin-left: 5px;
      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }
    &[x-placement^="left"] {
      margin-right: 5px;
      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }
    &[aria-hidden="true"] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }
    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
    &.popover {
      .popover-inner {
        background: $white;
        color: black;
        padding: 15px 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $white;
      }
    }
  }
  .toast-border {
    width: 50rem;
    height: 5rem;
    border: 1.5px solid;
    border-radius: 6px;
    font-family: Barlow-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #171e48;
    &.is-danger {
      border-color: #ff3722;
    }
    &.is-success {
      border-color: #1dba54;
    }
    &.is-warning {
      border-color: #ffc100;
    }
  }
  .iziToast-title {
    width: 58px;
    height: 17px;
    font-family: Barlow-Regular;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #171e48;
  }
  .is-caption {
    font-size: 1.2rem !important;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    opacity: 0.7;
  }
  // THIN
  .has-background-transparent {
    background-color: transparent;
  }
  .disabled-tabs {
    opacity: 0.3 !important;
    :hover {
      opacity: 0.3 !important;
    }
  }

  .translucent-loading-overlay {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 16;
  }
</style>

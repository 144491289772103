var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isMobile)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close_dropdown),expression:"close_dropdown"}],staticClass:"actions-dropdown is-relative"},[(!_vm.is_context)?_c('div',{staticClass:"actions-dropdown-selection",class:{ 'is-vhidden': !_vm.hover },on:{"click":_vm.toggle_dropdown}},[(_vm.is_list)?_c('sh-icon',{staticClass:"is-20x20 ml-10",attrs:{"name":'3dots-menu',"file":'tickets_sprite'}}):_c('p',{staticClass:"is-flex is-vcenter"},[_c('sh-icon',{staticClass:"is-20x20 mr-10",attrs:{"name":'ticket-settings'}}),_c('sh-icon',{staticClass:"is-14x14",attrs:{"name":'ticket-settings-arrow-down'}})],1)],1):_vm._e(),(_vm.active)?_c('ul',{staticClass:"actions-dropdown-content is-action",class:{ 'is-left': !_vm.is_list }},[_c('li',{staticClass:"is-flex is-between actions-dropdown-content__list is-relative"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-duplicate', 'Duplicate') }),expression:"{ content: localization('app-duplicate', 'Duplicate') }"}],staticClass:"actions-dropdown-content__list--item",class:{
            'has-no-access': !_vm.$store.getters.ticket_replication_features
          },on:{"click":function($event){$event.stopPropagation();_vm.close_modals();
            _vm.showDuplicateModal = true;}}},[_c('sh-icon',{staticClass:"is-22x22 actions-dropdown-content__list--icon",attrs:{"name":'duplicate'}})],1),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            hideOnTargetClick: false,
            content: `${
              _vm.copied
                ? _vm.localization(
                    'app-copied-to-clipboard',
                    'Copied to clipboard'
                  )
                : _vm.localization('app-copy-url', 'Copy URL')
            }`
          }),expression:"{\n            hideOnTargetClick: false,\n            content: `${\n              copied\n                ? localization(\n                    'app-copied-to-clipboard',\n                    'Copied to clipboard'\n                  )\n                : localization('app-copy-url', 'Copy URL')\n            }`\n          }"},{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.ticket_path),expression:"ticket_path",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"actions-dropdown-content__list--item"},[_c('sh-icon',{staticClass:"is-22x22 actions-dropdown-content__list--icon",attrs:{"name":_vm.copied ? 'copyurl-active' : 'copyurl'}})],1),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-archive', 'Archive') }),expression:"{ content: localization('app-archive', 'Archive') }"}],staticClass:"actions-dropdown-content__list--item"},[_c('sh-icon',{staticClass:"is-22x22 actions-dropdown-content__list--icon",attrs:{"name":'archive'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.archive_ticket()}}})],1),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-delete', 'Delete') }),expression:"{ content: localization('app-delete', 'Delete') }"}],staticClass:"actions-dropdown-content__list--item",class:{ 'is-disabled': !_vm.ticket.writeAccess }},[_c('span',{on:{"click":function($event){$event.stopPropagation();_vm.showDeleteModal = true;
              _vm.active = false;}}},[_c('sh-icon',{staticClass:"is-22x22 actions-dropdown-content__list--icon",attrs:{"name":'delete-ticket'}})],1)]),(_vm.showDuplicateModal)?_c('duplicate-template',{class:[
            _vm.is_modal_view ? 'viewer-actions-popup' : 'template-position'
          ],attrs:{"ticket":_vm.ticket},on:{"close-duplicate-modal":function($event){_vm.showDuplicateModal = false}}}):_vm._e()],1),_c('li',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){$event.stopPropagation();_vm.is_ticket_pinned ? _vm.unpin() : _vm.create_pin()}}},[(_vm.is_ticket_pinned)?_c('sh-icon',{staticClass:"is-16x16 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'unpinn'}}):_c('sh-icon',{staticClass:"is-20x20 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'pinned'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.is_ticket_pinned ? _vm.localization("app-unpin-ticket", "Unpin ticket") : _vm.localization("app-pin-ticket", "Pin ticket")))])],1),_c('li',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){$event.stopPropagation();return _vm.download()}}},[_c('sh-icon',{staticClass:"is-20x20 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'download-attachments'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-download-attachments", "Download Attachments")))])],1),(
          _vm.ticket &&
            _vm.ticket.takenUp &&
            _vm.ticket.takenUp.uid &&
            _vm.ticket.takenUp.uid == _vm.$store.state.user_claims.user_id
        )?_c('li',{staticClass:"actions-dropdown-content__item",class:{ 'is-disabled': !_vm.ticket.writeAccess },on:{"click":function($event){$event.stopPropagation();return _vm.giveup_ticket.apply(null, arguments)}}},[_c('sh-icon',{staticClass:"rotate180 is-20x20 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'giveup'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-give-up", "Give Up")))])],1):(
          !_vm.ticket ||
            !_vm.ticket.takenUp ||
            !_vm.ticket.takenUp.uid ||
            _vm.ticket.takenUp.uid != _vm.$store.state.user_claims.user_id
        )?_c('li',{staticClass:"actions-dropdown-content__item",class:{ 'is-disabled': !_vm.ticket.writeAccess },on:{"click":function($event){$event.stopPropagation();return _vm.takeup_ticket(_vm.$store.state.user_claims)}}},[_c('sh-icon',{staticClass:"is-20x20 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'giveup'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-taken-up", "Take Up")))])],1):_vm._e(),_c('li',{staticClass:"is-relative",class:{
          'has-no-access': !_vm.$store.getters.ticket_replication_features
        }},[_c('p',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){$event.stopPropagation();_vm.close_modals();
            _vm.showSaveModal = true;}}},[_c('sh-icon',{staticClass:"is-20x20 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'save-as-template'}}),_c('span',{staticClass:"actions-dropdown-content__item--text",class:{
              'is-disabled': !_vm.$store.getters.ticket_replication_features
            }},[_vm._v(" "+_vm._s(_vm.localization("app-save-as-template", "Save as Template")))])],1),(_vm.showSaveModal)?_c('save-template',{class:[
            _vm.is_modal_view ? 'viewer-actions-popup' : 'template-position'
          ],attrs:{"tickets":[_vm.ticket.uid],"type":'save',"asset":_vm.ticket.targetElement && _vm.ticket.targetElement.asset},on:{"close-save-template":function($event){_vm.showSaveModal = false}}}):_vm._e()],1),_c('li',{staticClass:"is-relative",class:{
          'has-no-access': !_vm.$store.getters.ticket_replication_features,
          'is-disabled': !_vm.ticket.writeAccess
        }},[_c('p',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){$event.stopPropagation();_vm.close_modals();
            _vm.showLoadModal = true;}}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'load-more-template'}}),_c('span',{staticClass:"actions-dropdown-content__item--text",class:{
              'is-disabled': !_vm.$store.getters.ticket_replication_features
            }},[_vm._v(_vm._s(_vm.localization("app-load-from-template", "Load from Template")))])],1),(_vm.showLoadModal)?_c('save-template',{class:[
            _vm.is_modal_view ? 'viewer-actions-popup' : 'template-position'
          ],attrs:{"tickets":[_vm.ticket.uid],"type":'load',"asset":_vm.ticket.targetElement && _vm.ticket.targetElement.asset},on:{"close-load-template":function($event){_vm.showLoadModal = false}}}):_vm._e()],1)]):_vm._e()]):_c('div',[(_vm.active || _vm.showLoadModal)?_c('div',{staticClass:"actions-backdrop",on:{"click":function($event){$event.stopPropagation();_vm.active = false;
        _vm.showLoadModal = false;}}}):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close_dropdown),expression:"close_dropdown"}],staticClass:"m-actions-dropdown"},[(!_vm.is_context && !_vm.$store.state.is_offline)?_c('div',{staticClass:"actions-dropdown-selection",on:{"click":function($event){_vm.active = !_vm.active}}},[_c('sh-icon',{staticClass:"is-20x20 ml-10",attrs:{"name":'3-dots-menu'}})],1):_vm._e(),(_vm.active)?_c('ul',{staticClass:"actions-dropdown-content is-action",class:{ 'is-left': !_vm.is_list }},[_c('li',{staticClass:"is-flex has-space-between align-center px-25 pt-10 pb-15"},[_c('p',{staticClass:"is-medium-16-500-19 has-text-black"},[_vm._v(" "+_vm._s(_vm.localization( "app-please-choose-an-option", "Please choose an option" ))+" ")]),_c('p',{on:{"click":function($event){_vm.active = false}}},[(_vm.active)?_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}}):_vm._e()],1)]),_c('hr',{staticClass:"is-marginless"}),(_vm.isMobile)?_c('li',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){$event.stopPropagation();_vm.$emit('open-description');
            _vm.active = false;}}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'description'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-description", "Description")))])],1):_vm._e(),_c('li',{staticClass:"actions-dropdown-content__item mt-5",on:{"click":function($event){$event.stopPropagation();return _vm.download()}}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'download-attachments'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-download-attachments", "Download Attachments")))])],1),_c('li',[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              hideOnTargetClick: false,
              content: `${
                _vm.copied
                  ? _vm.localization(
                      'app-copied-to-clipboard',
                      'Copied to clipboard'
                    )
                  : _vm.localization('app-copy-url', 'Copy URL')
              }`
            }),expression:"{\n              hideOnTargetClick: false,\n              content: `${\n                copied\n                  ? localization(\n                      'app-copied-to-clipboard',\n                      'Copied to clipboard'\n                    )\n                  : localization('app-copy-url', 'Copy URL')\n              }`\n            }"},{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.ticket_path),expression:"ticket_path",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"actions-dropdown-content__item"},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":_vm.copied ? 'copyurl-active' : 'copyurl'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(" "+_vm._s(_vm.localization("app-copy-link", "Copy Link"))+" ")])],1)]),_c('li',{staticClass:"actions-dropdown-content__item is-relative",class:{
            'has-no-access': !_vm.$store.getters.ticket_replication_features,
            'is-disabled': !_vm.ticket.writeAccess
          },on:{"click":function($event){$event.stopPropagation();_vm.active = false;
            _vm.close_modals();
            _vm.showLoadModal = true;}}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'load-more-template'}}),_c('span',{staticClass:"actions-dropdown-content__item--text",class:{
              'is-disabled': !_vm.$store.getters.ticket_replication_features
            }},[_vm._v(_vm._s(_vm.localization("app-load-from-template", "Load from Template")))])],1),_c('li',[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: `Archive` }),expression:"{ content: `Archive` }"}],staticClass:"actions-dropdown-content__item",on:{"click":function($event){$event.stopPropagation();return _vm.archive_ticket()}}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'archive'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-archive", "Archive")))])],1)]),(
            _vm.ticket &&
              _vm.ticket.takenUp &&
              _vm.ticket.takenUp.uid &&
              _vm.ticket.takenUp.uid == _vm.$store.state.user_claims.user_id
          )?_c('li',{staticClass:"actions-dropdown-content__item",class:{ 'is-disabled': !_vm.ticket.writeAccess },on:{"click":function($event){$event.stopPropagation();return _vm.giveup_ticket.apply(null, arguments)}}},[_c('sh-icon',{staticClass:"rotate180 is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'giveup'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-give-up", "Give Up")))])],1):(
            !_vm.ticket ||
              !_vm.ticket.takenUp ||
              !_vm.ticket.takenUp.uid ||
              _vm.ticket.takenUp.uid != _vm.$store.state.user_claims.user_id
          )?_c('li',{staticClass:"actions-dropdown-content__item",class:{ 'is-disabled': !_vm.ticket.writeAccess },on:{"click":function($event){$event.stopPropagation();return _vm.takeup_ticket(_vm.$store.state.user_claims)}}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'giveup'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-taken-up", "Take Up")))])],1):_vm._e(),_c('li',[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: `Delete` }),expression:"{ content: `Delete` }"}],staticClass:"actions-dropdown-content__item",class:{ 'is-disabled': !_vm.ticket.writeAccess },on:{"click":function($event){$event.stopPropagation();_vm.showDeleteModal = true;
              _vm.active = false;}}},[_c('span',[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__list--icon",attrs:{"name":'delete-ticket'}})],1),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-delete", "Delete")))])])])]):_vm._e(),(_vm.showLoadModal)?_c('save-template',{class:[
          _vm.is_modal_view ? 'viewer-actions-popup' : 'template-position'
        ],attrs:{"tickets":[_vm.ticket.uid],"type":'load',"asset":_vm.ticket.targetElement && _vm.ticket.targetElement.asset},on:{"close-load-template":function($event){_vm.showLoadModal = false}}}):_vm._e()],1)]),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.ticket.uid,"resourceName":_vm.ticket.name,"resourceType":_vm.ticket.isTemplate ? 'template' : 'ticket',"resourceService":_vm.ticketService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){return _vm.deleted_resource(_vm.ticket.uid)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
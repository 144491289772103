import { render, staticRenderFns } from "./therm-v2-bar-graph.vue?vue&type=template&id=3a1b74f8"
import script from "./therm-v2-bar-graph.vue?vue&type=script&lang=js"
export * from "./therm-v2-bar-graph.vue?vue&type=script&lang=js"
import style0 from "./therm-v2-bar-graph.vue?vue&type=style&index=0&id=3a1b74f8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vault-sidebar"},[_c('div',{staticClass:"vault-sidebar-wrapper"},[_c('div',{staticClass:"vault-sidebar-title"},[_c('img',{attrs:{"src":require("@/assets/icons/tickets/ticket-logo.svg"),"alt":""}}),_c('div',{staticClass:"vault-sidebar-title__right"},[_c('div',{staticClass:"is-semiBold-16-600-19"},[_c('router-link',{staticClass:"primary-title",attrs:{"to":{ name: 'vault' }}},[_vm._v(_vm._s(_vm.localization("app-vault", "Vault")))])],1),_c('div',{staticClass:"secondary-title is-regular-12-00-14"},[_vm._v(" "+_vm._s(_vm.localization( "app-manage-assets-with-ease", "Manage assets with ease!" ))+" ")])])]),_c('div',{staticClass:"vault-sidebar-content"},[_c('router-link',{staticClass:"vault-sidebar-content__item",class:{ 'is-active': _vm.$route.query.bookmark },attrs:{"to":{
          name: 'vault',
          query: { bookmark: true, asset: _vm.$route.query.asset }
        }}},[(_vm.$route.query.bookmark)?_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`bookmark-active`}}):_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`bookmark`}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization("app-favorites", "Favorites")))]),_c('div',{class:{ 'active-indicator': _vm.$route.query.bookmark }})],1),_c('router-link',{staticClass:"vault-sidebar-content__item",class:{ 'is-active': _vm.$route.name === 'vault' && _vm.is_everything },attrs:{"to":{
          name: 'vault',
          query: { asset: _vm.$route.query.asset }
        }}},[(_vm.$route.name === 'vault' && _vm.is_everything)?_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`everything-active`}}):_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`everything`}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization("app-everything", "Everything")))]),_c('div',{class:{
            'active-indicator': _vm.$route.name === 'vault' && _vm.is_everything
          }})],1),_c('router-link',{staticClass:"vault-sidebar-content__item",class:{ 'is-active': _vm.$route.query.shared },attrs:{"to":{
          name: 'vault',
          query: { shared: true, asset: _vm.$route.query.asset }
        }}},[(_vm.$route.query.shared)?_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`share-grey-active`}}):_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`share-grey`}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization("app-shared-with-me", "Shared with me")))]),_c('div',{class:{ 'active-indicator': _vm.$route.query.shared }})],1),_c('router-link',{staticClass:"vault-sidebar-content__item",class:{ 'is-active': _vm.$route.query.owner },attrs:{"to":{
          name: 'vault',
          query: { owner: true, asset: _vm.$route.query.asset }
        }}},[(_vm.$route.query.owner)?_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`assignee-active`}}):_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`assignee`}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization("app-owned-by-me", "Owned by me")))]),_c('div',{class:{ 'active-indicator': _vm.$route.query.owner }})],1),_c('router-link',{staticClass:"vault-sidebar-content__item",class:{ 'is-active': _vm.$route.name == 'vault-activities' },attrs:{"to":{
          name: 'vault-activities',
          query: { asset: _vm.$route.query.asset }
        }}},[(_vm.$route.name == 'vault-activities')?_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`activity-active`}}):_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`activity`}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization("app-activity", "Activity")))]),_c('div',{class:{ 'active-indicator': _vm.$route.name == 'vault-activities' }})],1),_c('router-link',{staticClass:"vault-sidebar-content__item",class:{ 'is-active': _vm.$route.query.archive },attrs:{"to":{
          name: 'vault',
          query: { archive: true, asset: _vm.$route.query.asset }
        }}},[(_vm.$route.query.archive)?_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`archive-grey-active`}}):_c('sh-icon',{staticClass:"is-24x24 vault-sidebar-content__item__icon",attrs:{"file":'vault_sprite',"name":`archive-grey`}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization("app-archived", "Archived")))]),_c('div',{class:{ 'active-indicator': _vm.$route.query.archive }})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="router-therm is-paddingless">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {};
</script>

<style>
  .router-therm {
    padding: 0 0 10rem 0;
  }
</style>

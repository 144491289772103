<template>
  <div>
    <router-view class="px-20"></router-view>
    <top-section
      class="mt-20 px-20"
      :details="details"
      @show_pin_modal="showPinModal = true"
    >
    </top-section>

    <!-- <div v-if="is_loading" class="px-20">
      <therm-skeleton></therm-skeleton>
    </div> -->
    <div v-if="!is_loading">
      <div class="columns px-20" v-if="details">
        <!-- <div class="column is-3">
          <details-sidecard>
            <template slot="header">
              <info-block
                :info="{
                  name: details.name,
                  subtext_class: 'is-semiBold-16-600-22',
                  type: 'projectManagement'
                }"
              ></info-block>
              <div
                v-if="!details.isAsset"
                class="sh-dropdown is-pointer"
                v-click-outside="
                  () => (dropdown ? (dropdown = !dropdown) : null)
                "
              >
                <div @click="dropdown = !dropdown">
                  <sh-icon :name="'3-dots-menu'" class="is-20x20"></sh-icon>
                </div>
                <div
                  class="sh-dropdown-content is-paddingless"
                  v-if="dropdown == true"
                >
                  <p
                    class="sh-dropdown-content__item is-medium-14-500-17"
                    @click="
                      open_form(
                        ThermFormComponent,
                        { header: 'Edit Thermal View for' },
                        details
                      )
                    "
                  >
                    <span class="mr-10">
                      <sh-icon :name="'edit-small'" class="is-24x24"></sh-icon>
                    </span>
                    <span class="no-whitespace-wrap"
                      >{{ localization("app-edit-view", "Edit View") }}
                    </span>
                  </p>

                  <p
                    @click="showDeleteModal = true"
                    class="sh-dropdown-content__item"
                  >
                    <span>
                      <sh-icon
                        :name="'delete-small'"
                        class="is-22x22"
                      ></sh-icon>
                    </span>
                    <span
                      class="no-whitespace-wrap is-medium-14-500-17 ml-10"
                      >{{
                        localization("app-delete-view", "Delete View")
                      }}</span
                    >
                  </p>
                </div>
              </div>
            </template>

            <template slot="main">
              <div class="details-card__content">
                <div class="details-card__content-heading">
                  {{ localization("app-description", "Description") }}
                </div>
                <p class="is-regular-13-00-20 has-text-light-grey">
                  {{
                    details.description ||
                      localization("app-no-description", "*No Description")
                  }}
                </p>
              </div>
              <div class="details-card__content">
                <div class="details-card__content-heading has-text-light-grey">
                  {{ localization("app-created-on", "Created on") }}:
                </div>
                <p
                  class="details-card__content-description is-regular-14-500-17 has-text-theme-black"
                >
                  {{ details.created_at }}
                </p>
              </div>
              <div class="details-card__content">
                <div class="details-card__content-heading">
                  {{ localization("app-entity", "Entity") }}
                </div>
                <p
                  class="details-card__content-description sh-text-semibold-16"
                >
                  {{
                    details.entity && details.entity.name
                      ? details.entity.name
                      : localization(
                          "app-no-associated-entity",
                          "*No associated entity"
                        )
                  }}
                </p>
              </div>
              <div class="details-card__content">
                <div class="pb-15 is-flex align-center">
                  <span class="has-text-light-grey is-regular-14-00-17">{{
                    localization("app-teams", "Teams")
                  }}</span>
                  <span
                    v-if="!details.isAsset"
                    @click="add_teams"
                    class="therm_add_icon is-pointer"
                    >+</span
                  >
                </div>
                <p class="details-card__content-description">
                  <primary-tag
                    :tags="get_teams(details.users || {})"
                    type="team"
                    :toShowCount="2"
                    :truncate_length="8"
                  ></primary-tag>
                </p>
              </div>
              <div class="details-card__content">
                <div class="pb-15 is-flex align-center">
                  <span class="has-text-light-grey is-regular-14-00-17">{{
                    localization("app-users", "Users")
                  }}</span>
                  <span
                    v-if="!details.isAsset"
                    @click="add_users"
                    class="therm_add_icon is-pointer"
                    >+</span
                  >
                </div>
                <p class="details-card__content-description">
                  <user-tag
                    :tagsCountToShow="3"
                    :custom_size="36"
                    :custom_class="'user-style is-medium-14-500-17'"
                    :users="[
                      ...(details.users.readOnly || []),
                      ...(details.users.readWrite || [])
                    ]"
                  ></user-tag>
                </p>
              </div>
            </template>
            <template slot="footer">
              <info-block
                :info="{
                  name: details.users.owner && details.users.owner.email,
                  top: localization('app-created-by', 'Created by'),
                  type: 'userManagement'
                }"
              ></info-block>
            </template>
          </details-sidecard>
        </div> -->
        <div class="column is-12">
          <ActionButton :details="details" @update="e => (details = e)" />

          <div class="my-30 is-black-border-bottom"></div>
          <bar-graph
            class="card mb-30"
            :use_metrics="true"
            :view_details="details"
          ></bar-graph>
          <temperature-histogram
            :use_metrics="true"
            :metrics="metrics"
          ></temperature-histogram>
          <!-- <temperature-histogram
              class="column is-12"
              :details="details"
              :metrics="metrics"
            ></temperature-histogram> -->
          <!-- <Metrics class="column is-3" :details="details" /> -->
          <!-- <div class="columns mt-25">
            <issue-counts
              :metrics="metrics"
              :details="details"
              class="column is-6"
            ></issue-counts>
            <therm-ticket-status
              v-if="$store.getters.therm_tickets_status_progress && details"
              :details="details"
              class="column is-6"
            ></therm-ticket-status>
            <issue-status class="column is-6"></issue-status>
          </div> -->
        </div>
      </div>
    </div>
    <delete-modal
      v-if="showDeleteModal"
      :resourceId="details.uid"
      :resourceName="details.name"
      :resourceType="'view'"
      :resourceService="viewService"
      @closed="showDeleteModal = false"
      @deleted="
        show_success('Resource successfully deleted');
        $router.push({ name: 'therm-view-list' });
        getData();
      "
    />
    <pin-modal
      v-if="showPinModal"
      :resourceName="details ? details.name : ''"
      :resourceType="'therm'"
      @closed="showPinModal = false"
    />
  </div>
</template>

<script>
  import { viewService } from "@/app/old/desktop/shared/services/";
  import detailsSidecard from "@/app/old/desktop/core/shared/components/details-sidecard.vue";
  import InfoBlock from "@/app/old/desktop/shared/components/info-block";
  import PrimaryTag from "@/app/old/desktop/shared/components/tags/primary-tag";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  // import TemperatureHistogram from "./components/temperature-histogram";
  import IssueCounts from "./components/issue-counts";
  import IssueStatus from "./components/issue-status";
  import ThermTicketStatus from "./components/therm-tickets-status";
  import ActionButton from "./components/action-button";
  import Metrics from "./components/metrics.vue";
  import ThermFormComponent from "../therm-form.component";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import AddUsers from "@/app/old/desktop/shared/components/add-users-modal";
  import FormPopup from "@/app/old/desktop/shared/mixins/form-popup.mixin.vue";
  import TopSection from "@/app/old/desktop/core/shared/components/details-top-section/details-top-section";
  import PinModal from "@/app/old/desktop/shared/components/pin-modal";
  import ThermSkeleton from "@/app/old/desktop/shared/components/skeleton/therm-skeleton.vue";
  import TemperatureHistogram from "@/app/old/desktop/therm-v2/components/temperature-histogram.vue";
  import BarGraph from "@/app/old/desktop/therm-v2/components/therm-v2-bar-graph.vue";

  export default {
    data() {
      return {
        metrics: null,
        details: null,
        issue_types: null,
        viewService: viewService,
        showDeleteModal: false,
        showPinModal: false,
        viewTeams: null,
        is_loading: false,
        ThermFormComponent: ThermFormComponent,
        dropdown: false
      };
    },
    mixins: [FormPopup],
    components: {
      detailsSidecard,
      InfoBlock,
      PrimaryTag,
      UserTag,
      TemperatureHistogram,
      IssueCounts,
      IssueStatus,
      ThermTicketStatus,
      ActionButton,
      Metrics,
      TopSection,
      PinModal,
      ThermSkeleton,
      BarGraph
    },
    async created() {
      this.getData(true);
      this.$eventHub.$on("refresh-therm-details", this.getData);
      this.get_view_metrics();
    },
    beforeDestroy() {
      this.$eventHub.$off("refresh-therm-details", this.getData);
    },

    methods: {
      async get_view_metrics() {
        try {
          this.metrics = await viewService.get_view_metrics({
            id: this.$route.params.id
          });
          console.log(this.metrics);
        } catch (err) {
          console.log("Error while fetching view metrics.");
        }
      },
      async getData(loading = false) {
        this.is_loading = loading;
        this.details = await viewService
          .get({
            id: this.$route.params.id,
            query: "users=true&metrics=true&complete=true&projects=true"
          })
          .catch(err => {
            this.handleErrors(err);
          });
        this.is_loading = false;
      },
      add_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [
              ...(this.details.users.readLabels || []).map(u => ({
                uid: u,
                access: "read"
              })),
              ...(this.details.users.writeLabels || []).map(u => ({
                uid: u,
                access: "write"
              }))
            ],
            item_uid: this.details.uid,
            complete: async e => {
              let readLabels = e
                .filter(u => u.access === "read")
                .map(u => u.uid);
              let writeLabels = e
                .filter(u => u.access === "write")
                .map(u => u.uid);
              await viewService
                .update({
                  id: this.details.uid,
                  body: {
                    uid: this.details.uid,
                    readLabels,
                    writeLabels
                  }
                })
                .then(res => {
                  this.getData();
                  this.$eventHub.$emit("refresh-details");
                })
                .catch(err => this.handleErrors(err));
            }
          },
          { height: "80%" }
        );
      },
      add_users() {
        this.$modal.show(
          AddUsers,
          {
            users: [
              ...(this.details.users.readOnly || []).map(u => ({
                ...u,
                access: "read"
              })),
              ...(this.details.users.readWrite || []).map(u => ({
                ...u,
                access: "write"
              }))
            ],
            item_uid: this.details.uid,
            complete: async e => {
              let readUsers = e.filter(u => u.access === "read");
              let writeUsers = e.filter(u => u.access === "write");
              await viewService
                .update({
                  id: this.details.uid,
                  body: {
                    uid: this.details.uid,
                    readUsers,
                    writeUsers
                  }
                })
                .then(res => {
                  this.getData();
                  this.$eventHub.$emit("refresh-details");
                })
                .catch(err => this.handleErrors(err));
            }
          },
          { height: "80%" }
        );
      }
    }
  };
</script>
<style>
  .box {
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(23, 30, 72, 0.07);
    background-color: #ffffff;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top-breadcrumb {
    margin-bottom: 1rem;
  }
  .therm_add_icon {
    display: inline-flex;
    justify-content: center;
    line-height: 1.7rem;
    margin: 0 1rem;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    font-size: 2rem;
    color: #6e748f;
    border: 1px dashed #6e748f;
  }
</style>

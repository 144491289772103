var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-20 py-15"},[_c('div',{staticClass:"is-inline-flex align-center is-pointer",on:{"click":function($event){_vm.repeat_forever = true;
      _vm.until = '';}}},[_c('sh-icon',{staticClass:"is-20x20 mr-10",attrs:{"name":`radio-btn${_vm.repeat_forever ? '-selected' : ''}`}}),_c('p',{staticClass:"is-regular-14-00-00",attrs:{"for":"until"}},[_vm._v(" "+_vm._s(_vm.localization("app-repeat-forever", "Repeat forever"))+" ")])],1),_c('div',[_c('div',{staticClass:"pt-10 is-flex"},[_c('div',{staticClass:"is-flex is-vcenter"},[_c('div',{staticClass:"is-inline-flex is-pointer",on:{"click":function($event){_vm.repeat_forever = false;
            _vm.until = 'occurrence';}}},[_c('sh-icon',{staticClass:"is-20x20 mr-10",attrs:{"name":`radio-btn${
                !_vm.repeat_forever && _vm.until == 'occurrence' ? '-selected' : ''
              }`}}),_c('span',{staticClass:"is-regular-14-00-00"},[_vm._v(" "+_vm._s(_vm.localization("app-repeat", "Repeat"))+" ")])],1)]),_c('div',{staticClass:"is-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.count),expression:"count"}],staticClass:"small-input is-semiBold-16-600-19 has-text-centered",class:[_vm.until != 'occurrence' ? 'is-disabled' : null],attrs:{"type":"number","min":"1","name":"internal","id":"internal"},domProps:{"value":(_vm.count)},on:{"input":function($event){if($event.target.composing)return;_vm.count=$event.target.value}}}),_c('p',{staticClass:"is-medium-16-500-19",class:{ 'has-text-lightgrey': _vm.until != 'occurrence' }},[_vm._v(" "+_vm._s(_vm.localization("app-times", "Times"))+" ")])])]),_c('div',{staticClass:"pt-10 is-flex"},[_c('div',{staticClass:"is-flex is-vcenter mr-30"},[_c('div',{staticClass:"is-inline-flex is-pointer",on:{"click":function($event){_vm.repeat_forever = false;
            _vm.until = 'date';}}},[_c('sh-icon',{staticClass:"is-20x20 mr-10",attrs:{"name":`radio-btn${
                !_vm.repeat_forever && _vm.until == 'date' ? '-selected' : ''
              }`}}),_c('span',{staticClass:"is-regular-14-00-00"},[_vm._v(" "+_vm._s(_vm.localization("app-repeat-until", "Repeat until")))])],1)]),_c('repeat-date-calendar',{attrs:{"is_disabled":_vm.until != 'date',"date":_vm.date},on:{"set_date":function($event){_vm.date = $event}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="{ invert_colors: has_dark_theme }">
    <div class="mobile-background" @click="$emit('close')"></div>

    <div
      class="dropdown"
      @click="active = true"
      v-click-outside="() => (active = false)"
    >
      <div class="dropdown-header">
        <span>
          <span class="is-medium-14-500-17 has-primary-text mr-10">{{
            localization("app-filters", "Filters")
          }}</span>
          <span
            class="is-medium-14-500-17 is-pointer"
            @click="
              resetFilters();
              $emit('close');
            "
            :class="{ 'selected-filter': selected_filter }"
            >{{ localization("app-clear-all", "Clear All") }}</span
          >
        </span>
        <span class="is-pointer" @click.stop.prevent="$emit('close')">
          <sh-icon :name="'ticket-close-filter'" class="is-10x10" />
        </span>
      </div>
      <div class="filter-dropdown-content">
        <div
          v-if="show_parameter('status')"
          class="filter-dropdown-content__item has-primary-text is-medium-14-500-17"
        >
          <span
            :class="[has_dark_theme ? 'has-text-black' : 'has-opacity-5']"
            >{{ localization("app-status", "Status") }}</span
          >
          <span>
            <all-filter-dropdown
              :add_icon_on_hover="false"
              :user_count="3"
              :is_name_hidden="true"
              :search_layout="false"
              :component="'status'"
              :has_dark_theme="has_dark_theme"
              :allFilters="allFiltersData.status"
              @updateValue="allFiltersData.status = $event"
            />
          </span>
        </div>

        <div
          v-if="show_parameter('priority')"
          class="filter-dropdown-content__item has-primary-text is-medium-14-500-17"
        >
          <span
            :class="[has_dark_theme ? 'has-text-black' : 'has-opacity-5']"
            >{{ localization("app-priority", "Priority") }}</span
          >
          <span>
            <all-filter-dropdown
              :has_dark_theme="has_dark_theme"
              :add_icon_on_hover="false"
              :user_count="3"
              :is_name_hidden="true"
              :search_layout="false"
              :component="'priority'"
              :allFilters="allFiltersData.priority"
              @updateValue="allFiltersData.priority = $event"
            />
          </span>
        </div>

        <div
          v-if="show_parameter('assignee')"
          class="filter-dropdown-content__item has-primary-text is-medium-14-500-17"
        >
          <span :class="[has_dark_theme ? 'has-text-black' : 'has-opacity-5']">
            {{ localization("app-assignee", "Assignee") }}</span
          >
          <span class="is-flex align-center" @click.prevent>
            <watcher-dropdown
              :no_access="true"
              :ticket_watchers="allFiltersData.assignee"
              @update-watchers="allFiltersData.assignee = $event"
              :custom_icon="'add-users-teams'"
              :user_count="3"
              :has_dark_theme="has_dark_theme"
              :inline_remove="true"
            ></watcher-dropdown>
          </span>
        </div>
        <div
          v-if="show_parameter('created_by')"
          class="filter-dropdown-content__item has-primary-text is-medium-14-500-17"
        >
          <span
            :class="[has_dark_theme ? 'has-text-black' : 'has-opacity-5']"
            >{{ localization("app-created-by", "Created by") }}</span
          >
          <span class="is-flex align-center" @click.prevent>
            <watcher-dropdown
              :no_access="true"
              :ticket_watchers="allFiltersData.owner"
              @update-watchers="allFiltersData.owner = $event"
              :custom_icon="'add-users-teams'"
              :has_dark_theme="has_dark_theme"
              :user_count="3"
              :inline_remove="true"
            ></watcher-dropdown>
          </span>
        </div>

        <div
          v-if="show_parameter('duedate')"
          class="filter-dropdown-content__item has-primary-text is-medium-14-500-17"
        >
          <span
            :class="[has_dark_theme ? 'has-text-black' : 'has-opacity-5']"
            >{{ localization("app-due-date", "Due Date") }}</span
          >
          <span class="is-flex align-center">
            <duedate-dropdown
              :icon_class="'is-20x20 mr-5'"
              class="is-medium-14-500-17 has-text-new-grey"
              :due="allFiltersData.dueDate"
              @change-duedate="allFiltersData.dueDate = $event"
            ></duedate-dropdown>
          </span>
        </div>

        <div
          v-if="show_parameter('tags')"
          class="filter-dropdown-content__item has-primary-text is-medium-14-500-17"
        >
          <span
            :class="[has_dark_theme ? 'has-text-black' : 'has-opacity-5']"
            >{{ localization("app-tags", "Tags") }}</span
          >
          <span class="is-flex align-center">
            <div v-if="isMobile">
              <mobile-tags-dropdown
                @input="allFiltersData.tags = $event"
                :initial_value.sync="allFiltersData.tags"
                :search_layout="true"
                :component="'tags'"
                :has_dark_theme="has_dark_theme"
              />
            </div>
            <div v-else>
              <all-filter-dropdown
                :add_icon_on_hover="false"
                :search_layout="false"
                :component="'tags'"
                :allFilters="allFiltersData.tags"
                @updateValue="allFiltersData.tags = $event"
                :has_dark_theme="has_dark_theme"
              />
            </div>
          </span>
        </div>

        <div class="filter-dropdown-footer">
          <label
            class="filter-dropdown-footer__item"
            v-for="item in filterOptions"
            :key="item.header"
            :class="{ 'is-hidden': !show_parameter(item.header) }"
          >
            <span class="is-flex">
              <sh-icon :name="item.icon" class="is-16x16 mr-10" />
              <span
                class="is-medium-14-500-17"
                :class="{ invert_colors: has_dark_theme }"
                >{{
                  localization(
                    `app-${item.header
                      .split(" ")
                      .join("-")
                      .toLowerCase()}`,
                    item.header
                  )
                }}</span
              >
            </span>
            <span>
              <input
                type="checkbox"
                class="sh-custom-checkbox"
                autocomplete="false"
                v-model="allFiltersData.options"
                :id="item.value"
                :value="item.value"
              />
            </span>
          </label>
          <!-- Use this slot to insert custom content -->
          <slot name="custom-content" :allFiltersData="allFiltersData"></slot>
          <div
            @click="apply"
            class="filter-dropdown-button is-semiBold-14-600-17"
          >
            {{ localization("app-apply-filters", "Apply Filters") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AllFilterDropdown from "@/app/old/desktop/tickets/shared/components/filter-card/components/all-filter-dropdown";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-range-dropdown";
  import WatcherDropdown from "@/app/old/desktop/tickets/shared/components/watchers-dropdown";
  import MobileTagsDropdown from "@/app/old/mobile/viewer/shared/components/mobile-tags-dropdown.vue";

  export default {
    components: {
      AllFilterDropdown,
      UserTag,
      WatcherDropdown,
      DuedateDropdown,
      MobileTagsDropdown
    },
    props: {
      has_dark_theme: {
        type: Boolean,
        required: false,
        default: false
      },
      key_map: {
        type: Object,
        default: {}
      },
      filtersData: {
        type: Object
      },
      visible_parameter: {
        type: Array,
        default: [
          "status",
          "priority",
          "assignee",
          "created_by",
          "duedate",
          "tags",
          "favorites",
          "archived"
        ]
      }
    },
    data() {
      return {
        allFiltersData: {
          priority: [],
          status: [],
          options: [],
          assignee: [],
          tags: [],
          dueDate: [],
          owner: []
        },
        selected_filter: true,
        active: false,
        filterOptions: [
          {
            header: "favorites",
            icon: "ticket-star-rating",
            value: "bookmarked"
          },
          { header: "archived", icon: "ticket-archieved", value: "archived" }
        ]
      };
    },
    created() {
      for (let k in this.filtersData) {
        this.$set(this.allFiltersData, k, [...this.filtersData[k]]);
      }
    },
    methods: {
      show_parameter(param) {
        // console.log(param);
        // console.log(this.visible_parameter);
        return _.includes(this.visible_parameter, param);
      },
      apply() {
        if (this.filtersData && this.filtersData["temperature_difference"]) {
          this.$set(this.allFiltersData, "temperature_difference", [
            ...this.filtersData["temperature_difference"]
          ]);
        }

        this.$emit("apply-viewer", this.allFiltersData);

        for (let k in this.allFiltersData) {
          this.filtersData[k] = [...this.allFiltersData[k]];
        }
        let str = this.filterQueryString();
        this.$emit("close");
        this.$emit("apply", str);
        this.$store.commit("tickets/SET_FILTERS_DATA", this.filtersData);
        this.$store.commit("thermv2/SET_ACTIVE_FILTERS", this.filtersData);
        this.$store.commit(
          "tickets/IS_FILTERS_SELECTED",
          str && str.length ? true : false
        );
      },
      filterQueryString() {
        let val = this.allFiltersData;
        let str = "";
        str += this.getQueryString(val.status, "status");
        str += this.getQueryString(val.priority, "priority");
        str += this.getQueryString(
          val.assignee.map(d => d.uid),
          "takenUp"
        );
        str += this.getQueryString(
          val.owner.map(d => d.uid),

          this.key_map.owner || "owner"
        );
        str += this.getQueryString(val.tags, "tag");
        str +=
          val.dueDate.length > 1
            ? `&${this.key_map.startDate || "startDate"}=` +
              val.dueDate[0].toISOString() +
              `&${this.key_map.dueDate || "dueDate"}=` +
              val.dueDate[1].toISOString()
            : "";
        str += val.options.reduce((c, d) => (c += `&${d}=true`), "");
        return str;
      },
      getQueryString(arr, query) {
        let str = "";
        arr.forEach(val => {
          str += `&${query}=` + val;
        });
        return str;
      },
      resetFilters() {
        Object.keys(this.filtersData).forEach(key => {
          this.filtersData[key] = [];
        });
        this.$emit("apply-viewer", {});
        this.$emit("apply", "");
        this.$store.commit("thermv2/SET_ACTIVE_FILTERS", null);
        this.$store.commit("tickets/IS_FILTERS_SELECTED", false);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dropdown {
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(23, 30, 72, 0.1);
    background-color: #ffffff;
    position: absolute;
    width: 260px;
    z-index: 1;
    border-radius: 1rem;
    top: auto;
    // left: 12px;
    right: 1.2rem;
    display: block;
    @include for-size(portrait-tablets) {
      position: fixed;
      width: 100vw;
      top: auto;
      left: 0;
      bottom: 0;
      z-index: 100;
    }
    @include for-size(tablet) {
      position: fixed;
      width: 100vw;
      top: auto;
      left: 0;
      bottom: 0;
      z-index: 100;
    }

    &-header {
      padding: 2rem 2rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(23, 30, 72, 0.15);
    }

    &-input {
      margin: 0 2rem;
    }
    &-content {
      padding: 2rem;
    }
  }
  .filter-dropdown-content {
    border-bottom: 1px solid rgba(23, 30, 72, 0.15);
    &__item {
      min-height: 4.2rem;
      padding: 1.2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .filter-dropdown-footer {
    &__item {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 2rem;
      cursor: pointer;
      // &:first-child {
      //   padding: 2rem 2rem 0.5rem 2rem;
      // }
      // &:last-child {
      //   padding: 2rem 2rem 0rem;
      // }
    }
  }
  .filter-dropdown-button {
    height: 4rem;
    margin: 2rem;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(92, 126, 247, 0.2);
    border: solid 1px $primary;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    cursor: pointer;
    &:hover {
      background-color: $primary;
      color: $white;
      box-shadow: 0 2px 5px 0 rgba(92, 126, 247, 0.2);
    }
  }

  .is-options-list {
    z-index: 10;
    width: 20rem;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(23, 30, 72, 0.1);
    background-color: $white;
    .input {
      height: 4rem;
    }

    .control.has-icons-left .icon.is-left {
      height: 4rem;
      width: 1.5rem;
    }
    .item--text {
      width: 15ch;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .add-followers {
    visibility: hidden;
  }
  .selected-filter {
    color: $primary;
  }
</style>

import { render, staticRenderFns } from "./assign-group-dropdown.vue?vue&type=template&id=15dad6bc"
import script from "./assign-group-dropdown.vue?vue&type=script&lang=js"
export * from "./assign-group-dropdown.vue?vue&type=script&lang=js"
import style0 from "./assign-group-dropdown.vue?vue&type=style&index=0&id=15dad6bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
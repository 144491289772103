<template>
  <div
    class="vault-versions has-background-white card "
    @mouseover.stop=""
    @mouseenter.stop=""
    @mouseover.prevent=""
    @mouseenter.prevent=""
  >
    <div class="is-flex is-between padding-10 px-20">
      <p class="is-medium-14-500-17">
        {{ localization("app-versions", "Versions") }}
      </p>
      <p>
        <sh-icon
          @click.native="$emit('close')"
          :name="'ticket-close-filter'"
          class="is-14x14 mr-5"
        />
      </p>
    </div>
    <div
      class="vault-versions--content has-slimscroll-xs"
      v-if="!is_loading && versions.length"
    >
      <template v-for="(file, i) in versions">
        <list-item-file
          :key="i"
          :is_viewer_small="is_viewer_small"
          :original_uid="item.uid"
          :default_uid="item.defaultversion"
          :item="file"
          :i="i"
        />
      </template>
    </div>
    <p
      :class="{ 'is-disabled': !item.ownerAccess }"
      v-if="!show_input"
      class="vault-versions--button  is-absolute is-flex is-medium-14-500-17"
      @click="show_input = true"
    >
      <sh-icon
        :file="'helper_sprite'"
        :name="'upload-grey'"
        class="ml-20 mr-5 is-pointer is-24x24"
      />
      <span class="is-medium-16-500-50 ">{{
        localization("app-upload-new-version", "Upload new Version")
      }}</span>
    </p>
    <div
      class="vault-versions-name vault-versions--button  is-absolute"
      v-click-outside="
        () => {
          show_input = false;
        }
      "
      v-if="show_input"
    >
      <div class=" is-flex align-center">
        <input
          @click.prevent.stop
          v-focus
          class="vault-versions-name--input is-medium-14-500-17"
          type="text"
          :placeholder="localization('app-version-name', 'Version Name')"
          v-model="form.name"
          @keyup.enter="
            form.name ? $emit('open_version_file_upload', form.name) : ''
          "
        />
        <p
          @click.stop="
            form.name ? $emit('open_version_file_upload', form.name) : ''
          "
          :class="{ 'is-disabled': !form.name }"
        >
          <sh-icon
            :name="'ticket-show-tick'"
            class="ml-10 is-16x16 is-pointer"
          ></sh-icon>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import ListItemFile from "@/app/old/desktop/vault/components/vault-list/components/vault-files/vault-file-versions/version-file-item";
  import { fileService } from "@/app/old/desktop/shared/services/";

  export default {
    components: {
      ListItemFile
    },
    data() {
      return {
        versions: [],
        show_input: false,
        form: { name: "" },
        is_loading: true,
        default: ""
      };
    },
    props: ["item", "is_viewer_small"],
    created() {
      this.$eventHub.$on("uploaded-new-version", () => {
        this.get_versions();
      });
      this.get_versions();
    },
    beforeDestroy() {
      this.$eventHub.$off("uploaded-new-version");
    },
    methods: {
      async get_versions() {
        this.versions = await fileService.get_versions({ id: this.item.uid });
        this.is_loading = false;
        // console.log(this.versions)
      }
    }
  };
</script>

<style lang="scss">
  .vault-versions {
    width: 40rem;
    height: 30rem;
    z-index: 30;
    border-radius: 1rem;
    box-shadow: 10px 20px 50px 0 rgba(23, 30, 72, 0.2);
    border: solid 1px rgba(23, 30, 72, 0.15);
    &--content {
      height: 21.2rem;
      overflow: auto;
    }
    &--button {
      border-top: 1px solid #eee;
      bottom: 0rem;
      width: 100%;
      padding: 1.6rem 0rem 1rem;
    }
    &-name {
      height: 50px;
      // width: 70%;
      // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      // display: flex;
      &--input {
        width: 80%;
        color: #171e48;
        outline: none;
        border: none;
        border-right: 1px solid #eee;
        margin-left: 2rem;
        &::placeholder {
          color: rgba(23, 30, 72, 0.4);
        }
      }
    }
  }
</style>

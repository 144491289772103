<template>
  <div class="columns is-regular-14-00-17 is-marginless bb-1 sh-list-header">
    <div class="column is-3_5">{{ localization("app-assets", "Assets") }}</div>
    <div class="column is-2_5">{{ localization("app-owner", "Owner") }}</div>
    <div class="column is-3">{{ localization("app-teams", "Teams") }}</div>
    <div class="column is-3">{{ localization("app-tags", "Tags") }}</div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_SYSTEM_MODEL_API_HOST

const URL = 'components'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }

  add_layout_for_parent_component(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/add/layout/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  add_alignment_for_association(req = {}) {
    return httpClient({
      url: `${this.url}/${req.parent_component}/add/alignment/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  add_grid_for_association(req = {}) {
    return httpClient({
      url: `${this.url}/${req.parent_component}/add/grid/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  duplicate_component(req = {}) {
    return httpClient({
      url: `${this.url}/${req.parent_component}/duplicate/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  fetch_parents(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/get-parents`,
      baseURL: this.baseURL,
      method: 'get',
      header: req.headers
    }).then(res => res.data)
  }

  fetch_valid_components(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/valid-components/`,
      baseURL: this.baseURL,
      method: 'get',
      header: req.headers
    }).then(res => res.data)
  }

  add_parent_for_component(req = {}) {
    return httpClient({
      url: `${this.url}/${req.component_id}/parent/${req.parent_id}/`,
      baseURL: this.baseURL,
      method: 'post',
      header: req.headers
    }).then(res => res.data)
  }

  remove_parent_for_component(req = {}) {
    return httpClient({
      url: `${this.url}/${req.component_id}/parent/${req.parent_id}/`,
      baseURL: this.baseURL,
      method: 'delete',
      header: req.headers
    }).then(res => res.data)
  }

  // bulk operations
  add_parents_for_component(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/parents/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  remove_parents_for_component(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/parents/`,
      baseURL: this.baseURL,
      method: 'delete',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  create_instance(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/instances`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  fetch_instances(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/instances${req.query ? `?${req.query}` : ''}`,
      baseURL: this.baseURL,
      method: 'get',
      header: req.headers
    }).then(res => res.data)
  }

  import_csv(req = {}) {
    return httpClient({
      url: `csv/plan/${req.plan_id}/component/${req.component_id}/import-csv/${
        req.query ? `?${req.query}` : ''
      }`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  delete_component(req = {}) {
    return httpClient({
      url: `${this.url}`,
      baseURL: this.baseURL,
      method: 'delete',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
}

export const componentsService = new API()

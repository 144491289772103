<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        Defects types
      </p>
    </header>
    <div class="card-content">
      <div class="content" style="height:300px;overflow:auto">
        <div
          v-for="issue in issue_types_list"
          :key="issue.class_id"
          class="mt-5"
        >
          <b-checkbox v-model="selected" :native-value="issue.class_id">{{
            issue.description
          }}</b-checkbox>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a @click="save" class="card-footer-item">{{
        loading ? "Saving..." : "Save"
      }}</a>
    </footer>
  </div>
</template>

<script>
  import { viewService } from "@/app/old/desktop/shared/services/";

  export default {
    props: ["details", "issue_types_list"],
    data() {
      return {
        loading: false,
        selected: []
      };
    },
    created() {
      this.selected = this.details.issueGroupIds || [];
    },
    methods: {
      async save() {
        try {
          this.loading = true;
          let data = await viewService.update({
            body: { uid: this.details.uid, issueGroupIds: this.selected }
          });
          this.$emit("update", {
            ...this.details,
            issueGroupIds: this.selected
          });
          setTimeout(() => {
            this.$emit("close");
          }, 100);

          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      }
    }
  };
</script>

<style></style>

<template>
  <div class="tickets-filters-groupby" v-click-outside="() => (active = false)">
    <div class="dropdown is-right" :class="{ 'is-active': active }">
      <div class="dropdown-trigger is-pointer" @click="active = !active">
        <p class="is-flex is-vcenter">
          <sh-icon :name="'export'" class="is-12x12 has-opacity-7"></sh-icon>
          <span class="heading-2">{{
            localization("app-export", "Export")
          }}</span>
          <span class="icon is-small">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content" style="width:15rem">
          <draggable v-model="list" handle=".handle">
            <transition-group type="transition" :name="'flip-list'">
              <a
                class="dropdown-item"
                :key="item"
                v-for="(item, i) in list"
                @click="handle_fields(item)"
              >
                <span class="is-capitalized is-flex is-vcenter handle">
                  <sh-icon :name="'drag-move'" class="is-16x16 mr-5 " />
                  {{
                    localization(
                      `app-${(item == "dueDate" ? "Due  Date" : item)
                        .split(" ")
                        .join("-")
                        .toLowerCase()}`,
                      item == "dueDate" ? "Due  Date" : item
                    )
                  }}
                </span>
                <sh-icon
                  :name="'tick'"
                  class="is-16x16"
                  :class="{ 'is-vhidden': !fields.includes(item) }"
                />
              </a>
            </transition-group>
          </draggable>
          <div class="bt-1 is-flex is-center py-10">
            <div
              class="button is-medium is-info is-outlined"
              style="width:10rem"
              @click="export_csv"
            >
              <span v-if="is_saving">
                <i class="fas fa-circle-notch rotation"></i>
              </span>
              <span v-else class="is-medium-14-500-17">
                {{ localization("app-export-to-csv", "Export to CSV") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import { mapState } from "vuex";

  export default {
    props: ["allFiltersData"],
    data() {
      return {
        active: false,
        list: [
          "name",
          "status",
          "tags",
          "assignee",
          "dueDate",
          "priority",
          "attachments"
        ],
        fields: [
          "name",
          "status",
          "tags",
          "assignee",
          "dueDate",
          "priority",
          "attachments"
        ],
        is_saving: false
      };
    },
    components: {
      draggable
    },

    computed: {
      ...mapState("tickets", {
        selected_asset: "selected_asset",
        search_by: "search_by",
        sort_key: "sort_key",
        selected: "selected",
        select_all_tickets: "select_all_tickets",
        state_filters: "allFiltersData",
        display_filters: "filters"
      })
    },

    methods: {
      handle_fields(item) {
        if (this.fields.includes(item)) {
          this.fields = _.pull(this.fields, item);
          this.fields = [...this.fields];
        } else {
          this.fields.push(item);
        }
      },
      async export_csv() {
        this.is_saving = true;
        let overview_filters;
        if (this.allFiltersData) {
          overview_filters = _.pickBy(
            this.allFiltersData,
            v => v !== null && v !== undefined
          );
          if (
            this.allFiltersData.options &&
            this.allFiltersData.options.length
          ) {
            overview_filters[
              "bookmarked"
            ] = this.allFiltersData.options.includes("bookmarked");
            overview_filters["archived"] = this.allFiltersData.options.includes(
              "archived"
            );
            delete overview_filters.options;
          }
          if (
            this.allFiltersData.dueDate &&
            this.allFiltersData.dueDate.length
          ) {
            overview_filters["dueDateStart"] = this.allFiltersData.dueDate[0];
            overview_filters["dueDateEnd"] = this.allFiltersData.dueDate[0];
            delete overview_filters.dueDate;
          }
          if (this.allFiltersData.tags && this.allFiltersData.tags.length) {
            overview_filters["tag"] = this.allFiltersData.tags;
            delete overview_filters.tags;
          }
          if (
            this.allFiltersData.assignee &&
            this.allFiltersData.assignee.length
          ) {
            overview_filters["assignee"] = this.allFiltersData.assignee.map(
              a => a.uid
            );
          }
        }
        let store_filters;
        if (this.state_filters) {
          store_filters = _.pickBy(
            this.state_filters,
            v => v !== null && v !== undefined
          );
          if (this.state_filters.options && this.state_filters.options.length) {
            store_filters["bookmarked"] = this.state_filters.options.includes(
              "bookmarked"
            );
            store_filters["archived"] = this.state_filters.options.includes(
              "archived"
            );
            delete store_filters.options;
          }
          if (this.state_filters.dueDate && this.state_filters.dueDate.length) {
            store_filters["dueDateStart"] = this.state_filters.dueDate[0];
            store_filters["dueDateEnd"] = this.state_filters.dueDate[0];
            delete store_filters.dueDate;
          }
          if (this.state_filters.tags && this.state_filters.tags.length) {
            store_filters["tag"] = this.state_filters.tags;
            delete store_filters.tags;
          }
          if (
            this.state_filters.assignee &&
            this.state_filters.assignee.length
          ) {
            store_filters["assignee"] = this.state_filters.assignee.map(
              a => a.uid
            );
          }
        }

        let filtersData = { ...overview_filters, ...store_filters };

        filtersData[
          "organization"
        ] = this.$store.state.current_organization.uid;
        if (this.selected_asset && this.selected_asset.length) {
          filtersData["assetUid"] = this.selected_asset;
        }
        if (this.search_by) {
          filtersData["q"] = this.search_by;
        }
        if (this.sort_key) {
          filtersData["sort"] = this.sort_key;
        }
        if (this.selected && this.selected.length && !this.select_all_tickets) {
          filtersData["ticketUid"] = this.selected;
        }

        this.display_filters.map(f =>
          f.active ? (filtersData[f.key] = f.value) : null
        );
        let res = await ticketService
          .generate_tickets_csv({
            body: {
              filters: filtersData,
              fields: _.intersection(this.list, this.fields)
            }
          })
          .catch(err => this.handleErrors(err, true));
        this.readFile(res);
      },
      readFile(doc) {
        let csv;
        const Papa = require("papaparse");
        Papa.parse(doc, {
          delimiter: "", // auto-detect
          newline: "", // auto-detect
          quoteChar: '"',
          escapeChar: '"',
          error: undefined,
          download: false,
          header: true,
          skipEmptyLines: true,
          comments: true,

          delimitersToGuess: [
            ",",
            "\t",
            "|",
            ";",
            Papa.RECORD_SEP,
            Papa.UNIT_SEP
          ],
          complete: results => {
            csv = results.data;
          }
        });

        let final = Papa.unparse(csv);
        var csvData = new Blob([final], { type: "text/csv;charset=utf-8;" });
        var csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "download.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "download.csv");
        tempLink.click();
        this.is_saving = false;
      }
    }
  };
</script>

<style lang="scss">
  .tickets-filters-groupby {
    .dropdown-content {
      width: 135px;
      .dropdown-item {
        font-size: 1.4rem !important;
      }
    }
    .dropdown-content {
      border-radius: 12px;
      box-shadow: 10px 20px 50px 0 rgba(23, 30, 72, 0.2);
      border: solid 1px rgba(23, 30, 72, 0.15);
      background-color: white;
      .dropdown-item {
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        font-family: Barlow-Medium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.13;
        letter-spacing: normal;
        text-align: left;
        color: #171e48;
        &:hover {
          background-color: #f4f6fe;
        }
      }
    }
  }
  .rotation {
    animation: spin 1s linear infinite;
  }
  .heading-1 {
    opacity: 0.4;
    font-family: Barlow-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #171e48;
  }
  .heading-2 {
    margin-left: 7px;
    margin-right: 7px;
    font-family: Barlow-SemiBold;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #171e48;
    cursor: pointer;
  }
</style>

<template>
  <div
    v-if="$route.name == 'therm-v2-tickets'"
    class="thermv2-defects-mainlist"
  >
    <div class="tickets-top-section mb-30"></div>
    <div class="has-text-centered mb-10 px-20" v-if="loading">
      <tickets-list-skeleton></tickets-list-skeleton>
    </div>
    <div v-if="!page_defects.length && !loading">
      <no-results>
        <img
          slot="image"
          src="@/assets/illustrations/tickets-illustration.svg"
          alt
        />
      </no-results>
    </div>
    <div
      class="px-20 defect-list"
      :class="{
        'is-hidden': loading || !page_defects.length || !active_projects.length
      }"
    >
      <div
        class="is-flex is-between align-center py-20 defect-list-header has-background-white"
        style="height:6rem;"
      >
        <p class="px-20 defect-list-header--name">
          {{ localization("app-defect-remediation", "Defect Remediation") }}
        </p>
        <div class="px-20 is-flex align-center">
          <sh-icon
            v-if="selected_defects.length"
            @click.native="unselect_all_defects"
            :name="'ticket-close-filter'"
            class="is-10x10 remove-icon is-pointer mr-10"
          />
          <p v-if="selected_defects.length">
            <span class="mr-5">{{ selected_defects.length }}</span
            >{{ localization("app-defects-selected", "Defects Selected") }}
          </p>
          <bulk-actions-bar
            class="mr-20 ml-10"
            @refresh-tickets="create_properties_string(true)"
            :active_projects="active_projects"
          ></bulk-actions-bar>
          <column-dropdown @column-change="handleColumn" />
        </div>
      </div>
      <div
        class="defect-list-grid"
        id="grid"
        style="height: 100%; width: 100%"
      ></div>
    </div>

    <div class="columns is-gapless px-20 is-marginless defect-list-pagination">
      <div class="column is-gapless is-marginless">
        <pagination
          :default_page_size="default_page_size"
          :current-page="currentPage"
          :page-count="pageCount"
          @nextPage="pageChangeHandle('next')"
          @previousPage="pageChangeHandle('previous')"
          @loadPage="pageChangeHandle"
          :visiblePagesCount="visiblePagesCount"
          :totalRecords="totalRecords"
          @pagination-clicked="pageClicked = true"
          :page_sizes="[10, 25, 50, 100]"
          @default-page-size="
            default_page_size = $event;
            pageChangeHandle(1, '', true);
          "
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import TicketDetails from "./therm-v2-ticket-details";
  import DefectsMixin from "@/app/old/desktop/therm-v2/components/defects.mixin.vue";
  import ColumnDropdown from "@/app/old/desktop/therm-v2/components/therm-v2-column-dropdown.vue";
  import BulkActionsBar from "@/app/old/desktop/therm-v2/components/therm-v2-defects-bulk-action.vue";
  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";
  import TicketsListSkeleton from "@/app/old/desktop/shared/components/skeleton/tickets/tickets-list-skeleton.vue";
  import PaginationMixin from "@/app/old/desktop/shared/mixins/pagination.mixin.vue";
  import Pagination from "@/app/old/desktop/shared/components/pagination.vue";
  import { mapState, mapMutations, mapGetters } from "vuex";
  import {
    ticketService,
    apiService
  } from "@/app/old/desktop/shared/services/";
  export default {
    mixins: [PaginationMixin, TicketDetailsMixin, DefectsMixin],
    components: {
      Pagination,
      TicketDetails,
      TicketsListSkeleton,
      BulkActionsBar,
      ColumnDropdown
    },
    data() {
      return {
        allFiltersData: {},
        show_details: false,
        properties: "",
        tags: [],
        search_by: null,
        loading: false,
        grid: null,
        pagination: null,
        priorities: {
          1: "Critical",
          2: "High",
          3: "Medium",
          4: "Low"
        },
        statuses: {
          1: "Pending",
          2: "In Progress",
          3: "Resolved",
          4: "Closed",
          5: "Rejected"
        },
        sort_by: {
          key: "name",
          order: "asc"
        }
      };
    },

    mounted() {
      this.$store.commit("thermv2/CLEAR_SELECTED_TICKETS");
    },
    computed: {
      ...mapState(["TAGS"]),
      ...mapState("thermv2", [
        "projects",
        "defects",
        "active_class_ids",
        "active_filters",
        "active_projects",
        "selected_defects",
        "vector_types",
        "mini_thermsidebar"
      ]),
      ...mapGetters("thermv2", ["filtered_defects"]),
      page_defects() {
        let start =
          (this.$route.query.page_size || 10) *
          ((this.$route.query.page || 1) - 1);
        let end = start + (parseInt(this.$route.query.page_size) || 10);
        return this.format_filtered_defects.slice(start, end);
      },
      format_filtered_defects() {
        return _.orderBy(
          this.format_defects_data(this.filtered_defects),
          [this.sort_by.key],
          [this.sort_by.order]
        );
      },
      config() {
        return {
          columns: [
            {
              id: "select",
              header: [
                {
                  text: `<input class='sh-custom-checkbox is-thinner fill' type='checkbox' id='select' value='select'>`
                }
              ],
              editorType: "checkbox",
              type: "boolean",
              width: 40,
              adjust: "data",
              sortable: false,
              resizable: false,
              autoWidth: false
              // mark: (cell, data) => {
              //   return "grid-unchecked";
              // }
            },
            // {
            //   id: "select",
            //   header: [{ text: "<input class='sh-custom-checkbox is-thinner fill' type='checkbox' disabled='true'>" }],
            //   width: 24,
            //   sortable: false,
            //   resizable: false,
            //   htmlEnable: true,
            //   template: (text, row, col) => {
            //     return `<input class='sh-custom-checkbox is-thinner fill' type='checkbox' id='${row.id}' value='${row.select}'>`;
            //   }
            // },

            {
              id: "name",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-name",
                    "Name"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
                // {
                //   content: "inputFilter",
                //   filterConfig: { placeholder: "Type name", virtual: true }
                // }
              ],
              sortable: false,
              editable: false
              // align: "left"
            },
            {
              id: "inverter",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-inverter",
                    "Inverter"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
                // {
                //   content: "inputFilter",
                //   filterConfig: { placeholder: "Type name", virtual: true }
                // }
              ],
              sortable: false,
              editable: false
              // align: "left"
            },
            {
              id: "string_name",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-string",
                    "String"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              editable: false
              // adjust: "header"
            },
            {
              id: "defect_type",
              header: [
                {
                  text: `<div class="is-flex align-center"><div> ${this.localization(
                    "app-defect-type",
                    "Defect Type"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
                // { content: "inputFilter" }
              ],
              editable: false
            },
            {
              id: "assignee_name",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-assignee",
                    "Assignee"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              editable: false
            },
            {
              id: "dueDate",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-end-date",
                    "End date"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              type: "date",
              htmlEnable: true,
              sortable: false,
              editable: false,
              template(text) {
                return text ? moment(text).format("MMMM Do YYYY") : "";
              },
              editorType: "datePicker"
            },
            // {
            //   id: "priority",
            //   width: 80,
            //   header: [{ text: "Priority" }],
            //   editorType: "select",
            //   options: ["Critical", "High", "Medium", "Low"],
            //   htmlEnable: true,
            //   template: (text, row, col) => {
            //     return this.priorities[row.priority];
            //   },
            //   mark: (cell, data, row, col) => {
            //     let color = "";
            //     switch (cell) {
            //       case 1:
            //         color = "has-text-red";
            //         break;
            //       case 2:
            //         color = "has-text-yellow";
            //         break;
            //       case 3:
            //         color = "has-text-green";
            //         break;
            //       case 4:
            //         color = "has-text-blue";
            //         break;
            //     }
            //     return color;
            //   }
            // },
            {
              id: "status_name",
              // width: 600,
              // autoWidth: false,
              // adjust: "header",

              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-status",
                    "Status"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }

                // {
                //   content: "inputFilter",
                //   filterConfig: { placeholder: "Type name", virtual: true }
                // }
              ],
              sortable: false,
              // align: "left",
              editorType: "select",
              options: [
                "Pending",
                "In Progress",
                "Resolved",
                "Closed",
                "Rejected"
              ],
              htmlEnable: true,
              template: (text, row, col) => {
                let ele = "";
                switch (text) {
                  case "Pending":
                    ele = `<div class="is-flex align-center"><span class="status-pointer  has-background-pending"></span><span class="pl-5">${text}</span></div>`;
                    break;
                  case "In Progress":
                    ele = `<div class="is-flex align-center"><span class="status-pointer  has-background-inprogress"></span><span class="pl-5">${text}</span></div>`;
                    break;
                  case "Resolved":
                    ele = `<div class="is-flex align-center"><span class="status-pointer  has-background-resolved"></span><span class="pl-5">${text}</span></div>`;
                    break;
                  case "Closed":
                    ele = `<div class="is-flex align-center"><span class="status-pointer  has-background-closed"></span><span class="pl-5">${text}</span></div>`;
                    break;
                  case "Rejected":
                    ele = `<div class="is-flex align-center"><span class="status-pointer  has-background-rejected"></span><span class="pl-5">${text}</span></div>`;
                    break;
                }
                return ele;
              },
              editable: false
            },
            {
              id: "priority_name",
              width: 80,
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-priority",
                    "Priority"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              sortable: false,
              editorType: "select",
              options: ["Critical", "High", "Medium", "Low"],
              htmlEnable: true,
              editable: false,
              template: (text, row, col) => {
                let ele = "";
                switch (text) {
                  case "Critical":
                    ele = '<div style="color: red;">' + text + "</div>";
                    break;
                  case "High":
                    ele = '<div style="color: #e19d3a;">' + text + "</div>";
                    break;
                  case "Medium":
                    ele = '<div style="color: green;">' + text + "</div>";
                    break;
                  case "Low":
                    ele = '<div style="color: blue;">' + text + "</div>";
                    break;
                }
                return ele;
              }
              // mark: (cell, data, row, col) => {
              //   let color = "";
              //   switch (cell) {
              //     case "Critical":
              //       color = "has-text-red";
              //       break;
              //     case "High":
              //       color = "has-text-yellow";
              //       break;
              //     case "Medium":
              //       color = "has-text-green";
              //       break;
              //     case "Low":
              //       color = "has-text-blue";
              //       break;
              //   }
              //   return color;
              // }
            },
            {
              id: "timestamp",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-timestamp",
                    "Timestamp"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              editable: false,
              hidden: false
              // adjust: "header"
            },
            {
              id: "temperature",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>Δ T</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              editable: false,
              hidden: true
              // adjust: "header"
            },

            {
              id: "tags",
              header: [
                {
                  text: `<div class="is-flex align-center"><div>${this.localization(
                    "app-tags",
                    "Tags"
                  )}</div><div><i
                  class="fas fa-chevron-up fa-xs header-sort-icon"
                ></i>
                <i
                  class="fas fa-chevron-down fa-xs header-sort-icon"
                ></i></div></div>`
                }
              ],
              htmlEnable: true,
              editable: false,
              template: (tags, row, col) => {
                let list_of_assigned_tags = [];
                if (row.tags.length) {
                  row.tags.forEach(tag_uid => {
                    if (this.TAGS[tag_uid]) {
                      list_of_assigned_tags.push(this.TAGS[tag_uid]);
                    }
                  });
                }
                let display_tags = list_of_assigned_tags.length
                  ? list_of_assigned_tags.slice(0, 3)
                  : [];
                let data = `<div class="is-inline-flex align-center">
                    ${display_tags
                      .map(
                        tag =>
                          `<span class="is-medium-14-500-17 tag-border mr-5" style="color: ${tag.color};">${tag.name}</span>`
                      )
                      .join("")}
                        </div>`;
                return row.tags.length ? data : "";
              }
            }
          ],

          css: "has-background-white",
          data: [],
          // height: 450,
          autoWidth: true,

          headerRowHeight: 50,
          rowHeight: 50,
          sortable: false,
          resizable: true,
          editable: true,
          selection: "row",
          keyNavigation: true,
          multiselection: true,
          // splitAt: 2,
          adjust: false,
          // dragMode: "both",
          draggable: true,
          dragItem: "column"
        };
      }
    },
    watch: {
      filtered_defects: {
        handler(newval, oldval) {
          if (this.$route.name == "therm-v2-tickets") {
            if (newval && oldval && newval.length !== oldval.length) {
              this.pageChangeHandle(1, "", true);
              this.updatePagination(this.filtered_defects.length);
            }
            if (this.grid) {
              this.grid.data.parse(this.page_defects);
              // this.grid.paint();
            } else {
              this.initGrid(this.page_defects);
              this.handleColumn(this.$store.state.thermv2.columns);
              // this.grid.paint();
            }
          }
        }
        // immediate: true
      },
      // active_projects(val) {
      //   this.create_properties_string();
      // },
      // active_filters: {
      //   handler(val) {
      //     if (val) this.create_properties_string();
      //   },
      //   deep: true
      // },
      mini_thermsidebar: {
        handler(val) {
          if (this.$route.name == "therm-v2-tickets") {
            this.initGrid(this.page_defects);
          }
        }
      }
    },
    beforeDestroy() {
      this.$store.dispatch("thermv2/select_ticket");
      if (this.grid) {
        this.grid.destructor();
      }
    },
    mounted() {
      if (this.filtered_defects) {
        this.initGrid(this.page_defects);
        this.updatePagination(this.filtered_defects.length);
        this.set_checkbox_style(this.page_defects);
      }
    },
    methods: {
      ...mapMutations("thermv2", ["SET_DEFECTS"]),
      // initPagination() {
      //   this.pagination = new dhx.Pagination("pagination", {
      //     css: "dhx_widget--bordered dhx_widget--no-border_top",
      //     data: this.grid.data,
      //     pageSize: 5
      //   });
      // },
      handleColumn(columns) {
        columns.forEach(column => {
          if (column.hidden) this.grid.hideColumn(column.id);
          else this.grid.showColumn(column.id);
        });
      },
      format_defects_data(defects) {
        return defects.map(defect => {
          return {
            name: defect.name,
            uid: defect.uid,
            string_name: defect.properties.stringName,
            defect_type:
              this.vector_types.find(
                vector => vector.class_id == defect.properties.classId
              ).description || defect.properties.classId,
            status: defect.status,
            status_name: this.statuses[defect.status] || defect.status,
            priority_name: this.priorities[defect.priority] || defect.priority,
            priority: defect.priority,
            assignee: defect.takenUp,
            assignee_name: this.get_assignee_name(defect.takenUp),
            dueDate: defect.dueDate,
            id: defect.uid,
            tags: defect.tags,
            select: this.selected_defects.includes(defect.uid),
            inverter:
              defect.properties &&
              defect.properties.groupUid &&
              defect.properties.projectUid &&
              this.projects[defect.properties.projectUid] &&
              this.projects[defect.properties.projectUid].name
                ? this.projects[defect.properties.projectUid].name
                : "NA",
            timestamp:
              (defect.properties && defect.properties.issueTimeStamp) || "NA",
            temperature:
              defect.properties && defect.properties.temperatureDifference >= 0
                ? `${defect.properties.temperatureDifference.toFixed(2)} °C`
                : "NA"
          };
        });
      },
      update_ticket(id, value) {
        this.$store.dispatch("thermv2/update_ticket_value", {
          id: id,
          value: value
        });
      },
      select_all_defects() {
        let data = this.grid.data.serialize();
        this.$store.commit("thermv2/SELECT_ALL_TICKETS", true);
        this.$store.commit(
          "thermv2/SELECT_MULTIPLE_TICKETS",
          this.format_filtered_defects.map(d => d.uid)
        );
        data = data.map(item => {
          return { ...item, select: true };
        });
        // this.initGrid(this.page_defects)
        this.grid.data.parse(data);
        this.format_filtered_defects.map(row => {
          this.grid.addCellCss(row.uid, "select", "grid-checked");
          this.grid.removeCellCss(row.uid, "select", "grid-unchecked");
        });
      },
      unselect_all_defects() {
        let data = this.grid.data.serialize();
        this.$store.commit("thermv2/SELECT_ALL_TICKETS", false);
        this.$store.commit("thermv2/CLEAR_SELECTED_TICKETS");
        data = data.map(item => {
          return { ...item, select: false };
        });
        // this.initGrid(this.page_defects)

        this.grid.data.parse(data);
        this.format_filtered_defects.map(row => {
          this.grid.removeCellCss(row.uid, "select", "grid-checked");
          this.grid.addCellCss(row.uid, "select", "grid-unchecked");
        });
        document.getElementById("select").checked = false;
      },
      async init_events() {
        let chk = document.getElementById("select");
        chk.addEventListener("change", e => {
          if (e.target.checked) {
            this.select_all_defects();
          } else {
            this.unselect_all_defects();
          }
        });

        document.addEventListener("keydown", e => {
          if (e.key == "Escape") {
            if (this.selected_defects.length) {
              this.unselect_all_defects();
              chk.checked = false;
            }
          }
        });

        // Sort
        this.grid.events.on("headerCellClick", (column, e) => {
          if (!e.target.className.includes("header-sort-icon")) return;
          else {
            // let order =
            //   column.id !== this.sort_by.key
            //     ? "asc"
            //     : this.sort_by.order == "asc"
            //     ? "desc"
            //     : "asc";
            let order = e.target.className.includes("fa-chevron-up")
              ? "asc"
              : "desc";
            this.sort_by = { key: column.id, order: order };
            // this.initGrid(this.page_defects);
            this.grid.data.parse(this.page_defects);
            this.set_checkbox_style(this.page_defects);
          }
        });

        // row click, open ticket
        this.grid.events.on("cellClick", (row, col) => {
          if (col.id !== "select") this.open_ticket_details(row.uid);
          // if (col.id == "priority_name" || col.id == "status")
          //   this.grid.editCell(row.id, col.id, "select");
          // else if (col.id == "dueDate")
          //   this.grid.editCell(row.id, col.id, "datePicker");
        });

        this.grid.events.on("afterEditEnd", (val, row, col) => {
          this.grid.paint();
          if (col.id == "priority_name")
            this.update_ticket(row.id, {
              priority: parseInt(
                Object.keys(this.priorities).find(
                  key => this.priorities[key] == val
                )
              )
            });
          if (col.id == "status")
            this.update_ticket(row.id, {
              status: parseInt(
                Object.keys(this.statuses).find(
                  key => this.statuses[key] == val
                )
              )
            });
          if (col.id == "dueDate") {
            if (val) {
              this.update_ticket(row.id, {
                dueDate: moment(val).toISOString()
              });
            }
          }

          if (col.id == "select") {
            this.$store.dispatch("thermv2/select_ticket", row.uid);
            if (this.selected_defects.includes(row.uid)) {
              this.grid.addCellCss(row.uid, col.id, "grid-checked");
              this.grid.removeCellCss(row.uid, col.id, "grid-unchecked");
            } else {
              this.grid.removeCellCss(row.uid, col.id, "grid-checked");
              this.grid.addCellCss(row.uid, col.id, "grid-unchecked");
            }
          }
        });
      },
      initGrid(defects) {
        if (this.$route.name == "therm-v2-tickets") {
          if (this.grid) {
            this.grid.destructor();
          }
          let config = {
            ...this.config,
            height: window.innerHeight - 220,
            data: defects
          };
          this.grid = new dhx.Grid("grid", config);
          if (this.grid) {
            this.init_events();
          }
        }
      },
      get_assignee_name(assignee) {
        if (assignee.uid == null) return " ";
        else {
          let user = this.$store.getters.state_users[assignee.uid];
          if (user && user.first_name)
            return user.first_name + " " + user.last_name;
          else if (assignee && assignee.first_name)
            return assignee.first_name + " " + assignee.last_name;
          else if (user) return user.email;
          else
            return (assignee.email && assignee.email.includes("@sensehawk")) ||
              this.$store.state.internal_users_uids.includes(assignee.uid)
              ? "Sensehawk"
              : "Unknown";
          ta;
        }
      },
      getData() {
        this.updatePagination(this.filtered_defects.length);
        if (this.grid && this.page_defects.length) {
          this.grid.data.parse(this.page_defects);
          // checkbox fix
          this.set_checkbox_style(this.page_defects);
        }
      },
      set_checkbox_style(defects) {
        defects.map(row => {
          if (row.select) {
            this.grid.addCellCss(row.uid, "select", "grid-checked");
            this.grid.removeCellCss(row.uid, "select", "grid-unchecked");
          } else {
            this.grid.removeCellCss(row.uid, "select", "grid-checked");
            this.grid.addCellCss(row.uid, "select", "grid-unchecked");
          }
        });
      }
    }
  };
</script>

<style lang="scss">
  .thermv2-defects-mainlist {
    min-height: 100vh;
  }
  .defect-list {
    &-header {
      border-radius: 0.5rem;
      &--name {
        font-family: Barlow-Semibold;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }

  .defect-list-grid {
    ::-webkit-scrollbar {
      width: 0.5rem !important;
      height: 0.5rem !important;
      background-color: rgba(250, 247, 247, 0.8);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.5rem !important;
      width: 0.5rem !important;
      background: rgba(184, 183, 183, 0.63) !important;
    }
    .dhx_grid-selected-row {
      background: rgb(172, 185, 255, 0.2);
      border-left: 2px solid #acb9ff;
    }
    .grid-unchecked {
      .dhx_cell-editor__checkbox {
        visibility: hidden;
      }
    }
    .grid-checked {
      .dhx_cell-editor__checkbox {
        visibility: visible !important;
      }
    }
    .dhx_grid-row:hover {
      background: rgb(172, 185, 255, 0.2) !important;
      // border-left: 2px solid #acb9ff;
      .grid-unchecked {
        .dhx_cell-editor__checkbox {
          visibility: visible;
        }
      }
    }
    .dhx_grid-cell {
      // border: none;
      border-left: none;
      text-align: left;
    }
    .dhx_grid-header-cell {
      font-family: Barlow-Regular;
      color: #171e48;
      font-size: 14px;
      text-align: left;
    }
    .dhx_cell-content {
      font-family: Barlow-medium;
      font-weight: 500;
      color: #171e48;
      font-size: 14px;
    }
    .dhx_grid-header-cell {
      background: white;
      &--sorted {
        background: white;
      }
    }

    .dhx_boolean-cell {
      width: 2rem;
    }
    .is-sh-dropdown .is-options-list {
      z-index: 15;
    }
    .dhx_grid-header {
      z-index: 1;
    }
  }
  .defect-list-pagination {
    .pagination {
      margin-bottom: 0;
    }
  }
  .grid-defect--status {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 2px;
    margin-right: 0.7rem;
  }
  .has-background-pending {
    background: #f79c4b;
  }
  .has-background-inprogress {
    background: #08aa4f;
  }
  .has-background-resolved {
    background: #5240b9;
  }
  .has-background-closed {
    background: #b9bbc7;
  }
  .has-background-rejected {
    background: #f85859;
  }
  .tag-border:not(:last-child) {
    border-right: 1px solid rgb(238, 238, 238);
    border-radius: 0;
    padding-right: 5px;
  }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ticket_id && _vm.detailed_ticket)?_c('div',[(_vm.$route.meta.screen == 'mobile')?_c('mobile-ticket-details',{attrs:{"ticket":_vm.detailed_ticket},on:{"exit":function($event){return _vm.close_popup()}}}):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.close_popup()),expression:"() => close_popup()"}],staticClass:"new-ticket-details has-slimscroll-xs",attrs:{"id":"scroll"}},[_c('div',{staticClass:"columns padding-20"},[_c('div',{staticClass:"column is-paddingless"},[_c('div',{staticClass:"bb-1 pb-20 is-flex is-vcenter has-space-between br-1"},[_c('div',{staticClass:"is-flex has-space-between pr-10",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"is-flex align-center"},[(_vm.edit_ticket_name == -1)?_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                  _vm.detailed_ticket.name.length > 42
                    ? _vm.detailed_ticket.name
                    : null
                ),expression:"\n                  detailed_ticket.name.length > 42\n                    ? detailed_ticket.name\n                    : null\n                "}],staticClass:"mr-10 is-medium-18-500-22",class:[
                  !_vm.detailed_ticket.writeAccess ? 'is-not-allowed' : null
                ],on:{"click":function($event){_vm.detailed_ticket.writeAccess
                    ? (_vm.edit_ticket_name = _vm.detailed_ticket.uid)
                    : null}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.detailed_ticket.name,42))+" ")]):_vm._e(),(_vm.edit_ticket_name == _vm.detailed_ticket.uid)?_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                  () => _vm.update_ticket_name(_vm.detailed_ticket.name)
                ),expression:"\n                  () => update_ticket_name(detailed_ticket.name)\n                "},{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.detailed_ticket.name),expression:"detailed_ticket.name"}],staticClass:"is-inline-input is-medium-18-500-22",staticStyle:{"width":"60rem","height":"29px"},attrs:{"type":"text","name":"name","id":"ticket","placeholder":_vm.detailed_ticket.name},domProps:{"value":(_vm.detailed_ticket.name)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.edit_ticket_name = -1},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.update_ticket_name(_vm.detailed_ticket.name)}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detailed_ticket, "name", $event.target.value)}}}):_vm._e(),_c('div',{on:{"click":function($event){return _vm.update_ticket({ bookmark: !_vm.detailed_ticket.bookmark })}}},[(_vm.detailed_ticket.bookmark)?_c('sh-icon',{staticClass:"is-18x18 mr-10 is-pointer",attrs:{"name":'bookmarked-highlight'}}):_c('sh-icon',{staticClass:"is-18x18 mr-10 is-pointer",attrs:{"name":'bookmark'}})],1)]),(_vm.currently_viewing && _vm.currently_viewing.length)?_c('div',{staticClass:"is-flex is-vcenter"},[_c('div',{staticClass:"has-text-theme-black is-regular-12-00-14 is-flex is-vcenter"},[_vm._v(" "+_vm._s(_vm.localization("app-viewing", "Viewing"))+" "),_c('sh-icon',{staticClass:"is-14x14 mr-10 ml-5 mt-2",attrs:{"name":'info-grey'}})],1),_c('user-tag',{attrs:{"users":_vm.currently_viewing,"tagsCountToShow":3,"is_precense_component":true,"custom_class":'tiny-user-avatar',"custom_size":24,"no_access":true}})],1):_vm._e()]),(
              _vm.detailed_ticket.properties &&
                _vm.detailed_ticket.properties.issueUid
            )?_c('div',{staticClass:"is-flex align-center has-space-around pr-20"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: `${_vm.localization(
                  'app-previous-defect',
                  'Previous Defect'
                )}`
              }),expression:"{\n                content: `${localization(\n                  'app-previous-defect',\n                  'Previous Defect'\n                )}`\n              }"}],staticClass:"is-pointer",class:{ 'is-disabled': _vm.index == 0 },on:{"click":_vm.previous_ticket}},[_c('i',{staticClass:"fas fa-chevron-left pr-10"})]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: `${_vm.localization('app-next-defect', 'Next Defect')}`
              }),expression:"{\n                content: `${localization('app-next-defect', 'Next Defect')}`\n              }"}],staticClass:"is-pointer",class:{ 'is-disabled': _vm.index == _vm.filtered_defects.length - 1 },on:{"click":_vm.next_ticket}},[_c('i',{staticClass:"fas fa-chevron-right pl-10"})])]):_vm._e()]),(_vm.detailed_ticket)?_c('div',{staticClass:"new-ticket-details__content"},[_c('div',[_c('div',{staticClass:"br-1"},[_c('task-list',{attrs:{"service":_vm.apiService,"channel":_vm.channel,"ticket":_vm.detailed_ticket,"component":'ticketDetails'}}),_c('attachments',{attrs:{"ticket":_vm.detailed_ticket,"component":'ticketDetails',"service":_vm.apiService}}),(_vm.ckeditorLoaded)?_c('comments',{attrs:{"commentsUnread":(_vm.commentsUnread = 0),"presence_members":_vm.viewingMembers,"ticket":_vm.detailed_ticket,"service":_vm.apiService,"channelName":'ticket-' + _vm.detailed_ticket.uid}}):_vm._e()],1)])]):_vm._e()]),_c('div',{staticClass:"column is-3 is-paddingless"},[_c('right-section',{attrs:{"is_loading":_vm.is_loading,"ticket":_vm.detailed_ticket,"presence_members":_vm.currently_viewing},on:{"overlay_popup":function($event){_vm.has_overlay = $event},"close":_vm.close_popup}})],1)])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
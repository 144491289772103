var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.details)?_c('div',{staticClass:"vault-info-tab mb-30"},[(_vm.folder)?[_c('div',{staticClass:"folder-icon-preview",staticStyle:{"width":"auto"}},[_c('svg',{staticClass:"image ml-5 folder",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/vault_sprite.svg") + "#add-folder"}})])])]:(_vm.file)?[_c('div',{staticClass:"file-thumbnail is-relative"},[_c('div',{staticClass:"is-pointer thumbnail-overlay",on:{"click":function($event){return _vm.$eventHub.$emit('init-pdftron', _vm.file)}}},[_c('sh-icon',{class:'is-48x48 view-eye',attrs:{"file":'tickets_sprite',"name":'attachment-eye'}})],1),(_vm.file.thumbnailLarge)?_c('img',{staticClass:"file-thumbnail-icon",staticStyle:{"width":"260px","height":"300px","object-fit":"contain"},attrs:{"src":_vm.file.thumbnailLarge}}):_c('div',{staticClass:"folder-icon-preview"},[_c('img',{staticClass:"file-type-icon-100",attrs:{"src":require('@/assets/file-type-icons/' +
              _vm.prettyFileIcons.getIcon(_vm.file.fileName) +
              '.svg')}})])])]:_vm._e(),_c('div',{staticClass:"details"},[(_vm.folder)?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.folder.name.length > 15 ? _vm.mutableTag && _vm.mutableTag.name : null
        ),expression:"\n          folder.name.length > 15 ? mutableTag && mutableTag.name : null\n        "}],staticClass:"name details-col"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.folder.name,15))+" ")]),_c('div',{staticClass:"props"},[_c('span',{staticClass:"size mr-10"},[_vm._v(_vm._s(_vm.$prettysize(_vm.folder.fileSize)))]),_vm._v(" | "),_c('span',{staticClass:"file-count ml-10"},[_vm._v(_vm._s(_vm.folder.fileCount)+" files")])])]:(_vm.file)?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.file.name.length > 15 ? _vm.file.name : null),expression:"file.name.length > 15 ? file.name : null"}],staticClass:"name details-col"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.file.name,15))+" ")]),_c('div',{staticClass:"props"},[_c('span',{staticClass:"size mr-10"},[_vm._v(_vm._s(_vm.$prettysize(_vm.file.fileSize)))])])]:_vm._e(),_c('div',{staticClass:"details-col"},[_c('div',[_c('div',{staticClass:"header"},[_vm._v(_vm._s(_vm.localization("app-created", "Created")))]),_c('div',{staticClass:"date-created"},[_vm._v(" "+_vm._s(_vm._f("prettyDate")(_vm.details && _vm.details.created))+" ")])]),_c('div',[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.localization("app-modified", "Modified"))+" ")]),_c('div',{staticClass:"date-modify"},[_vm._v(" "+_vm._s(_vm._f("prettyDate")(_vm.details && _vm.details.updated))+" ")])])]),_c('div',{staticClass:"mb-30"},[_c('div',{staticClass:"header mb-10"},[_c('span',[_vm._v(" "+_vm._s(_vm.localization("app-users", "Users"))+" ")])]),(
          (_vm.details.readUsers && _vm.details.readUsers.length) ||
            (_vm.details.writeUsers && _vm.details.writeUsers.length)
        )?_c('div',{staticClass:"is-flex is-vcenter"},[_c('user-tag',{attrs:{"tagsCountToShow":5,"custom_class":'tiny-user-avatar is-flex',"custom_size":30,"no_access":true,"users":[...(_vm.details.readUsers || []), ...(_vm.details.writeUsers || [])].map(
              user => _vm.$store.getters.state_users[user.uid]
            ),"howManyToShow":2},on:{"clicked_user":function($event){return _vm.add_users(_vm.details, _vm.file ? 'file' : 'folder')}}}),(_vm.details.writeAccess || _vm.details.ancestorWriteAccess)?_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-add-users', 'Add users'),
            html: false
          }),expression:"{\n            content: localization('app-add-users', 'Add users'),\n            html: false\n          }"}],staticClass:"is-22x22 ml-5 is-pointer add-user-assignee",class:[
            _vm.details && !(_vm.details.writeAccess || _vm.details.ancestorWriteAccess)
              ? 'has-no-access'
              : null
          ],attrs:{"name":'add-users-teams'},nativeOn:{"click":function($event){return _vm.add_users(_vm.details, _vm.file ? 'file' : 'folder')}}}):_vm._e()],1):_c('div',{staticClass:"is-flex is-vcenter is-pointer",class:[
          _vm.details && !(_vm.details.writeAccess || _vm.details.ancestorWriteAccess)
            ? 'has-no-access'
            : null
        ],on:{"click":function($event){return _vm.add_users(_vm.details, _vm.file ? 'file' : 'folder')}}},[_c('sh-icon',{staticClass:"mr-7 is-30x30",attrs:{"name":'add-users-2'}}),_c('span',{staticClass:"mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"},[_vm._v(_vm._s(_vm.localization("app-add-users", "Add Users")))])],1)]),_c('div',{staticClass:"details-col"},[_c('div',[_c('div',{staticClass:"header mb-10"},[_vm._v(" "+_vm._s(_vm.localization("app-owner", "Owner"))+" ")]),(_vm.details)?_c('user-dropdown',{staticClass:"is-medium-14-500-17 has-text-new-grey",class:[_vm.details && !_vm.details.ownerAccess ? 'has-no-access' : null],attrs:{"existing_user":_vm.details.owner,"is_name_hidden":false,"hide_close":true,"pxsize":30},on:{"update-user":function($event){return _vm.update_owner($event)}}}):_vm._e()],1)]),_c('div',{staticClass:"mb-30"},[_c('div',{staticClass:"header mb-10"},[_c('span',[_vm._v(" "+_vm._s(_vm.localization("app-teams", "Teams"))+" ")])]),(_vm.get_teams(_vm.details).length)?_c('div',{staticClass:"is-flex is-vcenter"},[_c('primary-tag',{attrs:{"no_access":true,"type":'team',"tags":_vm.get_teams(_vm.details),"toShowCount":1},on:{"clicked_tag":function($event){return _vm.add_teams(_vm.details, _vm.file ? 'file' : 'folder')}}}),(_vm.details.writeAccess || _vm.details.ancestorWriteAccess)?_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-add-teams', 'Add teams'),
            html: false
          }),expression:"{\n            content: localization('app-add-teams', 'Add teams'),\n            html: false\n          }"}],staticClass:"is-22x22 ml-5 is-pointer add-user-assignee mt-5",class:[
            _vm.details && !(_vm.details.writeAccess || _vm.details.ancestorWriteAccess)
              ? 'has-no-access'
              : null
          ],attrs:{"name":'add-users-teams'},nativeOn:{"click":function($event){return _vm.add_teams(_vm.details, _vm.file ? 'file' : 'folder')}}}):_vm._e()],1):_c('div',{staticClass:"is-flex is-vcenter is-pointer",class:[
          _vm.details && !(_vm.details.writeAccess || _vm.details.ancestorWriteAccess)
            ? 'has-no-access'
            : null
        ],on:{"click":function($event){return _vm.add_teams(_vm.details, _vm.file ? 'file' : 'folder')}}},[_c('sh-icon',{staticClass:"mr-7 is-30x30",attrs:{"name":'add-teams'}}),_c('span',{staticClass:"mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"},[_vm._v(_vm._s(_vm.localization("app-add-teams", "Add Teams")))])],1)]),_c('div',{staticClass:"details-col"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"header mb-5"},[_vm._v(_vm._s(_vm.localization("app-tags", "Tags")))]),_c('type-tag',{attrs:{"number_of_tags_to_display":4,"tags":_vm.details.tags,"resourceId":_vm.details.uid,"complete":_vm.update_tags,"write_access":_vm.details.writeAccess || _vm.details.ancestorWriteAccess}})],1)]),_c('hierarchy',{staticClass:"mb-15",attrs:{"stage":_vm.details.targetElement.stage,"element_id":_vm.details.targetElement.uid},on:{"close":function($event){return _vm.close_popup()}}}),_c('div',{staticClass:"details-col"},[(_vm.folder)?_c('a',{staticClass:"button download-button is-info is-fullwidth",class:{ 'is-disabled': !_vm.$store.getters.download_files_zip },on:{"click":function($event){return _vm.download_files()}}},[_c('span',{staticClass:"icon"},[_c('svg',{staticClass:"image is-24x24",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/vault_sprite.svg") + "#download-white"}})])]),_c('span',[_vm._v(_vm._s(_vm.localization("app-download-all", "Download All")))])]):[_c('a',{staticClass:"button download-button is-info is-fullwidth",on:{"click":function($event){return _vm.download()}}},[_c('span',{staticClass:"icon"},[_c('svg',{staticClass:"image is-24x24",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/vault_sprite.svg") + "#download-white"}})])]),_c('span',[_vm._v(_vm._s(_vm.localization("app-download", "Download")))])]),_c('a',{staticClass:"button download-button download-button--share ml-5 is-fullwidth",class:{
            'is-disabled': !(
              _vm.details.writeAccess || _vm.details.ancestorWriteAccess
            )
          },on:{"click":function($event){return _vm.add_users(_vm.details, _vm.file ? 'file' : 'folder')}}},[_c('span',{staticClass:"icon"},[_c('sh-icon',{staticClass:"image is-24x24 is-pointer",attrs:{"name":'share',"file":'vault_sprite'}})],1),_c('span',[_vm._v(_vm._s(_vm.localization("app-share", "Share")))])])]],2)],2)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
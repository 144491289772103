<template>
  <div class="sh-list-header  columns is-regular-14-00-17">
    <div class="column is-3">{{ localization("app-teams", "Teams") }}</div>
    <div class="column is-3">
      {{ localization("app-associated-asset", "Associated asset") }}
    </div>
    <div class="column is-3">{{ localization("app-assets", "Assets") }}</div>
    <div class="column is-2">{{ localization("app-users", "Users") }}</div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

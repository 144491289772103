<template>
  <div id="dhtmlx-slider"></div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    data() {
      return {
        slider: null
      };
    },
    computed: {
      ...mapState("thermv2", ["active_filters"]),
      active_range() {
        if (!this.active_filters) return [-100, 100];
        return this.active_filters.temperature_difference || [-100, 100];
      }
    },
    mounted() {
      this.slider = new dhx.Slider("dhtmlx-slider", {
        range: true,
        min: -100,
        max: 100,
        step: 1,
        value: this.active_range
      });
      this.slider.events.on("Change", this.onSliderValueChanged);
    },
    methods: {
      onSliderValueChanged() {
        const value = this.slider.getValue();
        this.$emit("updated", value);
      }
    }
  };
</script>

<style lang="scss"></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-relative"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.hover = false)),expression:"() => (hover = false)"}],staticClass:"show-project-icon",on:{"click":function($event){_vm.show_projects = !_vm.show_projects}}},[(!_vm.show_projects)?_c('sh-icon',{staticClass:"is-15x15",attrs:{"name":'group-right-arrow'}}):_c('sh-icon',{staticClass:"is-15x15",attrs:{"name":'group-right-arrow-expand'}})],1),_c('div',{staticClass:"columns",on:{"mouseleave":function($event){return _vm.close_dropdown(_vm.item.uid)},"mouseenter":function($event){_vm.hover = true}}},[_c('div',{staticClass:"column is-4"},[_c('router-link',{staticClass:"has-text-theme-black",attrs:{"to":{ name: 'group-details', params: { id: _vm.item.uid } }}},[_c('main-details-media',{attrs:{"truncate_value":_vm.$route.name == 'asset-details-groups' ||
            _vm.$route.name == 'container-details'
              ? '25'
              : '35',"show_tooltip":true,"info":{
            name: _vm.item.name,
            subtext: _vm.item.project_counts + ' Projects',
            name_class: 'is-semiBold-16-600-19 name-highlights-on-hover',
            subtext_class: 'is-medium-14-500-17 has-opacity-7 mt-5',
            properties: _vm.item.properties
          }}})],1)],1),(_vm.$route.name !== 'asset-details-groups')?_c('div',{staticClass:"column"},[_c('primary-tag',{attrs:{"tags":_vm.item.asset ? [_vm.item.asset] : null,"type":'asset'}})],1):_vm._e(),(_vm.$route.name !== 'container-details')?_c('div',{staticClass:"column"},[_c('primary-tag',{attrs:{"tags":_vm.item.container ? [_vm.item.container] : null,"type":'container'}})],1):_vm._e(),_c('div',{staticClass:"column is-flex is-between align-center"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.cal_active = false)),expression:"() => (cal_active = false)"}],staticClass:"column"},[(_vm.item.properties && _vm.item.properties.date)?_c('p',{staticClass:"is-marginless is-medium-16-500-19 no-whitespace-wrap is-pointer is-flex is-vcenter",on:{"click":function($event){_vm.cal_active = !_vm.cal_active;
            _vm.create_cal();}}},[_vm._v(" "+_vm._s(new Date(_vm.item.properties.date).toLocaleDateString())+" "),(_vm.hover)?_c('span',{staticClass:"ml-5",on:{"click":function($event){$event.stopPropagation();return _vm.remove_date()}}},[_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}})],1):_vm._e()]):_c('p',{staticClass:"is-marginless is-regular-14-00-17 has-text-blue-grey is-pointer",on:{"click":function($event){_vm.cal_active = !_vm.cal_active;
            _vm.create_cal();}}},[_vm._v(" "+_vm._s(_vm.localization("app-set-date", "Set Date"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cal_active),expression:"cal_active"}]},[_c('div',{attrs:{"id":`${'group_list_cal-' + _vm.item.uid}`}})])]),_c('div',[_c('transfer-owner',{ref:"groupListTransferOwner",attrs:{"name":_vm.item.name,"service":_vm.groupService,"id":_vm.item.uid},on:{"update_user":function($event){return _vm.$emit('update-group-owner', $event)}}})],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"margin-auto",class:{ 'is-hidden': !_vm.hover && !_vm.dropdown }},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.dropdown ? (_vm.dropdown = !_vm.dropdown) : null)),expression:"() => (dropdown ? (dropdown = !dropdown) : null)"}],staticClass:"asset-dropdown"},[_c('div',{staticClass:"asset-dropdown-selection",class:{
                'is-hidden': !_vm.hover && !_vm.dropdown
              },on:{"click":function($event){_vm.dropdown = !_vm.dropdown}}},[_c('sh-icon',{staticClass:"is-20x20 ml-auto",attrs:{"name":'3-dots-menu'}})],1),(_vm.dropdown)?_c('div',{staticClass:"asset-dropdown-content",staticStyle:{"right":"00"}},[_c('p',{on:{"click":function($event){return _vm.$emit('edit')}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'edit-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-edit-group", "Edit Group"))+" ")])],1),_c('p',{on:{"click":function($event){return _vm.$refs.groupListTransferOwner.open_popup()}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'transfer-2'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm.localization( "app-transfer-ownership", "Transfer ownership" )))])],1),_c('p',{on:{"click":function($event){_vm.showDeleteModal = true}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'delete-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-delete-group", "Delete Group")))])],1)]):_vm._e()])])])])]),_c('keep-alive',[(_vm.show_projects)?_c('group-project-list',{attrs:{"group_id":_vm.item.uid},on:{"update_project_count":function($event){_vm.item.project_counts = $event}}}):_vm._e()],1),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.item.uid,"resourceName":_vm.item.name,"resourceType":'group',"resourceService":_vm.groupService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){return _vm.$emit('refresh-group-list')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
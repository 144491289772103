var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'mt-10': !_vm.noTopMargin }},[(_vm.tags && _vm.tags.length)?_c('div',{staticClass:"sh-primary-tag--group is-flex align-center",style:([_vm.no_wrap ? { 'flex-wrap': 'nowrap' } : { 'flex-wrap': 'wrap' }])},[_vm._l((_vm.tags.slice(0, _vm.tagsToShowCount)),function(tag){return _c('div',{key:tag.uid},[(tag)?_c('div',{staticClass:"sh-primary-tag",on:{"click":function($event){_vm.no_access
            ? _vm.$emit('clicked_tag')
            : _vm.$router.push({
                name: _vm.type + '-details',
                params: { id: tag.uid }
              })}}},[(!_vm.hideIcon)?_c('img',{staticClass:"image is-16x16 mr-10",attrs:{"src":require(`@/assets/icons/primary-tag/${_vm.type}.svg`)}}):_vm._e(),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            tag.name &&
            (tag.name.length > _vm.truncate_length || tag.name.length > 10)
              ? tag.name
              : null
          ),expression:"\n            tag.name &&\n            (tag.name.length > truncate_length || tag.name.length > 10)\n              ? tag.name\n              : null\n          "}],staticClass:"truncate is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm._f("truncate")(tag.name || "Unknown",_vm.truncate_length || 10))+" "),(_vm.canDelete)?_c('span',{staticClass:"sh-primary-tag--close",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('onDelete', tag.uid)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete-group.svg"),"alt":"","srcset":""}})]):_vm._e()])]):_vm._e()])}),(_vm.toShowCount && _vm.tags.length > _vm.tagsToShowCount)?_c('div',{staticClass:"sh-primary-tag sh-primary-tag--round"},[_c('p',[_vm._v("+"+_vm._s(_vm.tags.length - _vm.tagsToShowCount))])]):_vm._e()],2):_c('div',[_c('not-assigned',{attrs:{"type":_vm.type}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="therm-v2">
    <div class="columns is-gapless">
      <div :style="{ width: mini_thermsidebar ? '6rem' : '20rem' }">
        <sidebar @active-classes-updated="active_classes = $event" />
      </div>
      <div class="column" style="width: 100%">
        <keep-alive include="therm-v2-map">
          <router-view />
        </keep-alive>
      </div>
      <thermv2ticketlist v-if="$route.name == 'therm-v2'" />
    </div>
  </div>
</template>

<script>
  import Sidebar from "./components/therm-v2-sidebar";
  import thermv2ticketlist from "./components/therm-v2-ticket-list";
  import { viewService, apiService } from "@/app/old/desktop/shared/services/";
  import { mapMutations, mapState, mapGetters } from "vuex";

  import DefectsMixin from "@/app/old/desktop/therm-v2/components/defects.mixin.vue";
  import PaginationMixin from "@/app/old/desktop/shared/mixins/pagination.mixin.vue";

  export default {
    mixins: [DefectsMixin, PaginationMixin],
    components: {
      Sidebar,
      thermv2ticketlist
    },
    computed: {
      ...mapState("thermv2", [
        "container",
        "mini_ticket_sidebar",
        "projects",
        "defects",
        "active_class_ids",
        "active_filters",
        "map",
        "selected_feature_id",
        "active_projects",
        "mini_thermsidebar"
      ]),
      ...mapGetters("thermv2", ["filtered_defects"]),
      is_sidebar() {
        return this.$route.meta.therm_sidebar;
      }
    },
    watch: {
      active_projects(val) {
        this.create_properties_string();
      },
      active_filters: {
        handler(val) {
          if (val) this.create_properties_string();
        },
        deep: true
      }
    },
    async created() {
      this.get_container();
      let tags = await apiService.getTags().catch(err => console.log(err));
      this.$store.commit("SET_TAGS", tags);
      this.create_properties_string(false);
    },

    destroyed() {
      this.RESET_STATE();
    },
    methods: {
      ...mapMutations("thermv2", [
        "SET_CONTAINER",
        "SET_VECTOR_TYPES",
        "SET_ACTIVE_CLASSES",
        "SET_MAP",
        "RESET_STATE",
        "SET_DEFECTS"
      ]),
      getData() {
        this.create_properties_string();
      },
      async get_container() {
        try {
          const active_container = await viewService.get_viewer_groups({
            id: this.$route.params.id
          });
          this.SET_CONTAINER(active_container);
          let vector_types = await viewService.get_viewer_vector_types({
            id: this.$route.params.id
          });
          vector_types = vector_types.filter(
            vector => vector.class_name !== "table" && vector.group === "issues"
          );
          this.SET_VECTOR_TYPES(vector_types);
          this.SET_ACTIVE_CLASSES(vector_types.map(vector => vector.class_id));
        } catch (err) {
          this.handleErrors(err);
          console.log("Error :: while fetching container details.");
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .therm-v2 {
    * {
      user-select: none;
    }
  }
</style>
<style lang="scss">
  .tooltip {
    &.inverted-tooltip {
      .tooltip-inner {
        // background: #e1e2e7;
        background: white;
        color: #171e48;
      }
      .tooltip-arrow {
        // border-color: #e1e2e7;
        border-color: white;
      }
    }
  }
</style>

<template>
  <div style></div>
</template>

<script>
  import AuthMixin from "@/app/shared/auth-old/shared/auth-mixin.js";
  export default {
    name: "auth-handle",
    mixins: [AuthMixin],
    created() {
      if (!window.$cookies.get("access_token")) this.handle_auth();
    }
  };
</script>

<style></style>

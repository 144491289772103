<template>
  <div
    class="therm-v2__sidebar__export-to-csv has-text-light has-width-100 is-flex is-center  is-medium-12-500-14"
    v-tooltip="{
      content: all_filtered_defects.length
        ? null
        : localization('app-no-data-to-export', 'No data to export'),
      classes: ['inverted-tooltip']
    }"
  >
    <div v-if="is_loading" class="therm-v2__sidebar__export-to-csv__loading">
      <i class="fa fa-2x fa-circle-notch fa-spin" />
    </div>
    <div
      v-else
      class="is-flex align-center"
      :class="{
        'pl-17': !$store.getters.export_therm_pdf,
        'is-disabled': !all_filtered_defects.length
      }"
    >
      <sh-icon
        name="export-to-csv"
        class="is-16x16 mr-3 ml-10"
        file="thermv2_sprite"
      />
      <span class="has-opacity-7">
        {{ localization("app-export-to", "Export to") }} :
      </span>
      <span
        class="is-pointer is-semiBold-12-600-17 ml-5 mr-3"
        role="button"
        @click="export_to_csv"
      >
        {{ localization("app-csv", "CSV").toUpperCase() }}
      </span>
      <div v-if="$store.getters.export_therm_pdf">
        <span class="has-opacity-7">
          {{ localization("app-or", "or").toLowerCase() }}
        </span>
        <span
          @click="validate_images"
          class="is-pointer is-semiBold-12-600-17 ml-3"
          role="button"
        >
          {{ localization("app-pdf", "PDF").toUpperCase() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ticketService,
    assetService
  } from "@/app/old/desktop/shared/services/";
  import { mapGetters, mapState } from "vuex";
  import * as Comlink from "comlink";

  export default {
    data() {
      return {
        is_loading: false
      };
    },
    created() {
      /*
        Contains information on whether the image
        can be accessed using the provided URL
      */

      this.validated_images = {};
      this.pdf_generator = Comlink.wrap(
        new Worker(new URL("../utils/therm-v2-pdf-generator.js", import.meta.url))
      );
    },
    computed: {
      ...mapState("thermv2", [
        "active_filters",
        "active_projects",
        "active_class_ids",
        "projects",
        "vector_types",
        "container"
      ]),
      ...mapGetters("thermv2", ["filtered_defects", "project_defects"]),
      all_filtered_defects() {
        return this.filtered_defects.concat(this.project_defects);
      },
      class_ids() {
        const class_ids = {};
        this.vector_types.forEach(vector => {
          class_ids[vector.class_id] = vector.description;
        });
        return class_ids;
      }
    },
    methods: {
      async export_to_csv() {
        let filtersData = {};
        let properties = null;
        this.is_loading = true;
        try {
          if (this.active_filters) {
            filtersData = _.pickBy(
              this.active_filters,
              v => v !== null && v !== undefined
            );
            if (
              this.active_filters.dueDate &&
              this.active_filters.dueDate.length
            ) {
              filtersData["dueDateStart"] = this.active_filters.dueDate[0];
              filtersData["dueDateEnd"] = this.active_filters.dueDate[0];
              delete filtersData.dueDate;
            }
            if (this.active_filters.tags && this.active_filters.tags.length) {
              filtersData["tag"] = this.active_filters.tags;
              delete filtersData.tags;
            }
            if (
              this.active_filters.assignee &&
              this.active_filters.assignee.length
            ) {
              filtersData["assignee"] = this.active_filters.assignee.map(
                a => a.uid
              );
            }
          }
          filtersData[
            "organization"
          ] = this.$store.state.current_organization.uid;
          if (this.active_projects && this.active_projects.length) {
            let projectUid = this.active_projects.join(",");
            const temperature_range = this.active_filters
              ? this.active_filters["temperature-difference"]
              : null;
            properties = btoa(
              `projectUid=${projectUid}
            ${
              this.active_class_ids && this.active_class_ids.length
                ? `&classId=${this.active_class_ids.join(",")},null`
                : ""
            }
            ${
              temperature_range && temperature_range.length
                ? `&temperatureDifference>=${temperature_range[0]}&temperatureDifference<=${temperature_range[1]}`
                : ""
            }`
            );
          }
          let projectMap = {};
          let groupMap = {};

          (this.container || []).forEach(item => {
            if (item.published) {
              projectMap[item.projectUid] = item.projectName;
              if (item.groupProperties && item.groupProperties.date) {
                groupMap[item.groupUid] = moment(
                  new Date(item.groupProperties.date)
                ).format("MMM DD, YYYY");
              } else if (!groupMap[item.groupUid]) {
                groupMap[item.groupUid] = item.groupName;
              }
            }
          });

          // for (const [key, value] of Object.entries(this.projects)) {
          //   projectMap[key] = value.name;
          //   groupMap[value.group_uid] = value.group_name;
          // }

          const res = await ticketService.generate_tickets_csv_therm({
            body: {
              filters: { ...filtersData, ...{ properties } },
              classIdMap: this.class_ids,
              projectMap,
              groupMap
            }
          });
          this.readFile(res);
        } catch (err) {
          console.log(err);
        } finally {
          this.is_loading = false;
        }
      },
      readFile(doc) {
        let csv;
        const Papa = require("papaparse");
        Papa.parse(doc, {
          delimiter: "", // auto-detect
          newline: "", // auto-detect
          quoteChar: '"',
          escapeChar: '"',
          error: undefined,
          download: false,
          header: true,
          skipEmptyLines: true,
          comments: true,
          delimitersToGuess: [
            ",",
            "\t",
            "|",
            ";",
            Papa.RECORD_SEP,
            Papa.UNIT_SEP
          ],
          complete: results => {
            csv = results.data;
          }
        });
        let final = Papa.unparse(csv);
        var csvData = new Blob([final], { type: "text/csv;charset=utf-8;" });
        var csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "defects.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "defects.csv");
        tempLink.click();
      },
      /*
        Checks if attachments/images in the defect/ticket can be accessed
        using the provided URLs. Images that can't be accessed are ignored
        when generating the PDF
      */

      async validate_images() {
        this.is_loading = true;
        let attachments_for_defects = await ticketService.get_attachments_for_tickets(
          {
            body: {
              tickets: this.all_filtered_defects.map(defect => defect.uid)
            }
          }
        );
        // Only consider images in the attachments
        attachments_for_defects = attachments_for_defects.filter(attachment =>
          /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(attachment.fileName)
        );
        const links_by_defect = _.groupBy(
          attachments_for_defects,
          "foreignObjectUid"
        );
        let images_checked = 0;
        if (attachments_for_defects.length) {
          for (let image of attachments_for_defects) {
            if (this.validated_images.hasOwnProperty(image.url)) {
              images_checked += 1;
              if (images_checked === attachments_for_defects.length) {
                this.export_to_pdf(links_by_defect);
                return;
              }
              continue;
            }
            fetch(image.url, {
              method: "GET",
              headers: {
                Range: "bytes=0-0"
              }
            })
              .then(res => {
                images_checked += 1;
                this.validated_images[image.url] =
                  res.status === 200 || res.status === 206;
                if (images_checked == attachments_for_defects.length) {
                  this.export_to_pdf(links_by_defect);
                }
              })
              .catch(err => {
                images_checked += 1;
                this.validated_images[image.url] = false;
                if (images_checked == attachments_for_defects.length) {
                  this.export_to_pdf(links_by_defect);
                }
              });
          }
        } else {
          this.export_to_pdf(links_by_defect);
        }
      },
      async export_to_pdf(links_by_defect) {
        if (!this.asset) {
          const asset_details = await assetService.get({
            id: this.all_filtered_defects[0].targetElement.asset
          });
          this.asset = asset_details.name;
        }
        // The Worker generates the blob and sends it to the main thread
        const generated_blob = await this.pdf_generator.generate_document_definition(
          {
            defects: this.all_filtered_defects,
            projects: this.projects,
            class_ids: this.class_ids,
            asset: this.asset,
            validated_images: this.validated_images,
            links_by_defect
          }
        );
        // Download the PDF that was generated by the Worker
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.href = window.URL.createObjectURL(generated_blob);
        anchor.download = "defects.pdf";
        anchor.click();
        this.is_loading = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .therm-v2__sidebar__export-to-csv {
    background: #45494d;
    box-shadow: 0px -2px 6px #0000001a;
    // width: 160px;
    height: 40px;
    bottom: 0;
    &__loading {
      transform: translate(43%, 35%);
      color: white !important;
    }
  }
</style>

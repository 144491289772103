import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_SYSTEM_MODEL_API_HOST

const URL = 'templates'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }

  duplicate_template(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/duplicate/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  get_component(req = {}) {
    return httpClient({
      url: `${URL}/${req.id}/components`,
      baseURL: this.baseURL,
      headers: req.headers
    }).then(res => res.data)
  }

  update_template(req = {}) {
    return httpClient({
      url: `${URL}/${req.id}/`,
      baseURL: this.baseURL,
      method: 'patch',
      data: req.body,
      headers: req.headers
    }).then(res => res.data)
  }

  set_template_bookmark(req = {}) {
    return httpClient({
      url: `${URL}/${req.id}/bookmark`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      headers: req.headers
    }).then(res => res.data)
  }

  remove_template_bookmark(req = {}) {
    return httpClient({
      url: `${URL}/${req.id}/bookmark`,
      baseURL: this.baseURL,
      method: 'delete',
      headers: req.headers
    }).then(res => res.data)
  }
}

export const templateService = new API()

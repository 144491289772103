var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!(_vm.isEditing || _vm.updating))?_c('div',{staticClass:"ml-20 mb-10 is-flex"},[_c('div',{staticClass:"mr-10"},[_c('user-like',{attrs:{"ticket":_vm.ticket,"channel":_vm.channel,"comment":_vm.comment,"reaction":_vm.reaction},on:{"send":_vm.sendReaction,"delete":_vm.deleteReaction}})],1),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"sh-comment-box ticket-details-action",class:this.$store.state.claims.user_id === _vm.comment.user.id
            ? 'comment-owner'
            : 'comment-not-owner'},[_c('div',{staticClass:"sh-comment-name is-flex",staticStyle:{"justify-content":"space-between"}},[_c('div',{staticClass:"is-medium-16-500-19 sh-comment-name--text is-flex align-center mb-1"},[(_vm.$store.getters.state_users[_vm.comment.user.id])?_c('span',{directives:[{name:"username",rawName:"v-username",value:(_vm.$store.getters.state_users[_vm.comment.user.id]),expression:"$store.getters.state_users[comment.user.id]"}]}):_vm._e(),_c('p',{staticClass:"is-regular-14-00-17 has-text-theme-black has-opacity-6 sh-created-at ml-10"},[_vm._v(" "+_vm._s(_vm._f("prettyDate")(new Date(_vm.comment.created_at).toISOString()))+" ")])]),_c('div',{staticClass:"is-flex align-center sh-comment-option"},[(this.comment.user.id === this.$store.state.claims.user_id)?_c('p',{on:{"click":function($event){_vm.description = _vm.comment.text;
                _vm.updating = true;}}},[_c('sh-icon',{staticClass:"is-pointer mr-20 is-18x18",attrs:{"name":'edit-2'}})],1):_vm._e(),(this.comment.user.id === this.$store.state.claims.user_id)?_c('p',{on:{"click":_vm.deleteMessage}},[_c('sh-icon',{staticClass:"is-pointer mr-20 is-18x18",attrs:{"name":'trash'}})],1):_vm._e()])]),_c('div',{staticClass:"sh-comment-text ck ck-content ck-editor__editable ck-rounded-corners ck-blurred",domProps:{"innerHTML":_vm._s(_vm.data)}})])])]):_c('div',{staticClass:"mb-10",class:{ 'ml-66': _vm.updating }},[_c('editor',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.updating = false)),expression:"() => (updating = false)"}],attrs:{"ticket":_vm.ticket,"channel":_vm.channel,"action":_vm.editorAction,"showAttachments":false,"attachments":[],"autofocus":true},on:{"send":_vm.sendReply},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),(_vm.showRetry)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization(
        'app-tooltip-retry-message',
        'Retry sending this message'
      ),
      html: false
    }),expression:"{\n      content: localization(\n        'app-tooltip-retry-message',\n        'Retry sending this message'\n      ),\n      html: false\n    }"}],staticClass:"sh-comments-comment--retry",staticStyle:{"right":"10px"},on:{"click":_vm.sendZombieComment}},[_c('i',{staticClass:"fa fa-redo mr-5",staticStyle:{"font-size":"12px"},attrs:{"aria-hidden":"true"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{staticClass:"tasks",attrs:{"group":{ name: 'g1' },"list":_vm.items,"handle":".handle","tag":"ul"},on:{"change":_vm.checkMove}},_vm._l((_vm.items),function(task,i){return _c('li',{key:task.uid,on:{"mouseenter":function($event){$event.stopPropagation();_vm.hover = task.uid},"mouseleave":function($event){$event.stopPropagation();_vm.hover = -1}}},[_c('div',{staticClass:"is-flex task is-vcentered",class:_vm.component == 'ticketDetails' ? null : 'mt-10 pt-5'},[_c('div',{staticClass:"is-flex is-vcenter checklist-task-actions"},[_c('div',{staticClass:"is-flex align-center checklist-task-actions--description",on:{"click":function($event){_vm.isMobile ? _vm.resolve_task(task) : ''}}},[_c('div',{staticClass:"checklist-grab-icon",class:[
              _vm.hover != task.uid || !_vm.ticket.writeAccess ? 'is-vhidden' : null
            ],staticStyle:{"cursor":"grab"}},[_c('sh-icon',{staticClass:"is-20x20 mr-10 handle",attrs:{"name":'drag-move'}})],1),_c('div',{staticClass:"is-flex checklist-status ck-status",class:{ 'is-hidden': _vm.forms && _vm.ticket.writeAccess },on:{"mouseenter":function($event){$event.preventDefault();return _vm.displayStatusHover(task.uid)},"mouseleave":function($event){$event.preventDefault();return _vm.hideStatusHover.apply(null, arguments)}}},[_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.resolve_task(task)}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                  task.status ? _vm.localize(task.status).toUpperCase() : ''
                ),expression:"\n                  task.status ? localize(task.status).toUpperCase() : ''\n                "}],staticClass:"image is-20x20",attrs:{"name":`checkbox-status` + (task.status ? `-${task.status}` : ``)}})],1),(!_vm.isMobile && _vm.status_hover === task.uid)?_c('div',{staticClass:"is-flex align-center checklist-status--box ck-status--box"},_vm._l((_vm.task_status_list),function(status){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(status !== task.status),expression:"status !== task.status"}],key:status,staticClass:"ck-status--box__value",on:{"click":function($event){$event.stopPropagation();task.status = status;
                  _vm.active = -1;
                  _vm.update_checklist_data();
                  _vm.track_changes_to_item_status();}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(status ? _vm.localize(status).toUpperCase() : ''),expression:"status ? localize(status).toUpperCase() : ''"}],staticClass:"image is-20x20",attrs:{"name":`checkbox-status` + (status ? `-${status}` : ``)}})],1)}),0):_vm._e()]),(_vm.edit_name != task.uid)?_c('p',{staticClass:"mx-5",class:[
              {
                'strike-through':
                  task.status == 'rejected' || task.status == 'resolved',
                'has-no-access': _vm.ticket && !_vm.ticket.writeAccess
              },
              [
                _vm.component == 'ticketDetails'
                  ? 'is-medium-14-500-17'
                  : 'is-medium-16-500-19'
              ]
            ],on:{"click":function($event){$event.stopPropagation();_vm.isMobile ? _vm.resolve_task(task) : (_vm.edit_name = task.uid)}}},[_vm._v(" "+_vm._s(task.name)+" ")]):_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(task.name),expression:"task.name"},{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.isMobile ? '' : _vm.edit_task_name(task))),expression:"() => (isMobile ? '' : edit_task_name(task))"}],staticClass:"input is-inline-input is-medium-16-500-19",staticStyle:{"padding":"2rem"},attrs:{"type":"text","name":"name","id":"name"},domProps:{"value":(task.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.edit_task_name(task)},"input":function($event){if($event.target.composing)return;_vm.$set(task, "name", $event.target.value)}}})]),_c('div',{staticClass:"is-flex is-medium-14-500-17 ml-10 align-center checklist-action--icons"},[(
              !_vm.forms &&
                !(
                  _vm.isMobile &&
                  (task.attachments ? task.attachments : 0) +
                    (_vm.added_attachments[task.uid]
                      ? _vm.added_attachments[task.uid]
                      : 0) ==
                    0
                )
            )?_c('div',{staticClass:"is-flex align-center"},[(_vm.show_attachments == task.uid)?_c('sh-icon',{staticClass:"mr-5 is-pointer is-16x16",attrs:{"name":'link-active'},nativeOn:{"click":function($event){_vm.show_attachments = -1;
                _vm.show_uploader = false;}}}):_c('sh-icon',{staticClass:"mr-5 is-pointer is-16x16",attrs:{"name":'link'},nativeOn:{"click":function($event){_vm.show_uploader = false;
                _vm.show_attachments = task.uid;}}}),_c('span',[_vm._v(_vm._s((task.attachments ? task.attachments : 0) + (_vm.added_attachments[task.uid] ? _vm.added_attachments[task.uid] : 0)))])],1):_vm._e(),(!_vm.forms)?_c('p',{directives:[{name:"show",rawName:"v-show",value:(
              task.assignee ||
                _vm.show_notes === task.uid ||
                _vm.hover === task.uid ||
                (_vm.clickedDropdown && _vm.clicked_id === task.uid) ||
                _vm.active == task.uid
            ),expression:"\n              task.assignee ||\n                show_notes === task.uid ||\n                hover === task.uid ||\n                (clickedDropdown && clicked_id === task.uid) ||\n                active == task.uid\n            "}],staticClass:"ml-10 sh-task-user-dropdown",class:{ 'has-assignee': task.assignee }},[_c('user-dropdown',{staticClass:"is-medium-16-500-19",class:{ 'has-no-access': _vm.ticket && !_vm.ticket.writeAccess },attrs:{"existing_user":task.assignee,"pxsize":24},on:{"update-user":function($event){return _vm.update_task($event, task)},"clicked-dropdown":function($event){_vm.clickedDropdown = $event;
                _vm.clicked_id = task.uid;}}})],1):_vm._e(),_c('div',{staticClass:"is-flex align-center sh-task-notes",class:{ 'is-hidden': _vm.forms && _vm.ticket.writeAccess },style:(task.notes
                ? 'order:-1;margin-right:15px;pointer-events:all;'
                : 'margin-left:15px;'),on:{"mouseenter":function($event){task.notes && !_vm.isMobile && _vm.notes_editing === -1
                ? (_vm.show_notes = task.uid)
                : ''},"mouseleave":function($event){task.notes && !_vm.isMobile && _vm.notes_editing === -1
                ? (_vm.show_notes = -1)
                : ''}}},[_c('add-notes',{attrs:{"notes":task.notes,"active":_vm.show_notes === task.uid,"isEditing":!task.notes || _vm.notes_editing === task.uid},on:{"close":function($event){_vm.show_notes = -1;
                _vm.notes_editing = -1;},"submit":function($event){_vm.notes_editing = -1;
                _vm.show_notes = -1;
                task.notes = $event;
                _vm.update_checklist_data();},"delete":function($event){_vm.notes_editing = -1;
                _vm.show_notes = -1;
                task.notes = null;
                _vm.update_checklist_data();}}},[(task.notes)?_c('sh-icon',{staticClass:"is-pointer is-18x18",attrs:{"name":'notes-active'},nativeOn:{"click":function($event){_vm.isMobile || (_vm.forms && _vm.forms.result)
                    ? ''
                    : (_vm.notes_editing = task.uid);
                  _vm.show_notes = task.uid;}}}):_c('sh-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  (_vm.show_notes === task.uid ||
                    _vm.hover === task.uid ||
                    _vm.active == task.uid) &&
                    !_vm.isMobile
                ),expression:"\n                  (show_notes === task.uid ||\n                    hover === task.uid ||\n                    active == task.uid) &&\n                    !isMobile\n                "}],staticClass:"is-pointer is-30x30",attrs:{"name":'notes'},nativeOn:{"click":function($event){_vm.show_notes = task.uid}}})],1)],1),(!_vm.isMobile)?_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_attachments == task.uid),expression:"show_attachments == task.uid"}],staticClass:"is-pointer ml-15 checklist-task-upload--icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.show_uploader = true;
              _vm.show_attachments = task.uid;}}},[_c('sh-icon',{staticClass:"is-pointer",class:[_vm.isMobile ? 'is-24x24' : 'is-30x30'],attrs:{"name":"task-atttachment"}})],1):_vm._e(),(!_vm.isMobile)?_c('sh-icon',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.active === task.uid ||
                _vm.subtask === task.uid ||
                _vm.hover === task.uid
            ),expression:"\n              active === task.uid ||\n                subtask === task.uid ||\n                hover === task.uid\n            "}],staticClass:"ml-15 task-list-sub-task is-pointer is-30x30",class:[
              [_vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access d-none' : ''],
              [_vm.isMobile ? 'is-24x24' : 'is-30x30'],
              { 'has-opacity-5': _vm.subtask == task.uid }
            ],attrs:{"name":"sub-task"},nativeOn:{"click":function($event){_vm.subtask = _vm.subtask === -1 ? task.uid : -1}}}):_vm._e(),(_vm.forms)?_c('sh-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === task.uid || _vm.hover === task.uid),expression:"active === task.uid || hover === task.uid"}],staticClass:"ml-15 task-list-sub-task is-pointer is-12x12",class:[
              [_vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access d-none' : ''],
              [_vm.isMobile ? 'is-12x12' : 'is-16x16']
            ],attrs:{"name":"close"},nativeOn:{"click":function($event){return _vm.delete_task(task)}}}):_vm._e()],1)]),_c('sh-menu',{staticClass:"checlist-task-menu is-flex align-center",class:{
          'has-no-access is-hidden': _vm.forms || (_vm.ticket && !_vm.ticket.writeAccess),
          'is-userdropdown': _vm.assign_all
        },attrs:{"items":_vm.menuData,"data":task,"active":_vm.active === task.uid,"width":_vm.assign_all ? '20rem;' : '20rem'},on:{"clicked-outside":function($event){_vm.active = -1;
          _vm.assign_all = false;},"item-clicked":function($event){return _vm.listAction($event, task, i)}},scopedSlots:_vm._u([{key:"item",fn:function(user){return (_vm.assign_all)?[_c('user-avatar',{attrs:{"user":user.item,"pxsize":20,"custom_class":'tiny-user-avatar',"show_tooltip":true}}),(!_vm.is_name_hidden)?_c('span',{directives:[{name:"username",rawName:"v-username",value:(user.item),expression:"user.item"}],staticClass:"ml-6 sh-dropdown-content__item--text is-medium-14-500-17",staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}}):_vm._e()]:undefined}}],null,true)},[_c('sh-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === task.uid || _vm.hover === task.uid),expression:"active === task.uid || hover === task.uid"}],staticClass:"sh-menu-horizontal is-24x24 ml-20 is-pointer",attrs:{"name":'3dots-menu-horizontal'},nativeOn:{"click":function($event){_vm.active = _vm.active !== -1 ? -1 : task.uid}}}),_c('sh-icon',{staticClass:"sh-menu-vertical is-24x24 ml-20 is-pointer",attrs:{"name":'3dots-menu'},nativeOn:{"click":function($event){_vm.active = _vm.active !== -1 ? -1 : task.uid}}}),(!_vm.assign_all)?_c('template',{slot:"prepend"},[_c('div',{staticClass:"px-15 pt-10 is-flex ck-status"},[_c('div',{staticClass:"is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-status", "Status"))+": ")]),_c('div',{staticClass:"is-flex align-center ck-status--box"},_vm._l((_vm.task_status_list),function(status){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(status !== task.status),expression:"status !== task.status"}],key:status,staticClass:"ck-status--box__value",on:{"click":function($event){task.status = status;
                  _vm.active = -1;
                  _vm.update_checklist_data();
                  _vm.track_changes_to_item_status();}}},[_c('sh-icon',{staticClass:"image is-20x20",attrs:{"name":`checkbox-status` + (status ? `-${status}` : ``)}})],1)}),0)])]):_vm._e(),(_vm.assign_all)?_c('template',{slot:"prepend"},[_c('div',{staticClass:"field px-10"},[_c('p',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"input",attrs:{"type":"email","placeholder":_vm.localization('app-search-users', 'Search Users')},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),_c('span',{staticClass:"icon is-left is-large"},[_c('sh-icon',{attrs:{"name":'search'}})],1)])])]):_vm._e()],2),_c('hr')],1),(_vm.show_attachments == task.uid)?_c('div',{staticClass:"task-list-attachment-backdrop",on:{"click":function($event){_vm.show_attachments = -1}}}):_vm._e(),(_vm.show_attachments == task.uid && !_vm.forms)?_c('task-attachments',{style:(`padding-left:-${_vm.depth * 3}rem;`),attrs:{"task":task,"showUploader":_vm.show_uploader,"upload_more":_vm.upload_more,"ticket":_vm.ticket,"service":_vm.service,"checklist":_vm.checklist,"component":_vm.component},on:{"added_attachments":function($event){_vm.show_uploader = false;
        _vm.added_attachments[task.uid] = _vm.added_attachments[task.uid]
          ? _vm.added_attachments[task.uid]
          : 0;
        _vm.added_attachments[task.uid] += $event;},"update_checklist":function($event){_vm.show_uploader = false;
        _vm.update_checklist_data();
        _vm.added_attachments[task.uid] = _vm.added_attachments[task.uid]
          ? _vm.added_attachments[task.uid]
          : 0;
        _vm.added_attachments[task.uid] += $event;}}}):_vm._e(),(task.children && task.children.length)?_c('div',[_c('sub-tasks',{attrs:{"items":task.children,"ticket":_vm.ticket,"checklist":_vm.checklist,"depth":_vm.depth + 1,"service":_vm.service,"forms":_vm.forms,"component":_vm.component},on:{"delete_task_from_checklist":function($event){return _vm.delete_task_from_checklist($event, task)}}})],1):_vm._e(),_c('div',{staticStyle:{"padding-left":"32px"}},[(_vm.subtask == task.uid)?_c('create-subtask',{staticClass:"subtask-create",attrs:{"keep_open":true,"isMobile":_vm.isMobile,"checklist":_vm.checklist,"ticket":_vm.ticket,"component":_vm.component,"service":_vm.service},on:{"close_mobile":function($event){_vm.subtask = -1},"task_created":function($event){return _vm.add_task(task, _vm.depth, $event)}}}):_vm._e(),(_vm.edit_name == task.uid && _vm.isMobile)?_c('create-subtask',{staticClass:"subtask-create",attrs:{"keep_open":true,"isMobile":_vm.isMobile,"checklist":_vm.checklist,"task":task,"ticket":_vm.ticket,"service":_vm.service,"component":_vm.component},on:{"close_mobile":function($event){_vm.edit_name = -1},"task_created":_vm.update_checklist_data},nativeOn:{"touchstart":function($event){$event.stopPropagation();}}}):_vm._e()],1),(_vm.delete_this_task == task.uid)?_c('prompt-modal',{attrs:{"name":_vm.delete_this_task,"heading":_vm.$options.filters.truncate(task.name),"iconPath":require('@/assets/icons/prompt/delete.svg'),"footer":true,"cancelString":'Cancel',"confirmString":'Delete'},on:{"cancel":function($event){return _vm.$modal.hide(task.uid)},"confirm":function($event){return _vm.delete_task(task)},"closed":function($event){return _vm.$modal.hide(task.uid)}}},[_c('div',{staticClass:"prompt-modal__content__text is-capitalized",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.localization( "app-are-you-sure-you-want-to-delete", "Are you sure you want to delete" ))+" "+_vm._s(_vm._f("truncate")(task.name,30))+"? ")])]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="thermv2-reports">
    <div class="" v-show="active_projects && active_projects.length">
      <div class="card mb-30">
        <bar-graph
          :metrics="data"
          :active_projects="active_projects"
        ></bar-graph>
      </div>
      <temperature-histogram
        :has_dark_theme="true"
        :active_projects="active_projects"
        :metrics="metrics"
      ></temperature-histogram>
    </div>
    <div v-if="!active_projects || !active_projects.length">
      <div
        class=" card padding-40 is-flex is-centered"
        style="background:white;box-shadow: none;flex-direction: column;"
      >
        <img
          style="height: 25rem"
          src="@/assets/illustrations/assets-illustration.svg"
          alt
        />
        <p class="is-medium-16-500-19 has-text-dark has-text-centered pt-20">
          {{ localization("app-not-enough-data", "Not enough data") }}
        </p>
      </div>
      <!-- <img src="@/assets/illustrations/data-illustration.svg" alt="" /> -->
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import { viewService } from "@/app/old/desktop/shared/services/";
  import BarGraph from "@/app/old/desktop/therm-v2/components/therm-v2-bar-graph.vue";
  import ProjectsList from "@/app/old/desktop/therm-v2/components/therm-v2-projects-list.vue";
  // import TemperatureHistogram from "@/app/old/desktop/therm/components/view-details/components/temperature-histogram.vue";
  import TemperatureHistogram from "./temperature-histogram.vue";

  export default {
    data() {
      return {
        metrics: {},
        data: null
      };
    },
    components: {
      BarGraph,
      ProjectsList,
      TemperatureHistogram
    },
    computed: {
      ...mapState("thermv2", ["container", "projects", "active_projects"]),
      ...mapGetters("thermv2", ["filtered_defects"])
    },
    watch: {
      filtered_defects: {
        handler() {
          this.getData();
        },
        immediate: true
      }
    },
    methods: {
      getData() {
        if (this.filtered_defects && this.filtered_defects.length)
          this.data = this.filtered_defects.map(d => d.properties);
      }
    }
  };
</script>

<style lang="scss">
  .thermv2-reports {
    // background: #4a4e52;
    padding: 7rem 4rem;
    min-height: 100vh;
    .card {
      // background: #1e2328;
    }
    .b-skeleton > .b-skeleton-item {
      // background: linear-gradient(
      //   90deg,
      //   #3f3f3f 25%,
      //   rgba(71, 70, 70, 0.5) 50%,
      //   #272727 75%
      // );
      background-size: 400% 100%;
      width: 100%;
    }
    .js-plotly-plot {
      .plot-container {
        .svg-container {
          max-width: 1100px;
          min-width: 900px;
        }
      }
    }
    .scrollbox {
      visibility: visible !important;
    }
  }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns vault-top-header has-background-white is-gapless is-marginless is-mobile has-text-centered is-normal-14-500-17 has-text-theme-grey py-20"},[_c('div',{staticClass:"column is-1 align-center"},[(!_vm.$route.query.recents)?_c('div',{staticClass:"is-flex is-center align-center",class:[
        _vm.selected_files.length || _vm.selected_folders.length
          ? 'is-visible'
          : 'is-hidden'
      ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_all),expression:"selected_all"}],staticClass:"sh-custom-checkbox is-thinner ml-10",attrs:{"type":"checkbox","id":"selection"},domProps:{"checked":Array.isArray(_vm.selected_all)?_vm._i(_vm.selected_all,null)>-1:(_vm.selected_all)},on:{"change":[function($event){var $$a=_vm.selected_all,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected_all=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected_all=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected_all=$$c}},function($event){return _vm.set_selected()}]}}),_c('span',{staticClass:"is-medium-14-500-19 mx-5 count",class:[
          _vm.selected_files.length || _vm.selected_folders.length
            ? 'is-visible'
            : 'is-vhidden'
        ]},[_vm._v(_vm._s(_vm.selected_files.length + _vm.selected_folders.length))])]):_vm._e()]),_c('div',{staticClass:"column is-flex is-vcenter",class:[
      _vm.info_tab.active || _vm.small_viewer || _vm.compact_view ? 'is-4' : 'is-2_5'
    ],on:{"click":function($event){return _vm.update_sort_key('name')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-file-name", "File Name")))]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
          _vm.sorting_key === 'name' ? 'has-text-blue' : 'has-text-theme-grey'
        ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
          _vm.sorting_key === '-name' ? 'has-text-blue' : 'has-text-theme-grey'
        ]})])]),_c('div',{staticClass:"column is-2_5 is-flex is-vcenter",class:[_vm.info_tab.active || _vm.compact_view ? 'is-3' : 'is-2_5'],on:{"click":function($event){return _vm.update_sort_key('owner')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-owner", "Owner")))]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
          _vm.sorting_key === 'owner' ? 'has-text-blue' : 'has-text-theme-grey'
        ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
          _vm.sorting_key === '-owner' ? 'has-text-blue' : 'has-text-theme-grey'
        ]})])]),(!_vm.small_viewer && !_vm.info_tab.active && !_vm.compact_view)?_c('div',{staticClass:"column is-flex is-vcenter",class:[_vm.small_viewer ? 'is-4' : 'is-2'],on:{"click":function($event){return _vm.update_sort_key('teams')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-teams", "Teams")))])]):_vm._e(),(!_vm.small_viewer && !_vm.info_tab.active && !_vm.compact_view)?_c('div',{staticClass:"column is-flex is-vcenter is-2 ml-20",class:[_vm.small_viewer ? 'ml-20' : null],on:{"click":function($event){return _vm.update_sort_key('users')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-users", "Users")))])]):_vm._e(),_c('div',{staticClass:"column is-flex is-vcenter",class:[_vm.info_tab.active ? 'is-4' : 'is-2'],on:{"click":function($event){return _vm.update_sort_key('tags')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-tags", "Tags")))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
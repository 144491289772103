<template>
  <div>
    <div v-if="isMobile">
      <div v-if="isLoading"><mobile-list-skeleton /></div>
      <div v-else>
        <div v-if="data && data.length">
          <div v-for="item in data" :key="item.id">
            <therm-mobile-item :item="item"></therm-mobile-item>
          </div>
        </div>
        <div v-else class="m-no-terra">
          <div class="m-no-terra--img">
            <img
              src="@/assets/illustrations/m-no-thermal-illustration.svg"
              alt=""
            />
          </div>
          <div
            class="is-flex is-center mt-20 is-medium-16-500-19 has-primary-text"
          >
            No Thermal
          </div>
          <div
            class="is-flex is-center mt-10 is-regular-12-00-18 has-primary-text has-opacity-8"
          >
            {{ localization("app-no-result-found", "No result found") }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="px-20 pt-20 pin is-flex align-center is-start"
        v-if="$route.name == 'therm-view-list'"
      >
        {{ localization("app-thermal", "thermal") }}
        <span class="ml-10 is-medium-14-500-17 has-text-dark">{{
          details && details.name
        }}</span>
      </div>

      <div class="therm-all mt-30" v-if="$route.name == 'therm-view-list'">
        <div class="is-semiBold-16-500-19 mb-20 px-20">
          {{ localization("app-all", "All") }}
        </div>
        <div class="list-top-section px-20">
          <div class="list-top-section--search">
            <searchbar @search="pageChangeHandle(1, $event)"></searchbar>
          </div>
          <div class="list-top-section--create">
            <a
              v-if="access('therm', 'can_create_views')"
              @click="
                open_form(ThermFormComponent, {
                  header: 'Create Thermal View for'
                })
              "
              class="sh-button sh-button--create"
              >{{ localization("app-new-view", "New View") }}</a
            >
            <a v-else @click="no_access_popup('therm', 'can_create_views')">
              <div
                class="sh-button sh-button--create is-not-allowed disabled-link"
              >
                {{ localization("app-new-view", "New View") }}
              </div>
            </a>
          </div>
        </div>
        <list-header v-if="data && data.length"></list-header>
        <div v-if="data && data.length">
          <div class="sh-list">
            <viewCard
              v-for="item in data"
              :key="item.uid"
              :item="item"
              class="sh-list__item"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="isLoading">
            <list-skeleton></list-skeleton>
          </div>
          <no-results v-else>
            <img
              slot="image"
              src="@/assets/illustrations/assets-illustration.svg"
              alt
            />
          </no-results>
        </div>
      </div>
      <div class="columns is-gapless" v-if="$route.name == 'therm-view-list'">
        <div class="column">
          <pagination
            :default_page_size="default_page_size"
            :current-page="currentPage"
            :page-count="pageCount"
            class="articles-list__pagination"
            @nextPage="pageChangeHandle('next')"
            @previousPage="pageChangeHandle('previous')"
            @loadPage="pageChangeHandle"
            :visiblePagesCount="visiblePagesCount"
            :totalRecords="totalRecords"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    viewService,
    userService,
    assetService
  } from "@/app/old/desktop/shared/services/";
  import viewCard from "@/app/old/desktop/therm/shared/components/view-card";
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";
  import filterButton from "@/app/old/desktop/core/shared/components/filter-button";
  const queryString = require("query-string");
  import ThermFormComponent from "../therm-form.component.vue";
  import { mapState } from "vuex";
  import FormPopup from "@/app/old/desktop/shared/mixins/form-popup.mixin.vue";
  import PaginationMixin from "@/app/old/desktop/shared/mixins/pagination.mixin.vue";
  import Pagination from "@/app/old/desktop/shared/components/pagination.vue";
  import ThermMobileItem from "@/app/old/mobile/modules/therm/therm-list-item.vue";
  import ListSkeleton from "@/app/old/desktop/core/shared/components/skeleton/list-skeleton.vue";
  import MobileListSkeleton from "@/app/old/mobile/shared/components/list-skeleton.vue";
  import OrderMixin from "@/app/old/desktop/shared/mixins/order.mixin.vue";
  import ListHeader from "@/app/old/desktop/therm/components/view-list/therm-list-header.vue";
  import StaleWhileRevalidateMixin from "@/app/old/desktop/shared/mixins/stale-while-revalidate.mixin.vue";

  export default {
    mixins: [FormPopup, PaginationMixin, OrderMixin, StaleWhileRevalidateMixin],
    props: ["asset_id"],
    components: {
      searchbar,
      filterButton,
      viewCard,
      Pagination,
      ThermMobileItem,
      ListSkeleton,
      MobileListSkeleton,
      ListHeader
    },
    data() {
      return {
        data: [],
        ThermFormComponent: ThermFormComponent,
        details: {}
      };
    },
    created() {
      this.getData();
      if (this.$route.query && this.$route.query.asset)
        this.get_asset(this.$route.query.asset);
    },
    methods: {
      async staleWhileRevalidate(event) {
        if (event.data.name == "therm" && event.data.data) {
          this.data = event.data.data;
        }
      },

      async getData() {
        try {
          this.isLoading = true;
          let query = {
            metrics: true,
            complete: true,
            projects: true,
            important: true,
            page_size: 1000,
            page_number: this.$route.query.page || 1,
            q: this.$route.query.q || ""
          };
          this.$route.query.asset
            ? (query.assetUid = this.$route.query.asset)
            : null;
          let { data } = await this.fetch_therm_data(
            queryString.stringify(query)
          );

          this.data = data;
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
        }
      },
      async get_asset(id) {
        this.details = await assetService.get({ id }).catch(err => {
          this.handleErrors(err);
        });
      },

      fetch_therm_data(query) {
        return viewService
          .getAll({
            query: query
          })
          .catch(err => this.handleErrors(err));
      }
    }
  };
</script>

<style></style>

<script>
  import NewTicketDetails from "@/app/old/desktop/tickets/components/ticket-details/ticket-details";
  // import FormSubmit from '@/app/old/desktop/forms/components/form/submission/form-submit.vue';
  import DefectDetails from "@/app/old/desktop/therm-v2/components/defect-details-popup/therm-v2-defect-details-popup.vue";

  export default {
    data() {
      return {
        is_ticket_details_popup_open: false,
        is_submission_popup_open: false,
        can_close: true
      };
    },
    methods: {
      open_form_submission(
        formUid,
        submissionUid = null,
        preview = false,
        ticketUid = null
      ) {
        let res;
        if (formUid && !this.is_submission_popup_open) {
          res = this.$buefy.modal.open({
            props: {
              formID: formUid,
              submissionID: submissionUid,
              preview: preview,
              ticketId: ticketUid,
              showClose: true,
              browser_back_modal_close: true
            },
            parent: this,
            component: () =>
              import(
                /* webpackChunkName: "forms" */ "@/app/old/desktop/forms/components/form/submission/form-submit.vue"
              ),
            fullScreen: this.isMobile,
            width: "1000px",
            customClass: "create-forms-popup",
            onCancel: () => {
              this.is_submission_popup_open = false;
            },
            events: {
              close: e => {
                this.is_submission_popup_open = false;
              }
            }
          });
        }
        if (res) this.is_submission_popup_open = true;
      },

      open_ticket_details(id) {
        return;
        let res = null;
        if (!this.is_ticket_details_popup_open) {
          res = this.$buefy.modal.open({
            props: {
              ticket_id: id
            },
            parent: this,
            component: this.$route.name.includes("therm-v2")
              ? DefectDetails
              : NewTicketDetails,
            destroyOnHide: true,
            hasModalCard: true,
            trapFocus: true,
            customClass: "ticket-details-popup",
            width: "100%",
            height: "100%",
            canCancel: ["escape"],
            onCancel: () => this.outputMessage(), // note the arrow
            events: {
              close: e => {
                (this.is_ticket_details_popup_open = false),
                  this.$store.commit(
                    "tickets/SET_CURRENT_OPEN_TICKET_ID",
                    null
                  );
              }
            }
          });
          if (res) {
            this.is_ticket_details_popup_open = true;
          }
        }
      },

      outputMessage(e) {
        if (this.$route.name == "ticket-details") {
          this.$router.go(-1);
          this.is_ticket_details_popup_open = false;
          this.$store.commit("tickets/SET_CURRENT_OPEN_TICKET_ID", null);
        } else {
          this.$emit("close");
          this.is_ticket_details_popup_open = false;
          this.$store.commit("tickets/SET_CURRENT_OPEN_TICKET_ID", null);
        }
      }
    }
  };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ticket)?_c('div',{staticClass:"ticket-list-item",class:[_vm.checkbox_checked ? 'is-highlighted' : null],on:{"contextmenu":_vm.openContextMenu}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clicked_outside),expression:"clicked_outside"}],staticClass:"ticket-list",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":_vm.reset_state}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-1 is-flex is-between is-align-flex-start no-padding-right"},[_c('div',{staticStyle:{"min-width":"25px"},on:{"click":function($event){_vm.has_write_access ? _vm.ticket_selection() : null}}},[_c('input',{staticClass:"sh-custom-checkbox is-marginless is-thinner",class:[
              {
                'is-hidden': !_vm.has_write_access
              },
              {
                'is-hidden': !_vm.hover && !_vm.checkbox_checked
              }
            ],attrs:{"disabled":!_vm.has_write_access,"type":"checkbox","name":"checkbox","id":_vm.ticket.uid},domProps:{"checked":_vm.checkbox_checked}})]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-bookmark', 'Bookmark'),
            html: false
          }),expression:"{\n            content: localization('app-bookmark', 'Bookmark'),\n            html: false\n          }"}],staticClass:"is-pointer",class:{ 'is-vhidden': !_vm.hover && !_vm.ticket.bookmark },on:{"click":function($event){return _vm.update_ticket({ bookmark: !_vm.ticket.bookmark })}}},[(_vm.ticket.bookmark)?_c('img',{staticClass:"image is-20x20",attrs:{"src":require("@/assets/svg/bookmarked.svg"),"alt":"bookmark"}}):_c('img',{staticClass:"image is-20x20",attrs:{"src":require("@/assets/svg/bookmark.svg"),"alt":"bookmark"}})]),_c('div',{staticStyle:{"margin":"2px 0"}},[(_vm.ticket)?_c('status-dropdown',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                _vm.ticket && !_vm.ticket.writeAccess
                  ? _vm.status_values[_vm.ticket.status]
                  : null
            }),expression:"{\n              content:\n                ticket && !ticket.writeAccess\n                  ? status_values[ticket.status]\n                  : null\n            }"}],class:[_vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access' : null],attrs:{"current_status":_vm.ticket.status,"ticket":_vm.ticket,"hide_names":true},on:{"update-ticket-status":function($event){_vm.update_ticket({ status: parseInt($event.value) })}}}):_vm._e()],1)]),_c('div',{staticClass:"column is-3"},[_c('div',{staticClass:"name-block-details"},[_c('div',{staticClass:"is-medium-16-500-19 no-whitespace-wrap is-flex is-vcenter is-relative"},[_c('p',{staticClass:"is-pointer has-text-dark mb-5",style:([
                _vm.is_viewer_small ? { width: '15rem' } : { width: '25rem' }
              ])},[(_vm.edit_ticket_name == -1)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                  _vm.ticket.name && _vm.ticket.name.length > 30 ? _vm.ticket.name : null
                ),expression:"\n                  ticket.name && ticket.name.length > 30 ? ticket.name : null\n                "}],staticClass:"ticket-name-text",on:{"click":function($event){return _vm.show_details(_vm.ticket.uid)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.ticket.name,30))+" ")]):(_vm.edit_ticket_name == _vm.ticket.uid)?_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.ticket.name),expression:"ticket.name"}],staticClass:"input is-inline-input is-medium-16-500-19 ticket-input",attrs:{"type":"text","name":"name","id":"ticket","placeholder":_vm.ticket.name},domProps:{"value":(_vm.ticket.name)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.edit_ticket_name = -1},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.update_ticket_name.apply(null, arguments)}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ticket, "name", $event.target.value)}}}):_vm._e()]),_c('sh-icon',{staticClass:"is-absolute is-16x16 is-inline is-pointer",class:{ 'is-hidden': !_vm.hover || !_vm.has_write_access },staticStyle:{"right":"0"},attrs:{"name":[
                _vm.edit_ticket_name == _vm.ticket.uid ? 'tick-set-complete' : 'pen'
              ]},nativeOn:{"click":function($event){_vm.edit_ticket_name == _vm.ticket.uid
                  ? _vm.update_ticket_name()
                  : (_vm.edit_ticket_name = _vm.ticket.uid)}}})],1),_c('tags-dropdown',{attrs:{"service":_vm.ticketService,"tags":_vm.ticket.tags,"resourceId":_vm.ticket.uid,"number_of_tags_to_display":2,"write_access":_vm.has_write_access,"display_on_hover":!_vm.hover,"complete":_vm.update_ticket}})],1)]),_c('div',{staticClass:"column is-one-fifth is-flex is-vcenter"},[(_vm.ticket)?_c('user-dropdown',{staticClass:"is-medium-14-500-17 has-text-new-grey",class:[_vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access' : null],attrs:{"existing_user":_vm.ticket.takenUp,"pxsize":30},on:{"update-user":function($event){return _vm.update_ticket({ takenUp: $event })}}}):_vm._e()],1),_c('div',{staticClass:"column is-2 is-flex is-vcenter align-center"},[(_vm.ticket.isRecurring)?_c('div',{staticClass:"is-flex align-center pl-10"},[_c('sh-icon',{staticClass:"is-18x18 mr-5",attrs:{"name":`due-date-${_vm.is_overdue ? 'danger' : 'dark'}`}}),_c('span',{staticClass:"is-medium-14-500-17",class:[_vm.is_overdue ? 'has-text-danger' : null]},[_vm._v(" "+_vm._s(_vm._f("sinceDays")(_vm.ticket.dueDate))+" ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.rrule_string }),expression:"{ content: rrule_string }"}],staticClass:"is-flex is-center ml-5"},[_c('sh-icon',{staticClass:"is-18x18 is-pointer",attrs:{"name":'recurrence-recurs'}})],1)],1):_c('div',[(_vm.ticket && _vm.ticket.uid)?_c('duedate-dropdown',{staticClass:"is-medium-14-500-17 has-text-new-grey",class:[!_vm.has_write_access ? 'has-no-access' : null],staticStyle:{"width":"16rem"},attrs:{"ticket":_vm.ticket,"due":_vm.ticket.dueDate || null},on:{"change-duedate":function($event){return _vm.update_ticket({
                dueDate: $event ? $event : null
              })}}}):_vm._e()],1)]),_c('div',{staticClass:"column is-one-sixth is-flex is-vcenter"},[_c('priority-dropdown',{staticClass:"is-medium-14-500-17 has-text-new-grey",class:[_vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access' : null],staticStyle:{"width":"13rem"},attrs:{"priority":_vm.ticket.priority,"ticket":_vm.ticket},on:{"change_ticket_priority":function($event){_vm.update_ticket({ priority: parseInt($event) })}}})],1),_c('div',{staticClass:"column is-2 is-flex align-center is-between"},[_c('div',{staticClass:"ticket-progress-bar"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                _vm.total_items > 0
                  ? `Taks: ${_vm.checked_items} / ${_vm.total_items}`
                  : null
            }),expression:"{\n              content:\n                total_items > 0\n                  ? `Taks: ${checked_items} / ${total_items}`\n                  : null\n            }"}],staticClass:"status-progress",class:{ 'is-pointer': _vm.total_items > 0 }},[_c('div',{staticClass:"status-progress--bar",style:(`width:${_vm.items_progress}%`)})]),_c('div',{staticClass:"ml-10 is-medium-14-500-17 has-text-new-grey"},[_vm._v(" "+_vm._s(_vm.items_progress)+"% ")])]),(_vm.ticket && _vm.has_write_access)?_c('div',[_c('actions-dropdown',{attrs:{"ticket":_vm.ticket,"hover":_vm.hover,"is_list":true},on:{"keep_active":function($event){_vm.keep_active = true},"close_dropdown":function($event){_vm.keep_active = false}}})],1):_vm._e()])])]),_c('ul',{staticStyle:{"position":"sticky","z-index":"1000"},attrs:{"id":"right-click-menu"}},[_c('actions-dropdown',{ref:"ticket-list-dropdown",staticStyle:{"position":"absolute","top":"-30px"},style:({ left: _vm.context_right }),attrs:{"ticket":_vm.ticket,"is_list":true,"is_context":true}})],1),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.ticket.uid,"resourceName":_vm.ticket.name,"resourceType":'ticket',"resourceService":_vm.ticketService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){return _vm.delete_ticket(_vm.ticket.uid)}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <no-result-dashboard-app v-if="!has_app_access('tickets')" class="mb-20">
    <img
      slot="image"
      style="width: 300px"
      src="@/assets/illustrations/no-permissions-illustration.svg"
      alt
      srcset
    />
    <div slot="title">{{ localization("app-no-access", "No Access") }}</div>
    <div slot="button" class="sh-button sh-button--create">
      {{ localization("app-request-access", "Request Access") }}
    </div>
    <div slot="no-result-desc">
      {{
        localization(
          "app-text-request-access",
          `Oops! You don't have enough permissions to access the feature/functionality.Your administrator should be able to give access to this feature if registered.Want this feature to be enabled for your organization?`
        )
      }}
    </div>
  </no-result-dashboard-app>
  <div
    v-else-if="
      ($route.meta && $route.meta.screen == 'mobile') || is_vertical_window
    "
  >
    <mobile-ticket-list
      :has_dark_theme="has_dark_theme"
      :element="element"
      @filter="getAllfilter"
      @get_more="load_more"
      :ticketService="ticketService"
      :is_fetched_all="is_fetched_all"
      :is_loading="isTicketsLoading"
      :allFiltersData="allFiltersData"
      :uploadbtnclass="uploadbtnclass"
      :is_vertical_window="is_vertical_window"
      :customclass="customclass"
      :hideSearchbar="hideSearchbar"
      :isThermTicket="isThermTicket"
      :webviewer="webviewer"
    ></mobile-ticket-list>
  </div>
  <div class="tickets" v-else>
    <router-view></router-view>
    <div
      class="tickets-top-section"
      v-if="$route.name !== 'dashboard'"
      :class="{ 'is-hidden': extra_query }"
    >
      <ticket-list-filters
        :isThermTicket="$route.name == 'therm-v2-tickets'"
        :allFiltersData="allFiltersData"
        v-if="!$route.name.includes('viewer')"
      ></ticket-list-filters>
      <div class="columns is-flex is-vcenter">
        <div class="column  is-flex is-vcenter">
          <searchbar
            @search="
              $store.commit('tickets/SET_SEARCH_BY', $event);
              segment_track_event('tickets_searched');
            "
          >
          </searchbar>

          <div
            class="filter__btn is-pointer"
            @click="open_filter = true"
            :class="{ 'is-active': filtersQueryString.length }"
          >
            <i class="fas fa-filter"></i>
          </div>
          <div class="is-relative" style="left: 22rem; top: 2.2rem">
            <filter-dropdown
              v-if="open_filter"
              v-click-outside="() => (open_filter = false)"
              :filtersData="allFiltersData"
              @apply="getAllfilter"
              @close="open_filter = false"
            />
          </div>
        </div>
        <!-- <div class="column">
        </div> -->

        <div
          class="column is-flex is-end is-vcenter align-center"
          v-if="$route.name != 'therm-v2-tickets'"
        >
          <bulk-actions-bar @refresh-tickets="getData(true)"></bulk-actions-bar>
          <create-ticket-button
            class="ml-10"
            @create_ticket="create_pending_ticket = true"
            v-if="!create_pending_ticket"
            :element="asset_details ? asset_details.element : element"
          ></create-ticket-button>
        </div>
      </div>
    </div>
    <div class="tickets-main-section">
      <div class="tickets-main-section--columns is-vcenter">
        <ticket-headers
          :small_viewer="is_vertical_window"
          :sorting_key="sort_key"
          @update_sort_key="sort_key = $event"
        ></ticket-headers>
      </div>

      <div v-if="create_pending_ticket">
        <create-new-ticket
          :small_viewer="is_vertical_window"
          :without_status="true"
          ticket_created_from="main_ticket_list"
          :element="asset_details ? asset_details.element : element"
        ></create-new-ticket>
      </div>
      <div class="mb-60">
        <div class="tickets-card--list">
          <div class="has-text-centered mb-10" v-if="isTicketsLoading">
            <tickets-list-skeleton></tickets-list-skeleton>
          </div>
          <div v-else-if="tickets.length">
            <div v-for="item in tickets" :key="item.uid">
              <tickets-list-item
                :ticket="item"
                :is_grouped="group_by.key ? true : false"
                :is_vertical_window="is_vertical_window"
              ></tickets-list-item>
            </div>
          </div>
          <div v-else>
            <no-results>
              <img
                slot="image"
                src="@/assets/illustrations/tickets-illustration.svg"
                alt
              />
              <div slot="button">
                <create-ticket-button
                  v-if="
                    !create_pending_ticket &&
                      $route.name != 'template-details ' &&
                      $route.name != 'therm-v2-tickets'
                  "
                  :element="asset_details ? asset_details.element : element"
                  @create_ticket="create_pending_ticket = true"
                  :no_result="true"
                ></create-ticket-button>
              </div>
            </no-results>
          </div>

          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="columns is-marginless">
      <div class="column">
        <pagination
          :default_page_size="default_page_size"
          :current-page="currentPage"
          :page-count="pageCount"
          class="articles-list__pagination"
          @nextPage="pageChangeHandle('next')"
          @previousPage="pageChangeHandle('previous')"
          @loadPage="pageChangeHandle"
          :visiblePagesCount="visiblePagesCount"
          :totalRecords="totalRecords"
          @pagination-clicked="pageClicked = true"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";
  import FilterDropdown from "@/app/old/desktop/tickets/shared/components/filter-card/filter-dropdown";
  import TicketListFilters from "./components/tickets-top-filters/tickets-top-filters";
  import CreateTicketButton from "@/app/old/desktop/tickets/components/tickets-list/components/create-ticket-button";
  import CreateNewTicket from "./components/create-new-ticket";
  import BulkActionsBar from "@/app/old/desktop/tickets/shared/components/bulk-actions";
  import TicketsListItem from "./components/tickets-list-item";
  import TicketHeaders from "./components/ticket-headers";
  import SegmentAnalytics from "@/app/old/desktop/shared/mixins/segment-analytics.mixin.vue";
  import {
    ticketService,
    assetService
  } from "@/app/old/desktop/shared/services/";
  import { mapState, mapActions, mapGetters } from "vuex";
  import mobileTicketList from "@/app/old/mobile/modules/tickets/components/tickets-list/mobile-tickets-list";
  import TicketsListSkeleton from "@/app/old/desktop/shared/components/skeleton/tickets/tickets-list-skeleton.vue";
  const queryString = require("query-string");
  import PaginationMixin from "@/app/old/desktop/shared/mixins/pagination.mixin.vue";
  import Pagination from "@/app/old/desktop/shared/components/pagination.vue";

  export default {
    mixins: [PaginationMixin, SegmentAnalytics],
    props: {
      element: {},
      is_grouped: {
        type: Boolean,
        default: true
      },
      is_vertical_window: {
        type: Boolean,
        default: false
      },
      extra_query: {
        type: Object,
        default: null
      },
      customclass: {
        type: String,
        default: null
      },
      hideSearchbar: {
        type: Boolean,
        default: false
      },
      uploadbtnclass: {
        type: String,
        default: null
      },
      webviewer: {
        type: Boolean,
        default: false
      }
    },
    components: {
      searchbar,
      TicketsListSkeleton,
      BulkActionsBar,
      TicketListFilters,
      CreateTicketButton,
      CreateNewTicket,
      TicketsListItem,
      TicketHeaders,
      FilterDropdown,
      mobileTicketList,
      Pagination
    },
    data() {
      return {
        default_page_size: 25,
        isTicketsLoading: false,
        create_pending_ticket: false,
        realTimeQuery: null,
        asset_details: null,
        pageClicked: false,
        is_fetched_all: false,
        open_filter: false,
        allFiltersData: {},
        filtersQueryString: "",
        finalQueryString: ""
      };
    },
    created() {
      if (this.$route.query.asset) {
        this.$store.commit("tickets/SET_SELECTED_ASSET", [
          this.$route.query.asset
        ]);
      }

      if (!this.has_app_access("tickets")) return;
      if (this.$route.name && this.$route.name.includes("overview")) {
        this.$store.commit("tickets/SET_FILTERS", this.filters);
      } else {
        this.reset_filters();
      }

      this.$store.dispatch("tickets/add_tickets", []);

      this.$eventHub.$on("update_element_data", this.update_element_data);
      // this.$eventHub.$on("get-other-tickets", () => {
      //   console.log("get-other-tickets")
      //   this.getData();
      // });

      this.$eventHub.$on("close_create_ticket", this.close_create_ticket);
      if (this.$route.query.asset) {
        this.get_asset();
      }
      document.addEventListener("keyup", this.clear_selection);
    },
    computed: {
      ...mapState("tickets", [
        "tickets",
        "group_by",
        "filters",
        "sort_by",
        "search_by",
        "selected_asset",
        "is_internal",
        "selected",
        "sort_key"
      ]),
      ...mapState([
        "PUSHER",
        "current_organization",
        "izitoast_options",
        "users",
        "forms_sync"
      ]),
      ...mapState("thermv2", [
        "active_class_ids",
        "active_projects",
        "active_filters"
      ]),
      apply_filter() {
        return {
          is_internal: this.is_internal,
          selected_asset: this.selected_asset,
          sort_by: this.sort_by,
          filters: this.filters,
          search_by: this.search_by,
          filtersQueryString: this.filtersQueryString
        };
      }
    },
    watch: {
      forms_sync: function(val, old) {
        if (old && !val) this.fetch_tickets(this.finalQueryString);
      },
      extra_query() {
        this.is_fetched_all = false;
        if (this.isMobile || this.is_vertical_window) this.getData(true);
        else this.pageChangeHandle(1, this.search_by, true);
      },
      apply_filter: {
        handler() {
          this.$store.dispatch("tickets/add_tickets", []);
          this.clear_selected_tickets();
          this.is_fetched_all = false;

          if (this.isMobile || this.is_vertical_window) this.getData(true);
          else this.pageChangeHandle(1, this.search_by, true);
        },
        deep: true
      },
      sort_key() {
        this.pageChangeHandle(1, this.search_by, true);
      }
    },
    beforeDestroy() {
      this.clear_selected_tickets();
      this.$store.commit("tickets/SET_SEARCH_BY", "");
      this.$store.commit("tickets/SET_GROUP_BY", {
        label: "None",
        key: null,
        filter_key: null,
        enable: true
      });
      this.$store.dispatch("tickets/add_tickets", []);
      this.$eventHub.$off("update_element_data", this.update_element_data);
      this.$eventHub.$off("close_create_ticket", this.close_create_ticket);
      this.$eventHub.$off("get-other-tickets", this.getData);
      document.removeEventListener("keyup", this.clear_selection);
    },
    methods: {
      load_more() {
        this.getData(true);
      },
      async getData(loading = false) {
        try {
          if (this.group_by.key || (this.element && _.isString(this.element)))
            return;

          if (this.element && this.element.geometry) {
            this.isTicketsLoading = false;
            return;
          }

          this.isTicketsLoading = loading;

          let query = {
            q: this.search_by,
            pageSize: parseInt(this.$route.query.page_size) || 25
          };
          if (this.sort_key) query["sort"] = this.sort_key;

          if (this.is_internal) query["internal"] = true;

          if (this.isMobile || this.is_vertical_window) {
            query["offset"] = this.tickets.length;
          } else {
            query["pageNumber"] = parseInt(this.$route.query.page) || 1;
          }
          // element related data
          if (this.element && this.element.uid)
            query["element"] = this.element.uid;
          if (this.element && this.element.dataUid) {
            query.dataUid = this.element.dataUid;
          }
          this.$route.query.asset
            ? (query["assetUid"] = this.$route.query.asset)
            : null;
          // archived, fav, overdue etc.
          this.filters.forEach(filter => {
            if (filter.key && filter.active) query[filter.key] = filter.value;
          });

          if (this.sort_by.key)
            query["sort"] = (this.sort_by.order ? "-" : "") + this.sort_by.key;

          let assets_query = "";
          if (this.selected_asset.length > 0) {
            this.selected_asset.forEach(asset => {
              assets_query += "&assetUid=" + asset;
            });
          }
          query = { ...query, ...this.extra_query };

          if (this.$route.name == "therm-v2-tickets") {
            let therm_query = {};
            if (this.active_projects && this.active_projects.length) {
              let projectUid = this.active_projects.join(",");
              if (projectUid.length) therm_query["projectUid"] = projectUid;
            }

            if (this.active_class_ids && this.active_class_ids.length) {
              therm_query["classId"] = this.active_class_ids.join(",");
            }
            query["organization"] = this.$store.state.current_organization.uid;
            if (Object.keys(therm_query).length) {
              query["properties"] = btoa(
                queryString.stringify(therm_query, {
                  encode: false
                })
              );
            }
            query = { ...query, ...this.active_filters };
          }
          let res;

          if (this.$route.name == "therm-v2-tickets") {
            if (!this.active_projects.length) {
              this.$store.dispatch("tickets/add_tickets", []);
            } else {
              res = await ticketService
                .get_therm_tickets({
                  body: {
                    filters: query
                  }
                })
                .catch(err => {
                  this.handleErrors(err);
                });
            }
          } else {
            this.finalQueryString =
              queryString.stringify(query, { encode: false }) +
              assets_query +
              this.filtersQueryString;
            res = await this.fetch_tickets(this.finalQueryString);
          }

          if (
            (this.$route.meta && this.$route.meta.screen == "mobile") ||
            this.is_vertical_window
          ) {
            this.$store.dispatch("tickets/add_tickets", [
              ...this.tickets,
              ...res.data
            ]);
          } else {
            this.$store.dispatch("tickets/add_tickets", res.data);
          }

          this.$store.dispatch(
            "tickets/add_tickets_total_count",
            res.headers["x-total-count"]
          );

          console.log(
            this.tickets.length >= res.headers["x-total-count"],
            this.tickets.length,
            res.headers["x-total-count"]
          );
          this.is_fetched_all = false;
          if (this.tickets.length >= res.headers["x-total-count"]) {
            this.is_fetched_all = true;
          }
          this.updatePagination(res.headers["x-total-count"]);
          this.isTicketsLoading = false;
        } catch (err) {
          console.log(err);
          this.isTicketsLoading = false;
          this.$store.dispatch("tickets/add_tickets", []);
        }
      },

      update_element_data(element) {
        if (!this.asset_details) return;
        this.asset_details.element = element;
        this.asset_details = Object.assign(this.asset_details);
      },
      close_create_ticket() {
        this.create_pending_ticket = false;
      },
      clear_selection(evt) {
        if (this.selected && this.selected.length) {
          if (evt.keyCode == 27) {
            this.clear_selected_tickets();
          }
        }
      },
      clear_selected_tickets() {
        this.$store.dispatch("tickets/select_ticket");
      },
      getAllfilter(val) {
        this.filtersQueryString = val;
        this.$store.commit("tickets/IS_FILTERS_SELECTED", val ? true : false);
        this.segment_track_event("tickets_filtered");
      },
      reset_filters() {
        let filters = [
          { label: "Archived", key: "archived", value: true, active: false },
          {
            label: "Favorites",
            key: "bookmarked",
            value: true,
            active: this.$route.path.includes("/ticket-dashboard/favorites")
              ? true
              : false
          },
          { label: "Overdue", key: "overdue", value: true, active: false },
          {
            label: "Assigned to me",
            key: "takenUp",
            value: this.$store.state.claims
              ? this.$store.state.claims.user_id
              : null,
            active: false
          },
          {
            label: "Created by me",
            key: "owner",
            value: this.$store.state.claims
              ? this.$store.state.claims.user_id
              : null,
            active: false
          }
        ];
        this.$store.commit("tickets/SET_FILTERS", filters);
      },
      async fetch_tickets(query) {
        return await ticketService
          .getAll({
            query: query
          })
          .catch(err => {
            this.handleErrors(err);
          });
      },

      async get_asset() {
        this.asset_details = await assetService
          .get({ id: this.$route.query.asset })
          .catch(err => {
            this.handleErrors(err);
          });
      }
    }
  };
</script>

<style lang="scss">
  .tickets {
    &-top-section {
      padding: 2rem;
    }
    &-main-section {
      // padding: 2rem 3rem;
      &--columns {
        background: white;
        margin-bottom: 0px !important;
        height: 5.5rem;
        border-bottom: 1px solid #eee;
      }
    }
  }
  .height-40 {
    height: 4rem;
  }
  .tickets-height-65 {
    height: 65vh;
  }
  .tickets-height-150 {
    height: 150vh;
  }

  .filter__btn {
    cursor: pointer;
    height: 4rem;
    min-width: 4rem;
    box-shadow: 2px 2px 4px 0px rgba(23, 30, 72, 0.164);
    border: none;
    border-radius: 0.5rem;
    margin: 0 1.5rem;
    background: white;
    i {
      color: $grey-text;
      margin: auto;
      position: relative;
      top: 1rem;
      left: 1.3rem;
    }
    &.is-active {
      i {
        color: $primary;
        margin: auto;
      }
    }
  }
</style>

<template>
  <div>
    <div class="header mb-10 columns">
      <h2 class="is-semiBold-18-600-22 column is-4">
        {{ localization("app-defects-counts", "Defects counts") }}
      </h2>
      <ProjectDropdown
        class="pr-10"
        :tree_select="true"
        :details="details"
        @change="getData"
      />
    </div>
    <div class="box">
      <table class="table is-fullwidth issue-table">
        <thead>
          <tr>
            <th>{{ localization("app-defects-type", "Defects Type") }}</th>
            <th>{{ localization("app-counts", "Counts") }}</th>
          </tr>
        </thead>
        <tbody class="pt-3">
          <tr v-for="issue_type of issue_types" :key="issue_type.class_id">
            <td>
              <i
                class="fas fa-circle mr-10"
                :style="{ color: issue_type.color }"
              ></i>
              <span class="is-regular-16-00-40 has-text-theme-black">{{
                issue_type.description
              }}</span>
            </td>
            <td class="is-medium-16-500-50 has-text-theme-black">
              {{ issues[issue_type.class_name] || 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import ProjectDropdown from "./project-dropdown";
  import { viewService } from "@/app/old/desktop/shared/services/";

  export default {
    props: ["details", "metrics"],
    data() {
      return {
        issue_types: [],
        issues: {}
      };
    },
    components: {
      ProjectDropdown
    },
    async created() {
      this.getData();
      this.issue_types = await viewService.get_view_issue_types({
        id: this.$route.params.id
      });

      this.issue_types = _.filter(
        this.issue_types || [],
        f => f.class_name !== "table"
      );
    },
    methods: {
      getData(projects) {
        if (!projects) {
          this.issues = this.details.metrics.issues;
        } else if (projects.length === 0) {
          this.issues = {};
        } else {
          this.issues = {};
          projects.forEach(project => {
            Object.keys(this.metrics[project.uid].issues || {}).forEach(key => {
              this.issues[key] =
                this.metrics[project.uid].issues[key] + (this.issues[key] || 0);
            });
          });
          this.issues = { ...this.issues };
          // this.issues = await viewService.get_view_project_issue_count({
          //   id: this.$route.params.id,
          //   project_id: project,
          // });
        }
      }
    }
  };
</script>
<style>
  .issue-table thead tr {
    margin: 10px;
  }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"add-item"},[_c('div',{staticClass:"add-item__header"},[_c('span',{staticClass:"add-item__header__title"},[_vm._v(_vm._s(_vm.localization("app-assign-groups", "Assign Groups")))]),_c('i',{staticClass:"fa fa-times add-item__header__close",attrs:{"aria-hidden":"true"},on:{"click":_vm.hide_modal}})]),_c('div',{staticClass:"add-item__content has-slimscroll"},[_c('div',{staticClass:"add-item__content__select"},[_c('img',{staticClass:"mr-10",attrs:{"src":require('@/assets/icons/form-search-icon.svg')}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupInput),expression:"groupInput"}],staticClass:"add-item__content__select__text",attrs:{"type":"text","placeholder":_vm.localization('app-search-group-name', 'Search Group Name')},domProps:{"value":(_vm.groupInput)},on:{"input":function($event){if($event.target.composing)return;_vm.groupInput=$event.target.value}}})]),_c('div',{staticClass:"add-item__content__heading"},[_c('div',{staticClass:"ml-50 mr-auto"},[_vm._v(" "+_vm._s(_vm.localization("app-groups", "Groups"))+" ")])]),_c('div',{staticClass:"add-item__content__top"},_vm._l((_vm.resourceGroups),function(group,i){return _c('div',{key:i,staticClass:"add-item__content__top__row"},[_c('i',{staticClass:"fa fa-times add-user__content__top__row__cancel",on:{"click":function($event){return _vm.remove_group(i)}}}),_c('div',{staticClass:"add-item__content__top__row__main"},[(group.properties && group.properties.icon)?_c('div',{staticClass:"image is-24x24 mr-10"},[_c('img',{staticClass:"image is-24x24 is-rounded",attrs:{"src":group.properties.icon}})]):_c('div',{staticClass:"add-item__content__top__row__main__icon",style:(`background-color: ${
                group.properties && group.properties.color
                  ? group.properties.color
                  : _vm.hashed_color(group.name, 'projectManagement')
              }`)},[_vm._v(" "+_vm._s(group.name.charAt(0).toUpperCase())+" ")]),_c('span',{staticClass:"add-item__content__top__row__main__name"},[_vm._v(_vm._s(group.name))])])])}),0),_c('div',{staticClass:"add-item__content__bottom"},_vm._l((_vm.filteredAllGroups),function(group,i){return _c('div',{key:i,staticClass:"add-item__content__bottom__row"},[_c('div',{staticClass:"add-item__content__bottom__row__checkbox mr-10",class:{
            'add-item__content__bottom__row__checkbox--selected':
              _vm.selectedGroups[group.uid]
          },on:{"click":function($event){return _vm.select_group(group.uid)}}},[(_vm.selectedGroups[group.uid])?_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}):_vm._e()]),_c('div',{staticClass:"add-item__content__bottom__row__main"},[(group.properties && group.properties.icon)?_c('div',{staticClass:"image is-24x24 mr-10"},[_c('img',{staticClass:"image is-24x24 is-rounded",attrs:{"src":group.properties.icon}})]):_c('div',{staticClass:"add-item__content__bottom__row__main__icon",style:(`background-color: ${
                group.properties && group.properties.color
                  ? group.properties.color
                  : _vm.hashed_color(group.name, 'projectManagement')
              }`)},[_vm._v(" "+_vm._s(group.name.charAt(0).toUpperCase())+" ")]),_c('span',{staticClass:"add-item__content__bottom__row__main__name"},[_vm._v(_vm._s(group.name))])])])}),0)]),_c('div',{staticClass:"add-item__footer"},[(_vm.is_saving)?_c('div',{staticClass:"add-item__footer__button button is-pulled-right"},[_c('i',{staticClass:"fa fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"add-item__footer__button is-pulled-right",on:{"click":_vm.add_groups}},[_vm._v(" "+_vm._s(_vm.localization("app-save", "Save"))+" ")]),_c('div',{staticClass:"add-item__footer__button__cancel button is-pulled-right is-medium-16-500-19 mr-10",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.localization("app-cancel", "Cancel"))+" ")])])]):_c('modal-skeleton')
}
var staticRenderFns = []

export { render, staticRenderFns }
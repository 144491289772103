<template>
  <div>
    <div class="template-modal">
      <div class="is-flex has-space-between is-vcenter px-20 mb-20">
        <h1 class="template-modal__header__text is-semiBold-20-600-000">
          {{ localization("app-duplicate", "Duplicate") }}
        </h1>
        <sh-icon
          :name="'close'"
          :file="'tickets_sprite'"
          class="is-16x16 is-pointer template-modal__header__close"
        ></sh-icon>
      </div>

      <div class="template-modal__content mb-30">
        <div class="template-modal__content--template mt-30 is-relative">
          <p class="is-regular-16-00-19 has-text-theme-black mb-10">
            {{ localization("app-group", "Group") }}
          </p>
          <treeselect
            class="is-regular-16-00-19"
            :valueFormat="'object'"
            v-model="selected"
            :multiple="true"
            :normalizer="projects_normalizer"
            :options="data"
            :valueConsistsOf="'LEAF_PRIORITY'"
          ></treeselect>
        </div>
        <div class="pt-40 pb-20">
          <p class="is-regular-16-00-19 has-text-theme-black">
            {{ localization("app-report-type", "Report type") }}
          </p>
          <select
            class="select mt-10"
            v-model="selected_type"
            style="width: 100%;"
          >
            <template v-for="(report, i) in report_typess">
              <option
                :hidden="report.name === ''"
                :disabled="report.name === ''"
                :value="report.name"
                :key="i"
                >{{ report.label }}</option
              >
            </template>
          </select>
        </div>
      </div>

      <div class="info-message columns px-20">
        <div class="column is-narrow">
          <sh-icon :name="'exclamation'" class="is-16x16"></sh-icon>
        </div>

        <div class="column">
          <p class="is-semiBold-14-600-17 has-text-blue">
            {{ localization("app-note", "Note") }}
          </p>
          <p class="is-regular-14-00-17 mt-5">
            Reports won't be duplicated and will be referencing to the original
            project. All changes to the existing reports will appear in the
            duplicated projects as well.
          </p>
        </div>
      </div>

      <div class="template-modal__footer is-pointer">
        <div class="sh-button is-flex ml-auto mr-20" @click="duplicate_report">
          <sh-icon
            :name="'duplicate-white'"
            :file="'tickets_sprite'"
            class="is-22x22  actions-dropdown-content__list--icon"
          ></sh-icon>
          <span class="ml-10">{{
            localization("app-duplicate", "Duplicate")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Treeselect from "@riophae/vue-treeselect";
  import {
    groupService,
    projectService,
    uploadService
  } from "@/app/old/desktop/shared/services/";

  export default {
    props: ["report"],
    data() {
      return {
        selected: null,

        data: null,
        report_types: null,
        selected_type: ""
      };
    },
    components: {
      Treeselect
    },
    async created() {
      if (this.report) this.getData();
    },
    computed: {
      report_typess() {
        return _.filter(this.report_types, r => {
          return r.name !== "images";
        });
      }
    },
    methods: {
      async getData() {
        let { results } = await groupService
          .getAll({ query: `projects=true` })
          .catch(err => this.handleErrors(err, true));
        this.data = results;
        this.report_types = await uploadService.get_report_types({});
        this.report_types.unshift({ name: "", label: "Select Report" });
        this.selected_type = this.report.report_type;
      },
      projects_normalizer(node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.projects
        };
      },
      async duplicate_report() {
        let report_data = this.selected.map(item => {
          return {
            source_report: this.report.uid,
            destination_project: item.uid,
            report_type: this.selected_type
          };
        });
        console.log({ report_data });
        let res = await projectService
          .duplicate_report({
            id: this.$route.params.id,
            body: report_data
          })
          .catch(err => this.handleErrors(err, true));
        if (res) {
          this.$emit("close");
          this.$buefy.toast.open({
            type: "is-success",
            message: "Reports duplicated successfully!"
          });
        }
      }
    }
  };
</script>

<style></style>

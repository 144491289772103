var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.permissionCategories &&
      _vm.activePermission &&
      _vm.organizationPermissions &&
      _vm.rolePermissions
  )?_c('div',{staticClass:"role-permissions"},[_c('div',{staticClass:"role-permissions__main"},[_c('div',{staticClass:"role-permissions__main__left"},_vm._l((_vm.permissionCategories),function(category,i){return _c('div',{key:i,staticClass:"role-permissions__main__left__each",on:{"click":function($event){return _vm.set_active_permission(category)}}},[_c('div',{staticClass:"role-permissions__main__left__each__left",class:{
            'role-permissions__main__left__each__left--active':
              _vm.activePermission.name === category.name
          }},[_c('img',{attrs:{"src":require(`@/assets/icons/permissions/${category.name}.svg`)}}),_vm._v(" "+_vm._s(category.label)+" ")]),(
            _vm.organizationPermissions.permissions.hasOwnProperty(
              category.name
            ) &&
              (!_vm.rolePermissions.hasOwnProperty(category.name) ||
                !_vm.rolePermissions[category.name].length)
          )?_c('span',{staticClass:"sh-access-tag",class:[
            {
              'sh-access-tag--read': _vm.rolePermissions.hasOwnProperty(
                category.name
              )
            },
            {
              'sh-access-tag--disabled': _vm.$store.getters.is_org_user
            }
          ],on:{"click":function($event){return _vm.give_app_access(category.name)}}},[_c('i',{staticClass:"fa fa-circle",attrs:{"aria-hidden":"true"}})]):_c('span',{staticClass:"sh-access-tag",class:[
            _vm.organizationPermissions.permissions[category.name] &&
            _vm.rolePermissions[category.name] &&
            _vm.organizationPermissions.permissions[category.name].length ===
              _vm.rolePermissions[category.name].length
              ? 'sh-access-tag--read-inverse'
              : _vm.rolePermissions[category.name] &&
                _vm.rolePermissions[category.name].length
              ? 'sh-access-tag--read'
              : 'sh-access-tag--blank',
            { 'sh-access-tag--disabled': _vm.$store.getters.is_org_user }
          ]},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})])])}),0),_c('div',{staticClass:"role-permissions__main__right",class:{
        'role-permissions__main__right--disabled': !_vm.organizationPermissions.permissions.hasOwnProperty(
          _vm.activePermission.name
        )
      }},[_c('span',{staticClass:"role-permissions__main__right__title"},[_vm._v(_vm._s(_vm.activePermission.label))]),_vm._l((_vm.activePermission.permissions),function(permission,i){return _c('div',{key:i,staticClass:"role-permissions__main__right__each",class:{
          'role-permissions__main__right__each--disabled': !(
            _vm.organizationPermissions.permissions[_vm.activePermission.name] &&
            _vm.organizationPermissions.permissions[
              _vm.activePermission.name
            ].includes(permission.name)
          )
        },on:{"click":function($event){return _vm.modify_permissions(permission)}}},[_c('div',{staticClass:"role-permissions__main__right__each__checkbox",class:[
            {
              'role-permissions__main__right__each__checkbox--selected':
                _vm.rolePermissions[_vm.activePermission.name] &&
                _vm.rolePermissions[_vm.activePermission.name].includes(
                  permission.name
                )
            },
            {
              'role-permissions__main__right__each__checkbox--disabled':
                _vm.$store.getters.is_org_user
            }
          ]},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"role-permissions__main__right__each__right"},[_c('div',{staticClass:"role-permissions__main__right__each__right__title"},[_vm._v(" "+_vm._s(permission.label)+" ")]),_c('div',{staticClass:"role-permissions__main__right__each__right__text"},[_vm._v(" "+_vm._s(permission.description)+" ")])])])})],2)]),_c('div',{staticClass:"role-permissions__button"},[(_vm.awaitingResponse)?_c('div',{staticClass:"sh-button",staticStyle:{"min-width":"18.3rem"}},[_c('i',{staticClass:"fa fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"sh-button",class:{ 'sh-button--disabled': _vm.$store.getters.is_org_user },on:{"click":_vm.save_permissions}},[_vm._v(" "+_vm._s(_vm.localization("app-save-permissions", "Save Permissions"))+" ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
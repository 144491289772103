<template>
  <div>
    <div
      class="filter__btn"
      @click="open_filter = true"
      :class="{
        'is-active': filter_query_string && filter_query_string.length
      }"
    >
      <i class="fas fa-filter"></i>
    </div>
    <div class="is-relative" style="left: 22rem;top: 2.2rem;">
      <filter-dropdown
        v-if="open_filter"
        v-click-outside="() => (open_filter = false)"
        :filtersData="allFiltersData"
        :visible_parameter="['created_by', 'duedate', 'tags', 'favorites']"
        @apply="$emit('apply', $event)"
        @close="open_filter = false"
      />
    </div>
  </div>
</template>
<script>
  import FilterDropdown from "@/app/old/desktop/tickets/shared/components/filter-card/filter-dropdown";
  export default {
    components: {
      FilterDropdown
    },
    data() {
      return {
        allFiltersData: {},
        open_filter: false
      };
    }
  };
</script>
<style lang="scss">
  .filter__button {
    height: 4rem;
    width: 4rem;
    box-shadow: 2px 2px 4px 0px rgba(23, 30, 72, 0.164);
    border: none;
    border-radius: 0.5rem;
    margin: 0 1.5rem;
    i {
      color: $grey-text;
      margin: auto;
    }
  }
</style>

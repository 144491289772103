<template>
  <div>
    <div class="is-inline-flex">
      <img
        class="is-pointer ml-15"
        @click="active = !active"
        :class="{ 'rotate-chevron': !active }"
        src="@/assets/icons/chevron-circle-down.svg"
        alt
      />
      <div>
        <span class="is-medium-14-500-17 mr-5 entity-name is-capitalized">
          <span v-if="group_key == 'tags'">
            <span v-if="heading.toString().length > 0" class="mt-20 is-flex">
              <assigned-tags
                :tags="heading.toString().split(',')"
                :write_access="false"
                :number_of_tags_to_display="
                  heading.toString().split(',').length
                "
              />
              <span class="entity-count is-flex icon">{{ group.length }}</span>
            </span>
            <span v-else>{{ localization("app-no-tags", "No tags") }}</span>
          </span>
          <span v-else-if="heading == 'undefined'">{{
            localization("app-all-entities", "All Entities")
          }}</span>
          <span v-else>{{
            heading
              ? localization(
                  `app-${heading
                    .split(" ")
                    .join("-")
                    .toLowerCase()}`,
                  heading
                )
              : "None"
          }}</span>
        </span>
        <span
          v-if="
            group_key !== 'tags' ||
              (group_key == 'tags' && !heading.toString().length > 0)
          "
          class="entity-count icon"
        >
          {{ group.length }}
        </span>
      </div>
    </div>
    <div class="asset-container" style="margin-bottom: 2rem" v-show="active">
      <asset-card
        @disable_asset="$emit('disable_asset', $event)"
        @delete_asset="$emit('delete_asset', $event)"
        @transfer_ownership="$emit('transfer_ownership', $event)"
        @asset_form="$emit('asset_form', $event)"
        @open_modal="$emit('open_modal', $event)"
        v-for="asset in group"
        :key="asset.uid"
        :item="asset"
      />
    </div>
  </div>
</template>

<script>
  import AssetCard from "./asset.component";
  import AssignedTags from "@/app/old/desktop/shared/components/tags/type-tag/components/assigned-tags";
  export default {
    props: ["heading", "group", "index", "group_key"],
    components: {
      AssignedTags,
      AssetCard
    },
    data() {
      return {
        active: true
      };
    }
  };
</script>

<style>
  .asset-container {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
  .entity-chevron-down {
    padding: 0rem 0.5rem 0rem 0.5rem;
    background-color: white;
    border: 1px solid gray;
    border-radius: 50%;
    margin-right: 0.4rem;
  }
  .rotate-chevron {
    transform: rotate(-90deg);
    margin-top: -2px;
  }
  .entity-name {
    opacity: 0.7;
  }
  .entity-count {
    font-family: Barlow-bold;
    font-size: 12px;
    color: #171e48;
    opacity: 0.7;
    background-color: #dee5f0;
    border-radius: 50%;
    margin-left: 5px;
    padding: 1.3rem 1.3rem;
  }
</style>

<template>
  <div class="download-contour is-medium-16-500-00">
    <b-tabs size="is-large" v-model="activeTab">
      <b-tab-item label="CONTOUR">
        <div style="max-width: 250px">
          <b-field size="is-large" label="format">
            <b-select
              size="is-large"
              v-model="contour.format"
              expanded
              placeholder="Select a format"
            >
              <option
                :value="v"
                v-for="v in ['kml', 'geojson', 'dxf']"
                :key="v"
                class="is-uppercase"
              >
                {{ v }}
              </option>
            </b-select>
          </b-field>
          <b-field size="is-large" label="Interval (cm)">
            <b-input
              v-model="contour.interval"
              placeholder="Interval"
              :expanded="false"
            ></b-input>
          </b-field>
          <a
            class="sh-button is-capitalized is-flex mt-20"
            @click="download_contour"
          >
            <i v-if="is_loading" class="fa fa-spinner fa-spin"></i>
            <span v-else>{{ localization("app-download", "Download") }}</span>
          </a>
        </div>
      </b-tab-item>
      <b-tab-item label="GRID">
        <div style="max-width: 250px">
          <b-field label="X Resolution (m)">
            <b-input v-model="grid.x_res" placeholder="Interval"></b-input>
          </b-field>
          <b-field label="Y Resolution (m)">
            <b-input v-model="grid.y_res" placeholder="Interval"></b-input>
          </b-field>
          <a
            class="sh-button is-capitalized is-flex mt-20"
            @click="download_grid"
          >
            <i v-if="is_loading" class="fa fa-spinner fa-spin"></i>
            <span v-else>{{ localization("app-download", "Download") }}</span>
          </a>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
  import {
    gisService,
    reportService
  } from "@/app/old/desktop/shared/services/";

  export default {
    props: ["project_uid", "report_uid"],
    data() {
      return {
        is_loading: false,
        activeTab: 0,
        contour: {
          interval: 500,
          format: "dxf"
        },
        grid: {
          x_res: 5,
          y_res: 5
        }
      };
    },
    methods: {
      async download_contour($event) {
        if (this.is_loading) return;

        try {
          this.is_loading = true;
          // Get report signed url link
          let download_report = await this.get_report_url();
          let body = {
            url: download_report.url,
            uid: this.report_uid,
            options: {
              format: this.contour.format,
              interval: +this.contour.interval
            }
          };
          let response = await gisService.contour({ body });
          window.open(response.data.url, "_blank");
          this.is_loading = false;
        } catch (err) {
          console.log(err);

          this.$toast.show(
            "Somthing went wrong!",
            "",
            this.$store.state.izitoast_options.appError
          );
          this.is_loading = false;
        }
      },
      async download_grid() {
        if (this.is_loading) return;
        try {
          this.is_loading = true;
          // Get report signed url link
          let download_report = await this.get_report_url();
          let body = {
            url: download_report.url,
            uid: this.report_uid,
            options: {
              x_res: this.grid.y_res,
              y_res: this.grid.y_res
            }
          };
          let response = await gisService.xyz({ body });
          window.open(response.data.url, "_blank");
          this.is_loading = false;
        } catch (err) {
          console.log(err);
          this.$toast.show(
            "Somthing went wrong!",
            "",
            this.$store.state.izitoast_options.appError
          );
          this.is_loading = false;
        }
      },
      get_report_url() {
        return reportService.download_report({
          id: this.project_uid,
          report_uid: this.report_uid
        });
      }
    }
  };
</script>
<style lang="scss">
  .download-contour {
    .tabs {
      margin: 0;
    }
    .select select {
      text-transform: uppercase !important;
    }
  }
</style>

<template>
  <router-view class="terra-management" />
</template>

<script>
  export default {};
</script>
<style lang="scss" scoped>
  .terra-management {
    padding-bottom: 15rem;
  }
</style>

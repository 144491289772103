var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vault-toolbar"},[_c('user-dropdown',{staticClass:"is-medium-14-500-17 has-text-new-grey",staticStyle:{"margin-right":"38px"},attrs:{"is_name_hidden":true,"icon":'owner',"pxsize":36},on:{"update-user":function($event){return _vm.update_owner(_vm.current_folder, $event)}}}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-users', 'Users'), html: false }),expression:"{ content: localization('app-users', 'Users'), html: false }"}],attrs:{"src":require("@/assets/icons/vault/users.svg")},on:{"click":function($event){return _vm.add_users(_vm.current_folder)}}}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-teams', 'Teams'), html: false }),expression:"{ content: localization('app-teams', 'Teams'), html: false }"}],attrs:{"src":require("@/assets/icons/vault/teams.svg")},on:{"click":function($event){return _vm.add_teams(_vm.current_folder)}}}),_c('type-tag',{attrs:{"complete":_vm.update_tags,"resourceId":_vm.current_folder.uid,"display_on_hover":false},scopedSlots:_vm._u([{key:"toggle-dropdown",fn:function(){return [_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-tags', 'Tags'), html: false }),expression:"{ content: localization('app-tags', 'Tags'), html: false }"}],attrs:{"src":require("@/assets/icons/vault/tags.svg")}})]},proxy:true}])}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization('app-download', 'Download'),
      html: false
    }),expression:"{\n      content: localization('app-download', 'Download'),\n      html: false\n    }"}],attrs:{"src":require("@/assets/icons/vault/download-2.svg")},on:{"click":function($event){return _vm.download()}}}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization('app-bookmark', 'Bookmark'),
      html: false
    }),expression:"{\n      content: localization('app-bookmark', 'Bookmark'),\n      html: false\n    }"}],attrs:{"src":require("@/assets/icons/vault/bookmark.svg")},on:{"click":function($event){_vm.files_bulk_update({ bookmark: true });
      _vm.folders_bulk_update({ bookmark: true });}}}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization('app-archive', 'Archive'),
      html: false
    }),expression:"{\n      content: localization('app-archive', 'Archive'),\n      html: false\n    }"}],class:{ 'is-disabled': _vm.$route.query.archive },attrs:{"src":require("@/assets/icons/vault/archive.svg")},on:{"click":function($event){_vm.files_bulk_update({ archive: true });
      _vm.folders_bulk_update({ archive: true });}}}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.localization('app-delete', 'Delete')),expression:"localization('app-delete', 'Delete')"}],attrs:{"src":require("@/assets/icons/vault/delete.svg")},on:{"click":function($event){return _vm.delete_files()}}}),_c('FolderMoveDropdown',{on:{"move_folder":function($event){_vm.files_bulk_update({ folder: $event });
      _vm.folders_bulk_update({ destination: $event }, '', true);}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-card"},[_c('router-view'),_c('div',{staticClass:"list-top-section",class:{ 'px-20': _vm.$route.name.includes('list') }},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),(!_vm.$store.getters.is_org_user)?_c('div',{staticClass:"list-top-section--create is-flex align-center"},[_c('div',{staticClass:"mr-20"},[(
            _vm.$store.getters.is_internal_user && _vm.$route.name !== 'team-details'
          )?_c('b-checkbox',{attrs:{"type":"is-info"},on:{"click":function($event){_vm.showAll = !_vm.showAll}},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}},[_c('span',{staticClass:"is-medium-16-500-19"},[_vm._v(_vm._s(_vm.localization("app-show-all-users", "show all Users")))])]):_vm._e()],1),(_vm.$route.name === 'admin-user-list')?_c('div',{on:{"click":function($event){return _vm.open_form(_vm.UsersFormComponent, { header: 'Create New User' })}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-user", "New User"))+" ")])]):_vm._e()]):_vm._e()]),(_vm.data && _vm.data.length)?_c('div',[_c('div',{staticClass:"sh-list"},[_c('list-header'),_vm._l((_vm.data),function(user){return _c('div',{key:user.uid,staticClass:"sh-list__item"},[_c('div',{staticClass:"columns is-flex align-center is-gapless"},[_c('div',{staticClass:"column is-4 user-card"},[_c('div',{staticClass:"left",staticStyle:{"height":"3rem","width":"3rem"}},[(user.display_picture || user.profile_picture)?_c('img',{staticClass:"image is-rounded is-30x30",attrs:{"src":user.display_picture || user.profile_picture}}):_c('div',{staticClass:"is-icon is-30x30 user-style is-marginless",style:(`background-color: ${_vm.hashed_color(
                    user.first_name
                      ? user.first_name + ' ' + user.last_name
                      : user.email,
                    'userManagement'
                  )};`)},[_vm._v(" "+_vm._s(user.first_name ? user.first_name.charAt(0) : user.email.charAt(0))+" ")]),(
                  _vm.$store.state.online_users &&
                    _vm.$store.state.online_users
                      .map(el => el.id)
                      .includes(user.uid)
                )?_c('div',{staticClass:"online-status"}):_vm._e(),_c('div',{staticClass:"badge-container"},[(user.is_owner)?_c('div',{staticClass:"badge badge--owner"},[_vm._v(" "+_vm._s(_vm.localization("app-owner", "Owner"))+" ")]):(user.is_manager)?_c('div',{staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.localization("app-manager", "Manager"))+" ")]):(user.is_guest)?_c('div',{staticClass:"badge badge--guest"},[_vm._v(" "+_vm._s(_vm.localization("app-guest", "Guest"))+" ")]):_vm._e()])]),_c('div',{staticClass:"right"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'user-details', params: { id: user.uid } }}},[_c('div',{staticClass:"is-semiBold-16-600-19 name-highlights-on-hover truncate-text is-width-20"},[_vm._v(" "+_vm._s(user.first_name ? user.first_name + " " + user.last_name : user.email ? user.email : "Unknown")+" ")]),_c('div',{staticClass:"is-medium-14-500-17 has-text-theme-black has-opacity-7 mt-5"},[_vm._v(" "+_vm._s(user.organization && user.organization.name)+" ")])])],1)]),_c('div',{staticClass:"column is-3"},[_c('primary-tag',{attrs:{"tags":user.labels,"type":'team',"truncate_length":6,"noTopMargin":true,"toShowCount":_vm.$route.name != 'team-details' ? 2 : 1}})],1),_c('div',{staticClass:"column is-2"},[(user.teams && user.teams.length)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(user.teams[0] && user.teams[0].name),expression:"user.teams[0] && user.teams[0].name"}],staticClass:"bottom no-whitespace-wrap is-medium-14-500-17 has-primary-text"},[_vm._v(" "+_vm._s(_vm._f("truncate")(user.teams[0].name,15))+" ")]):_c('div',{staticClass:"not-assigned is-regular-14-00-17 no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm.localization("app-no-role-assigned", "*No role assigned"))+" ")])]),_c('div',{staticClass:"column is-flex is-center",class:_vm.$route.name === 'admin-user-list' ? 'is-2' : 'is-3'},[_c('status-tag',{attrs:{"status":user.is_guest
                  ? user.active
                    ? 'active'
                    : 'inactive'
                  : user.status}})],1),(_vm.$route.name === 'admin-user-list')?_c('div',{staticClass:"column is-1"},[_c('div',{staticClass:"is-flex is-vcenter is-centered"},[_c('div',{staticClass:"is-pointer",on:{"click":function($event){return _vm.open_form(
                    _vm.UsersFormComponent,
                    { header: 'Edit Users' },
                    user
                  )}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'edit',"file":'helper_sprite'}})],1)])]):_vm._e()])])})],2)]):_c('div',[(_vm.isLoading)?_c('div',[_c('list-header'),_c('list-skeleton')],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/users-illustration.svg"),"alt":""},slot:"image"}),_c('div',{attrs:{"slot":"button"},slot:"button"},[(!_vm.is_next_tracker && _vm.$route.name === 'user-list')?_c('router-link',{attrs:{"to":{ name: 'user-invite' }}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-invite-users", "Invite Users"))+" ")])]):_vm._e()],1)])],1),_c('div',[_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
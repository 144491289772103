var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.view_type == 'list')?_c('div',[(!_vm.is_loading)?_c('div',[_vm._l((_vm.data),function(item,i){return [(item.hasOwnProperty('parent'))?_c('list-item-folder',{key:i,class:{ 'is-hidden': _vm.show_filter.filter_key == 'files' },attrs:{"is_viewer_small":_vm.is_viewer_small,"item":item,"i":i}}):_c('list-item-file',{key:i,class:{ 'is-hidden': _vm.show_filter.filter_key == 'folders' },attrs:{"is_viewer_small":_vm.is_viewer_small,"item":item,"i":i}})]})],2):[(_vm.is_viewer_small)?_c('mobile-list-skeleton'):_c('vault-list-skeleton')]],2):_c('div',[(!_vm.is_loading)?_c('div',[_c('drag-select',{staticClass:"columns is-multiline is-marginless padding-20",attrs:{"selectorClass":"dragitem"},on:{"change":function($event){return _vm.DRAG_SELECT($event)}}},[_vm._l((_vm.data),function(folder,i){return [(folder.hasOwnProperty('parent'))?_c('div',{key:folder.uid,staticClass:"column is-3 is-centered is-flex is-center",class:{ 'is-hidden': _vm.show_filter.filter_key == 'files' }},[_c('grid-item-folder',{staticClass:"is-pointer",attrs:{"data-file":folder.uid,"item":folder,"i":i},on:{"toggle_selection":function($event){return _vm.TOGGLE_SELECTION($event)},"shift_click":function($event){return _vm.$store.dispatch('vault/SHIFT_SELECTION', {
                  item: $event,
                  all_items: _vm.data
                })}}})],1):_c('div',{key:folder.uid,staticClass:"column is-3 is-centered is-flex is-center",class:{ 'is-hidden': _vm.show_filter.filter_key == 'folders' }},[_c('grid-item-file',{staticClass:"is-pointer",attrs:{"data-file":folder.uid,"is_viewer_small":_vm.is_viewer_small,"item":folder,"i":i},on:{"toggle_selection":function($event){return _vm.TOGGLE_SELECTION($event)},"shift_click":function($event){return _vm.$store.dispatch('vault/SHIFT_SELECTION', {
                  item: $event,
                  all_items: _vm.data
                })}}})],1)]})],2)],1):_vm._l((2),function(i){return _c('div',{key:i,staticClass:"columns is-gapless"},_vm._l((4),function(i){return _c('attachment-skeleton',{key:i,staticClass:"column is-3"})}),1)})],2),(!_vm.data.length && !_vm.is_loading)?[_c('vault-noresults')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l(([1, 2, 3, 4, 5]),function(j){return _c('skeleton-loading',{key:j,staticClass:"mt-40"},[_c('row',[_c('column',{attrs:{"span":24,"gutter":10}},[_c('square-skeleton',{attrs:{"boxProperties":{
            width: '100%',
            height: '10px'
          }}})],1)],1),_vm._l(([1, 2]),function(i){return _c('row',{key:i,staticClass:"mt-10",attrs:{"justify":'flex-end'}},[_c('column',{attrs:{"span":22,"gutter":10}},[_c('square-skeleton',{attrs:{"boxProperties":{
            width: '100%',
            height: '15px'
          }}})],1)],1)})],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close_dropdown),expression:"close_dropdown"}]},[(_vm.active == _vm.item.uid)?_c('ul',{staticClass:"actions-dropdown-content is-project"},[_c('li',{staticClass:"is-flex is-between actions-dropdown-content__list"},[_c('p',{staticClass:"actions-dropdown-content__list--item",on:{"click":function($event){_vm.copy_project = _vm.item.uid}}},[_c('sh-icon',{staticClass:"is-16x16 actions-dropdown-content__list--icon",attrs:{"name":'copy'}})],1),_c('p',{staticClass:"actions-dropdown-content__list--item",on:{"click":function($event){return _vm.$emit('on-edit')}}},[_c('sh-icon',{staticClass:"is-16x16 actions-dropdown-content__list--icon",attrs:{"name":'pen'}})],1),_c('p',{staticClass:"actions-dropdown-content__list--item",on:{"click":function($event){_vm.showDeleteModal = true;
          _vm.currentProject = _vm.item;}}},[_c('sh-icon',{staticClass:"is-16x16 actions-dropdown-content__list--icon",attrs:{"name":'trash'}})],1)]),_c('router-link',{staticClass:"actions-dropdown-content__item",attrs:{"tag":"li","to":{
        name: 'project-details',
        params: { group_id: _vm.item.group.uid, id: _vm.item.uid }
      }}},[_c('sh-icon',{staticClass:"is-22x22 mr-10 actions-dropdown-content__item--icon",attrs:{"name":'watchers'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-view-details", "View Details")))])],1),_c('li',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){_vm.move_project = _vm.item.uid}}},[_c('sh-icon',{staticClass:"is-18x18 mr-10 actions-dropdown-content__item--icon",attrs:{"name":'move-folder'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-move", "Move")))])],1),_c('li',{staticClass:"actions-dropdown-content__item",on:{"click":function($event){_vm.currentProject = _vm.item;
        _vm.showPropertiesModal = true;}}},[_c('sh-icon',{staticClass:"is-18x18 mr-10 actions-dropdown-content__item--icon",attrs:{"name":'properties'}}),_c('span',{staticClass:"actions-dropdown-content__item--text"},[_vm._v(_vm._s(_vm.localization("app-properties", "Properties")))])],1)],1):_vm._e(),(_vm.copy_project == _vm.item.uid)?_c('duplicate-project',{attrs:{"project":_vm.item},on:{"close":function($event){_vm.copy_project = -1;
      _vm.$emit('hide-dropdown');}}}):_vm._e(),(_vm.move_project == _vm.item.uid)?_c('move-project',{attrs:{"project":_vm.item.uid,"group":_vm.item.group.uid},on:{"on-group-change":function($event){return _vm.$emit('group-change', $event)},"close":function($event){_vm.move_project = -1;
      _vm.$emit('hide-dropdown');}}}):_vm._e(),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.currentProject.uid,"groupId":_vm.item.group.uid,"resourceName":_vm.currentProject.name,"resourceType":'project',"resourceService":_vm.projectService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){_vm.$emit('deleted');
      _vm.$emit('hide-dropdown');}}}):_vm._e(),(_vm.showPropertiesModal)?_c('project-properties',{attrs:{"project":_vm.currentProject},on:{"closed":function($event){_vm.currentProject = null;
      _vm.showPropertiesModal = false;
      _vm.$emit('hide-dropdown');},"properties_updated":function($event){return _vm.$eventHub.$emit('refresh-project-list')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
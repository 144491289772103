var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mission-inner-card",class:[
    _vm.$route.name === 'mission-list'
      ? 'mt-30'
      : 'mission-inner-card__card-shadow'
  ]},[_c('div',{staticClass:"mission-inner-card__row",staticStyle:{"border-bottom":"1px solid #d0d2da"}},[_c('div',{staticStyle:{"width":"25%"}},[_vm._v(_vm._s(_vm.localization("app-name", "Name")))]),_c('div',{staticStyle:{"width":"15%"}},[_vm._v("Mission Type")]),_c('div',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.localization("app-modified-on", "Modified On"))+" ")]),_c('div',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(_vm.localization("app-status", "Status")))]),_c('div',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(_vm.localization("app-project", "Project")))]),_c('div',{staticStyle:{"width":"10%"}})]),_vm._l((_vm.missions),function(mission,i){return _c('div',{key:i,staticClass:"mission-inner-card__row"},[_c('div',{staticStyle:{"width":"25%","font-weight":"600","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(mission.name)+" ")]),_c('div',{staticStyle:{"width":"15%","text-transform":"capitalize"}},[_vm._v(" "+_vm._s(mission.missionType.name)+" ")]),_c('div',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(_vm._f("prettyDate")(mission.lastModified)))]),_c('div',{staticStyle:{"width":"15%","text-transform":"capitalize","display":"flex","align-items":"center"}},[(mission.status === 'pending')?_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/pending.svg")}}):_vm._e(),_vm._v(" "+_vm._s(mission.status)+" ")]),_c('div',{staticStyle:{"width":"15%","text-transform":"capitalize"}},[(mission.projectUID && _vm.groupUid)?_c('router-link',{attrs:{"to":{
          name: 'project-details',
          params: {
            group_id: _vm.groupUid,
            id: mission.projectUID
          }
        },"tag":"span"}},[_c('div',{staticStyle:{"cursor":"pointer","width":"9rem","height":"4rem","border":"1.5px solid $primary","border-radius":"6px","color":"$primary","font-size":"1.6rem","display":"flex","align-items":"center","justify-content":"space-around"}},[_vm._v(" View ")])]):_c('span',{on:{"click":function($event){return _vm.create_project(mission)}}},[_c('div',{staticStyle:{"cursor":"pointer","width":"9rem","height":"4rem","border":"1.5px solid $primary","border-radius":"6px","color":"$primary","font-size":"1.6rem","display":"flex","align-items":"center","justify-content":"space-around"}},[_vm._v(" Create ")])])],1),_c('div',{staticStyle:{"width":"10%","display":"flex","justify-content":"space-evenly"}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/icons/delete.svg")},on:{"click":function($event){return _vm.delete_mission(mission)}}}),_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/icons/duplicate.svg")},on:{"click":function($event){return _vm.duplicate_mission(mission)}}})])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"therm-sidebar has-slimscroll-xs is-paddingless",style:({ width: `${_vm.sidebar_width}rem` })},[_c('projects-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'therm-v2-compare'),expression:"$route.name !== 'therm-v2-compare'"}],attrs:{"custom_styles_for_groups":_vm.$route.name == 'therm-v2-reports' || _vm.$route.name == 'therm-v2-tickets'
        ? `right: 4rem; top: 1rem; bottom: initial`
        : `left: ${_vm.sidebar_width + 2}rem`,"custom_styles_for_projects":_vm.$route.name == 'therm-v2-reports' || _vm.$route.name == 'therm-v2-tickets'
        ? `left: ${_vm.sidebar_width + 4}rem;top:1.5rem;`
        : `left: ${_vm.sidebar_width + 2}rem`},on:{"projects-selected":_vm.set_active_projects,"projects-unselected":_vm.set_inactive_projects}}),(!_vm.mini_thermsidebar)?_c('img',{staticClass:"mainlogo image",attrs:{"src":require("@/assets/icons/thermv2/logo.svg")}}):(_vm.mini_thermsidebar)?_c('div',{staticClass:"image is-32x32 logo-mini image"},[_c('img',{staticClass:"is-marginless",attrs:{"src":require("@/assets/logos/sensehawk-logo-small.svg"),"alt":""}})]):_vm._e(),_c('hr',{staticClass:"divider is-marginless has-opacity-1"}),_c('sh-icon',{staticClass:"is-20x20 image is-pointer toggle-thermsidebar",style:({ transform: _vm.mini_thermsidebar ? 'rotate(180deg)' : null }),attrs:{"file":"thermv2_sprite","name":"expand-collapse-left"},nativeOn:{"click":function($event){return _vm.SET_MINI_THERMSIDEBAR(!_vm.mini_thermsidebar)}}}),_c('div',{staticClass:"has-slimscroll-xs"},[_c('div',{staticClass:"mt-15"},_vm._l((_vm.navigation_headers),function(nav){return _c('router-link',{key:nav.header,staticClass:"therm-sidebar__item is-flex align-center is-pointer pl-20",class:{ 'is-bg-completed': _vm.handle_routes(nav.header) },attrs:{"tag":"div","to":nav.to},nativeOn:{"click":function($event){_vm.is_intercom_active = false}}},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.mini_thermsidebar ? nav.header : null,
            classes: ['inverted-tooltip'],
            placement: 'right-end',
            offset: '12px'
          }),expression:"{\n            content: mini_thermsidebar ? nav.header : null,\n            classes: ['inverted-tooltip'],\n            placement: 'right-end',\n            offset: '12px'\n          }"}],staticClass:"therm-sidebar__item--icon"},[_c('sh-icon',{staticClass:"icon is-24x24",attrs:{"name":_vm.handle_routes(nav.header) ? nav.sprite + '-active' : nav.sprite,"file":'thermv2_sprite'}})],1),(!_vm.mini_thermsidebar)?_c('p',{staticClass:"therm-sidebar__item--text is-medium-15-500-18 pl-10",class:{ 'has-text-white': _vm.handle_routes(nav.header) }},[_vm._v(" "+_vm._s(_vm.localization( `app-${nav.header .split(" ") .join("-") .toLowerCase()}`, nav.header ))+" ")]):_vm._e()])}),1),_c('div',{staticClass:"therm-sidebar__item is-flex align-center is-pointer pl-20",class:{ 'is-bg-completed ': _vm.is_intercom_active },on:{"click":function($event){_vm.is_intercom_active = !_vm.is_intercom_active}}},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Support',
          classes: ['inverted-tooltip'],
          placement: 'right-end',
          offset: '12px'
        }),expression:"{\n          content: 'Support',\n          classes: ['inverted-tooltip'],\n          placement: 'right-end',\n          offset: '12px'\n        }"}],staticClass:"therm-sidebar__item--icon ml-2"},[_c('sh-icon',{staticClass:"icon is-20x20",attrs:{"name":'applications'}})],1),(!_vm.mini_thermsidebar)?_c('p',{staticClass:"therm-sidebar__item--text is-medium-15-500-18 pl-10",class:{ 'has-text-white ': _vm.is_intercom_active }},[_vm._v(" "+_vm._s(_vm.localization("app-support", "Support"))+" ")]):_vm._e()]),_c('div',{staticClass:"filters-section mt-10"},[_c('div',{staticClass:"is-relative"},[_c('div',{staticClass:"is-pointer is-medium-15-500-18 therm-sidebar__item--text is-flex align-center my-15 pl-20",class:{ 'is-disabled': _vm.$route.name === 'therm-v2-compare' },on:{"click":function($event){_vm.tickets_filter_active = !_vm.tickets_filter_active}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.mini_thermsidebar ? 'Filters' : null,
              classes: ['inverted-tooltip'],
              placement: 'right-end',
              offset: '8px'
            }),expression:"{\n              content: mini_thermsidebar ? 'Filters' : null,\n              classes: ['inverted-tooltip'],\n              placement: 'right-end',\n              offset: '8px'\n            }"}]},[_c('sh-icon',{staticClass:"is-24x24 icon mr-10",attrs:{"file":'thermv2_sprite',"name":_vm.is_filter_active ? 'filter-selected' : 'filter'}})],1),(!_vm.mini_thermsidebar)?_c('span',[_vm._v(" "+_vm._s(_vm.localization("app-filters", "Filters"))+" ")]):_vm._e()]),(_vm.tickets_filter_active)?_c('ticket-filters',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
            () => {
              _vm.tickets_filter_active = false;
            }
          ),expression:"\n            () => {\n              tickets_filter_active = false;\n            }\n          "}],staticClass:"thermv2-filters-popup",style:({
            left: _vm.mini_thermsidebar ? '8rem' : '22rem'
          }),attrs:{"visible_parameter":[
            'status',
            'priority',
            'assignee',
            'created_by',
            'duedate',
            'tags'
          ],"has_dark_theme":true,"filtersData":_vm.filtersData},on:{"close":function($event){_vm.tickets_filter_active = false}},scopedSlots:_vm._u([{key:"custom-content",fn:function({ filtersData }){return [_c('div',{staticClass:"filter-dropdown-content__item has-text-black mt-5 is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization( "app-temperature-difference", "Temperature Difference" ))+" ")]),_c('temperature-difference',{staticClass:"filter-dropdown-content__item is-block no-padding-bottom",on:{"updated":_vm.onUpdated}})]}}],null,false,1422470257)}):_vm._e()],1),(!_vm.mini_thermsidebar)?_c('div',{staticClass:"therm-sidebar__filters has-slimscroll-xs"},_vm._l((_vm.therm_vector_filters),function(vector){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              vector.description.length > _vm.vector_description_max_length
                ? vector.description
                : null,
            classes: ['inverted-tooltip'],
            placement: 'top'
          }),expression:"{\n            content:\n              vector.description.length > vector_description_max_length\n                ? vector.description\n                : null,\n            classes: ['inverted-tooltip'],\n            placement: 'top'\n          }"}],key:vector.class_id,staticClass:"py-10 is-flex align-center therm-sidebar__filters--item is-between px-10 is-pointer"},[_c('div',{staticClass:"therm-sidebar__filters--box is-relative is-flex align-center",style:({ borderColor: vector.color }),on:{"click":function($event){return _vm.update_active_class_ids(vector.class_id)}}},[_c('span',{staticClass:"is-inner is-absolute",style:({
                background: _vm.active_class_ids.includes(vector.class_id)
                  ? vector.color
                  : 'none'
              })}),_c('p',{staticClass:"is-regular-14-500-17 therm-sidebar__item--text pl-20"},[_vm._v(" "+_vm._s(_vm._f("truncate")(vector.description,_vm.vector_description_max_length))+" ("+_vm._s(vector.count || 0)+") ")])]),_c('div',{staticClass:"is-regular-14-500-17 selector is-flex align-center is-center",on:{"click":function($event){_vm.active_class_ids = [];
              _vm.update_active_class_ids(vector.class_id);}}},[_c('span',[_vm._v("1")])])])}),0):_vm._e()]),_c('export-data',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mini_thermsidebar && _vm.$route.name !== 'therm-v2-compare'),expression:"!mini_thermsidebar && $route.name !== 'therm-v2-compare'"}],staticClass:"is-absolute"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="sh-list-header columns is-regular-14-00-17 is-marginless is-gapless"
  >
    <div
      class="column "
      :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-4']"
    >
      {{ localization("app-terra", "Terra") }}
    </div>
    <div
      class="column "
      :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-2_5']"
    >
      {{ localization("app-created-by", "Created by") }}
    </div>
    <div
      class="column "
      :class="[$route.name.includes('asset-details') ? 'is-2' : 'is-2_5']"
    >
      {{ localization("app-users", "Users") }}
    </div>
    <div
      class="column"
      :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-2']"
    >
      {{ localization("app-modified-on", "Modified On") }}
    </div>
    <div class="column is-1"></div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

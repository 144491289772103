var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideHandler),expression:"clickOutsideHandler"}],staticClass:"form-dropdown",class:{ 'form-dropdown--error': _vm.validationError }},[_c('div',{staticClass:"form-dropdown__select"},[_c('img',{staticClass:"mr-10",attrs:{"src":require('@/assets/icons/form-search-icon.svg')}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInput),expression:"userInput"}],staticClass:"form-dropdown__select__text",attrs:{"type":"text","placeholder":_vm.localization(
          `app-${_vm.selectText
            .split(' ')
            .join('-')
            .toLowerCase()}`,
          _vm.selectText
        ),"disabled":_vm.disabled},domProps:{"value":(_vm.userInput)},on:{"focus":function($event){_vm.dropdown = true},"input":[function($event){if($event.target.composing)return;_vm.userInput=$event.target.value},function($event){_vm.dropdown = true}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.enterKeyPressed.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.nextItem.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.previousItem.apply(null, arguments)}]}}),_c('span',{staticClass:"form-dropdown__select__icon",on:{"click":function($event){_vm.dropdown = !_vm.dropdown}}},[(_vm.dropdown)?_c('i',{staticClass:"fas fa-chevron-up"}):_c('i',{staticClass:"fas fa-chevron-down"})])]),(_vm.dropdown)?_c('div',{staticClass:"form-dropdown__options has-slimscroll"},[(_vm.filteredOptions.length)?_c('div',_vm._l((_vm.filteredOptions),function(item,i){return _c('div',{key:i,staticClass:"form-dropdown__options__item",class:{
          'form-dropdown__options__item--selected':
            _vm.currentItem.uid === item.uid
        },on:{"click":function($event){return _vm.itemSelected(item)}}},[(_vm.multi)?_c('div',{staticClass:"form-dropdown__options__item__checkbox",class:[
            _vm.multiInput[item.uid]
              ? 'form-dropdown__options__item__checkbox--selected'
              : ''
          ]},[(_vm.multiInput[item.uid])?_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}):_vm._e()]):_vm._e(),(_vm.showIcon)?_c('div',{staticClass:"form-dropdown__options__item__icon",style:(`background-color: ${
              item.properties && item.properties.color
                ? item.properties.color
                : _vm.hashed_color(item.name, 'projectManagement')
            }`)},[_vm._v(" "+_vm._s(item ? item.name[0].toUpperCase() : "")+" ")]):_vm._e(),_c('div',{staticClass:"form-dropdown__options__item__text",style:(`color: ${item.color}`)},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0):(_vm.isTag)?_c('div',{staticClass:"form-dropdown__options__item form-dropdown__options__item--new-tag"},[_vm._v(" Press enter to create tag ")]):_c('div',{staticClass:"form-dropdown__options__item form-dropdown__options__item--new-tag"},[_vm._v(" *"+_vm._s(_vm.localization("app-not-found", "Not Found"))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
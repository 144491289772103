var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('skeleton-loading',_vm._l((3),function(item){return _c('row',{key:item,attrs:{"gutter":{ top: '50px', bottom: '10px' }}},[_c('column',{attrs:{"span":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '50%',
            height: '15px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            top: '25px',
            width: '90%',
            height: '5px'
          }}})],1),_c('column',{attrs:{"span":4}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '75%',
            height: '15px'
          }}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
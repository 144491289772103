var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('skeleton-loading',[_c('row',{staticClass:"is-flex align-start"},[_c('column',{attrs:{"span":2}},[_c('circle-skeleton',{attrs:{"boxProperties":{
            width: '100%',
            height: '30px',
            top: '0px'
          }}})],1),_c('column',{attrs:{"span":22,"gutter":10}},[_c('square-skeleton',{attrs:{"boxProperties":{
            width: '100%',
            height: '10px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            width: '100%',
            height: '50px',
            top: '15px'
          }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
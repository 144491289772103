<template>
  <div v-if="ticket_id && detailed_ticket">
    <mobile-ticket-details
      :ticket="detailed_ticket"
      @exit="close_popup()"
      v-if="$route.meta.screen == 'mobile'"
    ></mobile-ticket-details>
    <div v-else class="new-ticket-details has-slimscroll-xs" id="scroll">
      <div class="columns padding-20">
        <div class="column is-8 is-flex is-between align-center">
          <div>
            <div class="is-flex align-center">
              <p
                v-tooltip="
                  detailed_ticket.name.length > 42 ? detailed_ticket.name : null
                "
                v-if="edit_ticket_name == -1"
                @click="
                  detailed_ticket.writeAccess
                    ? (edit_ticket_name = detailed_ticket.uid)
                    : null
                "
                class="mr-10 is-medium-18-500-22"
                :class="[
                  !detailed_ticket.writeAccess ? 'is-not-allowed' : null
                ]"
              >
                {{ detailed_ticket.name | truncate(42) }}
              </p>
              <input
                v-click-outside="() => update_ticket_name(detailed_ticket.name)"
                v-if="edit_ticket_name == detailed_ticket.uid"
                type="text"
                name="name"
                id="ticket"
                class="is-inline-input is-medium-18-500-22"
                :placeholder="detailed_ticket.name"
                v-focus
                style="width: 60rem; height: 29px"
                v-model="detailed_ticket.name"
                @keyup.esc="edit_ticket_name = -1"
                @keyup.enter="update_ticket_name(detailed_ticket.name)"
              />
              <div
                @click="update_ticket({ bookmark: !detailed_ticket.bookmark })"
              >
                <sh-icon
                  :name="'bookmarked-highlight'"
                  class="is-18x18 mr-10 is-pointer"
                  v-if="detailed_ticket.bookmark"
                />
                <sh-icon
                  :name="'bookmark'"
                  class="is-18x18 mr-10 is-pointer"
                  v-else
                />
              </div>
            </div>
            <tags-dropdown
              class="mt-10"
              :service="apiService"
              :tags="detailed_ticket.tags"
              :resourceId="detailed_ticket.uid"
              :write_access="detailed_ticket.writeAccess"
              :complete="update_ticket"
              :display_on_hover="detailed_ticket.tags.length"
            />
          </div>

          <div
            class="is-flex is-vcenter"
            v-if="currently_viewing && currently_viewing.length"
          >
            <div
              class="has-text-theme-black is-regular-12-00-14 is-flex is-vcenter"
            >
              {{ localization("app-viewing", "Viewing") }}
              <sh-icon
                :name="'info-grey'"
                class="is-14x14 mr-10 ml-5 mt-2"
              ></sh-icon>
            </div>
            <user-tag
              :users="currently_viewing"
              :tagsCountToShow="3"
              :is_precense_component="true"
              :custom_class="'tiny-user-avatar'"
              :custom_size="24"
              :no_access="true"
            ></user-tag>
          </div>
        </div>
        <div class="column is-4 is-flex is-end is-align-flex-start">
          <div class="is-flex align-center">
            <div class="mr-30 is-flex is-relative">
              <watcher-dropdown
                :class="[
                  detailed_ticket && !detailed_ticket.writeAccess
                    ? 'has-no-access'
                    : null
                ]"
                :ticket="detailed_ticket"
                :ticket_watchers="detailed_ticket.watchers"
                :hide_watchers="true"
                :custom_icon_size="26"
                :custom_icon="'follow-icon'"
                :icon_tooltip="localization('app-follow', 'Follow')"
                :component="'ticketDetails'"
                @update-watchers="update_ticket({ watchers: $event })"
              ></watcher-dropdown>
              <div
                class="watchers-indicator is-semiBold-12-600-14"
                v-if="
                  detailed_ticket.watchers && detailed_ticket.watchers.length
                "
              >
                {{ detailed_ticket.watchers.length }}
              </div>
            </div>
            <actions-dropdown
              class="ticket-details-button mr-30"
              :ticket="detailed_ticket"
              @close_details="close_popup()"
            ></actions-dropdown>
            <div class="is-pointer" @click="close_popup()">
              <sh-icon :name="'ticket-detail-close'" class="is-12x12 ml-5" />
            </div>
          </div>
        </div>
      </div>

      <div class="new-ticket-details__content">
        <div class="columns is-gapless">
          <div class="column is-8 br-1">
            <description-section
              class="px-20"
              :is_loading="is_loading"
              v-if="ticket && ckeditorLoaded"
              :description="ticket.description"
              :ticket="detailed_ticket"
              :owner="ticket.owner"
            ></description-section>

            <attachments
              :ticket="detailed_ticket"
              :component="'ticketDetails'"
              :service="apiService"
            ></attachments>

            <task-list
              :service="apiService"
              :channel="channel"
              :ticket="detailed_ticket"
              :component="'ticketDetails'"
            ></task-list>
            <forms-section
              v-if="$store.getters.ticket_forms"
              :ticket="detailed_ticket"
            ></forms-section>

            <comments
              v-if="ckeditorLoaded"
              :commentsUnread="(commentsUnread = 0)"
              :presence_members="viewingMembers"
              :ticket="detailed_ticket"
              :service="apiService"
              :channelName="'ticket-' + detailed_ticket.uid"
            ></comments>
          </div>
          <div class="column is-4">
            <right-section
              :is_loading="is_loading"
              :ticket="detailed_ticket"
              :presence_members="currently_viewing"
              @overlay_popup="has_overlay = $event"
              @close-popup="close_popup"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DescriptionSection from "./components/description-section";
  import TaskList from "./components/task-list/task-list-section";
  import Attachments from "@/app/old/desktop/tickets/components/ticket-details/components/attachments/attachments.vue";
  import FormsSection from "@/app/old/desktop/tickets/components/ticket-details/components/forms/forms-section.vue";
  import Comments from "@/app/old/desktop/tickets/components/ticket-details/new-ticket-details/comments/comments-section.vue";
  import ActionsDropdown from "@/app/old/desktop/tickets/shared/components/actions-dropdown";
  import { mapState } from "vuex";
  import MobileTicketDetails from "@/app/old/mobile/modules/tickets/components/ticket-details/mobile-ticket-details.vue";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import TagsDropdown from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import WatcherDropdown from "@/app/old/desktop/tickets/shared/components/watchers-dropdown.vue";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import { loadScript } from "@/utils/loadScript.js";
  import RightSection from "@/app/old/desktop/tickets/components/ticket-details/components/right-section.vue";
  import BrowserBackModalClose from "@/app/old/desktop/shared/mixins/browser-back-modal-close.mixin.vue";
  export default {
    mixins: [BrowserBackModalClose],
    props: ["ticket_id"],
    data() {
      return {
        apiService: ticketService,
        has_overlay: false,
        ticket: null,
        channel: null,
        is_loading: false,
        viewingMembers: null,
        presence_members: [],
        commentsUnread: 0,
        ckeditorLoaded: false,
        edit_ticket_name: -1
      };
    },
    components: {
      DescriptionSection,
      TaskList,
      Attachments,
      ActionsDropdown,
      FormsSection,
      Comments,
      MobileTicketDetails,
      RightSection,
      TagsDropdown,
      WatcherDropdown,
      UserTag
    },
    mounted() {
      loadScript(
        "https://cdn.jsdelivr.net/gh/sensehawk/cdn/ckeditor/ckeditor-classic.js",
        this.loadCkEditor,
        "ckeditor"
      );

      this.onModalBackgroundClick();

      this.channel = this.$store.state.PUSHER.subscribe(
        `presence-tickets-ticket_${this.ticket_id}`
      );
      this.channel.bind("pusher:subscription_succeeded", e => {
        this.update_members();
        this.add_presence_members(e);
      });

      this.channel.bind("pusher:member_added", member => {
        this.update_members();
        this.presence_members = [...this.presence_members, member];
      });

      this.channel.bind("pusher:member_removed", member => {
        this.update_members();
        this.presence_members = _.remove(
          this.presence_members,
          presence_member => {
            return presence_member == member.id;
          }
        );
        this.presence_members = Object.assign([], this.presence_members);
      });
    },

    created() {
      if (this.ticket_id) {
        this.get_details(true);
        this.$store.state.chat.client.on(
          "notification.message_new",
          this.chatStreamMessage
        );
      }
    },

    beforeDestroy() {
      this.$store.state.chat.client.off(
        "notification.message_new",
        this.chatStreamMessage
      );
      this.$store.state.PUSHER.unsubscribe(
        `presence-tickets-ticket_${this.ticket_id}`
      );
      this.channel = null;
      this.ticket_id = null;
    },
    computed: {
      ...mapState("tickets", ["tickets"]),

      ...mapState(["users"]),
      detailed_ticket() {
        return this.tickets.find(t => t.uid == this.ticket_id);
      },
      currently_viewing() {
        return this.viewingMembers
          ? this.viewingMembers.filter(
              user => user.uid != this.$store.state.claims.user_id
            )
          : [];
      },
      channel_members() {
        return this.channel.members;
      }
    },
    methods: {
      onModalBackgroundClick() {
        setTimeout(() => {
          this.$parent.$el
            .querySelector(".modal-background")
            .addEventListener("click", this.close_popup);
        }, 100);
      },
      loadCkEditor() {
        this.ckeditorLoaded = true;
      },
      update_ticket(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
      },

      async update_ticket_name(name) {
        this.update_ticket({ name: name });
        this.edit_ticket_name = -1;
      },

      update_members() {
        this.viewingMembers = this.channel_members
          ? Object.values(this.channel_members.members)
          : [];
      },
      chatStreamMessage(e) {
        if (e.channel_id === "ticket-" + this.ticket.uid) {
          this.commentsUnread = e.unread_count;
        }
      },
      remove_overlay() {
        this.has_overlay = false;
      },
      add_presence_members() {
        this.channel.members.each(member => {
          this.presence_members.push(member);
        });
      },
      async get_details(loading = false) {
        this.is_loading = loading;

        let res = await ticketService
          .get({
            id: this.ticket_id,
            query: "tags=true"
          })
          .catch(err => {
            console.log(err);
          });

        this.ticket = res;
        this.$store.commit(
          "tickets/SET_CURRENT_OPEN_TICKET_ID",
          this.ticket.uid
        );

        this.is_loading = false;
        if (this.ticket)
          this.$store.dispatch("tickets/add_ticket_details", {
            ticket: this.ticket,
            router: this.$router
          });
      },

      close_popup() {
        if (this.$route.name == "ticket-details") {
          this.$router.go(-1);
        } else if (
          (this.$route.name == "schedules" ||
            this.$route.name == "schedule-details") &&
          !this.detailed_ticket.schedule
        ) {
          this.$toast.show(
            "Please add schedule",
            "",
            this.$store.state.izitoast_options.appWarning
          );
        } else {
          this.$emit("close");
        }
      }
    }
  };
</script>

<style lang="scss">
  .new-ticket-details {
    min-width: 102.4rem;
    max-width: 102.4rem;
    overflow: hidden;
    &__title {
      padding: 2rem;
    }
    &__content {
      &--title {
        color: $ticket-heading;
      }
    }
  }
  .br-1 {
    border-right: 1px solid rgba(23, 30, 72, 0.1);
  }
  .v--modal-block-scroll {
    height: 100vh;
  }
  .modal-content {
    &::-webkit-scrollbar {
      width: 0.6rem !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem !important;
      background: rgba(0, 0, 0, 0.2) !important;
    }
  }
  .ticket-details {
    font-size: initial;
    min-width: 120rem;
    max-width: 120rem;
    min-height: 50rem;
    overflow: hidden;
    @include for-size("tablet") {
      min-width: 50%;
      min-height: 100%;
      overflow: scroll;
    }
    &__card {
      &__breadcrumb {
        font-family: Barlow-Medium !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        color: $dark-grey;
        display: flex;
        justify-content: space-between;
        padding: 3rem 3rem 5rem;
        &__left {
          display: flex;
          align-items: center;
        }
        &__right {
          display: flex;
          align-items: center;
        }
      }
      &--top-section {
        margin: 0 6rem;
      }
      &--body {
        min-height: 50rem;
        max-height: max-content;
        @include for-size("tablet") {
          min-height: 100rem;
        }
      }
    }
    &-button {
      border-radius: 6px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(23, 30, 72, 0.1);
      display: flex;
      align-items: center;
      padding: 8px;
      color: $primary-text;
      cursor: pointer;
    }
  }
  .mt-50 {
    margin-top: 5rem;
  }
  .ticket-tabs {
    display: flex;
    border-bottom: 1px solid rgba(23, 30, 72, 0.1);
    padding: 4rem 5rem 0 5rem;
    overflow: auto;
    &-item {
      padding: 1rem 1.6rem;
      display: flex;
      align-items: center;
      border-bottom: 4px solid $white;
      opacity: 0.4;
      &.is-active-tab {
        opacity: 1;
        border-bottom: 4px solid $primary;
      }
    }
  }
  .follow-btn {
    border: 1px solid transparent;
  }

  .mobile-ticket-details {
    padding-top: 5px;
    background: #fff;
    height: calc(var(--mobilevh, 1vh) * 100);
    width: 100vw;
    position: absolute;
    bottom: 0;
    display: flex;
    top: 0;
    left: 0;
    flex-flow: column;
    &--header {
      padding: 0 1rem;
      margin-top: 3px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &--main {
      border-top: 1px solid #eee;
      margin-top: 15px;
      height: calc(100vh - 16.5rem);
    }
    &--tabs {
      position: fixed;
      bottom: 0;
      background: #ffffff;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      border-top: 1px solid #ccc;
      box-shadow: -3px -3px 10px 2px #ddd;
      z-index: 40;
      .ticket-tabs-item {
        width: 100%;
        justify-content: center;
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.08);
        &.is-active-tab {
          border-top: 2px solid #2585d3;
          border-bottom: 0px solid transparent;
        }
      }
    }
  }

  .h-40 {
    height: 4rem;
  }
  .watchers-indicator {
    min-width: 20px;
    height: 20px;
    position: absolute;
    right: -6px;
    top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
  }
</style>

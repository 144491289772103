var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"custom-progress"},_vm._l((_vm.total),function(item,name){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: `<p class='is-capitalized'>${_vm.localization(
          `app-${name
            .toLowerCase()
            .split(' ')
            .join('-')}`,
          name
        )}</p><div style='width: 6px;height: 6px;background-color:#${
          _vm.colors[name]
        };display: inline-block;border-radius: 50%;margin-right: 5px;background-color:#${
          _vm.colors[name]
        }'></div><span style='color:#${_vm.colors[name]}'>${item.count}</span>`,
        html: true
      }),expression:"{\n        content: `<p class='is-capitalized'>${localization(\n          `app-${name\n            .toLowerCase()\n            .split(' ')\n            .join('-')}`,\n          name\n        )}</p><div style='width: 6px;height: 6px;background-color:#${\n          colors[name]\n        };display: inline-block;border-radius: 50%;margin-right: 5px;background-color:#${\n          colors[name]\n        }'></div><span style='color:#${colors[name]}'>${item.count}</span>`,\n        html: true\n      }"}],key:name,staticClass:"custom-progress-bar",class:name,style:({ width: item.percent + '%' }),on:{"click":function($event){return _vm.$emit('filter_by', name)}}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
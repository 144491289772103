var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => ((_vm.save_template_popup = false), (_vm.tags = null))),expression:"() => ((save_template_popup = false), (tags = null))"}]},[(_vm.selected.length || _vm.select_all_tickets)?_c('p',{staticClass:"bulk_action_bar"},[_c('span',[_c('status-dropdown',{attrs:{"hide_names":true},on:{"update-ticket-status":function($event){_vm.bulk_update_tickets({ status: parseInt($event.value) })}}})],1),_c('span',[_c('priority-dropdown',{on:{"change_ticket_priority":function($event){_vm.bulk_update_tickets({ priority: parseInt($event) })}}})],1),_c('span',[_c('duedate-dropdown',{attrs:{"bulk_selection":true,"icon":'due-date',"icon_class":'is-22x22 '},on:{"change-duedate":function($event){return _vm.bulk_update_tickets({ dueDate: $event })}}},[_vm._v(" >")])],1),_c('span',[_c('user-dropdown',{attrs:{"is_name_hidden":true,"pxsize":20,"icon":'assignee',"can_unassign":false},on:{"update-user":function($event){return _vm.bulk_update_tickets({ takenUp: $event })}}})],1),_c('span',[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.localization(
            'app-load-from-template',
            'Load from Template'
          )
        }),expression:"{\n          content: localization(\n            'app-load-from-template',\n            'Load from Template'\n          )\n        }"}],staticClass:"is-18x18 is-pointer",attrs:{"name":'load-from-template',"file":'tickets-2'},nativeOn:{"click":function($event){_vm.save_template_popup = !_vm.save_template_popup}}})],1),_c('span',{staticClass:"is-flex align-center"},[_c('type-tag',{attrs:{"display_on_hover":false,"complete":tag => {
            _vm.bulk_update_tickets({ tags: { add: [tag] } });
          }},scopedSlots:_vm._u([{key:"toggle-dropdown",fn:function(){return [_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.localization('app-add-tags', 'Add tags'),
              html: false
            }),expression:"{\n              content: localization('app-add-tags', 'Add tags'),\n              html: false\n            }"}],staticClass:"is-16x16 is-pointer",attrs:{"name":'tags',"file":'tickets-2'}})]},proxy:true}],null,false,1045638209)})],1),_c('span',{on:{"click":function($event){return _vm.bulk_update_tickets({ watch: true })}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.localization('app-follow-tickets', 'Follow tickets'),
          html: false
        }),expression:"{\n          content: localization('app-follow-tickets', 'Follow tickets'),\n          html: false\n        }"}],staticClass:"is-16x16 is-pointer",attrs:{"name":'watch',"file":'tickets-2'}})],1),_c('span',{on:{"click":_vm.add_users}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-add-users', 'Add users') }),expression:"{ content: localization('app-add-users', 'Add users') }"}],staticClass:"is-18x18 is-pointer",attrs:{"name":'users',"file":'tickets-2'}})],1),_c('span',{on:{"click":_vm.add_teams}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.localization('app-add-teams', 'Add teams') }),expression:"{ content: localization('app-add-teams', 'Add teams') }"}],staticClass:"is-18x18 is-pointer",attrs:{"name":'teams',"file":'tickets-2'}})],1),_c('span',{on:{"click":function($event){return _vm.bulk_update_tickets({ bookmark: true })}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.localization('app-add-bookmark', 'Add bookmark'),
          html: false
        }),expression:"{\n          content: localization('app-add-bookmark', 'Add bookmark'),\n          html: false\n        }"}],staticClass:"is-16x16 is-pointer",attrs:{"name":'bookmark'}})],1),_c('span',{on:{"click":_vm.toggle_multiple_archive}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.localization('app-archive-selected', 'Archive Selected'),
          html: false
        }),expression:"{\n          content: localization('app-archive-selected', 'Archive Selected'),\n          html: false\n        }"}],staticClass:"is-20x20 is-pointer",attrs:{"name":'archive'}})],1),_c('span',{class:{ 'is-disabled': _vm.select_all_tickets },on:{"click":function($event){_vm.show_multi_delete = true}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.localization('app-delete-selected', 'Delete selected'),
          html: false
        }),expression:"{\n          content: localization('app-delete-selected', 'Delete selected'),\n          html: false\n        }"}],staticClass:"is-20x20 is-pointer",attrs:{"name":'delete-ticket'}})],1)]):_vm._e(),_c('div',{staticClass:"is-absolute has-background-white"},[(_vm.save_template_popup)?_c('save-template',{attrs:{"type":'load',"tickets":_vm.selected},on:{"close-load-template":function($event){_vm.save_template_popup = false}}}):_vm._e()],1),(_vm.show_multi_delete)?_c('prompt-modal',{attrs:{"name":'delete-multiple',"heading":'Delete',"iconPath":require('@/assets/icons/prompt/delete.svg'),"footer":true,"cancelString":'Cancel',"confirmString":'Delete'},on:{"cancel":function($event){_vm.show_multi_delete = false},"confirm":_vm.delete_tickets,"closed":function($event){_vm.show_multi_delete = false}}},[_c('div',{staticClass:"prompt-modal__content__text",attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v(" "+_vm._s(_vm.localization( "app-text-delete-multple-ticket-confirmation", "Are you sure you want to delete multiple tickets?" ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.localization( "app-this-action-cannot-be-undone", "This action can not be undone" ))+" ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="columns is-regular-14-00-17 is-marginless bb-1 sh-list-header">
    <div class="column is-4">
      {{ localization("app-containers", "Containers") }}
    </div>
    <!-- <div class="column">{{ localization("app-owner", "Owner") }}</div> -->
    <div class="column" v-if="$route.name == 'container-list'">
      {{ localization("app-asset", "Asset") }}
    </div>
    <div class="column">
      {{ localization("app-applications", "Applications") }}
    </div>
    <div class="column">{{ localization("app-tags", "Tags") }}</div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .bb-1 {
    border-bottom: 1px solid #edeef2;
  }
</style>

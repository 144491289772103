var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.id)?_c('modal',{attrs:{"name":`transfer-owner${_vm.id}`,"transition":"nice-modal-fade","classes":"demo-modal-class","min-width":50,"min-height":100,"adaptive":true,"scrollable":true,"reset":true,"width":"40%","height":"auto"},on:{"closed":function($event){return _vm.$emit('closed')}}},[_c('div',{staticClass:"transfer-modal"},[_c('div',{staticClass:"transfer-modal__header"},[_c('h1',{staticClass:"transfer-modal__header__text"},[_vm._v(" "+_vm._s(_vm.localization("app-transfer-ownership", "Transfer ownership"))+" ")]),_c('i',{staticClass:"fa fa-times transfer-modal__header__close",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$modal.hide(`transfer-owner${_vm.id}`)}}})]),(!_vm.selected_user)?_c('div',{staticClass:"transfer-modal__content"},[_c('div',{staticClass:"transfer-modal__content--input"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_query),expression:"search_query"}],staticClass:"input custom-input",attrs:{"type":"text","placeholder":_vm.localization('app-search-user-name', 'Search User Name')},domProps:{"value":(_vm.search_query)},on:{"input":function($event){if($event.target.composing)return;_vm.search_query=$event.target.value}}}),_c('span',{staticClass:"icon is-left"},[_c('i',{staticClass:"fas fa-search has-text-dark has-opacity-5 is-size-4"})])])])]),(_vm.user_list)?_c('div',{staticClass:"transfer-modal__content__users-list has-slimscroll"},_vm._l((_vm.user_list),function(user){return _c('p',{key:user.uid,staticClass:"user is-flex is-vcenter is-pointer",class:{
            'is-highlighted': _vm.clicked_user && _vm.clicked_user.uid == user.uid
          },on:{"click":function($event){_vm.clicked_user = user}}},[_c('span',{staticClass:"mr-10"},[_c('user-avatar',{attrs:{"custom_class":'tiny-user-avatar',"user":user,"pxsize":24}})],1),_c('span',{directives:[{name:"username",rawName:"v-username",value:(user),expression:"user"}],staticClass:"is-medium-14-00-17"})])}),0):_c('div',[_c('skeleton-loading',[_c('row',{attrs:{"gutter":{ top: '10px' }}},[_c('column',{attrs:{"span":24,"gutter":10}},[_c('square-skeleton',{attrs:{"count":5,"boxProperties":{
                  top: '24px',
                  bottom: '24px',
                  width: '100%',
                  height: '35px'
                }}})],1)],1)],1)],1)]):_c('div',[_c('div',{staticClass:"transfer-modal__content is-on-top"},[_c('div',{staticClass:"transfer-modal__content--image"},[_c('sh-icon',{staticClass:"is-48x48",attrs:{"name":'transfer-fill'}})],1),_c('div',{staticClass:"transfer-modal__content--image sh-text-semibold-16 has-text-center"},[_c('info-block',{attrs:{"info":{
              name: _vm.name,
              image_class: 'image is-32x32',
              type: 'projectManagement'
            },"icon_size":30}})],1),_c('div',{staticClass:"transfer-modal__content__text sh-text-normal-14 has-text-grey"},[_vm._v(" Are you sure you want to change the owner to ")]),_c('div',{staticClass:"transfer-modal__content--image is-flex is-vcenter"},[_c('span',{staticClass:"mr-10"},[_c('user-avatar',{attrs:{"user":_vm.selected_user,"pxsize":32}})],1),_c('span',{directives:[{name:"username",rawName:"v-username",value:(_vm.selected_user),expression:"selected_user"}],staticClass:"is-medium-16-500-19"})]),_c('div',{staticClass:"transfer-modal__footer mt-20"},[_c('div',{staticClass:"prompt-modal__footer__button prompt-modal__footer__button__cancel",on:{"click":function($event){_vm.selected_user = null}}},[_vm._v(" "+_vm._s(_vm.localization("app-cancel", "Cancel"))+" ")]),_c('div',{staticClass:"prompt-modal__footer__button prompt-modal__footer__button__confirm",on:{"click":_vm.change_ownership}},[_vm._v(" Confirm ")])])])]),_c('div',{staticClass:"transfer-modal__footer"},[_c('div',{staticClass:"sh-button",class:{ 'is-disabled': _vm.selected_user },on:{"click":function($event){_vm.selected_user = _vm.clicked_user}}},[_vm._v(" "+_vm._s(_vm.localization("app-transfer-ownership", "Transfer ownership"))+" ")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_FORMS_API_HOST

const URL = 'sections'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }
  async update(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/`,
      baseURL: this.baseURL,
      method: 'patch',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
  async saveTemplate(req = {}) {
    return httpClient({
      url: `${this.url}/template/save/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
  async loadTemplate(req = {}) {
    return httpClient({
      url: `${this.url}/template/load/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
}

export const sections = new API()

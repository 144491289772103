import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_FORMS_API_HOST

const URL = 'submissions'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }

  async getAllSubmissions(req = {}) {
    return httpClient({
      url: `${this.url}/${req.query ? `?${req.query}` : ''}`,
      baseURL: this.baseURL,
      headers: req.headers
    })
  }

  async update(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/`,
      baseURL: this.baseURL,
      method: 'patch',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  async filter(req = {}) {
    return httpClient({
      url: `${this.url}/filter/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  async event(req = {}) {
    return httpClient({
      url: `${this.url}/event/`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  generate_submissions_csv(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/filter/csv`,
      baseURL: this.baseURL,
      method: 'post',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
}

export const submissions = new API()

<template>
  <div>
    <div class="is-flex is-vcenter has-space-between pb-20 px-20 pt-3 bb-1">
      <div class="is-semiBold-16-600-19 ">
        {{ localization("app-task-attributes", "Task Attributes") }}
      </div>
      <div class="is-pointer" @click="$emit('close')">
        <sh-icon :name="'ticket-detail-close'" class="is-12x12 ml-5" />
      </div>
    </div>
    <ul class="is-medium-14-500-17 pl-20">
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-assignee", "Assignee") }}
        </p>
        <p>
          <user-assignee-dropdown
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            :existing_user="ticket.takenUp"
            @update-user="update_ticket({ takenUp: $event })"
            :pxsize="24"
            :align_right="true"
            :width="17"
            class="is-medium-14-500-17"
          ></user-assignee-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-teams", "Teams") }}
        </p>
        <div class="is-flex is-vcenter" v-if="get_teams(ticket).length">
          <primary-tags
            :tags="get_teams(ticket)"
            @clicked_tag="ticket.writeAccess ? add_teams() : null"
            :toShowCount="1"
            :no_access="true"
            :type="'team'"
            :no_wrap="true"
            :truncate_length="6"
          />
          <sh-icon
            v-if="ticket.writeAccess"
            :name="'add-users-teams'"
            class="show-on-hover is-22x22 ml-5 is-pointer add-user-assignee"
            @click.native="add_teams"
          />
        </div>
        <div
          v-else
          class="is-flex is-vcenter is-pointer "
          @click="add_teams"
          :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
        >
          <sh-icon :name="'add-teams'" class="mr-7 is-24x24" />
          <span
            class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
            >{{ localization("app-add-teams", "Add Teams") }}</span
          >
        </div>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-defect-type", "Defect") }}
        </p>
        <p
          class="mr-10"
          v-tooltip="{
            content: defect_type.length >= 15 ? defect_type : null,
            html: false
          }"
        >
          {{ defect_type | truncate(15) }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter  bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-priority", "Priority") }}
        </p>
        <p>
          <priority-dropdown
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            class="is-medium-14-500-17"
            @change_ticket_priority="
              update_ticket({ priority: parseInt($event) })
            "
            :priority="ticket.priority"
          ></priority-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-status", "Status") }}
        </p>
        <p>
          <status-dropdown
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            style="left:1rem"
            :width="17"
            @close="$emit('close-popup')"
            :current_status="ticket.status"
            class="is-medium-14-500-17"
            :ticket="ticket"
            v-if="ticket"
            @update-ticket-status="
              update_ticket({ status: parseInt($event.value) })
            "
          ></status-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-ssset", "Asset") }}
        </p>
        <p class="is-medium-14-500-17 mr-10">
          {{ asset_name }}
        </p>
      </li>
      <!-- <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-location", "Location") }}
        </p>
        <div class="is-flex mr-10" v-if="nameArray && nameArray.length">
          <sh-icon
            v-if="
              elementData &&
                ['file', 'folder', 'vault'].includes(elementData.type)
            "
            :file="'vault_sprite'"
            :name="'add-folder'"
            class="image is-pointer mr-5 is-20x20"
          />
          <div class="is-flex is-flex-wrap">
            <div class="is-flex is-vcenter">
              <span
                class="is-medium-14-500-17 no-whitespace-wrap"
                :class="[
                  name == '*Not found'
                    ? 'has-text-light-grey'
                    : 'has-text-theme-black'
                ]"
                >{{ nameArray[nameArray.length - 1] | truncate(15) }}</span
              >
            </div>
          </div>
        </div>
      </li> -->
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-inverter", "Inverter") }}
        </p>
        <p class="mr-10">
          {{
            ticket.properties &&
            ticket.properties.projectUid &&
            projects[ticket.properties.projectUid] &&
            projects[ticket.properties.projectUid].name
              ? projects[ticket.properties.projectUid].name
              : "NA"
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-location", "Location") }}
        </p>
        <p class="mr-10">
          {{ (ticket.properties && ticket.properties.stringName) || "NA" }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-report-date", "Report Date") }}
        </p>
        <p class="mr-10">
          {{ get_report_date() }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          Δ {{ localization("app-temperature", "Temperature") }}
        </p>
        <p class="mr-10">
          {{
            ticket.properties && ticket.properties.temperatureDifference >= 0
              ? `${ticket.properties.temperatureDifference.toFixed(2)} °C`
              : "NA"
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-yield-loss-estimate", "Yield loss estimate") }}
        </p>
        <p class="mr-10">
          {{
            ticket.properties && ticket.properties.yield_loss_estimate
              ? `${ticket.properties.yield_loss_estimate} kWh`
              : "NA"
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-timestamp", "Timestamp") }}
        </p>
        <p class="mr-10">
          {{ (ticket.properties && ticket.properties.issueTimeStamp) || "NA" }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-start-date", "Start Date") }}
        </p>
        <p>
          <startdate-dropdown
            :is_therm="true"
            style="left:0"
            class="is-medium-14-500-17"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            :ticket="ticket"
            v-if="ticket"
            @change-startdate="
              update_ticket({
                startDate: $event ? new Date($event).toISOString() : null
              });
              track_start_date($event);
            "
          ></startdate-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter  bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-end-date", "End Date") }}
        </p>
        <p>
          <duedate-dropdown
            style="left:.5rem"
            :is_therm="true"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            class="is-medium-14-500-17"
            :ticket="ticket"
            v-if="ticket"
            :due="ticket.dueDate || null"
            :in_details="true"
            @change-duedate="
              update_ticket({
                dueDate: $event ? new Date($event).toISOString() : null
              })
            "
          ></duedate-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-tags", "Tags") }}
        </p>
        <p>
          <tags-dropdown
            :position="'left__-5'"
            :service="ticketService"
            :tags="ticket.tags"
            :resourceId="ticket.uid"
            :write_access="ticket.writeAccess"
            :complete="update_ticket"
            :number_of_tags_to_display="2"
            :truncate_length="4"
            :display_on_hover="ticket.tags.length"
          ></tags-dropdown>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
  import UserAssigneeDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";
  import PriorityDropdown from "@/app/old/desktop/tickets/shared/components/priority-dropdown";
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import StartdateDropdown from "@/app/old/desktop/tickets/shared/components/startdate-dropdown";
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-dropdown";
  import TagsDropdown from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";

  import { mapState } from "vuex";

  export default {
    props: ["ticket"],
    data() {
      return {
        nameArray: [],
        elementData: null,
        location: null,
        group_name: "",
        project_name: "",
        ticketService: ticketService
      };
    },
    components: {
      UserAssigneeDropdown,
      PriorityDropdown,
      StatusDropdown,
      StartdateDropdown,
      DuedateDropdown,
      TagsDropdown,
      PrimaryTags
    },
    computed: {
      ...mapState("thermv2", ["vector_types", "projects", "container"]),
      defect_type() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.classId
        ) {
          let vector = this.vector_types.find(
            v => v.class_id == this.ticket.properties.classId
          );
          if (vector && vector.description) {
            return vector.description;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      asset_name() {
        return this.ticket &&
          this.ticket.targetElement &&
          this.ticket.targetElement.asset
          ? _.find(
              this.$store.state.assets,
              a => a.uid == this.ticket.targetElement.asset
            ).name
          : null;
      }
    },
    methods: {
      update_ticket(value) {
        this.$store.dispatch("thermv2/update_ticket_value", {
          id: this.ticket.uid,
          value: value
        });
      },
      add_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [
              ...(this.ticket.readLabels || []).map(u => ({
                uid: u,
                access: "read"
              })),
              ...(this.ticket.writeLabels || []).map(u => ({
                uid: u,
                access: "write"
              }))
            ],
            asset_id:
              this.ticket && this.ticket.targetElement
                ? this.ticket.targetElement.asset
                : null,
            item_uid: this.ticket.uid,
            complete: async e => {
              let readLabels = e
                .filter(u => u.access === "read")
                .map(u => u.uid);
              let writeLabels = e
                .filter(u => u.access === "write")
                .map(u => u.uid);
              this.update_ticket({ readLabels, writeLabels });
            }
          },
          { height: "80%" }
        );
      },
      get_report_date() {
        if (this.ticket.properties.projectUid) {
          let project = this.container.find(
            p => p.projectUid == this.ticket.properties.projectUid
          );
          if (
            project &&
            project.groupProperties &&
            project.groupProperties.date
          ) {
            return moment(new Date(project.groupProperties.date)).format(
              "MMM DD, YYYY"
            );
          } else {
            return project.groupName;
          }
        } else {
          return "NA";
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tickets-cal {
    right: 15rem;
  }
</style>
<style lang="scss">
  .left__-5 {
    left: -5rem !important;
  }
</style>

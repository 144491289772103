<template>
  <div>
    <div class="columns">
      <transfer-owner
        ref="assetTransferOwner"
        :name="details.name"
        :service="assetService"
        :id="details.uid"
        v-if="details"
        @update_user="update_owner(details, $event)"
      ></transfer-owner>
      <div class="column is-3">
        <details-skeleton v-if="isloading"></details-skeleton>
        <details-sidecard v-else>
          <template slot="header">
            <main-details-media
              :truncate_value="25"
              :info="{
                name_class: 'is-semiBold-16-600-22',
                name: details.name,
                subtext:
                  details.asset_type &&
                  details.asset_type.charAt(0).toUpperCase() +
                    details.asset_type.slice(1),
                properties: details.properties,
                subtext_class:
                  'is-medium-14-500-17 has-text-theme-black has-opacity-7 is-capitalized'
              }"
            ></main-details-media>
            <div
              class="sh-dropdown is-pointer"
              v-click-outside="() => (dropdown ? (dropdown = !dropdown) : null)"
            >
              <div @click="dropdown = !dropdown">
                <sh-icon :name="'3-dots-menu'" class="is-20x20"></sh-icon>
              </div>
              <div
                class="sh-dropdown-content is-paddingless"
                v-if="dropdown == true"
              >
                <p
                  class="sh-dropdown-content__item is-medium-14-500-17"
                  @click="
                    open_form(
                      AssetFormComponent,
                      { header: 'Edit Asset for' },
                      details
                    )
                  "
                >
                  <span class="mr-10">
                    <sh-icon :name="'edit-small'" class="is-24x24 "></sh-icon>
                  </span>
                  <span class="no-whitespace-wrap"
                    >{{ localization("app-edit-asset", "Edit Asset") }}
                  </span>
                </p>
                <p
                  @click="$refs.assetTransferOwner.open_popup()"
                  class="sh-dropdown-content__item is-medium-14-500-17 is-flex is-vcenter "
                >
                  <span>
                    <sh-icon :name="'transfer'" class="is-18x18"></sh-icon>
                  </span>
                  <span class="no-whitespace-wrap ml-10">
                    {{
                      localization(
                        "app-transfer-ownership",
                        "Transfer ownership"
                      )
                    }}
                  </span>
                </p>
                <p @click="map_modal = true" class="sh-dropdown-content__item ">
                  <sh-icon :name="'location'" class="is-18x18"></sh-icon>
                  <span class="ml-10 is-medium-14-500-17">
                    {{ localization("app-add-location", "Add location") }}
                  </span>
                </p>

                <p
                  @click="showDeleteModal = true"
                  class="sh-dropdown-content__item "
                >
                  <span>
                    <sh-icon :name="'delete-small'" class="is-22x22"></sh-icon>
                  </span>
                  <span class="no-whitespace-wrap is-medium-14-500-17 ml-10">{{
                    localization("app-delete-asset", "Delete Asset")
                  }}</span>
                </p>
              </div>
            </div>
          </template>

          <template slot="main">
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-description", "Description") }}
              </div>
              <div class="is-flex">
                <p
                  class="details-card__content-description cursor-is-text"
                  v-if="update == false"
                  @click="update = true"
                >
                  {{
                    details.description
                      ? details.description
                      : localization(
                          "app-no-description-found",
                          "*No description found"
                        )
                  }}
                </p>
                <input
                  type="text"
                  class="details-card__content-description bpm-less"
                  style="12px"
                  :placeholder="
                    localization('app-add-description', 'Add description')
                  "
                  v-else
                  v-focus
                  @keypress.enter="update_description"
                  v-model="updated_description"
                  v-click-outside="() => (update = false)"
                />
                <p class="details-card__content-description--icon">
                  <sh-icon
                    class="is-16x16"
                    :name="'edit'"
                    :file="'tickets_sprite'"
                    v-if="update == false"
                    @click.native="update = true"
                  >
                  </sh-icon>
                </p>
                <sh-icon
                  class="is-16x16 is-pointer"
                  :name="'tick-set-complete'"
                  :file="'tickets_sprite'"
                  v-if="update == true"
                  @click.native="update_description"
                >
                </sh-icon>
              </div>
            </div>

            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-created-on", "Created on") }}
              </div>
              <p class="details-card__content-description is-uppercase">
                {{ details.created_at | dateFormat }}
              </p>
            </div>
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-entity", "Entity") }}
              </div>
              <p class="details-card__content-descriptio is-semiBold-16-600-19">
                {{ details.entity && details.entity.name }}
              </p>
            </div>
            <div class="details-card__content">
              <div class="details-card__content-heading mb-5">
                {{ localization("app-teams", "Teams") }}
                <div
                  v-if="hasEditAccess"
                  @click="
                    add_teams({
                      teams: details.labels,
                      service: assetService,
                      item_uid: details.uid,
                      asset_id: details.uid
                    })
                  "
                  class="details-card__content__plus is-pointer"
                >
                  +
                </div>
              </div>
              <p
                class="details-card__content-description"
                style="flex-wrap:wrap"
              >
                <primary-tag
                  :tags="details.labels"
                  :toShowCount="2"
                  type="team"
                  :no_wrap="false"
                  :noTopMargin="true"
                ></primary-tag>
              </p>
            </div>
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-users", "Users") }}
                <div
                  v-if="hasEditAccess"
                  @click="
                    add_users({
                      users: details.users,
                      service: assetService,
                      item_uid: details.uid
                    })
                  "
                  class="details-card__content__plus is-pointer"
                >
                  +
                </div>
              </div>
              <p class="details-card__content-description">
                <user-tag
                  v-if="details.users.length"
                  :custom_size="36"
                  :users="details.users || []"
                  :tagsCountToShow="3"
                  :custom_class="'user-style is-medium-14-500-17'"
                ></user-tag>
              </p>
            </div>
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-tags", "Tags") }}
              </div>
              <p
                class="details-card__content-description"
                v-if="details.tags.length"
              >
                <type-tag
                  :tags="details.tags"
                  :write_access="hasEditAccess"
                  :service="assetService"
                  :complete="
                    () => {
                      $emit('update');
                    }
                  "
                ></type-tag>
              </p>
              <p
                class="details-card__content-description is-flex is-vcenter"
                v-else
              >
                <sh-icon
                  :name="'tags'"
                  class="is-20x20 mr-5 has-opacity-6"
                ></sh-icon>
                <span
                  class="is-regular-14-00-17 has-opacity-7 has-text-light-grey"
                  >{{
                    localization("app-no-tags-assigned", "*No tags assigned")
                  }}</span
                >
              </p>
            </div>
          </template>
          <template slot="footer">
            <div class="is-flex is-vcenter ">
              <user-avatar
                :user="
                  details.owner &&
                    details.owner.uid &&
                    $store.getters.state_users[details.owner.uid]
                "
                class="mr-5"
              ></user-avatar>
              <router-link
                v-if="details.owner && details.owner.uid"
                class="has-text-dark"
                :to="{
                  name: 'user-details',
                  params: { id: details.owner.uid }
                }"
              >
                <div
                  class="is-medium-14-500-17 has-text-theme-black has-opacity-7"
                >
                  {{ localization("app-owner", "Owner") }}
                </div>
                <div
                  class="is-semiBold-16-600-19 name-highlights-on-hover"
                  v-username="
                    details.owner &&
                      details.owner.uid &&
                      $store.getters.state_users[details.owner.uid]
                  "
                ></div>
              </router-link>
              <div
                v-else
                class="is-regular-14-00-17 has-opacity-6 has-text-new-grey"
              >
                *Not found
              </div>
            </div>
          </template>
        </details-sidecard>
      </div>
      <div class="column is-9">
        <div v-if="details" class="sh-tabs">
          <ul>
            <li>
              <router-link
                class="is-medium-18-500-22 has-text-theme-black"
                active-class="is-active"
                :to="{ name: 'asset-details-containers' }"
              >
                {{ localization("app-containers", "Containers") }}
                <span>{{
                  (details.containers && details.containers.length) || 0
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                class="is-medium-18-500-22 has-text-theme-black"
                active-class="is-active"
                :to="{ name: 'asset-details-groups' }"
              >
                {{ localization("app-groups", "Groups") }}
                <span>{{
                  (details.groups && details.groups.length) || 0
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                class="is-medium-18-500-22 has-text-theme-black"
                active-class="is-active"
                :to="{ name: 'asset-details-reports' }"
              >
                {{ localization("app-reports", "Reports") }}
                <span>{{
                  (resource_metrics && resource_metrics.no_of_reports) || 0
                }}</span>
              </router-link>
            </li>
            <li class="ml-auto" v-if="$store.getters.asset_configuration">
              <router-link
                class="is-medium-18-500-22 has-text-theme-black"
                active-class="is-active"
                :to="{ name: 'asset-details-configuration' }"
              >
                {{ localization("app-configuration", "Configuration") }}
              </router-link>
            </li>
          </ul>
        </div>
        <keep-alive>
          <router-view :details="details" class="router-asset-details" />
        </keep-alive>

        <div
          class="sh-tabs mt-30"
          v-if="$route.name !== 'asset-details-configuration'"
        >
          <ul>
            <li>
              <a
                :class="{ 'is-active': active_tab == 'terra' }"
                @click="active_tab = 'terra'"
              >
                {{ localization("app-terra", "Terra") }}
                <span>{{ terra_count }}</span>
              </a>
            </li>
            <li>
              <a
                :class="{ 'is-active': active_tab == 'therm' }"
                @click="active_tab = 'therm'"
              >
                {{ localization("app-therm", "Therm") }}
                <span>{{ therm_count }}</span>
              </a>
            </li>
          </ul>
          <div class="py-20" v-if="details">
            <keep-alive>
              <therm-list
                @count="therm_count = $event"
                v-if="active_tab == 'therm'"
                ref="therm_tab"
                :asset_id="details.uid"
              ></therm-list>
              <terra-list
                @count="terra_count = $event"
                v-if="active_tab == 'terra'"
                ref="terra_tab"
                :asset_id="details.uid"
              ></terra-list>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>

    <delete-modal
      v-if="showDeleteModal"
      :resourceId="details.uid"
      :resourceName="details ? details.name : ''"
      :resourceType="'asset'"
      :resourceService="assetService"
      @closed="showDeleteModal = false"
      @deleted="
        show_success('Resource successfully deleted');
        $router.push({ name: 'asset-list' });
        getData();
      "
    />
    <pin-modal
      v-if="showPinModal"
      :resourceName="details ? details.name : ''"
      :resourceType="'asset'"
      @closed="showPinModal = false"
    />
    <b-modal class="location-modal" :active.sync="map_modal">
      <div class="card">
        <div class="card-content">
          <location-picker
            class="location-picker-map"
            v-model="location"
            :options="options"
          ></location-picker>
          <div
            @click="update_location"
            class="is-pulled-right button is-large is-info mt-10 mr-10"
          >
            {{ localization("app-save", "Save") }}
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import detailsSidecard from "@/app/old/desktop/core/shared/components/details-sidecard.vue";
  import { assetService } from "@/app/old/desktop/shared/services/";
  import MainDetailsMedia from "@/app/old/desktop/shared/components/main-details-media";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import PrimaryTag from "@/app/old/desktop/shared/components/tags/primary-tag";
  import TypeTag from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";

  import ThermList from "@/app/old/desktop/therm/components/view-list/view-list";
  import TerraList from "@/app/old/desktop/terra/components/terra-list/terra-list";
  import PinModal from "@/app/old/desktop/shared/components/pin-modal";
  import ResourceEbs from "@/app/old/desktop/core/shared/components/resource-ebs";
  import AssetFormComponent from "@/app/old/desktop/core/components/asset/asset-form.component.vue";
  import { LocationPicker } from "vue2-location-picker";
  import FormPopup from "@/app/old/desktop/shared/mixins/form-popup.mixin.vue";
  import UserTeamPopup from "@/app/old/desktop/shared/mixins/user-team-popup.mixin.vue";
  import DetailsSkeleton from "@/app/old/desktop/shared/components/skeleton/details-skeleton.vue";
  export default {
    props: ["details", "resource_metrics", "isloading"],
    mixins: [FormPopup, UserTeamPopup],
    data() {
      return {
        therm_count: 0,
        terra_count: 0,
        view_type: "resource",

        assetUsers: [],
        assetService: assetService,
        active_tab: "terra",
        showDeleteModal: false,
        showPinModal: false,
        hasEditAccess: false,
        update: false,
        updated_description:
          this.details &&
          this.details.description &&
          this.details.description.length
            ? this.details.description
            : null,
        location: {},
        options: {
          // is not required
          map: {
            /** other map options **/
          },
          marker: {
            /** marker options **/
          },
          autocomplete: {
            /** autocomplete options **/
          }
        },
        map_modal: false,
        AssetFormComponent: AssetFormComponent,
        dropdown: false
      };
    },
    components: {
      detailsSidecard,
      MainDetailsMedia,
      UserTag,
      TypeTag,
      ThermList,
      TerraList,
      PrimaryTag,
      PinModal,
      ResourceEbs,
      LocationPicker,
      DetailsSkeleton
    },
    created() {
      this.checkEditAccess();
      setTimeout(() => {
        if (
          this.details &&
          this.details.location &&
          this.details.location.coordinates
        ) {
          this.location["lat"] = this.details.location.coordinates[1];
          this.location["lng"] = this.details.location.coordinates[0];
        } else {
          this.location["lat"] = 0;
          this.location["lat"] = 0;
        }
      }, 2000);
    },

    methods: {
      checkEditAccess() {
        assetService
          .get({ id: this.$route.params.id, query: "edit=true" })
          .then(() => (this.hasEditAccess = true))
          .catch(err => this.handleErrors(err));
      },
      async update_description() {
        let res = await this.assetService.patch({
          body: {
            description: this.updated_description
          },
          id: this.details.uid
        });
        this.details.description = this.updated_description;
        this.update = false;
      },
      async update_location() {
        await this.assetService
          .patch({
            body: {
              location: {
                coordinates: [this.location.lng, this.location.lat],
                type: "Point"
              }
            },
            id: this.details.uid
          })
          .then(res => {
            this.$toast.show(
              "Location added successfully",
              "",
              this.$store.state.izitoast_options.appSuccess
            );
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .is-borderless {
    border: none !important;
  }

  .pinned-resource-buttons {
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    width: 212px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  }
  .location-modal {
    .card {
      padding-bottom: 4rem;
    }
  }
  .location-picker-map {
    height: 650px;
    #map {
      height: 600px;
    }
  }
</style>

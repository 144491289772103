var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex has-direction-column is-vcenter pt-30 mb-100"},[(_vm.is_valid_email)?_c('div',[(
        _vm.search &&
          (_vm.access('core', 'invite_users') ||
            _vm.access('core', 'add_guest_users'))
      )?_c('div',{staticClass:"has-text-centered is-medium-16-500-22 has-primary-text user-list-info-text truncate-text"},[_vm._v(" “"+_vm._s(_vm.search)+"” ")]):_vm._e(),(_vm.invite_user_org)?_c('div',[(_vm.access('core', 'add_guest_users'))?[_c('div',{staticClass:"info-text-invite has-text-centered"},[_vm._v(" is available from different organization ")]),_c('div',{staticClass:"is-flex is-center mt-25"},[_c('div',{staticClass:"sh-form__content__organization__icon is-24x24",staticStyle:{"width":"24px","height":"24px"},style:(`background-color: ${_vm.hashed_color(
                _vm.invite_user_org.organization.name,
                'projectManagement'
              )}`)},[_vm._v(" "+_vm._s(_vm.invite_user_org.organization.name.charAt(0))+" ")]),_c('span',{staticClass:"is-medium-16-500-19",staticStyle:{"color":"#171e48"}},[_vm._v(" "+_vm._s(_vm.invite_user_org.organization.name)+" ")])])]:_c('no-results',{attrs:{"search":_vm.search}})],2):[(_vm.access('core', 'invite_users'))?_c('div',{staticClass:"info-text-invite has-text-centered"},[_vm._v(" is not available ")]):_c('no-results',{attrs:{"search":_vm.search}})],(
        _vm.access('core', 'invite_users') && _vm.access('core', 'add_guest_users')
      )?_c('div',{staticClass:"has-text-centered mt-20"},[_c('a',{staticClass:"button is-large is-info",class:{ 'is-loading': _vm.isLoading },on:{"click":function($event){return _vm.invite_user()}}},[_vm._v("Invite Now")])]):_vm._e()],2):_c('no-results',{attrs:{"search":_vm.search}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sh-dropdown"},[_c('div',{on:{"mousedown":_vm.changeLayout}},[_vm._t("default")],2),(_vm.active)?_c('div',{directives:[{name:"touch",rawName:"v-touch:start.stop",arg:"start",modifiers:{"stop":true}}],staticClass:"backdrop",on:{"click":function($event){return _vm.$emit('clicked-outside')}}}):_vm._e(),(_vm.active)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.$emit('clicked-outside')),expression:"() => $emit('clicked-outside')"}],staticClass:"sh-dropdown-content",style:(`width:${_vm.width};${_vm.offsetY};${_vm.offsetX};   z-index: 1000`)},[_vm._t("prepend"),_c('ul',{staticClass:"sh-dropdown-content__Box has-slimscroll-xs"},[_c('li',{staticClass:"is-flex is-between sh-dropdown-content__list"},_vm._l((_vm.icons),function(icon){return _c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.localization(
                `app-${icon.tooltip
                  .split(' ')
                  .join('-')
                  .toLowerCase()}`,
                icon.tooltip
              ),
              html: false
            }),expression:"{\n              content: localization(\n                `app-${icon.tooltip\n                  .split(' ')\n                  .join('-')\n                  .toLowerCase()}`,\n                icon.tooltip\n              ),\n              html: false\n            }"}],key:icon,staticClass:"actions-dropdown-content__list--item",class:{ 'is-disabled': icon.is_disabled },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('icon-clicked', icon.action)}}},[_vm._t("image-heading",function(){return [_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":icon.icon}})]},{"icons":icon})],2)}),0),_vm._l((_vm.items),function(item){return _c('li',{key:item,staticClass:"sh-dropdown-content__item",class:{ 'is-disabled': item.is_disabled, 'is-hidden': item.hide },attrs:{"item":item},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('item-clicked', item.action)}}},[_vm._t("item",function(){return [_c('sh-icon',{staticClass:"is-22x22 sh-dropdown-content__item--icon",attrs:{"name":item.icon}}),_c('span',{staticClass:"sh-dropdown-content__item--text is-medium-16-500-19 has-text-theme-black"},[_vm._v(_vm._s(!item.text ? undefined : typeof item.text === "function" ? _vm.localization( `app-${item .text(_vm.data) .split(" ") .join("-") .toLowerCase()}`, item.text(_vm.data) ) : _vm.localization( `app-${item.text .split(" ") .join("-") .toLowerCase()}`, item.text )))])]},{"item":item})],2)})],2)],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
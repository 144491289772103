<template>
  <div
    v-click-outside="() => close()"
    class="is-relative dropdown is-right vault-menu-dropdown"
    :class="{ 'is-active': is_dropdown }"
  >
    <div class="dropdown-trigger" @click="is_dropdown = !is_dropdown">
      <a aria-haspopup="true" aria-controls="dropdown-menu">
        <span>
          <sh-icon :name="'3-dots-menu'" class="is-20x20" />
        </span>
      </a>
    </div>
    <div v-if="is_dropdown" class="dropdown-menu" role="menu">
      <!-- :style="{top : position.top, left: position.left}" -->
      <div class="dropdown-content">
        <div class="vault-menu-dropdown--top">
          <sh-icon
            :class="{
              'is-disabled': !(file.writeAccess || file.ancestorWriteAccess)
            }"
            v-tooltip="{
              content: localization('app-rename', 'Rename'),
              html: false
            }"
            @click.native="
              $emit('rename');
              is_dropdown = false;
            "
            :file="'vault_sprite'"
            :name="'rename'"
            class="image is-pointer is-24x24"
          />
          <sh-icon
            :class="{
              'is-disabled': !(file.writeAccess || file.ancestorWriteAccess)
            }"
            v-tooltip="{
              content: localization('app-share', 'Share'),
              html: false
            }"
            @click.native="
              $emit('share');
              is_dropdown = false;
            "
            :file="'vault_sprite'"
            :name="'share-grey'"
            class="image is-pointer is-24x24"
          />
          <sh-icon
            v-tooltip="{
              content: !file.archive
                ? localization('app-archive', 'Archive')
                : localization('app-unarchive', 'Unarchive'),
              html: false
            }"
            @click.native="
              $emit('archive');
              is_dropdown = false;
            "
            :file="'vault_sprite'"
            :name="'archive'"
            class="image is-pointer is-24x24"
          />
          <p>
            <sh-icon
              :class="{ 'is-disabled': !file.ownerAccess }"
              v-tooltip="{
                content: localization('app-delete', 'Delete'),
                html: false
              }"
              @click.native="
                $emit('delete');
                is_dropdown = false;
              "
              :file="'vault_sprite'"
              :name="'delete'"
              class="image is-pointer is-24x24"
            />
          </p>
        </div>
        <div class="vault-menu-dropdown--bottom">
          <a
            class="dropdown-item"
            @click="
              SET_INFO_TAB({
                file: file,
                type: 'file',
                active: true,
                tab: 'info'
              });
              is_dropdown = false;
            "
          >
            <img class="mr-10" src="@/assets/icons/info.svg" alt />
            <span class="is-medium-16-500-50">{{
              localization("app-info", "Info")
            }}</span>
          </a>
          <a
            class="dropdown-item"
            @click="
              SET_INFO_TAB({
                file: file,
                type: 'file',
                active: true,
                tab: 'activity'
              });
              is_dropdown = false;
            "
          >
            <sh-icon
              :file="'vault_sprite'"
              :name="'view-activity'"
              class="image is-pointer is-24x24"
            />
            <span class="is-medium-16-500-50 ml-10">{{
              localization("app-view-activity", "View Activity")
            }}</span>
          </a>
          <a
            class="dropdown-item is-relative version-name"
            @click="version_input = true"
            :class="{ 'is-disabled': !file.ownerAccess }"
          >
            <sh-icon
              :file="'helper_sprite'"
              :name="'upload-grey'"
              class="image is-pointer is-24x24"
            />
            <span class="is-medium-16-500-50 ml-10">{{
              localization("app-upload-new-version", "Upload new Version")
            }}</span>
            <div
              :class="[is_viewer_small ? 'viewer' : 'regular']"
              class="is-absolute "
              v-if="version_input"
              v-click-outside="() => (version_input = false)"
            >
              <div class=" create-new-folder  is-flex align-center">
                <input
                  v-focus
                  @keyup.enter="
                    form.name
                      ? $emit('open_version_file_upload', form.name)
                      : ''
                  "
                  class="create-new-folder-input is-medium-14-500-17"
                  :placeholder="
                    localization('app-version-name', 'Version Name')
                  "
                  type="text"
                  v-model="form.name"
                />
                <p
                  @click="
                    form.name
                      ? $emit('open_version_file_upload', form.name)
                      : ''
                  "
                  :class="{ 'is-disabled': !form.name }"
                >
                  <sh-icon
                    :name="'ticket-show-tick'"
                    class="is-16x16 is-pointer"
                  ></sh-icon>
                </p>
              </div>
            </div>
          </a>
          <a class="dropdown-item" @click="$emit('show_file_versions')">
            <sh-icon
              :file="'vault_sprite'"
              :name="'upload-new-versions'"
              class="image is-pointer is-24x24"
            />
            <span class="is-medium-16-500-50 ml-10">{{
              localization("app-show-versions", "Show Versions")
            }}</span>
          </a>
          <a
            v-if="$store.getters.download_files_zip"
            class="dropdown-item"
            @click="$emit('download')"
          >
            <img class="mr-15" src="@/assets/icons/vault/download.svg" alt />
            <span class="is-medium-16-500-50">{{
              localization("app-download", "Download")
            }}</span>
          </a>
          <a
            class="dropdown-item is-relative move-to"
            @click="is_active = !is_active"
          >
            <img
              class="mr-15"
              src="@/assets/icons/vault/vault-move-to.svg"
              alt
            />
            <span class="is-medium-16-500-50">{{
              localization("app-move-to", "Move To")
            }}</span>
            <folder-move-dropdown
              class="is-absolute"
              :class="[is_viewer_small ? 'viewer' : 'regular']"
              @close="is_active = false"
              :hide_icon="true"
              v-if="is_active"
              :show_dropdown="is_active ? true : false"
              @move_folder="
                $emit('move', $event);
                close();
              "
            />
          </a>
          <a
            class="dropdown-item is-relative create-vault-ticket"
            @click="open_create_ticket = !open_create_ticket"
          >
            <sh-icon :name="'sidebar-tickets'" class="is-24x24 mr-10" />
            <span class="is-medium-16-500-50">{{
              localization("app-create-ticket", "Create Ticket")
            }}</span>
            <create-new-ticket
              class="is-absolute "
              :class="[is_viewer_small ? 'viewer' : 'regular']"
              v-if="open_create_ticket"
              :open_on_create="true"
              :element="file.element"
              :vault="true"
              @close="close()"
            />
          </a>
          <!-- @click="$eventHub.$emit('show_folder_info',node)" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { fileService } from "@/app/old/desktop/shared/services/";
  import { mapMutations } from "vuex";
  import FolderMoveDropdown from "@/app/old/desktop/vault/components/vault-navbar/components/folder-move-dropdown/folder-move-dropdown";
  import CreateNewTicket from "@/app/old/desktop/tickets/components/tickets-list/components/create-new-ticket";

  export default {
    props: ["file", "is_viewer_small"],
    components: {
      FolderMoveDropdown,
      CreateNewTicket
    },
    data() {
      return {
        is_active: false,
        open_create_ticket: false,
        apiService: fileService,
        is_dropdown: false,
        position: {},
        version_input: false,
        form: { name: "" }
      };
    },
    created() {
      this.$parent.$on("contextmenu", (file, $event) => {
        if (this.file.uid === file.uid) {
          this.is_dropdown = true;
        } else {
          this.is_dropdown = false;
        }
        // this.position.right = $event.pageX + "px";
        // this.position.top = $event.pageY + "px";
      });
    },
    beforeDestroy() {
      this.close();
    },
    methods: {
      ...mapMutations("vault", ["SET_INFO_TAB"]),
      close() {
        this.is_dropdown = false;
        this.is_active = false;
        this.open_create_ticket = false;
        this.version_input = false;
      }
    }
  };
</script>

<style lang="scss">
  .file-actions .dropdown-trigger {
    visibility: hidden;
  }
  .vault__list-item,
  .vault__grid-item {
    &:hover .file-actions .dropdown-trigger {
      visibility: visible;
    }
  }
  .create-vault-ticket {
    .regular {
      top: -4rem;
      left: 0rem;
    }
    .viewer {
      position: absolute;
      top: 1rem;
      left: 5rem;
    }
  }
  .version-name {
    .regular {
      top: -4rem;
      left: 0rem;
    }
    .viewer {
      position: absolute;
      top: 1rem;
      left: 5rem;
    }
  }
  .move-to {
    .regular {
      // top: -4rem;
      // left: 0rem;
    }
    .viewer {
      position: absolute;
      top: 3rem;
      left: 20rem;
    }
  }
  .nonvisible {
    opacity: 0;
  }
</style>

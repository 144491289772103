var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pin-list__item is-flex align-center",on:{"click":_vm.navigate_to_path,"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{staticClass:"pin-list__item--text columns align-center is-vcenter"},[_c('sh-icon',{staticClass:"image column is-3 is-36x36",staticStyle:{"border-radius":"50%"},style:(`background-color:${
          _vm.hover || _vm.$route.path == _vm.pin.path ? '#2685d3' : '#616468'
        }`),attrs:{"name":_vm.pin.type == 'therm'
          ? _vm.localization('app-thermal', 'thermal')
          : _vm.pin.type}}),(_vm.edit_pin == _vm.pin.path)?_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.pin.name),expression:"pin.name"}],staticClass:"input column is-10 ml-10 is-inline-input is-medium-14-500-20",attrs:{"type":"text","name":"name","id":"pin","placeholder":_vm.pin.name},domProps:{"value":(_vm.pin.name)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.edit_pin = -1},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.update_pin(_vm.pin);
        _vm.edit_pin = -1;}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pin, "name", $event.target.value)}}}):_c('p',{staticClass:"ml-10 column no-padding-left is-10 is-medium-14-500-20 has-opacity-7"},[_vm._v(" "+_vm._s(_vm.pin.name)+" ")])],1),_c('div',{staticClass:"no-whitespace-wrap pin-list__item--actions"},[_c('sh-icon',{staticClass:"mr-10 is-16x16 is-inline is-pointer",class:{ 'is-vhidden': !_vm.hover || _vm.edit_pin == _vm.pin.path },attrs:{"name":'pen'},nativeOn:{"click":function($event){$event.stopPropagation();_vm.edit_pin = _vm.pin.path}}}),_c('sh-icon',{staticClass:"mr-10 is-16x16 is-inline is-pointer",class:{ 'is-hidden': !_vm.hover || _vm.edit_pin !== _vm.pin.path },attrs:{"name":'tick-set-complete'},nativeOn:{"click":function($event){$event.stopPropagation();_vm.update_pin(_vm.pin);
        _vm.edit_pin = -1;}}}),_c('sh-icon',{staticClass:"ml-10 is-16x16 is-inline is-pointer",class:{ 'is-vhidden': !_vm.hover },attrs:{"name":'unpinn'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.unpin(_vm.pin)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="sh-list-header  columns is-regular-14-00-17">
    <div class="column">{{ localization("app-roles", "Roles") }}</div>
    <div class="column">{{ localization("app-created-by", "Created by") }}</div>
    <div class="column">
      {{ localization("app-applications", "Applications") }}
    </div>
    <div class="column">{{ localization("app-users", "Users") }}</div>
    <div class="column has-text-centered">
      <div>{{ localization("app-status", "Status") }}</div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

import { httpClient } from "@/app/old/desktop/shared/services/";
import { BaseService } from "@/app/old/desktop/shared/services/";
const API_HOST = process.env.VUE_APP_FORMS_API_HOST;

const URL = "forms";

class API extends BaseService {
  constructor() {
    super(API_HOST, URL);
  }
  async getAll(req = {}) {
    return httpClient({
      url: `${this.url}/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      headers: req.headers
    });
  }
  async duplicate(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/duplicate/`,
      baseURL: this.baseURL,
      method: "post",
      data: req.body,
      header: req.headers
    }).then(res => res.data);
  }
  async update(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/`,
      baseURL: this.baseURL,
      method: "patch",
      data: req.body,
      header: req.headers
    }).then(res => res.data);
  }
  get_metrcis(req = {}) {
    return httpClient({
      url: `metrics/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      header: req.headers
    }).then(res => res.data);
  }
  get_form_metrics(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/metrics/${req.query ? `?${req.query}` : ""}`,
      baseURL: this.baseURL,
      header: req.headers
    }).then(res => res.data);
  }
  get_filterForms(req = {}) {
    return httpClient({
      url: `${this.url}/filter/`,
      baseURL: this.baseURL,
      method: "post",
      data: req.body,
      header: req.headers
    }).then(res => res.data);
  }
  bulk_template(req = {}) {
    return httpClient({
      url: `${this.url}/bulkCreate/`,
      baseURL: this.baseURL,
      method: "post",
      data: req.body,
      header: req.headers
    }).then(res => res.data);
  }
  bulk_update(req = {}) {
    return httpClient({
      url: `${this.url}/bulkUpdate/`,
      baseURL: this.baseURL,
      method: "post",
      data: req.body,
      header: req.headers
    }).then(res => res.data);
  }
  async bulk_delete(req = {}) {
    return httpClient({
      url: `${this.url}/bulkDelete`,
      method: "delete",
      data: req.body,
      baseURL: this.baseURL,
      headers: req.headers
    });
  }
}

export const forms = new API();

<template>
  <div class="is-flex is-center">
    <div class="has-text-centered">
      <svg
        style="width: 149.2px; height: 155.2px; margin-top: 2rem"
        viewBox="0 0 100 100"
      >
        <use
          xlink:href="@/assets/icons/vault_sprite.svg#empty-folder-illustration-vault"
        />
      </svg>
      <template
        v-if="
          !($route.query.shared || $route.query.recents || $route.query.archive)
        "
      >
        <div class="mt-20" v-if="!current_folder_uid">
          <a
            class="sh-button sh-button--create sh-button--small"
            @click="$eventHub.$emit('create_new_folder')"
            >{{ localization("app-create-new-folder", "Create New Folder") }}</a
          >
        </div>
        <p
          v-else
          class="has-text-centered pl-20 pr-20 has-text-grey is-medium-14-500-17 mt-10"
        >
          {{
            localization(
              `app-${text
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              text
            )
          }}
          <br />{{
            localization(
              `app-${subtext
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              subtext
            )
          }}
          <!-- <br>
            <a @click="$eventHub.$emit('open_file_upload',Math.random())">Browse</a> -->
        </p>
      </template>
      <template v-else>
        <p
          class="has-text-centered pl-20 pr-20 has-text-grey is-medium-14-500-17 mt-10"
        >
          {{
            localization(
              "app-no-file-folder-shared-with-you",
              "No files/folders shared with you!"
            )
          }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        text:
          "Drag and drop files here to upload. You can add users, teams, tags and multiple versions to a file.",
        subtext:
          "You can share a file with other members without adding them to the file."
      };
    },
    computed: {
      ...mapState("vault", ["current_folder_uid"])
    }
  };
</script>

<style></style>

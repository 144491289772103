var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.stop",value:(null),expression:"null",arg:"swipe",modifiers:{"stop":true}}],staticClass:"mobile-ticket-details is-paddingless bg-light"},[_c('div',{staticClass:"mobile-ticket-details--header bg-white"},[_c('div',{staticClass:"is-flex is-vcenter h-40 pl-5",staticStyle:{"width":"90%"}},[_c('sh-icon',{staticClass:"is-14x14 mr-10 is-pointer",attrs:{"name":'ticket-header-back'},nativeOn:{"click":function($event){_vm.activeTab == 1 ? _vm.$emit('exit') : (_vm.activeTab = 1)}}}),_c('p',{staticClass:"is-medium-16-500-19 flex-wrap is-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm.ticket_name)+" ")]),_c('sh-icon',{staticClass:"is-16x16 ml-10",attrs:{"name":_vm.ticket.bookmark ? 'bookmarked-highlight' : 'bookmark'},nativeOn:{"click":function($event){return _vm.update_ticket({ bookmark: !_vm.ticket.bookmark })}}})],1)],1),_c('div',{staticClass:"is-flex is-vcenter h-40"},[_c('actions-dropdown',{attrs:{"ticket":_vm.ticket,"is_list":true},on:{"open-description":function($event){_vm.open_description = true;
          _vm.activeTab = 6;
          _vm.track_description_views(_vm.ticket.description);},"close_details":function($event){return _vm.$emit('exit')}}})],1)]),_c('div',{staticClass:"pl-15 pb-10 bg-white"},[_c('tags-dropdown',{attrs:{"service":_vm.ticketService,"tags":_vm.ticket.tags,"resourceId":_vm.ticket.uid,"number_of_tags_to_display":2,"write_access":_vm.ticket && _vm.ticket.writeAccess,"display_on_hover":false,"complete":_vm.update_ticket}})],1),_c('div',{staticClass:"mobile-ticket-details--main is-marginless"},[(_vm.activeTab == 1)?_c('div',{staticClass:"mt-10"},[_c('ticket-details-overview',{attrs:{"ticket":_vm.ticket,"nameArray":_vm.nameArray,"elementData":_vm.elementData},on:{"active-section":function($event){_vm.activeTab = $event}}})],1):_vm._e(),(_vm.activeTab == 2)?_c('div',{staticClass:"bg-white"},[_c('task-list',{attrs:{"ticket":_vm.ticket,"channel":_vm.channel}})],1):_vm._e(),(_vm.activeTab == 3)?_c('div',{staticClass:"bg-white"},[(_vm.$store.getters.ticket_forms)?_c('forms-section',{attrs:{"ticket":_vm.ticket}}):_vm._e()],1):_vm._e(),(_vm.activeTab == 4)?_c('div',{staticClass:"bg-white"},[_c('attachments',{attrs:{"ticket":_vm.ticket,"service":_vm.ticketService},on:{"uploaded-files-details":attachments => {
            _vm.track_file_uploads(attachments, 'main_list', 'ticket');
          }}})],1):_vm._e(),(_vm.activeTab == 5)?_c('div',{staticClass:"bg-white"},[_c('comments',{attrs:{"commentsUnread":(_vm.commentsUnread = 0),"presence_members":_vm.viewingMembers,"ticket":_vm.ticket,"apiService":_vm.ticketService,"channelName":'ticket-' + _vm.ticket.uid}})],1):_vm._e(),(_vm.activeTab == 5)?_c('div',[_c('details-overview',{attrs:{"ticket":_vm.ticket},on:{"close":function($event){_vm.activeTab = 1}}})],1):_vm._e(),(_vm.activeTab == 6 && _vm.open_description)?_c('div',{class:{ 'mobile-desc': _vm.isMobile }},[(_vm.ticket)?_c('description-section',{attrs:{"is_loading":_vm.is_loading,"description":_vm.ticket.description,"ticket":_vm.ticket,"owner":_vm.ticket.owner}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"mobile-ticket-details--tabs"},_vm._l((_vm.tab_items),function(tab_item,index){return _c('div',{key:index,staticClass:"ticket-tabs-item is-medium-16-500-19 is-pointer no-whitespace-wrap",class:{
        'disable-comments': _vm.offline && index == 4,
        'is-active-tab': _vm.activeTab == index + 1,
        'is-display-none': tab_item.hide
      },on:{"click":() => {
          _vm.activeTab = index + 1;
          if (_vm.activeTab === 6) {
            _vm.track_description_views(_vm.ticket.description);
          }
        }}},[_c('sh-icon',{staticClass:"is-20x20 mr-10",attrs:{"name":_vm.activeTab !== index + 1
            ? `ticket-tab-${tab_item.icon}`
            : `ticket-tab-${tab_item.icon}-active`}}),(index === 5 && _vm.commentsUnread > 0)?_c('span',{staticClass:"tag is-medium is-info is-rounded ml-5"},[_vm._v(_vm._s(_vm.commentsUnread))]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
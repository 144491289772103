<template>
  <div
    class="is-sh-dropdown is-assignee "
    :class="{ 'has-no-width': is_name_hidden }"
    v-click-outside="() => (active = false)"
  >
    <div
      :style="[!isMobile && !active ? { 'max-width': `${width}rem` } : null]"
      :class="[
        [active ? 'is-open' : 'is-close'],
        [is_name_hidden ? 'is-flex is-vcenter' : 'is-selector'],
        [
          has_dark_theme && active
            ? 'black-background'
            : has_dark_theme
            ? 'dark-theme-colors'
            : null
        ]
      ]"
    >
      <span class="is-pointer" @click="active = !active">
        <div
          v-if="existing_user && existing_user.uid"
          class="is-flex is-vcenter"
        >
          <p
            class="is-flex is-vcenter"
            :style="{ width: `${width - 5}rem` }"
            :class="[align_right ? 'is-end' : null]"
          >
            <user-avatar
              :user="existing_user"
              :pxsize="pxsize"
              :custom_class="'tiny-user-avatar'"
              :show_tooltip="true"
            ></user-avatar>
            <span
              class="ml-6 username-truncate"
              :style="[!align_right ? { width: `${width - 7}rem` } : null]"
              v-if="!is_name_hidden"
              v-username="existing_user"
            ></span>
          </p>

          <sh-icon
            :class="{ 'is-hidden': hide_close }"
            :name="'ticket-priority-close'"
            v-if="(existing_user && existing_user.uid) || can_unassign"
            class="is-8x8 mx-5 close-icon is-pointer"
            @click.native.stop="update_user(null)"
          ></sh-icon>
        </div>
        <div v-else class="is-flex is-vcenter is-pointer">
          <sh-icon
            :name="icon"
            class="mr-7"
            :class="[
              { invert_colors: has_dark_theme },
              [pxsize ? `is-${pxsize}x${pxsize}` : 'is-30x30']
            ]"
            v-tooltip="{
              content: localization('app-assign-user', 'Assign user'),
              html: true
            }"
            v-if="icon && icon.length"
          />
          <img
            v-else
            src="@/assets/svg/add-assignee.svg"
            alt=""
            class="mr-7"
            :class="[
              { invert_colors: has_dark_theme },
              [pxsize ? `is-${pxsize}x${pxsize}` : 'is-30x30']
            ]"
            v-tooltip="{
              content: localization('app-assign-user', 'Assign user'),
              html: true
            }"
          />

          <span
            class="mr-10 is-medium-14-500-17 has-opacity-5"
            :class="[
              has_dark_theme ? 'has-text-white' : ' has-text-theme-black'
            ]"
            v-if="!is_name_hidden"
            >{{ localization("app-add-assignee", "Add Assignee") }}</span
          >
        </div>
      </span>
    </div>
    <div
      :class="{ 'mobile-background-new': isMobile }"
      v-if="active"
      @click="active = !active"
    ></div>
    <ul
      class="is-options-list has-slimscroll-xs "
      v-if="active"
      :class="[
        { 'mobile-view-option': isMobile },
        { 'dark-theme-colors': has_dark_theme }
      ]"
    >
      <li class="item mobile-header">
        <p class="is-medium-16-500-19">
          {{ localization("app-add-assignee", "Add Assignee") }}
        </p>
        <p @click="active = false">
          <sh-icon :name="'close'" class="is-12x12" v-if="active"></sh-icon>
        </p>
      </li>
      <div class="field is-marginless">
        <p class="control has-icons-left has-icons-right ">
          <input
            :class="{ 'dark-theme-colors': has_dark_theme }"
            class="input"
            type="email"
            :placeholder="localization('app-search', 'Search')"
            v-model="searchQuery"
          />
          <span class="icon is-left is-large">
            <sh-icon :name="'search'" />
          </span>
        </p>
      </div>

      <div v-if="is_loading && !users">
        <img
          src="@/assets/icons/ellipsis-animation.svg"
          class="image is-64x64 ml-auto mr-auto"
        />
      </div>

      <div v-if="users.length" :class="{ 'dark-theme-colors': has_dark_theme }">
        <li
          class="item is-vcenter"
          :class="{ 'dark-theme-colors': has_dark_theme }"
          v-for="user in users"
          :key="user.uid"
          @click.stop="update_user(user)"
        >
          <div v-if="user">
            <user-avatar
              :user="user"
              :pxsize="24"
              :custom_class="[
                has_dark_theme
                  ? 'tiny-user-avatar mr-5 '
                  : 'tiny-user-avatar mr-5'
              ]"
            ></user-avatar>
          </div>
          <span
            class="item--text"
            :class="[
              { 'has-text-light-grey': has_dark_theme },
              { 'username-truncate': !isMobile }
            ]"
            :style="{ width: `${width - 5}rem` }"
            v-username="user"
          ></span>
        </li>
      </div>
      <div v-else-if="searchQuery.length" class="mb-10">
        <invite-user
          :class="{ invert_colors: has_dark_theme }"
          :search="searchQuery"
          @invited="update_user($event)"
        ></invite-user>
      </div>
    </ul>
  </div>
</template>

<script>
  import InviteUser from "@/app/old/desktop/shared/components/add-teams-modal-user-list-invite/add-teams-modal-user-list-invite.vue";
  export default {
    props: {
      align_right: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        default: 19
      },
      existing_user: Object,
      icon: String,
      can_unassign: {
        type: Boolean,
        default: false
      },
      is_name_hidden: {
        type: Boolean,
        defaut: false
      },
      pxsize: {
        type: Number,
        default: 36
      },
      hide_close: {
        type: Boolean,
        default: false
      },
      has_dark_theme: {
        type: Boolean,
        default: false
      }
    },
    components: {
      InviteUser
    },

    data() {
      return {
        active: false,
        searchQuery: "",
        is_loading: false
      };
    },

    computed: {
      users() {
        if (this.existing_user) {
          return Object.values(this.searched_users).filter(
            s => s.uid != this.existing_user.uid
          );
        } else {
          return Object.values(this.searched_users);
        }
      },
      searched_users() {
        if (this.searchQuery.length) {
          return Object.values(this.$store.getters.state_users).filter(user =>
            _.includes(
              (user.first_name
                ? user.first_name + " " + user.last_name + user.email
                : user.email
              ).toLowerCase(),
              this.searchQuery.toLowerCase()
            )
          );
        } else {
          return Object.values(this.$store.getters.state_users);
        }
      }
    },
    watch: {
      active() {
        this.$emit("clicked-dropdown", this.active);
      }
    },
    methods: {
      update_user(value) {
        this.searchQuery = "";
        this.$emit("update-user", value);
        this.active = false;
      }
    }
  };
</script>
<style lang="scss">
  .has-no-width {
    width: 0px;
  }

  .is-sh-dropdown {
    position: relative;
    ul {
      padding-left: 0;
      margin-top: 0;
    }

    .is-selector {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1rem;
      height: 3.8rem;
      white-space: nowrap;
      object-fit: contain;
      border: solid 1px transparent;
      cursor: pointer;
      .close-icon {
        visibility: hidden;
      }
      &:hover {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px rgba(23, 30, 72, 0.1);
        .close-icon {
          visibility: visible;
        }
        @include for-size(portrait-tablets) {
          box-shadow: none;
        }
      }
      @include for-size(portrait-tablets) {
        padding: 0;
        height: auto;
        width: max-content;
        border: none;
      }
      &.is-close {
        background-color: transparent;
        border-radius: 6px;
        &:hover {
          background: #fff;
        }
      }
      &.is-open {
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
        border: solid 1px rgba(23, 30, 72, 0.1) !important;
        background: #fff;
        @include for-size(portrait-tablets) {
          box-shadow: none !important;
          border: none !important;
        }
      }
      .username-truncate {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .is-options-list {
      max-height: 26rem;
      overflow-y: auto;
      overflow-x: hidden;
      background: $white;
      display: block;
      position: absolute;
      z-index: 10;
      top: 3.8rem;
      font-family: Barlow-Medium;
      border-bottom-left-radius: 10px;
      border-top: 0 solid transparent;
      border-bottom-right-radius: 10px;
      box-shadow: 0 22px 30px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px rgba(23, 30, 72, 0.1);
      border-top: none;

      &:hover {
        background-color: $white;
      }
      &.mobile-view-option {
        all: unset;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 35rem;
        position: fixed !important;
        bottom: 0px;
        width: 100%;
        left: 0;
        z-index: 1024;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      .input {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #eee;
        border-radius: 0px;
        &:focus {
          border: none;
          box-shadow: none;
          border-bottom: 1px solid #eee;
          border-radius: 0px;
        }
        &::placeholder {
          opacity: 0.5;
          font-family: Barlow-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #171e48;
        }
      }

      .item {
        height: 4rem;
        padding: 0.7rem 1rem;
        display: flex;
        align-items: center;
        background-color: $white;
        &--icon {
          margin-right: 0.6rem;
          max-height: 2.4rem;
          max-width: 2.4rem;
          @include for-size(portrait-tablets) {
            height: 2rem;
            width: 2rem;
            margin-right: 1rem;
          }
        }
        &--text {
          white-space: nowrap;
          font-family: Barlow-Medium;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.9rem;
          @include for-size(portrait-tablets) {
            height: 1.7rem;
            font-size: 1.4rem;
            font-weight: 500;
            font-family: Barlow-Medium;
          }
          &.is-footer {
            color: $primary;
          }

          &.username-truncate {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        &:hover {
          background-color: $primary-background;
          cursor: pointer;
        }
        &.is-footer {
          border-top: 1px solid #ddd;
        }
        &.is-not-allowed {
          cursor: not-allowed;
        }
        &.mobile-header {
          display: none;
          @include for-size(portrait-tablets) {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #eee;
            border-top: 1px solid #eee;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
        }
      }
    }
  }

  .mobile-background {
    display: none;
    @include for-size(portrait-tablets) {
      display: block;
      height: 100vh;
      background: black;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0.5;
      z-index: 5;
    }
    @include for-size(tablet) {
      display: block;
      height: 100vh;
      background: black;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0.5;
      z-index: 5;
    }
  }
  .mobile-background-new {
    display: block;
    height: 100vh;
    background: black;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 10;
  }

  .is-assignee {
    .is-selector {
      &.is-close {
        display: flex;
        .is-pointer {
          .close-icon {
            display: none;
          }
        }
      }
      &:hover {
        .is-pointer {
          .close-icon {
            display: flex;
          }
        }
      }
      &.is-open {
        display: flex;
        width: 19rem;
        @include for-size(portrait-tablets) {
          width: min-content;
        }
      }
    }
    .is-options-list {
      width: 19rem;
      @include for-size(portrait-tablets) {
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .dark-theme-colors {
    background: #1e2328 !important;
    color: #bbbdbe;
    &::placeholder {
      color: #bbbdbe !important;
    }
    &:hover {
      background: #000 !important;
    }
  }
  .black-background {
    background: #000 !important;
    color: #bbbdbe;
  }
</style>

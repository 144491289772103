var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":'duplicate-project',"transition":"nice-modal-fade","classes":"demo-modal-class overflow-visible","min-width":200,"min-height":200,"adaptive":true,"scrollable":false,"reset":false,"pivot-y":0.5,"width":"30%","height":"auto"},on:{"closed":function($event){_vm.$modal.hide('duplicate-project');
    _vm.$emit('close');}}},[_c('div',{staticClass:"template-modal"},[_c('div',{staticClass:"is-flex has-space-between is-vcenter px-20 mb-20"},[_c('h1',{staticClass:"template-modal__header__text is-semiBold-20-600-000"},[_vm._v(" "+_vm._s(_vm.localization("app-duplicate", "Duplicate"))+" ")]),_c('sh-icon',{staticClass:"is-16x16 is-pointer template-modal__header__close",attrs:{"name":'close',"file":'tickets_sprite'},nativeOn:{"click":function($event){return _vm.$modal.hide('duplicate-project')}}})],1),_c('div',{staticClass:"template-modal__content mb-30"},[_c('div',{staticClass:"template-modal__content--template"},[_c('p',{staticClass:"is-medium-16-500-19 has-text-theme-black"},[_vm._v(" "+_vm._s(_vm.multiple_projects ? "Add suffix" : "Add Name")+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input mt-10 is-paddingless is-regular-16-00-19",attrs:{"placeholder":_vm.localization('app-project-name', 'Project Name'),"required":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.show_result = false)),expression:"() => (show_result = false)"}],staticClass:"template-modal__content--template mt-30 is-relative"},[_c('p',{staticClass:"is-regular-16-00-19 has-text-theme-black"},[_vm._v(" "+_vm._s(_vm.localization("app-group", "Group"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_keyword),expression:"search_keyword"}],staticClass:"input mt-10 is-paddingless",attrs:{"placeholder":_vm.project && _vm.project.group && _vm.project.group.name
              ? _vm.project.group.name
              : _vm.multiple_projects &&
                _vm.multiple_projects[0] &&
                _vm.multiple_projects[0].group
              ? _vm.multiple_projects[0].group.name
              : _vm.localization('app-select-a-group', 'Select a group')},domProps:{"value":(_vm.search_keyword)},on:{"input":[function($event){if($event.target.composing)return;_vm.search_keyword=$event.target.value},function($event){return _vm.get_groups()}],"click":function($event){_vm.show_result = true;
            _vm.selected_group = null;}}}),(_vm.show_result && _vm.data && !_vm.selected_group)?_c('div',{staticClass:"group-dropdown-results has-slimscroll-xs is-absolute"},_vm._l((_vm.data),function(list){return _c('div',{key:list.uid,staticClass:"py-5 px-10",class:[
              _vm.selected_template && _vm.selected_template.name == list.name
                ? 'selected_template'
                : null
            ],on:{"click":function($event){return _vm.select_group(list)}}},[_c('p',[_vm._v(_vm._s(list.name))])])}),0):_vm._e()])]),_c('div',{staticClass:"info-message columns px-20"},[_c('div',{staticClass:"column is-narrow"},[_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'exclamation'}})],1),_c('div',{staticClass:"column"},[_c('p',{staticClass:"is-semiBold-14-600-17 has-text-blue"},[_vm._v(" "+_vm._s(_vm.localization("app-note", "Note"))+" ")]),_c('p',{staticClass:"is-regular-14-00-17 mt-5"},[_vm._v(" Reports won't be duplicated and will be referencing to the original project. All changes to the existing reports will appear in the duplicated projects as well. ")])])]),_c('div',{staticClass:"template-modal__footer is-pointer"},[_c('div',{staticClass:"sh-button is-flex ml-auto mr-20",on:{"click":_vm.duplicate_project}},[_c('sh-icon',{staticClass:"is-22x22 actions-dropdown-content__list--icon",attrs:{"name":'duplicate-white',"file":'tickets_sprite'}}),_c('span',{staticClass:"ml-10"},[_vm._v(_vm._s(_vm.localization("app-duplicate", "Duplicate")))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
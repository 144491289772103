<template>
  <div
    @dragenter.prevent.stop="dragFile"
    class="sh-comment-section"
    id="blockend"
  >
    <div
      class="is-flex align-center is-between pl-20 pr-35 pt-40"
      :class="{ 'pb-15': totalcount && totalcount.length }"
    >
      <div>
        <span class="is-medium-16-500-19 has-primary-text">{{
          localization("app-activity", "Activity")
        }}</span>
        <span
          class="is-medium-16-500-19 has-primary-text ml-5 has-opacity-7"
          v-if="channelData && channelData.length"
          >({{ channelData.length }})</span
        >
      </div>
      <div
        v-if="totalcount && totalcount.length"
        @click="display_only_pinned = !display_only_pinned"
        class="is-medium-14-500-17 is-flex is-pointer align-center"
      >
        <span class="mr-10 has-opacity-7">{{
          localization("app-pinned", "Pinned")
        }}</span>
        <sh-icon
          v-if="display_only_pinned"
          :name="'only-comment-enable'"
          class="is-29x43"
        ></sh-icon>
        <sh-icon
          v-else
          :name="'only-comment-disable'"
          class="is-29x43"
        ></sh-icon>
      </div>
    </div>
    <div v-if="channel && !dataError">
      <div
        v-if="
          !(start_conversation || small) &&
            !commentsLoading &&
            !(
              channel &&
              channel.state.messages &&
              channel.state.messages.length
            ) &&
            channelData.length == 0
        "
      >
        <div v-if="!isMobile">
          <div class="ticket-details-add-checklist mr-20">
            <div class="has-text-centered">
              <div class="is-flex is-center">
                <sh-icon
                  :name="'no-activity'"
                  :file="'tickets_sprite'"
                  class="is-20x20"
                ></sh-icon>
              </div>
              <div class="is-medium-18-500-22 pt-5">
                {{ localization("app-no-activity", "No Activity") }}
              </div>
              <div class="is-medium-14-500-17 no-desc pt-10">
                {{
                  localization(
                    "app-no-changes-made-to-this-ticket-since-its-creation",
                    "No changes made to this ticket since its creation"
                  )
                }}
              </div>
              <div
                class="is-medium-14-500-17 pt-10 has-accent-text is-flex is-vcenter is-pointer is-center"
                @click="
                  start_conversation = true;
                  scrollPageToBottom();
                "
              >
                <sh-icon :name="'add-task'" class="is-12x12 mr-5"></sh-icon>
                {{ localization("app-new-comments", "New Comments") }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-result">
          <img src="@/assets/illustrations/no-comments-illustration.svg" alt />
          <div class="has-text-centered">
            <div class="mt-10">
              <p class="sh-text-normal-22 mb-10">
                {{ localization("app-no-comments", "No comments") }}
              </p>
              <p style="width: 50rem; font-size: 1.4rem">
                {{
                  localization(
                    "app-text-start-conversation",
                    `Conversation help in better collaboration and getting instant feedback with realtime updates. You can @mention other users and attach
                files to a comment`
                  )
                }}
              </p>
            </div>
            <div
              style="width: 20rem"
              class="sh-button ml-auto mr-auto mt-20"
              @click="
                start_conversation = true;
                scrollPageToBottom();
              "
            >
              <p class="is-flex">
                <sh-icon :name="'comments-white'" class="is-16x16"></sh-icon>
                <span class="is-regular-16-00-20">{{
                  localization(
                    "app-start-a-conversation",
                    "Start a conversation"
                  )
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="is-relative">
        <div class="is-flex is-center is-hidden">
          <div
            @click="scrollToTop"
            v-show="!threads"
            class="sh-td-comment_scroll--top"
          >
            <i class="fas fa-arrow-up mr-7"></i>
            <span class="is-medium-14-500-17">Jump to last read</span>
          </div>
        </div>
        <div class="sh-comments-divs">
          <div class="action-section sh-comments-all is-paddingless">
            <!-- :class="threads ? 'open' : ''" -->
            <div class="sh-td-comment-only">
              <div class="sh-comments-loader" v-if="commentsLoading">
                <img
                  src="@/assets/icons/ellipsis-animation.svg"
                  class="image is-64x64 margin-auto"
                />
              </div>
              <div
                v-else
                class="has-slimscroll-xs td-comment-section"
                ref="comment"
              >
                <comment
                  @reply="getReplies($event, true)"
                  @reply-added="getReplies($event, true)"
                  v-for="comment in channelData"
                  :presence_members="presence_members"
                  :key="comment.id"
                  :comment="comment"
                  :service="service"
                  :ticket="ticket"
                  :files="existing_files"
                  :channel="channel"
                  :dataUid="dataUid"
                  :small="small"
                  :zombie="false"
                  :userIcon="true"
                  :replyData="replyData"
                  :parent="threads"
                  :threads="threads"
                >
                  <div v-if="threads">
                    <div
                      v-if="
                        offlineComments &&
                          offlineComments[threads.id] &&
                          offlineComments[threads.id].reply
                      "
                    >
                      <reply
                        v-for="comment in offlineComments[threads.id].reply"
                        class="sh-zombie-comment"
                        :key="comment.id"
                        :comment="comment"
                        :ticket="ticket"
                        :channel="channel"
                        :zombie="true"
                      ></reply>
                    </div>
                    <div style="margin-left: 66px">
                      <reply
                        @reply-added="getReplies"
                        isEditing="true"
                        :parent="threads"
                        :comment="threads"
                        :presence_members="presence_members"
                        :ticket="ticket"
                        :channel="channel"
                        @add-reply="addOfflineReply"
                      ></reply>
                    </div>
                  </div>
                </comment>
                <comment
                  class="sh-zombie-comment"
                  v-for="comment in tempComments"
                  :key="comment"
                  :comment="comment"
                  :small="small"
                  :service="service"
                  :ticket="ticket"
                  :files="existing_files"
                  :channel="channel"
                  :dataUid="dataUid"
                  :zombie="true"
                  :userIcon="true"
                ></comment>
              </div>
            </div>
          </div>
          <footer class="comment-regular-editor">
            <comment
              isEditing="true"
              :userIcon="false"
              :presence_members="presence_members"
              :ticket="ticket"
              :channel="channel"
              :small="small"
              :service="service"
              :dataUid="dataUid"
              :addAttachments="addAttachments"
              @sendMessage="pushToTemp"
              ref="commentEditor"
              @messageSent="popFromTemp"
            ></comment>
          </footer>
        </div>
      </div>
    </div>
    <div class="sh-comment-error" v-else-if="channel">
      <b>
        {{
          localization(
            "app-unable-to-connect-to-servers",
            " Unable to connect to servers"
          )
        }}
      </b>
      <b>
        {{
          localization(
            "app-please-check-if-you-are-online-and-retry",
            " Please check if you are online and retry"
          )
        }}</b
      >
      <div class="btn button mt-20" @click="init">
        <i
          class="fa fa-redo mr-5"
          style="font-size: 12px"
          aria-hidden="true"
        ></i>
        {{ small ? init() : "Retry." }}
      </div>
    </div>
    <div class="sh-comment-error" v-else>
      <div class="sh-comments-loader" v-if="loading">
        <img
          src="@/assets/icons/ellipsis-animation.svg"
          class="image is-64x64 margin-auto"
        />
      </div>
      <div v-else>
        <b>Ooops!, Chat server is not initialised</b>
        <div>
          <br />Refresh the page and try again <br />OR <br />Contact support.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    userService,
    ticketService
  } from "@/app/old/desktop/shared/services/";
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";
  import CommentsHeader from "@/app/old/desktop/tickets/components/ticket-details/components/comments/components/header/comments-header.vue";
  import Comment from "./components/comment/comment";
  import Loader from "./components/comment/components/loader";
  import Reply from "./components/comment/reply";
  import VEmbed from "vue-embed";
  import RadialProgressBar from "vue-radial-progress";

  export default {
    props: [
      "ticket",
      "presence_members",
      "channelName",
      "service",
      "small",
      "dataUid"
    ],
    data() {
      return {
        allUsers: [],
        user_typing: null,
        threads: false,
        channel: null,
        replies: [],
        existing_files: [],
        start_conversation: false,
        filters: null,
        showFilter: false,
        commentsLoading: false,
        threadsLoading: false,
        searchData: null,
        tempComments: [],
        addAttachments: false,
        dataError: false,
        offlineComments: {},
        loading: false,
        connected: false,
        allActivities: [],
        ticketFeed: null,
        constLimit: 0,
        activity_limit: 100,
        current_response: [],
        is_loading: false,
        load_more_scroll_position: 600,
        display_only_pinned: false,
        totalcount: null
      };
    },
    beforeDestroy() {
      this.unsubscribe_events();

      // await this.$store.state.chat.client.disconnect();
    },
    mounted() {
      this.init();
    },
    created() {
      this.getActivities();
    },
    components: {
      PromptModal,
      VEmbed,
      RadialProgressBar,
      CommentsHeader,
      Comment,
      Reply,
      Loader
    },
    computed: {
      current_user() {
        return this.$store.state.claims.user_id;
      },
      channelData() {
        let commentdata = this.showFilter
          ? this.filters
          : this.channel
          ? this.channel.state.messages
          : [];

        this.totalcount = commentdata;

        if (this.display_only_pinned) {
          let tempdata = _.filter(commentdata, function(c) {
            return c.pinned;
          });
          return tempdata;
        } else {
          let temp = _.sortBy([...commentdata, ...this.allActivities], d => {
            return d.created_at;
          });
          return temp;
        }
      },
      replyData() {
        return this.replies;
      }
    },
    watch: {
      dataUid: function(newVal, oldVal) {
        if (newVal) {
          this.search(this.searchData);
        }
      },
      "ticket.uid": {
        async handler(newVal, oldVal) {
          if (this.$route.name.includes("therm-v2") && newVal !== oldVal) {
            this.constLimit = 0;
            this.allActivities = [];
            this.getActivities();
            await this.unsubscribe_events();
            this.init();
          }
        }
      }
    },
    methods: {
      async getActivities() {
        console.log("getting activities");
        this.is_loading = true;
        let { data } = await ticketService
          .get_activities({
            id: this.ticket.uid
          })
          .catch(err => this.handleErrors(err));

        this.ticketFeed = stream.connect(data.appKey, data.token, data.appId, {
          location: data.location
        });

        this.repeat_get_activities();
      },
      async repeat_get_activities() {
        let act = this.ticketFeed.feed("ticket", this.ticket.uid);
        let { results } = await act.get({
          limit: this.activity_limit,
          offset: this.constLimit
        });
        this.constLimit = parseInt(this.activity_limit + this.constLimit);

        this.current_response = results.map(
          r => (r["created_at"] = new Date(r.time + "Z"))
        );
        this.is_loading = false;
        this.allActivities = [...results, ...this.allActivities];
        if (results && results.length < 100) {
          return;
        } else {
          return this.repeat_get_activities();
        }
      },
      async init() {
        try {
          await this.initialise();
        } catch (e) {
          this.dataError = true;
        }
      },
      async initialise() {
        this.dataError = false;
        this.commentsLoading = true;
        this.client = this.$store.state.chat.client;
        this.channel = this.client.channel("messaging", this.channelName, {
          name: this.ticket.name,
          members: [this.current_user]
        });
        this.state = await this.channel.watch();
        this.commentsLoading = false;
        // this.scrollPageToBottom();
        this.channel.on("message.deleted", this.searchOnUpdate);
        this.channel.on("message.updated", this.searchOnUpdate);
        this.channel.on("message.new", this.onNewMessage);
        this.channel.on("reaction.new", this.onNewReaction);
        this.channel.on("reaction.deleted", this.onDeleteReaction);
        this.scrollToBottomComments();
      },
      dragFile(event) {
        try {
          this.$refs.commentEditor.$el
            .querySelector(".sh-attachment-btn")
            .click();
        } catch (e) {}
      },
      formatVal(val) {
        val.unprocessed = Object.assign({}, val);
        val.user = {
          id: this.$store.state.claims.user_id,
          email: this.$store.state.claims.email,
          name:
            this.$store.state.claims.firstname +
            " " +
            this.$store.state.claims.lastname
        };
        val.created_at = new Date();
        return val;
      },
      pushToTemp(val) {
        if (!this.showFilter) this.tempComments.push(this.formatVal(val));
        this.scrollToBottomComments();
      },
      addOfflineReply({ data, comment }) {
        if (
          this.offlineComments[comment.id] &&
          this.offlineComments[comment.id].reply
        ) {
          this.offlineComments[comment.id].reply.push(this.formatVal(data));
          this.offlineComments = Object.assign({}, this.offlineComments);
        } else {
          this.offlineComments[comment.id] = {
            reply: [this.formatVal(data)]
          };
          this.offlineComments = Object.assign({}, this.offlineComments);
        }
        this.scrollToBottom(this.$refs.thread);
      },
      async removeOfflineReply({ data, commentID, failed }) {
        if (!failed) {
          this.replies = (await this.channel.getReplies(commentID)).messages;
          this.scrollToBottom(this.$refs.thread);
        }
        if (
          this.offlineComments[commentID] &&
          this.offlineComments[commentID] &&
          this.offlineComments[commentID].reply &&
          this.offlineComments[commentID].reply.length > 0
        ) {
          this.offlineComments[commentID].reply = this.offlineComments[
            commentID
          ].reply.filter(d => d.uuid !== data.uuid);
          this.offlineComments = Object.assign({}, this.offlineComments);
        }
      },
      popFromTemp(val) {
        this.tempComments = this.tempComments.filter(d => d.uuid !== val);
      },
      searchOnUpdate(e) {
        if (this.showFilter) {
          this.search(this.searchData);
        }
      },
      refreshReply(message) {
        if (this.threads && this.threads.id === message.parent_id) {
          this.removeOfflineReply({
            commentID: message.parent_id,
            data: message
          });
        }
      },
      onNewMessage(event) {
        if (event.message.type === "regular") {
          this.popFromTemp(event.message.uuid);
          this.scrollToBottomComments();
          this.searchOnUpdate();
        } else if (event.message.type === "reply") {
          this.refreshReply(event.message);
        }
      },
      onNewReaction(e) {
        this.refreshReply(e.message);
      },
      onDeleteReaction(e) {
        this.refreshReply(e.message);
      },
      async getReplies(comment, updating = false) {
        try {
          this.threadsLoading = true;
          this.threads = comment;
          this.replies = (await this.channel.getReplies(comment.id)).messages;
          this.threadsLoading = false;
          if (!updating) {
            this.scrollToBottom(this.$refs.thread);
          }
        } catch (e) {
          this.threadsLoading = false;
        }
      },
      async search(messageFilter) {
        try {
          this.searchData = messageFilter;
          this.commentsLoading = this.showFilter ? false : true;
          this.showFilter = true;
          const filters = { id: this.channel.id };
          if (this.dataUid) {
            if (!messageFilter) {
              messageFilter = { dataUid: { $eq: this.dataUid } };
            }
          }
          const search = await this.$store.state.chat.client.search(
            filters,
            messageFilter
          );
          this.filters = search.results
            .map(s => s.message)
            .filter(
              s =>
                s.type === "regular" &&
                (s.dataUid ? s.dataUid === this.dataUid : true)
            )
            .reverse();
          this.commentsLoading = false;
          // this.scrollToBottomComments();
        } catch (e) {
          this.commentsLoading = false;
        }
      },
      scrollToBottomComments(timeout = 500) {
        setTimeout(() => {
          this.scrollToBottom(this.$refs.comment);
        }, timeout);
      },
      scrollPageToBottom() {
        setTimeout(() => {
          let element = document.getElementById("blockend");
          element.scrollIntoView({ block: "end" });
        }, 100);
      },
      scrollToTop() {
        let container = document.querySelector(".action-section");
        container.scrollTop = 0;
      },
      // scrollToTop(top) {
      //   if (top) {
      //     this.$nextTick(() => {
      //       div.scrollTop = 0;
      //     });
      //   }
      // },
      scrollToBottom(div) {
        if (div) {
          this.$nextTick(() => {
            div.scrollTop = div.scrollHeight;
          });
        }
      },
      async unsubscribe_events() {
        if (!this.connected) {
          return;
        }
        this.channel.off("message.deleted", this.searchOnUpdate);
        this.channel.off("message.new", this.onNewMessage);
        this.channel.off("message.updated", this.searchOnUpdate);
        this.channel.off("reaction.new", this.onNewReaction);
        this.channel.off("reaction.deleted", this.onDeleteReaction);
        await this.channel.stopWatching();
      }
    }
  };
</script>

<style lang="scss">
  .comments-header {
    padding: 1rem 6rem;
    border-bottom: 1px solid #e6e6e6;
  }

  .sh-threads-div--scroll > div:first-child {
    border-bottom: 1px solid #e6e6e6;
  }

  .sh-comments-all {
    overflow: auto;
    height: 100%;
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: flex-end;
    position: relative;
    .sh-comment-box {
      padding: 1.3rem;
    }
    &.open {
      // .sh-td-comment-only {
      // margin: 15px 50px;
      // }
      overflow: hidden;
    }
    padding-top: 2rem;
    .sh-comment-text {
      margin-top: 10px;
    }
  }

  .sh-comments-div {
    height: 100vh;
    flex-direction: column;
    display: flex;
    position: relative;
  }

  .sh-td-comment-only {
    // margin: 15px auto;
    // width: 100%;
    min-height: 0;
    position: relative;
    // overflow-y: auto;
  }

  .sh-comments-threads {
    position: absolute;
    display: flex;
    flex-flow: column;
    right: 0;
    top: 0px;
    width: 40rem;
    height: 100%;
    background: white;
    border-left: 1px solid #eaeaea;
    .ck-editor {
      margin: 0 !important;
      .ck-editor__top {
        display: none !important;
      }
    }
    .sh-comment {
      margin: 0;
      padding-bottom: 0.5rem;
    }
    .sh-comment-action {
      align-items: center;
    }
    .sh-comment-text {
      margin-top: 20px;
    }
  }

  .comment-regular-editor {
    width: 100%;
    margin: 0 auto;
    background: white;
    padding: 0px 0px 20px 0px;
    position: relative;
    left: -2px;
  }

  .sh-comment {
    margin: 15px auto;
    position: relative;
    .sh-dropdown-content__Box {
      height: auto;
    }
    .sh-unlike,
    .sh-comment-option > * {
      display: none;
    }
    .sh-comment-option.active > * {
      display: flex;
    }
    .sh-comment-option.active > .sh-created-at {
      display: none;
    }
    .sh-created-at {
      display: flex;
    }
    &:not(.sh-zombie-comment):hover {
      .sh-unlike,
      .sh-comment-option > * {
        display: flex;
      }
      .sh-comment-option > .sh-created-at {
        display: none;
      }
    }
  }
  .sh-comment-name {
    max-height: 20px;
    &--text {
      font-family: Barlow-Regular;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.22;
      color: #171e48;
    }
  }
  .sh-comment-pin {
    opacity: 0.7;
    font-family: Barlow-Regular;
    font-size: 14px;
    line-height: 1.21;
    text-align: left;
    color: #171e48;
  }
  .sh-user {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 1rem;
    font-size: 14px;
  }
  .sh-comment-box {
    word-break: break-all;
    border-radius: 0px 10px 10px 10px;
    position: relative;
    &[pinned]::before {
      content: " ";
      width: 4px;
      height: 100%;
      left: 0px;
      top: 0;
      opacity: 0.6;
      position: absolute;
      background-color: #fd9c0f;
    }
    &.comment-owner {
      background-color: #fff;
      border: solid 1px #d0d2da;
    }
    &.comment-not-owner {
      background-color: #f3f8fc;
    }
    .sh-comment-text {
      padding: 0;
    }
  }

  .sh-comment-replies__button {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.19;
    text-align: left;
    color: #2585d3;
  }

  .sh-comment-section {
    font-family: Barlow-Medium;
    position: relative;
    .sh-comment-send {
      border-radius: 10rem;
      padding: 1 rem 0.8rem;
    }
    .sh-comment-send.disabled {
      background-color: #fff;
      box-shadow: none;
      color: #ccc;
      pointer-events: none;
    }
  }

  .sh-comment-attachments {
    margin: 10px;
    padding-left: 5px;
    border-left: 3px solid #eaeaea;
    img {
      width: 360px;
    }
  }

  .sh-comment-section {
    .ck-editor {
      flex: 1;
      .ck-toolbar,
      .ck-content,
      .ck-focused {
        border: 0 !important;
        background: #fff;
        box-shadow: none !important;
        outline: none !important;
      }
      border: 0 !important;
      box-shadow: none !important;
      margin-bottom: 30px;
      position: relative;
      .ck-editor__top {
        display: none;
        position: absolute;
        bottom: -30px;
      }
    }
  }

  .sh-comments-loader {
    position: relative;
    height: 70vh;
    display: flex;
    flex-flow: column;
  }

  .sh-comment-pinned {
    animation: comment-like-wiggle 1.25s ease forwards infinite;
  }

  .sh-comment-like-wiggle {
    animation: comment-like-wiggle 1.25s ease forwards infinite;
  }

  @keyframes comment-like-wiggle {
    0% {
      transform: rotate(-15deg);
    }
    20% {
      transform: rotate(15deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    60% {
      transform: rotate(10deg);
    }
    80% {
      transform: rotate(-5deg);
    }
    80% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .sh-comment-section .has-slimscroll-xs,
  .animation-content {
    scroll-behavior: smooth;
  }

  .sh-comments-div .attachment-list {
    padding: 0;
    .attachment.card {
      margin: 0.5rem;
      // width: 19rem;
    }
  }
  .sh-comment-action.focused {
    .ck-editor__top {
      display: block;
    }
  }
  .sh-td-comment_scroll--top {
    position: absolute;
    top: -10px;
    z-index: 1;
    font-size: 14px;
    width: 16rem;
    height: 3.6rem;
    color: #fff;
    background-color: #747891;
    border-radius: 6px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #6c6f7c;
      cursor: pointer;
    }
  }

  .comments-header .watchers-dropdown > div:first-child > div:first-child {
    flex-wrap: wrap;
  }
  .sh-zombie-comment {
    opacity: 0.6;
    pointer-events: none;
    position: relative;
  }

  .sh-comment-error {
    height: 30vh;
    width: 100%;
    flex-flow: column;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.75;
    font-size: 2rem;
    text-align: center;
    .sh-comments-loader {
      width: 70%;
      top: 100px;
    }
  }

  .comment-regular-editor,
  .sh-td-comment-only {
    .sh-comment-action {
      flex-flow: column;
      align-items: flex-start;
      .ck-editor {
        margin: 0;
        width: 100%;
        .ck-editor__top {
          bottom: -35px;
        }
      }
    }

    .sh-comment-action-bar {
      align-self: flex-end;
      top: -5px;
      right: 5px;
    }
  }
  .sh-comments-comment--retry {
    position: absolute;
    top: 20%;
    right: -10px;
    opacity: 0.75;
    background: white;
    pointer-events: all;
    cursor: pointer;
  }

  .ck .ck-link_selected,
  .ck-content .mention {
    color: #2585d3;
    background: none;
  }
  .td-comment-section {
    // height: 36rem;
    height: calc(100vh - 270px);
    overflow-y: auto;
  }
</style>

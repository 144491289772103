<template>
  <div class="sh-list-header columns is-regular-14-00-17 is-gapless">
    <div
      class="column"
      :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-4']"
    >
      {{ localization("app-therm", "Therm") }}
    </div>
    <div
      class="column "
      :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-2_5']"
    >
      {{ localization("app-created-by", "Created by") }}
    </div>
    <!-- <div class="column is-4"> -->
    <!-- <div class="columns"> -->
    <div
      class="column  no-whitespace-wrap is-flex is-center"
      :class="[$route.name.includes('asset-details') ? 'is-1_5' : 'is-1']"
    >
      {{ localization("app-affected-kw", "Affected KW") }}
    </div>
    <div
      class="column  no-whitespace-wrap is-flex is-center"
      :class="[$route.name.includes('asset-details') ? 'is-1_5' : 'is-1_5']"
    >
      {{ localization("app-total-dc-capacity", "Total DC Capacity") }}
    </div>
    <div class="column is-1 no-whitespace-wrap is-flex is-center">
      {{ localization("app-count-defects", "# Defects") }}
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <div class="column is-2"></div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

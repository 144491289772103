<template>
  <div>
    <treeselect
      style="background:white"
      :limit="1"
      :placeholder="localization('app-select-project', 'Select Project')"
      @input="$emit('change', projects)"
      :valueFormat="'object'"
      v-model="projects"
      :multiple="true"
      :normalizer="projects_normalizer"
      :options="details.groups"
      :valueConsistsOf="'LEAF_PRIORITY'"
    ></treeselect>
  </div>
</template>

<script>
  import Treeselect from "@riophae/vue-treeselect";

  export default {
    components: {
      Treeselect
    },
    props: ["details"],
    data() {
      return {
        projects: [],
        active: "all"
      };
    },
    created() {
      this.projects = this.projectss;
    },
    computed: {
      projectss() {
        let projects = [];
        if (this.details && this.details.groups)
          for (let group of this.details.groups) {
            for (let project of group.projects) {
              projects.push(project);
            }
          }
        return projects;
      }
    },
    methods: {
      projects_normalizer(node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.projects
        };
      },
      sort(data) {
        if (!data) return [];
        return _.sortBy(data, [p => p.name.toLowerCase()]);
      }
    }
  };
</script>

<style></style>

import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_FORMS_API_HOST

const URL = 'fieldValues'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }
  async update(req = {}) {
    return httpClient({
      url: `${this.url}/${req.id}/`,
      baseURL: this.baseURL,
      method: 'patch',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }

  async matrix(req = {}) {
    return httpClient({
      url: `${this.url}/matrix/${req.query ? `?${req.query}` : ''}`,
      baseURL: this.baseURL,
      method: 'get',
      data: req.body,
      header: req.headers
    }).then(res => res.data)
  }
}

export const fieldValues = new API()

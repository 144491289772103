import { render, staticRenderFns } from "./save-template.vue?vue&type=template&id=77d5d2af"
import script from "./save-template.vue?vue&type=script&lang=js"
export * from "./save-template.vue?vue&type=script&lang=js"
import style0 from "./save-template.vue?vue&type=style&index=0&id=77d5d2af&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
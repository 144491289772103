<template>
  <div class="project__image-details">
    <!-- <div class="sh-medium-header is-marginless">Image details</div> -->
    <div
      class="pin-text has-text-grey is-medium-14-500-17 is-pointer mb-10"
      @click.stop="$router.go(-1)"
    >
      <i class="fas fa-chevron-left mr-10"></i>
      {{ $route.params && $route.params.name }}
    </div>
    <div class="is-flex">
      <div class="tabs">
        <ul>
          <li
            :class="{ 'is-active': active_tab == 'map' }"
            @click="active_tab = 'map'"
          >
            <a style="font-weight: 500; padding-top: 0; font-size: 18px">{{
              localization("app-map", "Map")
            }}</a>
          </li>
          <li
            :class="{ 'is-active': active_tab == 'Image' }"
            @click="active_tab = 'Image'"
          >
            <a style="font-weight: 500; padding-top: 0; font-size: 18px"
              >Images ({{ Object.keys(project_report_images).length }})</a
            >
          </li>
          <li></li>
        </ul>
      </div>
      <div class="is-flex">
        <a @click="download_meta_data()" class="mt-5 ml-10">Download</a>
        <div class="is-flex ml-50" style="height: 36px;align-items: center;">
          <b-field class="file" :class="{ 'has-name': !!file }">
            <b-upload @input="readFile()" v-model="file" class="file-label">
              <span class="file-cta">
                <sh-icon
                  :name="'upload-active'"
                  class="is-16x16 mr-10"
                ></sh-icon>
                <span class="file-label"
                  >Choose kml or geojson to update coverage</span
                >
              </span>
            </b-upload>
          </b-field>
          <span class="ml-10  file-name tag  is-medium" v-if="file">
            {{ file.name | truncate(10) }}
            <button @click="file = null" class="delete is-small"></button>
          </span>
          <span
            v-if="file"
            @click="update_coverage_data()"
            class="ml-10 file-name is-info tag is-medium is-pointer"
            >Update</span
          >
        </div>
      </div>
    </div>

    <div class="app-details" v-if="active_tab == 'map'">
      <thumbnails-map
        :project_details="project_details"
        v-if="project_report && project_report.datas && project_details"
        :raw_images="project_report_images"
        :report_details="project_report"
      ></thumbnails-map>
      <!-- :missions_data="mission" -->
    </div>

    <div class="app-details" v-else>
      <thumbnails-image-grid
        :raw_images="project_report_images"
        :report_details="project_report"
      ></thumbnails-image-grid>
    </div>
  </div>
</template>

<script>
  import ThumbnailsMap from "./components/thumbnails-map.component";
  import ThumbnailsImageGrid from "./components/thumbnails-image-grid.component";
  import {
    projectService,
    reportService,
    uploadService
  } from "@/app/old/desktop/shared/services/";
  import togeojson from "@mapbox/togeojson";

  export default {
    components: {
      ThumbnailsMap,
      ThumbnailsImageGrid
    },
    data() {
      return {
        file: null,
        file_data: null,
        project_details: null,
        active_tab: "map",
        is_upload_popup: false,
        project_report: {},
        project_report_images: [],
        mission: {},
        details: {
          data_captured_time: null,
          data_upload_time: null,
          area_covered: null,
          camera_model: null,
          image_altitude: null,
          mission: null,
          average_ground_sampling_distance: null
        }
      };
    },
    async created() {
      this.get_project_details();
      await this.get_project_report();
      if (this.project_report.uid) await this.get_project_report_images();
    },
    methods: {
      async update_coverage_data() {
        let response = await projectService.patch({
          group_id: this.$route.params.group_id,
          id: this.$route.params.id,
          body: {
            coverage: this.file_data
          }
        });
        this.file = null;
        this.file_data = null;
        this.get_project_details();
      },
      async get_project_details() {
        let response = await projectService.get({
          group_id: this.$route.params.group_id,
          id: this.$route.params.id
        });
        this.project_details = response;
      },
      readFile() {
        if (!this.file) {
          this.file_data = null;
          return;
        }
        if (
          !this.file.name.includes(".kml") &&
          this.file.name.includes(".json") &&
          this.file.name.includes(".geojson")
        ) {
          this.$toast.show(
            "Choose correct file extention",
            "",
            this.$store.state.izitoast_options.appWarning
          );
          return;
        }
        var name = this.file.name;
        var reader = new FileReader();
        reader.onload = e => {
          let json;
          if (this.file.name.includes(".kml")) {
            var dom = new DOMParser().parseFromString(
              reader.result,
              "text/xml"
            );
            json = togeojson.kml(dom);
          } else {
            json = JSON.parse(reader.result);
          }
          this.file_data = json;
        };
        reader.readAsText(this.file, "UTF-8");
      },

      download_meta_data() {
        if (this.project_report && this.project_report.datas) {
          let features = _.filter(
            Object.keys(this.project_report.datas).map(key => ({
              ...this.project_report.datas[key],
              key
            })),
            e => e.exif && e.exif.GPSLongitude && e.exif.GPSLatitude
          ).map((image, i) => {
            return {
              type: "Feature",
              id: i,
              geometry: {
                type: "Point",
                coordinates: [
                  (image.exif && image.exif.GPSLongitude) || 0,
                  (image.exif && image.exif.GPSLatitude) || 0
                ]
              },
              properties: {
                key: image.key,
                filename: image.filename,
                ...(image.misc || {}),
                is_active: 0,
                ...(image.exif || {})
              }
            };
          });
          var blob = new Blob(
            [
              JSON.stringify({
                type: "FeatureCollection",
                features: features
              })
            ],
            {
              type: "text/json"
            }
          );
          console.log(this.project_report);
          var filename =
            this.project_details.name + " - raw-images-meta-data.json";
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }
        }
      },

      async get_project_report() {
        let { results } = await reportService.get_reports({
          resource_name: "projects",
          query: "report_type=image_thumbnails",
          id: this.$route.params.id
        });
        if (results && results.length) this.project_report = results[0];

        let new_datas = {};
        Object.values(this.project_report.datas).forEach(el => {
          new_datas[el.filename] = el;
        });
        this.project_report.datas = new_datas;
      },
      async get_project_report_images() {
        let { urls } = await uploadService.get_raw_datas_download_links({
          id: this.$route.params.id,
          report_uid: this.project_report.uid,
          body: {}
        });
        if (urls) this.project_report_images = urls;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .sh-image-details-card {
    background: $white;
    border-radius: 0.5rem;
    height: max-content;
    padding: 2rem;
    margin: 1rem 0;

    .column {
      padding: 1rem;
    }
  }
  .project__image-details {
    padding-left: 1rem;
    padding-right: 1rem;
    &--images {
      display: flex;
      &--element {
        width: 7rem;
        height: 7.5rem;
        margin-right: 1rem;
        img {
          border-radius: 0.5rem;
        }
      }
      .image-block {
        height: 6.5rem;
        width: 7rem;
        background: $blue;
        margin-right: 1rem;
        border-radius: 0.5rem;

        &--upload {
          background: $primary-background;
          border: 1px solid $grey-text;
          border-radius: 0.5rem;

          height: 6rem;
          width: 7rem;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 1.4rem;
          flex-direction: column;
        }
      }
    }
  }
</style>

<script>
  import DeleteConfirmation from "@/app/old/desktop/vault/components/delete-confirmation";

  import { mapMutations, mapState, mapGetters } from "vuex";
  import {
    folderService,
    fileService
  } from "@/app/old/desktop/shared/services/";
  import ShareFile from "@/app/old/desktop/vault/components/share-file/share-file";
  import AskPassword from "@/app/old/desktop/vault/components/ask-password-2";

  import CommonMixin from "./common.mixin";

  export default {
    mixins: [CommonMixin],
    data() {
      return {
        uppy: null,
        show_versions: false,
        version: ""
      };
    },
    computed: {
      ...mapState("vault", [
        "selected",
        "last_selected",
        "files",
        "info_tab",
        "compact_view",
        "current_folder"
      ]),
      selected: {
        get() {
          return this.$store.state.vault.selected_files;
        },
        set(value) {
          this.$store.commit("vault/SET_SELECTED_FILES", value);
        }
      }
    },
    methods: {
      ...mapMutations("vault", [
        "SET_LAST_SELECTED",
        "SET_SELECTED",
        "SET_INFO_TAB",
        "UPDATE_FILE_DETAILS",
        "REMOVE_FILE",
        "REMOVE_FOLDER"
      ]),
      upload_version_file(name) {
        this.version = name;
        try {
          this.uppy.getPlugin("Dashboard").openModal();
        } catch (err) {
          console.log(err);
        }
      },
      uploadMethod({ service }, file) {
        let file_version_data = {
          name: this.form.name || file.name,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          service: service,
          version: this.version || null
        };
        return fileService.upload_version({
          id: this.current_folder.uid,
          file_id: this.item.uid,
          body: file_version_data,
          headers: this.get_password_header(
            this.current_folder.rootFolder
              ? this.current_folder.rootFolder
              : this.current_folder.uid
          )
        });
      },
      upload_completed() {
        this.version = "";
        this.$eventHub.$emit("refetch-folder-data");
        this.show_versions = true;
        this.$eventHub.$emit("uploaded-new-version");
        // if (this.current_folder_uid) this.getFolderData();
      },
      open_share_modal(item) {
        this.$modal.show(
          ShareFile,
          {
            file: item
          },
          {
            width: "400px",
            classes: ["has-background-transparent"],
            height: "auto"
          }
        );
      },
      move(folder) {
        this.patch({ id: this.item.uid, body: { folder } }, "move").then(() =>
          this.REMOVE_FILE([this.item.uid])
        );
      },
      async patch(api_payload, method) {
        return await this.apiService[method]({
          ...api_payload
        })
          .then(res => {})
          .catch(this.handleErrors);
      },
      bookmark(item, i) {
        let val = !item.bookmark;
        this.UPDATE_FILE_DETAILS({ uid: item.uid, bookmark: val });
        this.patch(
          { id: item.uid, body: { bookmark: val } },
          "toggle_bookmark"
        );
      },
      download(file) {
        let req = {
          id: file.uid,
          headers: this.get_password_header(file.rootFolder)
        };
        if (this.original_uid)
          req = { ...req, version: file.uid, id: this.original_uid };
        fileService.get_file_download_url(req).then(res => {
          window.open(res.data, "_blank");
        });
      },
      // delete_item(item) {
      //   this.$eventHub.$emit("delete_file_folder", {
      //     folder: this.folder,
      //     file: item
      //   });
      // },
      archive(item) {
        let archive = !item.archive;
        this.REMOVE_FILE([item.uid]);
        this.patch({ id: item.uid, body: { archive } }, "toggle_archive");
      },
      save(file, i) {
        this.is_edit = -1;
        if (file.name == this.form.name) return;
        this.UPDATE_FILE_DETAILS({ uid: file.uid, name: this.form.name });
        this.patch(
          {
            id: file.uid,
            body: { name: this.form.name },
            headers: this.get_password_header(file.rootFolder)
          },
          "patch"
        );
      },
      async update_owner(user, file) {
        let action = async password => {
          let body = {
            owner: {
              uid: user.uid,
              email: user.email
            }
          };

          this.$store.commit(`vault/UPDATE_FILE_DETAILS`, {
            owner: _.pick(user, ["uid", "email"]),
            uid: file.uid
          });
          this.patch(
            {
              id: file.uid,
              body: body,
              headers: this.get_password_header(password)
            },
            "patch"
          );
        };

        if (!file.passwordProtected) {
          action("");
          return;
        }
        this.$modal.show(
          AskPassword,
          {
            item: file,
            inputPassword: action
          },
          {
            width: "400px",
            classes: ["has-background-transparent"],
            height: "auto"
          }
        );
      }
    }
  };
</script>

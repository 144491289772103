<template>
  <div
    class="therm-sidebar has-slimscroll-xs is-paddingless"
    :style="{ width: `${sidebar_width}rem` }"
  >
    <!-- Emits projects that were selected/unselected -->
    <projects-list
      v-show="$route.name !== 'therm-v2-compare'"
      :custom_styles_for_groups="
        $route.name == 'therm-v2-reports' || $route.name == 'therm-v2-tickets'
          ? `right: 4rem; top: 1rem; bottom: initial`
          : `left: ${sidebar_width + 2}rem`
      "
      :custom_styles_for_projects="
        $route.name == 'therm-v2-reports' || $route.name == 'therm-v2-tickets'
          ? `left: ${sidebar_width + 4}rem;top:1.5rem;`
          : `left: ${sidebar_width + 2}rem`
      "
      @projects-selected="set_active_projects"
      @projects-unselected="set_inactive_projects"
    />
    <img
      src="@/assets/icons/thermv2/logo.svg"
      class="mainlogo image"
      v-if="!mini_thermsidebar"
    />
    <div class="image is-32x32 logo-mini image" v-else-if="mini_thermsidebar">
      <img
        src="@/assets/logos/sensehawk-logo-small.svg"
        alt
        class="is-marginless"
      />
    </div>
    <hr class="divider is-marginless has-opacity-1" />
    <sh-icon
      @click.native="SET_MINI_THERMSIDEBAR(!mini_thermsidebar)"
      file="thermv2_sprite"
      name="expand-collapse-left"
      :style="{ transform: mini_thermsidebar ? 'rotate(180deg)' : null }"
      class="is-20x20 image is-pointer toggle-thermsidebar"
    ></sh-icon>

    <div class="has-slimscroll-xs">
      <div class="mt-15">
        <router-link
          tag="div"
          :to="nav.to"
          v-for="nav in navigation_headers"
          :key="nav.header"
          class="therm-sidebar__item is-flex align-center is-pointer pl-20"
          :class="{ 'is-bg-completed': handle_routes(nav.header) }"
          @click.native="is_intercom_active = false"
        >
          <p
            class="therm-sidebar__item--icon"
            v-tooltip="{
              content: mini_thermsidebar ? nav.header : null,
              classes: ['inverted-tooltip'],
              placement: 'right-end',
              offset: '12px'
            }"
          >
            <sh-icon
              :name="
                handle_routes(nav.header) ? nav.sprite + '-active' : nav.sprite
              "
              :file="'thermv2_sprite'"
              class="icon is-24x24"
            ></sh-icon>
          </p>

          <p
            v-if="!mini_thermsidebar"
            :class="{ 'has-text-white': handle_routes(nav.header) }"
            class="therm-sidebar__item--text is-medium-15-500-18 pl-10"
          >
            {{
              localization(
                `app-${nav.header
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`,
                nav.header
              )
            }}
          </p>
        </router-link>
      </div>
      <div
        class="therm-sidebar__item is-flex align-center is-pointer pl-20"
        :class="{ 'is-bg-completed ': is_intercom_active }"
        @click="is_intercom_active = !is_intercom_active"
      >
        <p
          class="therm-sidebar__item--icon ml-2"
          v-tooltip="{
            content: 'Support',
            classes: ['inverted-tooltip'],
            placement: 'right-end',
            offset: '12px'
          }"
        >
          <sh-icon :name="'applications'" class="icon is-20x20"></sh-icon>
        </p>
        <p
          class="therm-sidebar__item--text is-medium-15-500-18 pl-10"
          :class="{ 'has-text-white ': is_intercom_active }"
          v-if="!mini_thermsidebar"
        >
          {{ localization("app-support", "Support") }}
        </p>
      </div>
      <div class="filters-section mt-10">
        <div class="is-relative">
          <div
            :class="{ 'is-disabled': $route.name === 'therm-v2-compare' }"
            @click="tickets_filter_active = !tickets_filter_active"
            class="is-pointer is-medium-15-500-18 therm-sidebar__item--text is-flex align-center my-15 pl-20"
          >
            <span
              v-tooltip="{
                content: mini_thermsidebar ? 'Filters' : null,
                classes: ['inverted-tooltip'],
                placement: 'right-end',
                offset: '8px'
              }"
            >
              <sh-icon
                :file="'thermv2_sprite'"
                class="is-24x24 icon mr-10"
                :name="is_filter_active ? 'filter-selected' : 'filter'"
              ></sh-icon>
            </span>
            <span v-if="!mini_thermsidebar">
              {{ localization("app-filters", "Filters") }}
            </span>
          </div>
          <ticket-filters
            class="thermv2-filters-popup"
            v-if="tickets_filter_active"
            :visible_parameter="[
              'status',
              'priority',
              'assignee',
              'created_by',
              'duedate',
              'tags'
            ]"
            :has_dark_theme="true"
            :filtersData="filtersData"
            :style="{
              left: mini_thermsidebar ? '8rem' : '22rem'
            }"
            @close="tickets_filter_active = false"
            v-click-outside="
              () => {
                tickets_filter_active = false;
              }
            "
          >
            <template v-slot:custom-content="{ filtersData }">
              <div
                class="filter-dropdown-content__item has-text-black mt-5 is-medium-14-500-17 "
              >
                {{
                  localization(
                    "app-temperature-difference",
                    "Temperature Difference"
                  )
                }}
              </div>
              <temperature-difference
                class="filter-dropdown-content__item is-block no-padding-bottom"
                @updated="onUpdated"
              />
            </template>
          </ticket-filters>
        </div>
        <div
          class="therm-sidebar__filters has-slimscroll-xs"
          v-if="!mini_thermsidebar"
        >
          <div
            v-tooltip="{
              content:
                vector.description.length > vector_description_max_length
                  ? vector.description
                  : null,
              classes: ['inverted-tooltip'],
              placement: 'top'
            }"
            v-for="vector in therm_vector_filters"
            :key="vector.class_id"
            class="py-10 is-flex align-center therm-sidebar__filters--item is-between px-10 is-pointer"
          >
            <div
              @click="update_active_class_ids(vector.class_id)"
              class="therm-sidebar__filters--box is-relative is-flex align-center"
              :style="{ borderColor: vector.color }"
            >
              <span
                class="is-inner is-absolute"
                :style="{
                  background: active_class_ids.includes(vector.class_id)
                    ? vector.color
                    : 'none'
                }"
              ></span>
              <p class="is-regular-14-500-17 therm-sidebar__item--text pl-20">
                {{
                  vector.description | truncate(vector_description_max_length)
                }}
                ({{ vector.count || 0 }})
              </p>
            </div>
            <div
              @click="
                active_class_ids = [];
                update_active_class_ids(vector.class_id);
              "
              class="is-regular-14-500-17 selector is-flex align-center is-center"
            >
              <span>1</span>
            </div>
          </div>
        </div>
      </div>
      <export-data
        class="is-absolute"
        v-show="!mini_thermsidebar && $route.name !== 'therm-v2-compare'"
      />
    </div>
  </div>
</template>
<script>
  import { mapMutations, mapState } from "vuex";
  import ProjectsList from "./therm-v2-projects-list.vue";
  import TicketFilters from "@/app/old/desktop/tickets/shared/components/filter-card/filter-dropdown.vue";
  import TemperatureDifference from "./slider.vue";
  import ExportData from "./therm-v2-export-data.vue";
  import MapsMixin from "../mixins/therm-v2.maps.mixin.vue";

  export default {
    mixins: [MapsMixin],
    props: {
      vector_description_max_length: {
        type: Number,
        default: 20,
        required: false
      }
    },
    data() {
      return {
        is_intercom_active: false,
        filtersData: {},
        tickets_filter_active: false,
        active_projects: [],
        icon: require("@/assets/icons/sprite.svg"),
        navigation_headers: [
          {
            header: "Home",
            to: { name: "home" },
            sprite: "home",
            access_key: ""
          },
          {
            header: "Map",
            to: {
              name: "therm-v2",
              id: this.$route.params.id
            },
            sprite: "map",
            access_key: ""
          },
          {
            header: "Defects",
            to: {
              name: "therm-v2-tickets",
              id: this.$route.params.id
            },
            sprite: "tickets",
            access_key: "tickets"
          },
          {
            header: "Reports",
            to: {
              name: "therm-v2-reports",
              id: this.$route.params.id
            },
            sprite: "reports",
            access_key: "reports"
          }
        ]
      };
    },
    components: {
      ProjectsList,
      TicketFilters,
      TemperatureDifference,
      ExportData
    },

    computed: {
      ...mapState("thermv2", [
        "vector_types",
        "projects",
        "map",
        "mini_thermsidebar",
        "active_layer_types",
        "defects",
        "active_filters"
      ]),
      ...mapState(["claims"]),

      // Update counts for issues displayed in the left sidebar
      therm_vector_filters() {
        if (this.$route.name === "therm-v2-compare") {
          return this.vector_types || [];
        }
        return this.vector_types
          ? this.vector_types.map(vector => {
              const vectors_with_class_id = this.defects.filter(
                defect => defect.properties.classId === vector.class_id
              ).length;
              return {
                ...vector,
                count: vectors_with_class_id
              };
            })
          : [];
      },
      sidebar_width() {
        return this.mini_thermsidebar ? 6 : 20;
      },
      is_filter_active() {
        if (!this.active_filters) return false;
        for (let filter in this.active_filters) {
          if (
            this.active_filters[filter] &&
            this.active_filters[filter].length
          ) {
            return true;
          }
        }
        return false;
      }
    },
    watch: {
      defects: {
        deep: true,
        immediate: true,
        handler() {
          // Update features on the map whenever defects are fetched
          this.set_active_vectors();
        }
      },
      active_layer_types: {
        deep: true,
        handler() {
          // Update layers for the active projects whenever layers are enabled/disabled
          this.active_projects.forEach(project => {
            this.set_active_layers(
              project,
              true,
              this.map,
              this.active_layer_types
            );
          });
        }
      },
      is_intercom_active: {
        handler() {
          if (this.is_intercom_active == false) {
            window.Intercom("hide");
          } else {
            window.Intercom("show");
          }
        }
      }
    },

    methods: {
      ...mapMutations("thermv2", [
        "SET_MINI_THERMSIDEBAR",
        "SET_ACTIVE_PROJECTS"
      ]),

      onUpdated(temperature) {
        this.filtersData["temperature_difference"] = temperature;
      },
      set_active_projects(selected_projects) {
        this.active_projects = [...this.active_projects, ...selected_projects];
        this.SET_ACTIVE_PROJECTS(this.active_projects);
        for (let project_index in selected_projects) {
          // When multiple projects are selected at once, fly to the first project in the list
          this.set_project_layers(
            selected_projects[project_index],
            project_index == 0
          );
        }
      },
      set_inactive_projects(unselected_projects) {
        this.active_projects = this.active_projects.filter(
          project => !unselected_projects.includes(project)
        );
        this.SET_ACTIVE_PROJECTS(this.active_projects);
        unselected_projects.forEach(project => {
          // Hide the layers for projects that were unselected
          this.unset_project_layers(project);
        });
      },
      update_active_class_ids(class_id) {
        if (this.active_class_ids.includes(class_id)) {
          const class_id_index = this.active_class_ids.findIndex(
            active_class_id => active_class_id === class_id
          );
          this.active_class_ids.splice(class_id_index, 1);
        } else {
          this.active_class_ids = [...this.active_class_ids, class_id];
        }
        this.set_active_vectors();
      },
      async set_project_layers(project_id, fly = true) {
        let project_details = null;

        if (this.projects[project_id]) {
          project_details = this.projects[project_id];
        } else {
          project_details = await this.fetch_project_details(project_id);
        }

        if (!this.map) return;
        if (fly) this.fly_to_project(project_details, this.map);
        // Refer the mixin
        this.setup_map_with_active_layers_for_projects(project_id);
        this.set_active_vectors();
      },
      unset_project_layers(project_id) {
        if (!this.map) return;
        this.set_active_layers(
          project_id,
          false,
          this.map,
          this.active_layer_types
        );
      },
      handle_routes(nav) {
        if (nav == "Home") return this.$route.name == "home";
        if (nav == "Defects") return this.$route.name == "therm-v2-tickets";
        if (nav == "Reports") return this.$route.name == "therm-v2-reports";
        if (nav == "Map")
          return (
            this.$route.name === "therm-v2-compare" ||
            this.$route.name === "therm-v2"
          );
      }
    }
  };
</script>
<style lang="scss">
  .therm-sidebar {
    z-index: 30;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    background-color: $dark-background;
    width: 20rem;
    transition: width 0.2s linear;

    .has-slimscroll-xs {
      &::-webkit-scrollbar {
        width: 0.3rem !important;
        height: 0.3rem !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1rem !important;
        background: rgba(250, 247, 247, 0.7) !important;
      }

      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
    .mainlogo {
      width: 13rem;
      height: 5.8rem;
      margin: 0rem auto;
    }
    &__item {
      padding-top: 0.7rem;
      padding-bottom: 0.8rem;
      margin: 0.6rem 0;
    }

    .filters-section {
      border-top: 1px solid #bbbdbe21;
      .dueDate-calendar {
        transform: translate(110%, -75%);
      }
      #filters-dropdown-tags {
        transform: translateY(-100%);
      }
    }
    .toggle-thermsidebar {
      position: absolute;
      top: 2rem;
      right: -1.5rem;
      background-color: white;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      z-index: 100;
      box-shadow: 1px 2px 15px 1px rgba(0, 0, 0, 0.2);
    }
    &__filters {
      height: 30rem;
      overflow: auto;
      padding-bottom: 25rem;
      &--box {
        height: 1.5rem;
        width: 1.5rem;
        border: 1px solid red;
        border-radius: 0.3rem;
        .is-inner {
          height: 0.8rem;
          width: 0.8rem;
          transform: translate(32%, 2%);
          border-radius: 0.2rem;
        }
      }
      &--item {
        .selector {
          height: 1.5rem;
          width: 1.5rem;
          border: 1px solid white;
          color: white;
          border-radius: 0.2rem;
          visibility: hidden;
        }
        &:hover {
          .selector {
            visibility: visible;
          }
        }
      }
    }

    &__item {
      &--text {
        color: $grey-text;
        white-space: nowrap;
      }
      // &--arrow {
      //   display: none;
      //   margin: 0;
      //   position: absolute;
      //   right: 0;
      //   top: 50%;
      // }
      // &--editOptions {
      //   position: relative;
      //   right: 0;
      //   margin: auto;
      //   img {
      //     margin: 0 1.2rem;
      //     display: none;
      //   }
      // }
    }
  }
  // .therm-sidebar__item:hover {
  //   .therm-sidebar__item--text is-medium-15-500-18 {
  //     color: $hover-white-text;
  //     cursor: pointer;
  //   }
  //   .therm-sidebar__item--editOptions {
  //     img {
  //       display: inline;
  //       cursor: pointer;
  //     }
  //   }
  // }
  .logo-mini {
    margin: 1.5rem auto;
  }

  .thermv2-filters-popup {
    // transform: scale(0.9);
    bottom: 0;
    position: fixed;

    .dropdown {
      position: relative;
    }
  }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-list-item"},[_c('div',{staticClass:"ticket-header"},[_c('div',{staticClass:"columns is-mobile has-text-centered is-normal-14-500-17 has-text-theme-grey"},[_c('div',{staticClass:"column is-1 is-flex is-between is-align-flex-start no-padding-right"},[_c('div',{staticClass:"field is-flex is-relative is-vcenter is-pointer"},[_c('input',{staticClass:"sh-custom-checkbox is-thinner",class:{
              'is-square': _vm.selected && _vm.selected.length < 25 && !_vm.type.length
            },attrs:{"type":"checkbox","name":"checkbox"},domProps:{"checked":_vm.type || _vm.selected.length},on:{"click":function($event){_vm.selected.length ? _vm.clear_selection() : _vm.select('displayed')}}}),(_vm.$route.name !== 'dashboard')?_c('span',{staticClass:"icon is-small",on:{"click":function($event){_vm.selection_dropdown = true}}},[_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.selection_dropdown)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.selection_dropdown = false)),expression:"() => (selection_dropdown = false)"}],staticClass:"is-absolute",staticStyle:{"top":"3rem","left":"2rem"}},[_c('ul',{staticClass:"card has-text-left selection-dropdown py-10"},[_c('li',{staticClass:"is-medium-14-500-17 padding-10 no-whitespace-wrap is-pointer hover-background",class:{ 'has-text-blue': _vm.type == 'all' },on:{"click":function($event){return _vm.select('all')}}},[_vm._v(" "+_vm._s(_vm.localization("app-select-all", "Select all"))+" ")]),_c('li',{staticClass:"is-medium-14-500-17 padding-10 no-whitespace-wrap is-pointer hover-background",class:{
                  'has-text-blue': _vm.type == 'displayed',
                  'bb-1': _vm.type || _vm.selected.length
                },on:{"click":function($event){return _vm.select('displayed')}}},[_vm._v(" "+_vm._s(_vm.localization("app-select-displayed", "Select displayed"))+" ")]),(_vm.type || _vm.selected.length)?_c('li',{staticClass:"is-medium-14-500-17 padding-10 no-whitespace-wrap is-pointer hover-background bt-1",on:{"click":function($event){return _vm.select(null)}}},[_vm._v(" "+_vm._s(_vm.localization("app-clear-selection", "Clear Selection"))+" ")]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"column is-3 is-flex is-vcenter",on:{"click":function($event){return _vm.update_sort_key('name')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(" "+_vm._s(_vm.localization("app-ticket-name", "Ticket name"))+" ")]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
              _vm.sorting_key === 'name' ? 'has-text-blue' : 'has-text-theme-grey'
            ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
              _vm.sorting_key === '-name'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]})])]),_c('div',{staticClass:"column is-one-fifth is-flex is-vcenter",on:{"click":function($event){return _vm.update_sort_key('takenUp')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(" "+_vm._s(_vm.localization("app-assignee", "Assignee"))+" ")]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
              _vm.sorting_key === 'takenUp'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
              _vm.sorting_key === '-takenUp'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]})])]),_c('div',{staticClass:"column is-flex is-vcenter",class:[_vm.small_viewer ? 'is-4' : 'is-2'],on:{"click":function($event){return _vm.update_sort_key('dueDate')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-due-date", "Due Date")))]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
              _vm.sorting_key == 'dueDate'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
              _vm.sorting_key == '-dueDate'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]})])]),_c('div',{staticClass:"column is-flex is-vcenter is-one-sixth",class:[_vm.small_viewer ? 'ml-20' : null],on:{"click":function($event){return _vm.update_sort_key('priority')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-priority", "Priority")))]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
              _vm.sorting_key === 'priority'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
              _vm.sorting_key === '-priority'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]})])]),(!_vm.small_viewer)?_c('div',{staticClass:"column is-flex is-vcenter is-2",on:{"click":function($event){return _vm.update_sort_key('progress')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-progress", "Progress")))]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
              _vm.sorting_key === 'progress'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
              _vm.sorting_key === '-progress'
                ? 'has-text-blue'
                : 'has-text-theme-grey'
            ]})])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.updating = false)),expression:"() => (updating = false)"},{name:"touch",rawName:"v-touch:touchhold",value:(_vm.touchhold),expression:"touchhold",arg:"touchhold"}],staticClass:"sh-comment is-flex",on:{"contextmenu":function($event){$event.preventDefault();},"click":function($event){$event.stopPropagation();}}},[_c('user-like',{attrs:{"ticket":_vm.ticket,"channel":_vm.channel,"isEditing":_vm.showEditor || (_vm.comment && _vm.comment.type === 'deleted'),"comment":_vm.comment,"reaction":_vm.reaction},on:{"send":_vm.sendReaction,"delete":_vm.deleteReaction}}),(_vm.comment && _vm.comment.type === 'deleted')?_c('div',{staticClass:"sh-comment-deleted",staticStyle:{"flex":"1","border":"2px dashed #ccc"}},[_vm._m(0)]):(!_vm.showEditor)?_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"sh-comment-box",class:this.$store.state.claims.user_id === _vm.comment.user.id
          ? 'comment-owner'
          : 'comment-not-owner',attrs:{"pinned":_vm.comment.pinned}},[_c('options',{attrs:{"ticket":_vm.ticket,"channel":_vm.channel,"isEditing":_vm.showEditor,"comment":_vm.comment,"option":_vm.option},on:{"mobileEvent":function($event){_vm.mobileEvents = $event},"reply":function($event){return _vm.$emit('reply', _vm.comment)},"edit":function($event){_vm.description = _vm.comment.text;
          _vm.updating = true;},"delete":_vm.deleteMessage,"pinned":function($event){_vm.option.pinned = true;
          _vm.updateComment({ pinned: !_vm.comment.pinned });}}}),_c('p',{staticClass:"sh-comment-text ck ck-content ck-editor__editable ck-rounded-corners ck-blurred",attrs:{"role":"textbox"},domProps:{"innerHTML":_vm._s(_vm.dataHtml)}}),(
          _vm.attachment_files.length === 0 &&
            _vm.dataHtml === '' &&
            _vm.loadingAttachment
        )?_c('div',{staticStyle:{"height":"38px"}}):(
          _vm.attachment_files.length === 0 &&
            _vm.dataHtml === '' &&
            !_vm.loadingAttachment
        )?_c('div',{staticStyle:{"color":"#aaa","border":"2px dashed #ccc","padding":"5px"}},[_vm._v(" Attachments deleted ")]):_vm._e(),(_vm.comment.id)?_c('div',{directives:[{name:"touch",rawName:"v-touch:start.stop",arg:"start",modifiers:{"stop":true}}]},[(_vm.comment.id && _vm.attachment_files && _vm.attachment_files.length > 0)?_c('attachment-list',{staticClass:"task-list-tab",attrs:{"service":_vm.service,"id":"task-tab","existing_files":_vm.attachment_files,"deselect":false,"ticket":_vm.ticket},on:{"delete_attachment":function($event){return _vm.delete_attachment($event)},"refresh_attachments":_vm.getFiles}}):_vm._e()],1):(
          _vm.comment.attachment_upload && _vm.comment.attachment_upload.length > 0
        )?_c('div',[_vm._v(" "+_vm._s(_vm.comment.attachment_upload.length)+" Files Uploaded ")]):_vm._e(),(_vm.comment.attachments)?_c('attachment',{attrs:{"comment":_vm.comment}}):_vm._e()],1),(_vm.comment.reply_count)?_c('reply-count',{attrs:{"comment":_vm.comment},on:{"clicked":function($event){return _vm.$emit('reply', _vm.comment)}}}):_vm._e()],1):_c('editor',{ref:"inlineEditor",attrs:{"ticket":_vm.ticket,"attachment_files":_vm.attachment_files,"channel":_vm.channel,"attachments":_vm.attachments,"comment":_vm.comment,"showAttachments":true && _vm.service,"isMobile":_vm.isMobile,"action":_vm.editorAction,"service":_vm.service,"presence_members":_vm.presence_members},on:{"send":_vm.sendComment,"attachment_upload":function($event){(_vm.attachments = $event.attachments), (_vm.custom_id = $event.id)}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),(_vm.showRetry)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization(
        'app-tooltip-retry-message',
        'Retry sending this message'
      ),
      html: false
    }),expression:"{\n      content: localization(\n        'app-tooltip-retry-message',\n        'Retry sending this message'\n      ),\n      html: false\n    }"}],staticClass:"sh-comments-comment--retry",on:{"click":_vm.sendZombieComment}},[_c('i',{staticClass:"fa fa-redo mr-5",staticStyle:{"font-size":"12px"},attrs:{"aria-hidden":"true"}})]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-comment-box"},[_c('p',{staticClass:"sh-comment-text",attrs:{"role":"textbox"}},[_vm._v("This comment was deleted")])])
}]

export { render, staticRenderFns }
<template>
  <div class="columns is-regular-14-00-17 user-header">
    <div class="column">{{ localization("app-assets", "Assets") }}</div>
    <div class="column">{{ localization("app-teams", "Teams") }}</div>
    <div class="column">{{ localization("app-tags", "Tags") }}</div>
    <div class="column">{{ localization("app-access", "Access") }}</div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .user-header {
    background-color: #fff;
    margin: 0;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #eeeeee;
  }
</style>

<template>
  <div class="add-item">
    <div class="add-item__header">
      <span
        class="is-flex is-vcenter add-item__header__title is-medium-18-500-22"
      >
        Generate API key
      </span>
      <div
        @click="$emit('close')"
        class="is-24x24 is-flex align-center is-center"
      >
        <i class="fa fa-times add-item__header__close" aria-hidden="true" />
      </div>
    </div>
    <div class="content">
      <a
        class="button is-info is-large"
        :class="{ 'is-loading': is_loading }"
        @click="reGenerateAPIKey"
        >Re-Generate</a
      >
      <div v-if="token" class="is-flex mt-10">
        <input
          v-focus
          v-model="token"
          class="sh-form__content__input mr-10 mt-0"
          type="text"
        />
        <button class="sh-button" @click="copyAPIKey()">
          {{ copy_button_text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { authService, userService } from "@/app/old/desktop/shared/services/";
  export default {
    components: {},
    props: [],
    data() {
      return {
        token: "",
        copy_button_text: "Copy",
        is_loading: false
      };
    },
    created() {
      this.generateAPIKey();
    },
    computed: {
      ...mapState(["claims"])
    },
    methods: {
      async generateAPIKey() {
        this.is_loading = true;
        let result = await authService.get_token({}).catch(() => {
          this.is_loading = false;
          this.$toast.show(
            "Could not generate the token",
            "",
            this.$store.state.izitoast_options.appError
          );
        });
        if (result) this.token = result.Authorization;
        this.is_loading = false;
      },
      async reGenerateAPIKey() {
        this.is_loading = true;
        let result = await userService
          .refresh_user_token({ id: this.claims.user_id })
          .catch(() => {
            this.is_loading = false;
            this.$toast.show(
              "Could not generate the token",
              "",
              this.$store.state.izitoast_options.appError
            );
          });
        this.is_loading = false;
        if (result) this.token = result.Authorization;
      },
      copyAPIKey() {
        this.$copyText(this.token).then(res => {
          this.copy_button_text = "Copied";
          setTimeout(() => {
            this.copy_button_text = "Copy";
          }, 3000);
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .content {
    padding: 2rem;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
</style>

<template>
  <div>
    <a
      class="button is-info is-large ml-10"
      :class="{ 'is-loading': is_loading }"
      @click="
        s3_url = '';
        isCardModalActive = true;
      "
      >+ New sync</a
    >
    <b-modal
      class="location-modal"
      :active.sync="isCardModalActive"
      :width="600"
    >
      <div class="card">
        <div class="card-header px-10 py-10">
          <div class="card-header-title">New sync</div>
        </div>
        <div class="card-content">
          <div>S3 URL</div>
          <input
            v-model="s3_url"
            class="sh-form__content__input"
            style="margin-top:10px;"
            type="url"
            placeholder="Enter s3 url"
          />
          <div style="margin-top:20px">Image scale</div>
          <select
            v-model="scale"
            type="number"
            class="sh-form__content__input"
            style="margin-top:10px;padding: 10px;"
            placeholder="Select image scale"
          >
            <option value=".1">10%</option>
            <option value=".2">20%</option>
            <option value=".3">30%</option>
            <option value=".4">40%</option>
            <option value=".5">50%</option>
            <option value=".6">60%</option>
            <option value=".7">70%</option>
            <option value=".8">80%</option>
            <option value=".9">90%</option>
            <option value="1">100%</option>
          </select>
        </div>

        <div class="card-footer px-20 py-20">
          <a @click="isCardModalActive = false" class="button mr-10 is-large">{{
            localization("app-cancel", "Cancel")
          }}</a>
          <a
            class="button is-info is-large"
            :class="{ 'is-loading': is_loading }"
            @click="submit"
            :disabled="!s3_url"
            >{{ localization("app-submit", "Submit") }}</a
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { projectService } from "@/app/old/desktop/shared/services/";
  export default {
    props: ["project_details"],
    data() {
      return {
        is_loading: false,
        s3_url: "",
        scale: 1,
        isCardModalActive: false
      };
    },
    methods: {
      async submit() {
        try {
          this.is_loading = true;
          await projectService.project_upload_path({
            id: this.$route.params.id,
            body: {
              s3_url: this.s3_url,
              scale: Number(this.scale)
            }
          });
          this.$emit("update_details");
          this.is_loading = false;
          this.isCardModalActive = false;
        } catch (err) {
          this.is_loading = false;
          if (err?.response?.data?.message) {
            this.$toast.show(
              err.response.data.message,
              "",
              this.$store.state.izitoast_options.appWarning
            );
          } else {
            this.$toast.show(
              "SERVER ERROR",
              "",
              this.$store.state.izitoast_options.appWarning
            );
          }
        }
      }
    }
  };
</script>

<style></style>

<template>
  <div
    class="columns is-gapless is-vcentered file-version py-10"
    :class="{
      'pl-20 pr-20': is_viewer_small,
      'active-background':
        info_tab.active &&
        info_tab.type == 'file' &&
        info_tab.file &&
        info_tab.file.uid == item.uid
    }"
    style="margin:0;"
  >
    <div
      v-if="!is_viewer_small"
      class="column is-1 is-flex"
      style="flex:0 0 40px;margin-right:13px;"
    >
      <div class="is-flex is-center align-center actions">
        <template v-if="!$route.query.recents">
          <!-- :class="{'is-disabled':file.passwordProtected}" -->
          <input
            type="checkbox"
            class="sh-custom-checkbox is-thinner ml-15"
            :id="item.uid"
            v-model="selected"
            :value="item.uid"
          />
        </template>
      </div>
    </div>
    <div
      class="column"
      :class="[
        is_viewer_small
          ? 'is-12'
          : info_tab.active || compact_view
          ? 'is-4'
          : 'is-2'
      ]"
    >
      <div class="is-flex align-center">
        <div
          class="file-thumbnail"
          style="width:100%"
          :class="[!is_viewer_small ? 'align-center' : 'align-start']"
        >
          <div
            @click="
              $eventHub.$emit('init-pdftron', {
                ...item,
                has_original_uid: original_uid
              })
            "
            class="file-thumbnail-icon mt-3 is-relative is-flex align-center"
          >
            <div class="is-pointer file-version-overlay">
              <sh-icon
                style="opacity:1"
                :class="'is-20x20 file-version-view-eye'"
                :file="'tickets_sprite'"
                :name="'attachment-eye'"
              ></sh-icon>
            </div>

            <img
              v-if="item.thumbnailSmall"
              class="image is-36x36"
              :src="item.thumbnailSmall"
            />
            <img
              v-else
              class="file-type-icon"
              :src="
                require('@/assets/file-type-icons/' +
                  prettyFileIcons.getIcon(item.name) +
                  '.svg')
              "
            />
          </div>
          <div class="file-thumbnail-details" style="width:100%">
            <div
              class="is-medium-16-500-19 has-text-theme-black is-flex align-center is-between"
              :class="{ 'mb-5': !is_viewer_small }"
              v-if="item.name"
            >
              <template v-if="is_edit === i">
                <input
                  class="is-fullwidth input is-medium-14-500-17"
                  v-focus
                  v-click-outside="() => (is_edit = -1)"
                  v-model="form.name"
                  @keyup.enter="save(item)"
                  type="text"
                />
              </template>
              <template v-else>
                <!-- @click="SET_INFO_TAB({ file: item, type: 'file', active: true, tab: 'info' })" -->
                <p class="is-inline-flex mb-3 align-center">
                  <span
                    class="file-version-name is-inline"
                    v-tooltip="
                      info_tab.active || is_viewer_small || compact_view
                        ? item.fileName.length > 15
                          ? item.fileName
                          : null
                        : item.fileName.length > 10
                        ? item.fileName
                        : null
                    "
                    >{{
                      item.fileName
                        | truncate(
                          info_tab.active || is_viewer_small || compact_view
                            ? 15
                            : 10
                        )
                    }}</span
                  >
                  <span class="file-version-download is-flex">
                    <a @click.stop="download(item)">
                      <sh-icon
                        class="mr-10 ml-10 is-pointer is-14x14"
                        :file="'tickets_sprite'"
                        :name="'download-blue'"
                      ></sh-icon>
                    </a>
                  </span>
                </p>
                <p class="is-medium-12-500-14 has-text-grey">{{ date }}</p>
              </template>
            </div>
            <div class="file-thumbnail-details-extra is-flex is-between">
              <div class="is-flex align-center">
                <div class="file-thumbnail-details-extra-size">
                  {{ $prettysize(item.fileSize || 0) }}
                </div>
                <img
                  class="ml-10"
                  style="height:18px"
                  src="@/assets/icons/seperate.svg"
                  alt
                />
                <img
                  v-if="item.shareCount"
                  class="ml-5 mr-5"
                  style="height:18px"
                  src="@/assets/icons/seperate.svg"
                  alt
                />
                <div
                  v-if="item.shareCount"
                  class="file-thumbnail-details-extra-share"
                >
                  <svg class="image is-18x18 mr-5" viewBox="0 0 100 100">
                    <use xlink:href="@/assets/icons/vault_sprite.svg#share" />
                  </svg>
                  {{ item.shareCount }}
                  sharing
                </div>
                <div class="is-flex align-center ml-10">
                  <user-avatar
                    v-if="item.createdBy"
                    :user="item.createdBy"
                    :pxsize="20"
                    class="mr-5"
                    :avatarfontsize="12"
                    :custom_class="'tiny-user-avatar'"
                  ></user-avatar>
                  <p
                    class="is-medium-12-500-14 has-text-blue-grey is-width-10"
                    v-username.truncate="item.createdBy"
                  ></p>
                </div>
              </div>
              <p
                v-if="item.uid !== default_uid"
                class="file-version-mark-active has-text-blue is-medium-12-500-14"
                @click="set_active_version"
              >
                {{ localization("app-mark-as-active", "Mark as Active") }}
              </p>
              <p v-else class="has-text-grey is-medium-12-500-14">
                {{ localization("app-active", "Active") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import prettyFileIcons from "pretty-file-icons";

  import { mapMutations, mapState, mapGetters } from "vuex";
  import {
    folderService,
    fileService
  } from "@/app/old/desktop/shared/services/";
  import TypeTag from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import vaultMixin from "@/app/old/desktop/vault/shared/mixins/vault.mixin";
  import AskPassword from "@/app/old/desktop/vault/components/ask-password-2";
  import UserDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";
  import FileMixin from "@/app/old/desktop/vault/mixin/file-mixin.vue";

  export default {
    mixins: [vaultMixin, FileMixin],
    props: ["item", "i", "is_viewer_small", "original_uid", "default_uid"],
    components: {
      UserTag,
      TypeTag,
      UserDropdown
    },
    data() {
      return {
        prettyFileIcons,
        date: moment(moment.utc(this.item.createdAt).toDate())
          .local()
          .format("lll")
      };
    },
    created() {
      this.item.fileName = this.item.version
        ? this.item.version
        : this.item.name;
    },
    methods: {
      ...mapMutations("vault", ["UPDATE_FILE_DETAILS"]),
      async set_active_version() {
        let res = await fileService.set_active_version({
          id: this.original_uid,
          version: this.item.uid
        });
        if (res) this.UPDATE_FILE_DETAILS({ uid: this.original_uid, ...res });
        // this.$eventHub.$emit("refetch-folder-data");
      }
      // download(file) {
      //   fileService
      //     .get_version_file_download_url({
      //       id: this.original_uid,
      //       version: file.uid,
      //       headers: this.get_password_header(file.rootFolder),
      //     })
      //     .then((res) => {
      //       window.open(res.data, "_blank");
      //     });
      // },
    }
  };
</script>

<style lang="scss">
  .mb-3 {
    margin-bottom: 3px;
  }
  .mt-3 {
    margin-top: 3px;
  }
  .file-version {
    border-bottom: 1px solid #eee;
    background-color: white;
    color: #2585d3;
    &:hover {
      cursor: pointer;
      box-shadow: 0 1px 0 0 rgba(92, 126, 247, 0.3);
      background-color: #f5f7fc;
    }
    &.active-background {
      background-color: #f5f7fc;
    }
    &:hover .file-version-overlay {
      opacity: 0.5;
    }

    &:hover .file-version-name {
      color: $primary;
    }
    .file-version-download {
      visibility: hidden;
    }
    &:hover .file-version-download {
      visibility: visible;
    }
    .file-thumbnail .file-version-mark-active {
      visibility: hidden;
    }
    &:hover .file-thumbnail .file-version-mark-active {
      visibility: visible;
    }

    .download-is-hidden {
      margin-left: 1rem;
      width: 32px;
      height: 32px;
      border-radius: 100px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      visibility: hidden;
    }
    &:hover .download-is-hidden {
      visibility: visible;
    }
  }
  .file-thumbnail {
    display: flex;
    // align-items: center;
    &-icon {
      width: 36px;
      height: 36px;
      object-fit: contain;
      margin-right: 1rem;
      display: flex;
      .file-version-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 5;
        transition: 0.1s ease;
        background: rgba(23, 30, 72, 1);
        border-radius: 4px;
      }
      .file-version-view-eye {
        z-index: 10;
        color: white;
        font-size: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
      img {
        border-radius: 4px;
      }
    }
    .input {
      border: 0px;
      border-bottom: 1px dashed rgba(23, 30, 72, 0.2);
    }
    .input:focus {
      border: 0px !important;
      border-bottom: 1px dashed rgba(23, 30, 72, 0.2) !important;
    }
    &-details-archive {
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(23, 30, 72, 0.2);
      border: solid 0.7px #707590;
      color: #747891;
      padding: 0.2rem 0.5rem;
      background-color: white;
    }
    &-details-name {
      line-height: 1.19;
      text-align: left;
      color: #171e48;
    }
    &-details-extra {
      display: flex;
      &-size,
      &-upload,
      &-files,
      &-share {
        white-space: nowrap;
        display: flex;
        align-items: center;
        // opacity: 0.6;
        font-family: Barlow-Regular;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #171e48;
      }
      &-upload {
        padding-left: 4px;
        padding-right: 6px;
      }
      &-markactive {
        visibility: hidden;
      }
    }
  }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-item",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"add-item__header"},[_c('span',{staticClass:"is-flex is-vcenter add-item__header__title is-medium-18-500-22",class:{ 'is-medium-16-500-19': _vm.isMobile }},[_vm._v(" "+_vm._s(_vm.localization("app-add-teams", "Add Teams"))+" ")]),_c('div',{staticClass:"is-24x24 is-flex align-center is-center",on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"fa fa-times add-item__header__close",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"add-item__content__select",class:{ forms__content__select: _vm.isForms }},[_c('img',{staticClass:"mr-10",attrs:{"src":require('@/assets/icons/form-search-icon.svg')}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"add-item__content__select__text is-medium-16-500-19",attrs:{"type":"text","placeholder":_vm.localization('app-search-teams', 'Search teams')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_c('div',{staticClass:"add-item__content__heading mb-0",class:{ forms__content__heading: _vm.isForms }},[_c('div',{staticClass:"mr-auto is-regular-14-00-17",class:[_vm.isMobile ? 'ml-40' : 'ml-45']},[_vm._v(" "+_vm._s(_vm.localization("app-teams", "Teams"))+" ")]),(!_vm.remove_access && !_vm.isForms)?[_c('div',{staticClass:"is-regular-14-00-17"},[_vm._v(" "+_vm._s(_vm.localization("app-read", "Read"))+" ")]),_c('div',{staticClass:"is-regular-14-00-17"},[_vm._v(" "+_vm._s(_vm.localization("app-write", "Write"))+" ")])]:_vm._e(),(_vm.isForms)?[_c('div',{staticClass:"is-regular-14-00-17"},[_vm._v(" "+_vm._s(_vm.localization("app-access", "Access"))+" ")])]:_vm._e()],2),_c('div',{staticClass:"has-slimscroll",class:[
      { 'forms__content pr-25': _vm.isForms },
      { 'add-item__content': !_vm.isForms }
    ]},[_c('div',{class:[{ 'add-item__content__top': !_vm.isMobile }],staticStyle:{"padding-top":"0.7rem"}},[_vm._l((_vm.grouped_teams),function(group,i){return _c('div',{key:i,class:[_vm.isForms ? 'bb-1' : 'pt-20']},[(group.length)?_c('h1',{staticClass:"is-medium-14-500-17 is-flex is-vcenter ml-10",class:{ 'bb-1 py-10': _vm.isForms }},[(group[0].asset_id)?[_c('img',{staticClass:"mr-7",attrs:{"src":require("@/assets/icons/asset-icon-1.svg")}}),_vm._v(" "+_vm._s(_vm.assets_hash[group[0].asset_id] && _vm.assets_hash[group[0].asset_id].name)+" ")]:[_c('sh-icon',{staticClass:"is-20x20 mr-7",attrs:{"name":`organizations`,"file":'admin_sprite'}}),_vm._v(" "+_vm._s(_vm.$store.state.current_organization.name)+" ")]],2):_c('h1',{staticClass:"is-medium-14-500-17 is-flex is-vcenter ml-15",class:{ ' bb-1 pb-10': _vm.isForms }},[_c('img',{staticClass:"mr-7",attrs:{"src":require("@/assets/icons/asset-icon-1.svg")}}),_vm._v(" "+_vm._s(_vm.assets_hash[_vm.asset_id] && _vm.assets_hash[_vm.asset_id].name)+" ")]),(!_vm.isForms)?_c('div',{staticStyle:{"height":"0","margin":"10px 10px 0","opacity":"0.1","border":"solid 1px #171e48"}}):_vm._e(),(!_vm.isMobile)?_c('add-team',{attrs:{"asset_id":group.length ? group[0].asset_id : _vm.asset_id,"isForms":_vm.isForms}}):_vm._e(),_vm._l((_vm.sorted(group)),function(team,i){return _c('div',{key:i},[_c('div',{staticClass:"add-item__content__top__row"},[(_vm.existing_uids.includes(team.uid))?_c('i',{staticClass:"fa fa-times add-item__content__top__row__cancel mr-5",on:{"click":function($event){return _vm.remove_from_existing(team.uid)}}}):_c('div',{staticClass:"add-item__content__bottom__row__b-checkbox"},[_c('sh-icon',{staticClass:"is-20x20 is-pointer",attrs:{"name":`${
                    _vm.selected[team.uid]
                      ? 'share-checkbox-selected'
                      : 'share-checkbox'
                  }`,"file":'forms-sprite'},nativeOn:{"click":function($event){return _vm.toggle(team)}}})],1),_c('div',{staticClass:"add-item__content__top__row__main"},[(
                  _vm.$store.getters.state_teams[team.uid] &&
                    _vm.$store.getters.state_teams[team.uid].properties &&
                    _vm.$store.getters.state_teams[team.uid].properties.icon
                )?_c('div',{staticClass:"image is-24x24 mr-10",staticStyle:{"flex-shrink":"0"}},[_c('img',{staticClass:"image is-24x24 is-rounded",attrs:{"src":_vm.$store.getters.state_teams[team.uid].properties.icon}})]):_c('div',{staticClass:"add-item__content__top__row__main__icon",staticStyle:{"flex-shrink":"0"},style:(`background-color: ${
                    _vm.$store.getters.state_teams[team.uid] &&
                    _vm.$store.getters.state_teams[team.uid].properties &&
                    _vm.$store.getters.state_teams[team.uid].properties.color
                      ? _vm.$store.getters.state_teams[team.uid].properties.color
                      : _vm.hashed_color(
                          _vm.$store.getters.state_teams[team.uid]
                            ? _vm.$store.getters.state_teams[team.uid].name
                            : team.uid,
                          'userManagement'
                        )
                  }`)},[_vm._v(" "+_vm._s(_vm.$store.getters.state_teams[team.uid] ? _vm.$store.getters.state_teams[team.uid].name .charAt(0) .toUpperCase() : team.uid.charAt(0).toUpperCase())+" ")]),_c('div',{staticClass:"add-item__content__top__row__main__name is-inline-flex is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.$store.getters.state_teams[team.uid] ? _vm.$store.getters.state_teams[team.uid].name : team.uid)+" "),(!_vm.isMobile)?_c('a',{staticClass:"ml-15 add-item__content__top__row-add-user-button show-all",on:{"click":function($event){_vm.is_user_list === team.uid
                      ? (_vm.is_user_list = null)
                      : (_vm.is_user_list = team.uid)}}},[_vm._v(_vm._s(_vm.localization("app-show-users", "Show Users")))]):_vm._e()])]),(!_vm.remove_access && !_vm.isForms)?[_c('span',{staticClass:"sh-access-tag is-pointer",class:{
                  'sh-access-tag--read':
                    _vm.selected[team.uid] &&
                    (_vm.selected[team.uid].access === 'read' ||
                      _vm.selected[team.uid].access === 'write')
                },on:{"click":function($event){_vm.selected[team.uid] && _vm.selected[team.uid].access === 'read'
                    ? _vm.remove_from_existing(team.uid)
                    : _vm.change_access(team, 'read')}}},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"sh-access-tag is-pointer",class:{
                  'sh-access-tag--write':
                    _vm.selected[team.uid] &&
                    _vm.selected[team.uid].access === 'write'
                },on:{"click":function($event){_vm.selected[team.uid] && _vm.selected[team.uid].access === 'write'
                    ? _vm.change_access(team, 'read')
                    : _vm.change_access(team, 'write')}}},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})])]:_vm._e(),(_vm.isForms)?_c('access-forms-dropdown',{attrs:{"data":team,"selected":_vm.selected},on:{"update-access":function($event){_vm.change_access(team, $event);
                _vm.update();}}}):_vm._e()],2),(!_vm.isMobile && _vm.is_user_list === team.uid)?_c('user-list',{attrs:{"team_id":team.uid}}):_vm._e()],1)})],2)}),_c('a',{staticClass:"show-all",on:{"click":function($event){_vm.show_all = !_vm.show_all}}},[_vm._v(_vm._s(_vm.show_all ? _vm.localization("app-show-less", "Show less") : _vm.localization("app-show-all", "Show all")))])],2)]),(_vm.isMobile)?_c('div',{staticClass:"add-item__footer mobile-footer"},[(_vm.is_saving)?_c('div',{staticClass:"add-item__footer__full-button button is-pulled-right"},[_c('i',{staticClass:"fa fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"add-item__footer__full-button button has-text-white is-pulled-right is-medium-16-500-19",on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.localization("app-add-teams", "Add Teams"))+" ")])]):_c('div',{staticClass:"add-item__footer"},[(_vm.is_saving)?_c('div',{staticClass:"add-item__footer__button button is-pulled-right"},[_c('i',{staticClass:"fa fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"add-item__footer__button button has-text-white is-pulled-right is-medium-16-500-19",on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.localization("app-add-teams", "Add Teams"))+" ")]),_c('div',{staticClass:"add-item__footer__button__cancel button is-pulled-right is-medium-16-500-19 mr-10",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.localization("app-cancel", "Cancel"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sh-comment-section",on:{"dragenter":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dragFile.apply(null, arguments)}}},[(_vm.channel && !_vm.dataError)?_c('div',[(
        !(_vm.start_conversation || _vm.small) &&
        !_vm.commentsLoading &&
        !(_vm.channel && _vm.channel.state.messages && _vm.channel.state.messages.length)
      )?_c('div',{staticClass:"no-result"},[_c('img',{attrs:{"src":require("@/assets/illustrations/no-comments-illustration.svg"),"alt":""}}),_c('div',{staticClass:"has-text-centered"},[_c('div',{staticClass:"mt-10"},[_c('p',{staticClass:"sh-text-normal-22 mb-10"},[_vm._v(" "+_vm._s(_vm.localization("app-no-comments", "No comments"))+" ")]),_c('p',{staticStyle:{"width":"50rem","font-size":"1.4rem"}},[_vm._v(" "+_vm._s(_vm.localization( "app-text-start-conversation", `Conversation help in better collaboration and getting instant feedback with realtime updates. You can @mention other users and attach files to a comment` ))+" ")])]),_c('div',{staticClass:"sh-button ml-auto mr-auto mt-20",staticStyle:{"width":"20rem"},on:{"click":function($event){_vm.start_conversation = true;
            _vm.scrollPageToBottom();}}},[_c('p',{staticClass:"is-flex"},[_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'comments-white'}}),_c('span',{staticClass:"is-regular-16-00-20"},[_vm._v(_vm._s(_vm.localization("app-start-a-conversation", "Start a conversation")))])],1)])])]):_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.threads),expression:"!threads"}],staticClass:"sh-comment_scroll--top",on:{"click":_vm.scrollToTop}},[_vm._v(" Scroll to top "),_c('i',{staticClass:"fas fa-arrow-up"})]),_c('div',{staticClass:"sh-comment-header-section",staticStyle:{"height":"auto","overflow":"visible"}},[_c('comments-header',{staticClass:"comments-header",attrs:{"dataUid":_vm.dataUid},on:{"filter":_vm.search,"clearFilter":function($event){_vm.dataUid ? null : (_vm.filters = null);
            _vm.dataUid ? _vm.search() : (_vm.showFilter = false);
            _vm.searchData = null;
            _vm.scrollToBottomComments();}}})],1),_c('div',{staticClass:"sh-comments-div"},[_c('div',{ref:"comment",staticClass:"sh-comments-all has-slimscroll-xs",class:_vm.threads ? 'open' : ''},[_c('div',{staticClass:"sh-comment-only"},[(_vm.commentsLoading)?_c('div',{staticClass:"sh-comments-loader"},[_c('img',{staticClass:"image is-64x64 margin-auto",attrs:{"src":require("@/assets/icons/ellipsis-animation.svg")}})]):_c('div',[_vm._l((_vm.channelData),function(comment){return _c('comment',{key:comment.id,attrs:{"presence_members":_vm.presence_members,"comment":comment,"service":_vm.service,"ticket":_vm.ticket,"files":_vm.existing_files,"channel":_vm.channel,"dataUid":_vm.dataUid,"small":_vm.small,"zombie":false},on:{"reply":_vm.getReplies}})}),_vm._l((_vm.tempComments),function(comment){return _c('comment',{key:comment,staticClass:"sh-zombie-comment",attrs:{"comment":comment,"small":_vm.small,"service":_vm.service,"ticket":_vm.ticket,"files":_vm.existing_files,"channel":_vm.channel,"dataUid":_vm.dataUid,"zombie":true}})})],2)])]),_c('footer',{staticClass:"comment-regular-editor"},[(!_vm.threads)?_c('comment',{ref:"commentEditor",attrs:{"isEditing":"true","presence_members":_vm.presence_members,"ticket":_vm.ticket,"channel":_vm.channel,"small":_vm.small,"service":_vm.service,"dataUid":_vm.dataUid,"addAttachments":_vm.addAttachments},on:{"sendMessage":_vm.pushToTemp,"messageSent":_vm.popFromTemp}}):_vm._e()],1),(_vm.threads)?_c('div',{staticClass:"sh-comments-threads"},[_c('div',{staticClass:"is-flex align-center is-between padding-20"},[_c('div',{staticClass:"is-medium-18-500-22"},[_vm._v("Thread")]),_c('div',{on:{"click":function($event){_vm.threads = null;
                _vm.replies = [];}}},[_c('sh-icon',{staticClass:"is-pointer is-18x18",attrs:{"name":'close'}})],1)]),_c('div',{ref:"thread",staticClass:"has-slimscroll-xs sh-threads-div--scroll",staticStyle:{"flex":"1","overflow":"auto"}},[_c('reply',{staticClass:"pb-10",staticStyle:{"pointer-events":"none"},attrs:{"comment":_vm.threads,"ticket":_vm.ticket,"channel":_vm.channel}}),_vm._l((_vm.replyData),function(comment){return _c('reply',{key:comment.id,attrs:{"comment":comment,"ticket":_vm.ticket,"presence_members":_vm.presence_members,"parent":_vm.threads,"zombie":false,"channel":_vm.channel},on:{"reply-added":function($event){return _vm.getReplies($event, true)}}})}),(
                this.offlineComments &&
                this.offlineComments[_vm.threads.id] &&
                this.offlineComments[_vm.threads.id].reply
              )?_c('div',_vm._l((this.offlineComments[_vm.threads.id].reply),function(comment){return _c('reply',{key:comment.id,staticClass:"sh-zombie-comment",attrs:{"comment":comment,"ticket":_vm.ticket,"channel":_vm.channel,"zombie":true}})}),1):_vm._e()],2),(_vm.threadsLoading)?_c('div',{staticStyle:{"padding":"20px"}},[_c('loader')],1):_vm._e(),_c('reply',{attrs:{"isEditing":"true","parent":_vm.threads,"comment":_vm.threads,"presence_members":_vm.presence_members,"ticket":_vm.ticket,"channel":_vm.channel},on:{"reply-added":_vm.getReplies,"add-reply":_vm.addOfflineReply}})],1):_vm._e()])])]):(_vm.channel)?_c('div',{staticClass:"sh-comment-error"},[_c('b',[_vm._v("Unable to connect to servers")]),_c('b',[_vm._v("Please check if you are online and retry")]),_c('div',{staticClass:"btn button mt-20",on:{"click":_vm.init}},[_c('i',{staticClass:"fa fa-redo mr-5",staticStyle:{"font-size":"12px"},attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.small ? _vm.init() : "Retry.")+" ")])]):_c('div',{staticClass:"sh-comment-error"},[(_vm.loading)?_c('div',{staticClass:"sh-comments-loader"},[_c('img',{staticClass:"image is-64x64 margin-auto",attrs:{"src":require("@/assets/icons/ellipsis-animation.svg")}})]):_c('div',[_c('b',[_vm._v("Ooops!, Chat server is not initialised")]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br'),_vm._v("Refresh the page and try again "),_c('br'),_vm._v("OR "),_c('br'),_vm._v("Contact support. ")])
}]

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.active = false)),expression:"() => (active = false)"}],staticClass:"vault-filters-groupby"},[_c('span',{staticClass:"heading-1"},[_vm._v(" "+_vm._s(_vm.localization("app-shared", "Shared"))+":")]),_c('div',{staticClass:"dropdown",class:{ 'is-active': _vm.active }},[_c('div',{staticClass:"dropdown-trigger",on:{"click":function($event){_vm.active = !_vm.active}}},[_c('span',{staticClass:"heading-2"},[_vm._v(_vm._s(_vm.selected ? _vm.custom_dates : _vm.localization( `app-${(_vm.selected_filter || "") .split(" ") .join("-") .toLowerCase()}`, _vm.selected_filter )))]),_vm._m(0)]),_c('div',{staticClass:"dropdown-menu",attrs:{"role":"menu"}},[_c('div',{staticClass:"dropdown-content pb-15"},[_c('div',{staticClass:"dropdown-header padding-10"},[_c('span',{staticClass:"is-medium-16-500-19 has-opacity-5 ml-10 pt-10"},[_vm._v(" "+_vm._s(_vm.localization("app-shared", "Shared"))+" ")])]),_vm._l((_vm.data),function(item,i){return _c('a',{key:i,staticClass:"dropdown-item px-20",class:{ 'is-disabled': !item.enable },on:{"click":function($event){_vm.set_time(item.label);
            _vm.active = false;}}},[_c('span',[_vm._v(_vm._s(_vm.localization( `app-${item.label .split(" ") .join("-") .toLowerCase()}`, item.label )))]),(_vm.selected_filter == item.label)?_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'tick'}}):_vm._e()],1)}),_c('p',{staticClass:"ml-10"},[_c('duedate-dropdown',{attrs:{"icon_class":'is-14x14',"due":_vm.startDate && _vm.dueDate
                ? [new Date(_vm.startDate), new Date(_vm.dueDate)]
                : _vm.dueDate
                ? [new Date(_vm.dueDate)]
                : []},on:{"change-duedate":function($event){_vm.selected = $event;
              _vm.set_custom_time($event);}}})],1),_c('p',{staticClass:"filter-dropdown-bottom",on:{"click":function($event){_vm.selected = null;
            _vm.selected_filter = 'All Time';}}},[_c('span',{staticClass:"is-pointer is-flex align-center",on:{"click":function($event){return _vm.clear_filters()}}},[_c('sh-icon',{staticClass:"is-12x12 mr-10",attrs:{"name":'ticket-filter-clear-all'}}),_c('span',[_vm._v(_vm._s(_vm.localization("app-clear-all", "Clear All")))])],1)])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }
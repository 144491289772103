<template>
  <div>
    <p class="is-semiBold-24-600-106 mt-40 mb-30">
      {{ localization("app-vault", "Vault") }}
    </p>

    <div class="columns is-multiline">
      <div class="column is-half">
        <span class="ticket-detail-heading mb-5">{{
          localization("app-users", "Users")
        }}</span>

        <div
          class="is-flex is-vcenter"
          v-if="
            (vault.readUsers && vault.readUsers.length) ||
              (vault.writeUsers && vault.writeUsers.length)
          "
        >
          <user-tag
            :custom_class="'tiny-user-avatar '"
            :custom_size="24"
            :users="[...(vault.readUsers || []), ...(vault.writeUsers || [])]"
            :tagsCountToShow="5"
            :no_access="true"
            :is_precense_component="true"
            @handle_click="add_vault_users"
          ></user-tag>
          <sh-icon
            :name="'add-users-teams'"
            class="is-22x22 ml-5 is-pointer add-user-takenUp"
            v-tooltip="{
              content: localization(
                'app-add-users-to-this-ticket',
                'Add users to this ticket'
              )
            }"
            @click.native="add_vault_users"
          />
        </div>
        <div
          class="is-flex is-vcenter is-pointer"
          v-else
          @click="add_vault_users"
        >
          <sh-icon :name="'add-users-2'" class="mr-7 is-24x24" />
          <span
            class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
            >{{ localization("app-add-users", "Add Users") }}</span
          >
        </div>
      </div>

      <div class="column is-half ">
        <span class="ticket-detail-heading is-flex is-vcenter">
          <span class="mr-5">{{ localization("app-teams", "Teams") }}</span>
          <sh-icon
            :name="'ticket-details-info'"
            class="is-14x14 is-question"
            v-tooltip="{
              content: localization(
                'app-tooltip-parent-team-member-access',
                'All members of the parent teams will also be given the same level of access'
              )
            }"
          />
        </span>

        <div
          class="is-flex is-vcenter"
          v-if="
            (vault.readLabels && vault.readLabels.length) ||
              (vault.writeLabels && vault.writeLabels.length)
          "
        >
          <primary-tags
            :tags="get_teams(vault)"
            @clicked_tag="add_vault_teams"
            :toShowCount="3"
            :no_access="true"
            :type="'team'"
            :no_wrap="true"
            :truncate_length="10"
          />
          <sh-icon
            :name="'add-users-teams'"
            class="show-on-hover is-22x22 ml-5 is-pointer add-user-takenUp"
            @click.native="add_vault_teams"
          />
        </div>
        <div
          class="is-flex is-vcenter is-pointer"
          @click="add_vault_teams"
          v-else
        >
          <span class="ticket-detail-heading is-flex is-vcenter">
            <sh-icon :name="'add-teams'" class="mr-7 is-24x24" />
            <span
              class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
              >{{ localization("app-add-teams", "Add Teams") }}</span
            >
          </span>
        </div>
      </div>
      <div class="column is-half mb-10 is-flex is-vcenter">
        <span class="ticket-detail-heading ">
          <span class="mr-5">{{ localization("app-tags", "Tags") }}</span>
        </span>
        <tags-dropdown
          :complete="add_vault_tags"
          :tags="vault.tags || []"
          :display_on_hover="false"
          :resourceId="123"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import TagsDropdown from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import AddUsers from "@/app/old/desktop/shared/components/add-users-modal";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  export default {
    props: ["config", "asset_id"],
    data() {
      return {
        vault: {
          tags: [],
          readUsers: [],
          writeUsers: [],
          readLabels: [],
          writeLabels: []
        }
      };
    },
    components: {
      UserTag,
      AddTeams,
      TagsDropdown,
      AddUsers,
      PrimaryTags
    },

    created() {
      if (this.config) {
        this.vault.tags = this.config.tags || [];
        this.vault.readUsers = this.config.readUsers || [];
        this.vault.writeUsers = this.config.writeUsers || [];
        this.vault.readLabels = this.config.readLabels || [];
        this.vault.writeLabels = this.config.writeLabels || [];
      }
    },
    methods: {
      add_vault_tags(tag) {
        this.vault.tags.push(tag);
        this.$emit("update", { vault: this.vault });
      },
      add_vault_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [
              ...(this.vault.readLabels || []).map(u => ({
                uid: u,
                access: "read"
              })),
              ...(this.vault.writeLabels || []).map(u => ({
                uid: u,
                access: "write"
              }))
            ],
            asset_id: this.asset_id,
            complete: async e => {
              this.vault.readLabels = e
                .filter(u => u.access === "read")
                .map(u => u.uid);
              this.vault.writeLabels = e
                .filter(u => u.access === "write")
                .map(u => u.uid);
              this.$emit("update", { vault: this.vault });
            }
          },
          { height: "80%" }
        );
      },
      add_vault_users() {
        this.$modal.show(
          AddUsers,
          {
            users: [
              ...(this.vault.readUsers || []).map(u => ({
                ...u,
                access: "read"
              })),
              ...(this.vault.writeUsers || []).map(u => ({
                ...u,
                access: "write"
              }))
            ],
            complete: e => {
              this.vault.readUsers = e.filter(u => u.access === "read");
              this.vault.writeUsers = e.filter(u => u.access === "write");

              this.$emit("update", { vault: this.vault });
            }
          },
          { height: "80%" },
          { name: "add-vault-users" }
        );
      }
    }
  };
</script>

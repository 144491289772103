var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-center is-relative mb-40"},[(_vm.activities.length)?_c('div',{staticClass:"overall-activity mt-40"},[_vm._l((_vm.activities),function(activities_data,i){return _c('div',{key:i,staticClass:"mt-20 activity-group has-background-white is-center"},[_c('div',{staticClass:"activity-header has-background-white pt-20 pb-15 pl-25 pr-20",staticStyle:{"border-bottom":"1px solid #dde"}},[_c('div',{staticClass:"is-flex align-center has-space-between"},[_c('div',{staticClass:"is-medium-14-500-17"},[(activities_data.group.includes('folder'))?_c('div',{staticClass:"is-inline-flex mt-5 align-center",class:{ 'is-pointer': !_vm.item_deleted(activities_data) },on:{"click":function($event){return _vm.redirect_user_to_folder(activities_data)}}},[_c('sh-icon',{staticClass:"image is-20x20",attrs:{"file":'vault_sprite',"name":'add-folder'}}),_c('p',{staticClass:"ml-5 is-medium-18-500-22"},[_vm._v(" "+_vm._s(activities_data.activities[0].object.data.name)+" ")])],1):_c('div',{staticClass:"is-flex align-center mt-5"},[_c('div',{staticClass:"file-thumbnail is-relative is-flex"},[(!_vm.item_deleted(activities_data))?_c('div',{staticClass:"is-pointer thumbnail-overlay",on:{"click":function($event){_vm.$eventHub.$emit(
                      'init-pdftron',
                      _vm.vault_file(activities_data.activities[0].object.id)
                        ? _vm.vault_file(activities_data.activities[0].object.id)
                        : {
                            uid: activities_data.activities[0].object.id,
                            ...activities_data.activities[0].object.data
                          }
                    )}}},[_c('sh-icon',{class:'is-20x20 view-eye',attrs:{"file":'tickets_sprite',"name":'attachment-eye'}})],1):_vm._e(),(
                    activities_data.activities[0].object.data.thumbnailSmall
                  )?_c('img',{staticClass:"is-20x20",attrs:{"src":activities_data.activities[0].object.data.thumbnailSmall}}):_c('img',{staticClass:"is-20x20",attrs:{"src":require('@/assets/file-type-icons/' +
                      _vm.prettyFileIcons.getIcon(
                        activities_data.activities[0].object.data.name
                      ) +
                      '.svg')}})]),_c('p',{staticClass:"ml-5 is-medium-18-500-22"},[_vm._v(" "+_vm._s(activities_data.activities[0].object.data.name)+" ")])])])])]),_c('activities-container',{attrs:{"activities":activities_data.activities}})],1)}),(!_vm.is_loading && _vm.current_response.length)?_c('button',{staticClass:"has-text-centered has-background-white is-relative is-medium-14-500-17 show-btn is-pointer px-5 py-5",on:{"click":_vm.load_more}},[_vm._v(" "+_vm._s(_vm.localization("app-load-more", "Load More"))+" ")]):_vm._e(),(_vm.is_loading)?_c('img',{staticClass:"image is-64x64 is-relative loading-icon",attrs:{"src":require("@/assets/icons/ellipsis-animation.svg")}}):_vm._e()],2):_c('div',{staticClass:"overall-activity mt-60"},[(_vm.is_loading)?_c('activities-skeleton'):_c('div',[_c('p',{staticClass:"is-medium-14-500-17 has-text-dark has-text-centered"},[_vm._v(" No Activities ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="sh-list-header columns is-regular-14-00-17">
    <div class="column is-8">
      {{ localization("app-organization", "Organization") }}
    </div>
    <div class="column is-2"># Users</div>
    <div class="column is-2 is-center is-flex">
      {{ localization("app-status", "Status") }}
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>

import { httpClient } from '@/app/old/desktop/shared/services/'
import { BaseService } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_SYSTEM_MODEL_API_HOST

const URL = 'fields'

class API extends BaseService {
  constructor() {
    super(API_HOST, URL)
  }
}

export const customFieldsService = new API()

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.close()),expression:"() => close()"}],staticClass:"dropdown is-active is-right vault-menu-dropdown is-pulled-right"},[_c('div',{staticClass:"dropdown-trigger",on:{"click":function($event){_vm.is_dropdown = !_vm.is_dropdown}}},[_c('a',{attrs:{"aria-haspopup":"true","aria-controls":"dropdown-menu"}},[_c('span',[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1)])]),(_vm.is_dropdown)?_c('div',{staticClass:"dropdown-menu",attrs:{"role":"menu"}},[_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"vault-menu-dropdown--top"},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.localization('app-rename', 'Rename'),
              html: false
            }),expression:"{\n              content: localization('app-rename', 'Rename'),\n              html: false\n            }"}],staticClass:"image is-pointer is-24x24",class:{
              'is-disabled': !(
                _vm.folder.writeAccess || _vm.folder.ancestorWriteAccess
              )
            },attrs:{"file":'vault_sprite',"name":'rename'},nativeOn:{"click":function($event){_vm.$emit('rename');
              _vm.is_dropdown = false;}}}),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.localization('app-share', 'Share'),
              html: false
            }),expression:"{\n              content: localization('app-share', 'Share'),\n              html: false\n            }"}],staticClass:"image is-pointer is-24x24",class:{
              'is-disabled': !(
                _vm.folder.writeAccess || _vm.folder.ancestorWriteAccess
              )
            },attrs:{"file":'vault_sprite',"name":'share-grey'},nativeOn:{"click":function($event){_vm.$emit('share');
              _vm.is_dropdown = false;}}}),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: !_vm.folder.archive
                ? _vm.localization('app-archive', 'Archive')
                : _vm.localization('app-unarchive', 'Unarchive'),
              html: false
            }),expression:"{\n              content: !folder.archive\n                ? localization('app-archive', 'Archive')\n                : localization('app-unarchive', 'Unarchive'),\n              html: false\n            }"}],staticClass:"image is-pointer is-24x24",attrs:{"file":'vault_sprite',"name":'archive'},nativeOn:{"click":function($event){_vm.$emit('archive');
              _vm.is_dropdown = false;}}}),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.localization('app-delete', 'Delete'),
              html: false
            }),expression:"{\n              content: localization('app-delete', 'Delete'),\n              html: false\n            }"}],staticClass:"image is-pointer is-24x24",class:{ 'is-disabled': !_vm.folder.ownerAccess },attrs:{"file":'vault_sprite',"name":'delete'},nativeOn:{"click":function($event){_vm.$emit('delete');
              _vm.is_dropdown = false;}}})],1),_c('div',{staticClass:"vault-menu-dropdown--bottom"},[(_vm.folder.passwordProtected)?_c('a',{staticClass:"dropdown-item",class:{
              'is-disabled':
                _vm.folder.owner.uid !== _vm.$store.state.claims.user_id &&
                _vm.folder.passwordProtected
            },on:{"click":function($event){return _vm.$emit('reset_password')}}},[_c('svg',{staticClass:"image is-24x24 mr-10",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/vault_sprite.svg") + "#reset-password"}})]),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v("Reset password")])]):_vm._e(),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){_vm.SET_INFO_TAB({
                folder: _vm.folder,
                type: 'folder',
                active: true,
                tab: 'activity'
              });
              _vm.is_dropdown = false;}}},[_c('svg',{staticClass:"image is-24x24 mr-10",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/vault_sprite.svg") + "#view-activity"}})]),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-view-activity", "View activity")))])]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){_vm.SET_INFO_TAB({
                folder: _vm.folder,
                type: 'folder',
                active: true,
                tab: 'info'
              });
              _vm.is_dropdown = false;}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/info.svg"),"alt":""}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-info", "Info")))])]),(_vm.$store.getters.download_files_zip)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('download')}}},[_c('img',{staticClass:"mr-15",attrs:{"src":require("@/assets/icons/vault/download.svg"),"alt":""}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-download", "Download")))])]):_vm._e(),_c('a',{staticClass:"dropdown-item is-relative move-to",on:{"click":function($event){_vm.is_active = !_vm.is_active}}},[_c('img',{staticClass:"mr-15",attrs:{"src":require("@/assets/icons/vault/vault-move-to.svg"),"alt":""}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-move-to", "Move To")))]),(_vm.is_active)?_c('folder-move-dropdown',{staticClass:"is-absolute",class:[_vm.is_viewer_small ? 'viewer' : 'regular'],attrs:{"exclude_folders":[_vm.folder.uid],"hide_icon":true,"show_dropdown":_vm.is_active ? true : false},on:{"close":function($event){_vm.is_active = false},"move_folder":function($event){_vm.$emit('move', $event);
                _vm.close();}}}):_vm._e()],1),_c('a',{staticClass:"dropdown-item is-relative create-vault-ticket",on:{"click":function($event){_vm.open_create_ticket = !_vm.open_create_ticket}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'sidebar-tickets'}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-create-ticket", "Create Ticket")))]),(_vm.open_create_ticket)?_c('create-new-ticket',{staticClass:"is-absolute",class:[_vm.is_viewer_small ? 'viewer' : 'regular'],attrs:{"open_on_create":true,"element":_vm.folder.element,"vault":true},on:{"close":function($event){return _vm.close()}}}):_vm._e()],1)])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
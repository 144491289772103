<template>
  <div class="sh-list-header columns is-regular-14-00-17 is-gapless">
    <div class="column is-3">{{ localization("app-groups", "Groups") }}</div>
    <div class="column is-3">{{ localization("app-assets", "Assets") }}</div>
    <div class="column is-3">
      {{ localization("app-containers", "Containers") }}
    </div>
    <div class="column is-3">{{ localization("app-access", "Access") }}</div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .sh-list-header {
    padding: 1.5rem 1.5rem 1.5rem 4rem;
  }
</style>

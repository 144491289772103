<template>
  <div>
    <div class="therm-ticket-details">
      <p
        @click="$store.commit('thermv2/SET_SELECTED_FEATURE_ID', null)"
        class="padding-20"
      >
        <i class="fas is-24x24 fa-chevron-left is-pointer"></i>
        {{ localization("app-defect-details", "Defect details") }}
      </p>

      <div class="tabs " style="background: rgb(75, 79, 83)">
        <ul class="is-flex has-space-around">
          <li
            class=""
            :class="{ 'is-active': active_tab == 'details' }"
            @click="switch_tabs('details')"
          >
            <a class="is-medium-14-500-17 has-text-light">{{
              localization("app-details", "Details")
            }}</a>
          </li>
          <li
            :class="{ 'is-active': active_tab == 'attachments' }"
            @click="switch_tabs('attachments')"
          >
            <a class="is-medium-14-500-17 has-text-light">{{
              localization("app-attachments", "Attachments")
            }}</a>
          </li>
        </ul>
      </div>

      <div
        v-if="active_tab == 'details' && ticket"
        class="mt-20 padding-20 has-slimscroll-xs"
        style="height:70vh;overflow-y:scroll"
      >
        <p>{{ ticket.name || "" }}</p>
        <div class="divider has-background-light-grey"></div>
        <div class="is-flex is-vcenter has-space-between">
          <p>{{ localization("app-assignee", "Assignee") }}</p>
          <user-assignee-dropdown
            :align_right="true"
            :has_dark_theme="true"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            :existing_user="ticket.takenUp"
            @update-user="update_ticket({ takenUp: $event })"
            :pxsize="20"
            class="is-medium-16-500-19"
            width="15"
          />
        </div>
        <div class="divider has-background-light-grey"></div>
        <div class="is-flex is-vcenter has-space-between">
          <p>{{ localization("app-teams", "Teams") }}</p>
          <div
            class="is-flex is-vcenter invert_colors"
            v-if="get_teams(ticket).length"
          >
            <primary-tags
              :tags="get_teams(ticket)"
              @clicked_tag="ticket.writeAccess ? add_teams() : null"
              :toShowCount="1"
              :no_access="true"
              :type="'team'"
              :no_wrap="true"
              :truncate_length="8"
            />
            <sh-icon
              v-if="ticket.writeAccess"
              :name="'add-users-teams'"
              class="show-on-hover is-22x22 ml-5 is-pointer add-user-assignee"
              @click.native="add_teams"
            />
          </div>
          <div
            v-else
            class="is-flex is-vcenter is-pointer"
            @click="add_teams"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
          >
            <sh-icon :name="'add-teams'" class="mr-7 is-24x24" />
            <span
              class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
              >{{ localization("app-add-teams", "Add Teams") }}</span
            >
          </div>
        </div>
        <div class="divider has-background-light-grey"></div>
        <div class="is-flex is-vcenter has-space-between py-10">
          <p>{{ localization("app-defect-type", "Defect type") }}</p>
          <p>{{ defect_type }}</p>
        </div>
        <div class="divider has-background-light-grey"></div>
        <div class="is-flex is-vcenter has-space-between">
          <p>{{ localization("app-status", "Status") }}</p>

          <status-dropdown
            :has_dark_theme="true"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            :current_status="ticket.status"
            :ticket="ticket"
            v-if="ticket"
            @update-ticket-status="
              update_ticket({ status: parseInt($event.value) })
            "
          ></status-dropdown>
        </div>
        <div class="divider has-background-light-grey"></div>
        <div class="is-flex is-vcenter has-space-between py-10">
          <p>{{ localization("app-location", "Location") }}</p>
          <p>
            {{ (ticket.properties && ticket.properties.stringName) || "NA" }}
          </p>
        </div>
        <div class="divider has-background-light-grey"></div>
        <div class="is-flex is-vcenter has-space-between pt-10 pb-20">
          <p>Δ {{ localization("app-temperature", "Temperature") }}</p>
          <p>
            {{
              ticket.properties &&
              ([undefined, null].includes(
                ticket.properties.temperatureDifference
              )
                ? false
                : ticket.properties.temperatureDifference)
                ? `${ticket.properties.temperatureDifference.toFixed(2)} °C`
                : "NA"
            }}
          </p>
        </div>
        <div
          v-if="ticket.properties && ticket.properties.serialNumber"
          class="is-flex is-vcenter has-space-between pt-10 pb-20"
        >
          <p>{{ localization("app-serial-number", "Serial number") }}</p>
          <p>
            {{ ticket.properties && ticket.properties.serialNumber }}
          </p>
        </div>
        <div style="width: 80%" class="margin-auto py-20">
          <div
            class="button is-outlined is-info is-large is-fullwidth"
            @click="open_ticket_details(ticket.uid)"
          >
            {{ localization("app-view-details", "View details") }}
          </div>
        </div>
      </div>
      <div v-else-if="active_tab == 'attachments'">
        <p
          class="is-pointer ml-20 mb-10 has-text-info"
          @click="open_raw_image_modal()"
        >
          {{ localization("app-check-markers", "Check markers") }}
        </p>
        <div>
          <p class="px-20">
            {{ localization("app-total-images", "Total images") }} ({{
              raw_images && raw_images.length
            }})
          </p>
          <div class="py-20">
            <div
              v-if="raw_images && raw_images.length"
              class="is-flex is-flex-wrap"
              style="justify-content: space-evenly"
            >
              <div
                class="is-inline is-relative  attachment_block mb-20"
                v-for="(image, i) in raw_images"
                :key="i"
                @click="download_images(image)"
              >
                <sh-icon
                  :name="'download'"
                  class="is-36x36 download_icon is-pointer"
                ></sh-icon>
                <img
                  :src="
                    image.src ||
                      require('@/assets/illustrations/working-illustration.png')
                  "
                  alt=""
                  class="image is-90x120"
                />
              </div>
            </div>
            <div v-else class="has-text-centered">
              {{ localization("app-no-attachments", "NO ATTACHMENTS") }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="details-actions  is-flex align-center has-space-around is-absolute"
        style="background: rgb(75, 79, 83)"
      >
        <div
          :class="{ 'is-disabled': index == 0 }"
          class="is-pointer"
          @click="previous_ticket"
        >
          <i class="fas fa-chevron-left has-text-light pr-10"></i>
          <a class="is-medium-14-500-17 has-text-light">{{
            localization("app-previous", "Previous")
          }}</a>
        </div>
        <div
          :class="{ 'is-disabled': index == filtered_defects.length - 1 }"
          class="is-pointer"
          @click="next_ticket"
        >
          <a class="is-medium-14-500-17 has-text-light">{{
            localization("app-next", "Next")
          }}</a>
          <i class="fas fa-chevron-right has-text-light pl-10"></i>
        </div>
      </div>
    </div>
    <!-- <div v-if="is_popup_active" class="image_viewer">
      <b-modal :active="is_popup_active" @close="is_popup_active = false">
        <RawImageMapModal :raw_images="raw_images" />
      </b-modal>
    </div> -->
  </div>
</template>

<script>
  import UserAssigneeDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import RawImageMapModal from "@/app/old/desktop/therm-v2/components/popup-modal.component.vue";

  import { mapMutations, mapState, mapGetters } from "vuex";
  import { apiService } from "@/app/old/desktop/shared/services/";

  export default {
    mixins: [TicketDetailsMixin],
    data() {
      return {
        is_popup_active: false,
        active_tab: "details",
        index: -1,
        raw_images: [],
        status_bg_classes: {
          1: "is-bg-yellow",
          2: "is-bg-green",
          3: "is-bg-blue",
          4: "is-bg-light-grey",
          5: "is-bg-red",
          6: "is-bg-blue"
        },
        status_options: [
          {
            name: "pending",
            value: 1
          },
          {
            name: "in progress",
            value: 2
          },
          {
            name: "resolved",
            value: 3
          },
          {
            name: "closed",
            value: 4
          },
          {
            name: "rejected",
            value: 5
          }
        ]
      };
    },
    computed: {
      ...mapState("thermv2", [
        "projects",
        "vector_types",
        "map",
        "selected_feature_id"
      ]),
      ...mapGetters("thermv2", ["filtered_defects"]),

      ticket() {
        return this.index >= 0 && this.filtered_defects[this.index]
          ? this.filtered_defects[this.index]
          : {};
      },
      attachments() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.projectUid &&
          this.ticket.properties.issueUid
        ) {
          return this.projects[this.ticket.properties.projectUid].features[
            this.ticket.properties.issueUid
          ].properties.raw_images;
        }
        return [];
      },
      ticket_project() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.projectUid
        ) {
          let projectid = this.ticket.properties.projectUid;
          return this.projects[projectid];
        } else {
          return null;
        }
      },
      defect_type() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.classId
        ) {
          let vector = this.vector_types.find(
            v => v.class_id == this.ticket.properties.classId
          );
          if (vector && vector.description) {
            return vector.description;
          } else {
            return "";
          }
          // return vector;
        } else {
          return "";
        }
      }
    },
    components: {
      UserAssigneeDropdown,
      StatusDropdown,
      PrimaryTags,
      RawImageMapModal
    },
    watch: {
      selected_feature_id: {
        handler(val) {
          this.index = this.filtered_defects.findIndex(
            t => t.properties && t.properties.issueUid === val
          );

          this.fly_to();
        },
        immediate: true
      },
      "ticket.uid": {
        handler(newVal, oldVal) {
          if (!newVal) {
            this.$store.commit("thermv2/SET_SELECTED_FEATURE_ID", null);
          } else if (newVal !== oldVal) {
            this.get_raw_images();
          }
        }
      }
    },
    methods: {
      ...mapMutations("thermv2", ["SET_SELECTED_FEATURE_ID"]),
      open_raw_image_modal() {
        this.$buefy.modal.open({
          props: {
            raw_images: this.raw_images
          },
          width: "600px",
          parent: this.$root,
          component: RawImageMapModal
        });
      },
      switch_tabs(tab) {
        this.active_tab = tab;
        if (tab == "attachments") {
          this.get_raw_images();
        }
      },
      fly_to(ticket) {
        if (this.ticket.location && this.ticket.location.coordinates)
          this.map.flyTo({
            center: this.ticket.location.coordinates,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });
      },
      next_ticket() {
        if (!this.filtered_defects[this.index + 1]) return;
        this.SET_SELECTED_FEATURE_ID(
          this.filtered_defects[this.index + 1].properties.issueUid
        );
      },
      previous_ticket() {
        if (!this.filtered_defects[this.index - 1]) return;
        this.SET_SELECTED_FEATURE_ID(
          this.filtered_defects[this.index - 1].properties.issueUid
        );
      },
      async get_raw_images() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.projectUid &&
          this.ticket.properties.issueUid
        ) {
          let raw_images = this.projects[this.ticket.properties.projectUid]
            .features[this.ticket.properties.issueUid].properties.raw_images;
          console.log({ raw_images });
          if (raw_images && typeof raw_images == "string") {
            let res = await apiService.get({ url: raw_images });
            this.raw_images = res.data;
          }
        }
        return [];
      },
      download_images(image) {
        window.open(image.src, "_blank");
        // alert("downloading");
      },

      update_ticket(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
      },
      add_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [
              ...(this.ticket.readLabels || []).map(u => ({
                uid: u,
                access: "read"
              })),
              ...(this.ticket.writeLabels || []).map(u => ({
                uid: u,
                access: "write"
              }))
            ],
            asset_id:
              this.ticket && this.ticket.targetElement
                ? this.ticket.targetElement.asset
                : null,
            item_uid: this.ticket.uid,
            complete: async e => {
              let readLabels = e
                .filter(u => u.access === "read")
                .map(u => u.uid);
              let writeLabels = e
                .filter(u => u.access === "write")
                .map(u => u.uid);
              this.update_ticket({ readLabels, writeLabels });
            }
          },
          { height: "80%" }
        );
      }
    }
  };
</script>

<style lang="scss">
  .therm-ticket-details {
    width: 30rem;
    height: 100vh;
    font-family: Barlow-Medium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    .details-actions {
      bottom: 0;
      width: 100%;
      height: 4rem;
    }
  }

  .has-background-light-grey {
    background: #00000029;
  }

  .is-90x120 {
    height: 9rem;
    width: 12rem;
  }
  .add_attachment_button {
    position: fixed;
    bottom: 4rem;
    left: 7rem;
    width: 55%;
  }
  .download_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    visibility: hidden;
  }
  .attachment_block {
    &:hover {
      .download_icon {
        visibility: visible;
      }
    }
  }

  .image_viewer {
    .modal {
      width: 50vw;
      left: -80vw;
      position: fixed;
      font-size: 1.6rem;
    }
  }
</style>

<template>
  <div class="project__image-details">
    <div class="sh-medium-header is-marginless">
      {{ localization("app-image-details", "Image details") }}
    </div>
    <div v-if="is_loading" class="mt-10">
      <project-images-skeleton />
    </div>
    <div
      v-else
      class="mt-10 columns sh-image-details-card is-multiline is-mobile"
    >
      <!-- <div class="column is-one-quarter">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-data-captured-time", "Data Captured Time") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            details.data_captured_time
              ? details.data_captured_time
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div> -->

      <div class="column is-one-quarter">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-region", "Region") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            project_report.service && project_report.service.region
              ? project_report.service.region
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <div class="column is-one-quarter">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-data-upload-time", "Data upload time") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            details.data_upload_time
              ? details.data_upload_time
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <div class="column is-one-quarter">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-area-covered", "Area covered") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            details.area_covered
              ? details.area_covered + " ha"
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <div class="column is-one-quarter">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-camera-model", "Camera model") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            details.camera_make || details.camera_model
              ? details.camera_make && details.camera_model
                ? details.camera_make + " | " + details.camera_model
                : details.camera_make || details.camera_model
              : localization(
                  "app-not-found",
                  localization("app-not-found", "*Not found")
                )
          }}
        </p>
      </div>
      <div class="column is-one-quarter mt-20 mb-20">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-megapixel", "Megapixel") }}
        </p>
        <p class="is-regular-14-00-17">
          {{
            exif_data["Composite:Megapixels"]
              ? exif_data["Composite:Megapixels"]
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <!-- <div class="column is-one-quarter mt-20 mb-20">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-image-altitude", "Image altitude") }}
        </p>
        <p class="is-regular-14-00-17">
          {{
            details.image_altitude
              ? details.image_altitude
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div> -->
      <div class="column is-one-quarter mt-20 mb-20">
        <p
          class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless no-whitespace-wrap"
        >
          {{
            localization(
              "app-average-ground-sampling-distance",
              "Average ground sampling distance"
            )
          }}
        </p>
        <p class="is-regular-14-00-17">
          {{
            details.average_ground_sampling_distance != null
              ? details.average_ground_sampling_distance
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <div class="column is-one-quarter mt-20 mb-20">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-shutter-speed", "Shutter speed") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            exif_data["Composite:ShutterSpeed"]
              ? exif_data["Composite:ShutterSpeed"]
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <div class="column is-one-quarter mt-20 mb-20">
        <p class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless">
          {{ localization("app-lens-info", "Lens info") }}
        </p>
        <p class="is-medium-14-00-17">
          {{
            exif_data["APP1:LensModel"]
              ? exif_data["APP1:LensModel"]
              : localization("app-not-found", "*Not found")
          }}
        </p>
      </div>
      <hr class="divider" />
      <div class=" has-full-width is-vcenter pr-30 mt-20">
        <div class="is-flex has-space-between">
          <p class="is-medium-14-500-20 ml-10">
            Sync from S3
            <i
              v-tooltip="'Refresh'"
              @click="() => sync_images()"
              class="fa fa-sync ml-10 is-pointer"
              :class="{ 'fa-spin': synching }"
            ></i>
          </p>
          <sync-s3-images-form
            @update_details="$emit('update_details')"
            :project_details="project_details"
          ></sync-s3-images-form>
        </div>
        <div class="is-flex">
          <div
            v-if="project_details && project_details.properties"
            class="column is-one-quarter mb-20"
          >
            <p
              class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless"
            >
              Images found
            </p>
            <p class="is-medium-14-00-17">
              {{ project_details.properties.no_of_images_found || 0 }}
            </p>
          </div>
          <div
            v-if="project_details && project_details.properties"
            class="column is-one-quarter mb-20"
          >
            <p
              class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless"
            >
              Images synced
            </p>
            <p class="is-medium-14-00-17">
              {{ project_details.properties.no_of_images_synced || 0 }}
            </p>
          </div>
          <div
            v-if="project_details && project_details.properties"
            class="column is-one-quarter mb-20"
          >
            <p
              class="is-regular-14-00-17 has-text-light-grey pb-10 is-marginless"
            >
              % Complete
            </p>
            <p
              class="is-medium-14-00-17"
              v-if="project_details.properties.no_of_images_found"
            >
              {{
                (
                  (project_details.properties.no_of_images_synced * 100) /
                  project_details.properties.no_of_images_found
                ).toFixed(0)
              }}
              %
            </p>
            <p class="is-medium-14-00-17" v-else>0</p>
          </div>
        </div>
      </div>

      <hr class="divider" />
      <div
        class="is-flex is-vcentred has-space-between has-full-width is-vcenter pr-30"
      >
        <div class="mt-25 mb-40 ml-10">
          <p class="is-medium-14-500-20 mb-20">
            {{ localization("app-raw-images", "Raw images") }} ({{
              Object.keys((project_report && project_report.datas) || {})
                .length
            }})
            <i
              v-tooltip="localization('app-refresh-images', 'Refresh Images')"
              @click="sync_images()"
              class="fa fa-sync ml-10 is-pointer"
              :class="{ 'fa-spin': synching }"
            ></i>

            <router-link
              v-if="project_report_images && project_report_images.length"
              :to="{
                name: 'project-images-list',
                params: {
                  group_id: $route.params.group_id,
                  id: $route.params.id,
                  name: project_details.name
                }
              }"
              class="has-text-blue is-medium-14-500-20 ml-10 is-pointer"
            >
              {{ localization("app-view-all", "View all") }}
              <i class="fas fa-chevron-right"></i>
            </router-link>
          </p>

          <div class="project__image-details--images">
            <template v-if="project_report_images">
              <p
                class="project__image-details--images--element"
                v-for="(url, i) in project_report_images"
                :key="i"
              >
                <img :src="url" alt="i" />
              </p>
            </template>
            <upload-images
              :project_details="project_details"
              :project_report="project_report"
              :project_report_images="project_report_images"
              :is_upload_popup="is_upload_popup"
              @update="
                is_upload_popup = false;
                update_details();
                upload_completed();
                $emit('update_details');
              "
            ></upload-images>
          </div>
        </div>
        <div class="is-flex">
          <a
            v-tooltip="{
              content: localization(
                'app-tooltip-project-image-sync',
                'Synchronize uploaded images i.e, generate thumbnails, zip etc with updated files.'
              )
            }"
            @click="sync_report(project_report)"
            class="has-text-theme-black"
          >
            <i class="fa fa-sync"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    reportService,
    uploadService,
    missionService,
    projectService
  } from "@/app/old/desktop/shared/services/";

  import uploadImages from "@/app/old/desktop/core/components/project/project-details/components/project-images/components/upload-images.vue";
  import ProjectImagesSkeleton from "@/app/old/desktop/core/components/project/project-details/components/project-images/components/project-images-skeleton.vue";
  import SyncS3ImagesForm from "@/app/old/desktop/core/components/project/project-details/components/project-images/components/sync-s3-images-form.vue";
  export default {
    props: ["project_details"],
    components: {
      uploadImages,
      SyncS3ImagesForm,
      ProjectImagesSkeleton
    },
    data() {
      return {
        is_loading: false,
        synching: false,
        is_upload_popup: false,
        project_report: {},
        project_report_images: [],
        mission: {},
        details: {
          data_captured_time: null,
          data_upload_time: null,
          area_covered: null,
          camera_model: null,
          image_altitude: null,
          mission: null,
          average_ground_sampling_distance: null
        }
      };
    },
    watch: {
      project_report() {
        this.details.data_upload_time = this.project_report.updated_at;
        if (this.project_report.datas) {
          let data_index = Object.keys(this.project_report.datas)[0];
          if (
            this.project_report.datas[data_index] &&
            this.project_report.datas[data_index].exif
          ) {
            this.details.camera_make = this.project_report.datas[
              data_index
            ].exif.Make;
            this.details.camera_model = this.project_report.datas[
              data_index
            ].exif.Model;
            this.details.image_altitude = this.project_report.datas[
              data_index
            ].exif.GPSAltitude;
          }
        }
      },
      mission() {
        this.details.mission = {
          name: this.mission.name,
          uid: this.mission.uid
        };
        this.details.area_covered = this.mission.area;
        if (this.mission.details) {
          this.details.data_captured_time = this.mission.details.end;
        }
        this.details.average_ground_sampling_distance = this.mission.settings
          ? this.mission.settings.gsd
          : null;
      }
    },
    computed: {
      exif_data() {
        if (
          this.project_report &&
          this.project_report.datas &&
          Object.values(this.project_report.datas) &&
          Object.values(this.project_report.datas).length
        ) {
          return Object.values(this.project_report.datas)[0]?.exif;
        } else return {};
      }
    },
    async created() {
      this.is_loading = true;
      await Promise.all([this.update_details(), this.get_mission()]);
      this.is_loading = false;
    },
    methods: {
      async update_details() {
        await this.get_project_report();
        if (this.project_report.uid) await this.get_project_report_images();
      },
      async upload_completed() {
        uploadService.upload_completed({
          id: this.$route.params.id
        });
      },
      async get_project_report() {
        let { results } = await reportService.get_reports({
          resource_name: "projects",
          query: "report_type=images",
          id: this.$route.params.id
        });
        if (results && results.length) this.project_report = results[0];
      },
      async get_project_report_images() {
        let { urls } = await uploadService.get_raw_datas_download_links({
          id: this.$route.params.id,
          report_uid: this.project_report.uid,
          body: {},
          query: "limit=3"
        });
        if (urls) this.project_report_images = Object.values(urls).slice(0, 3);
      },
      async get_mission() {
        let result = await missionService
          .get_project_mission({
            query: `projectUid=${this.$route.params.id}`
          })
          .catch(err => this.handleErrors(err));
        if (result && result.data[0]) this.mission = result.data[0].missions[0];
      },
      sync_images() {
        this.synching = true;
        setTimeout(() => {
          this.update_details();
          this.$emit("update_details");
          this.synching = false;
        }, 1000);
      },
      async sync_report(report) {
        let res = await projectService
          .sync_report({
            id: this.$route.params.id,
            report_id: report.uid
          })
          .catch(err => {
            this.handleErrors(err);
          });
        if (res) {
          this.$toast.show(
            "Synchronization initiated",
            "",
            this.$store.state.izitoast_options.appSuccess
          );
        }
      }
    }
  };
</script>

<style lang="scss">
  .sh-image-details-card {
    background: $white;
    border-radius: 1rem;
    height: max-content;
    padding: 2rem;
    margin: 1rem 0;
    box-shadow: 0 2px 4px 0 rgba(23, 30, 72, 0.07);

    .column {
      padding: 1rem;
    }
  }
  .project__image-details {
    &--images {
      display: flex;
      &--element {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        width: 7rem;
        height: 7.5rem;
        margin-right: 1rem;
        border-radius: 0.5rem;
        img {
          object-fit: contain;
        }
      }
      .image-block {
        height: 6.5rem;
        width: 7rem;
        background: $blue;
        margin-right: 1rem;
        border-radius: 0.5rem;

        &--upload {
          background: $primary-background;
          border: 1px solid $grey-text;
          border-radius: 0.5rem;

          height: 6rem;
          width: 7rem;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 1.4rem;
          flex-direction: column;
        }
      }
    }
  }
</style>

<template>
  <div class="vault-filters-groupby" v-click-outside="() => (active = false)">
    <span class="heading-1"> {{ localization("app-shared", "Shared") }}:</span>
    <div class="dropdown" :class="{ 'is-active': active }">
      <div class="dropdown-trigger" @click="active = !active">
        <span class="heading-2">{{
          selected
            ? custom_dates
            : localization(
                `app-${(selected_filter || "")
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`,
                selected_filter
              )
        }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content pb-15">
          <div class="dropdown-header padding-10">
            <span class="is-medium-16-500-19 has-opacity-5 ml-10 pt-10">
              {{ localization("app-shared", "Shared") }}
            </span>
          </div>
          <a
            @click="
              set_time(item.label);
              active = false;
            "
            class="dropdown-item px-20"
            :class="{ 'is-disabled': !item.enable }"
            v-for="(item, i) in data"
            :key="i"
          >
            <span>{{
              localization(
                `app-${item.label
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`,
                item.label
              )
            }}</span>
            <sh-icon
              v-if="selected_filter == item.label"
              :name="'tick'"
              class="is-16x16"
            />
          </a>
          <p class="ml-10">
            <duedate-dropdown
              :icon_class="'is-14x14'"
              :due="
                startDate && dueDate
                  ? [new Date(startDate), new Date(dueDate)]
                  : dueDate
                  ? [new Date(dueDate)]
                  : []
              "
              @change-duedate="
                selected = $event;
                set_custom_time($event);
              "
            ></duedate-dropdown>
          </p>
          <p
            class="filter-dropdown-bottom"
            @click="
              selected = null;
              selected_filter = 'All Time';
            "
          >
            <span
              class="is-pointer is-flex align-center"
              @click="clear_filters()"
            >
              <sh-icon
                :name="'ticket-filter-clear-all'"
                class="is-12x12 mr-10"
              />
              <span>{{ localization("app-clear-all", "Clear All") }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-range-dropdown";
  export default {
    components: {
      DuedateDropdown
    },
    props: [],
    data() {
      return {
        active: false,
        selected_filter: "All Time",
        selected: "",
        data: [
          { label: "Today", enable: true },
          {
            label: "Last week",
            enable: true
          },
          {
            label: "Last month",
            enable: true
          },
          {
            label: "Last year",
            enable: true
          }
        ]
      };
    },
    computed: {
      ...mapState("vault", ["filter_query"]),
      vault_filters: {
        get() {
          return this.$store.state.vault.vault_filters;
        },
        set(value) {
          this.$store.commit("vault/SET_VAULT_FILTERS", value);
        }
      },
      custom_dates() {
        if (typeof this.selected == "string") {
          return this.selected;
        } else {
          if (this.selected && this.selected.length == 2) {
            return `${moment(this.selected[0]).format("DD MMM YY")} - ${moment(
              this.selected[1]
            ).format("DD MMM YY")}`;
          } else if (this.selected && this.selected.length == 1) {
            return moment(this.selected[0]).format("DD MMM YY");
          } else {
            return this.selected;
          }
        }
      }
    },
    methods: {
      set_time(item) {
        this.selected_filter = item;
        this.selected = "";
        let current_date = new Date();
        this.clear_filters();
        let endDate;
        let startDate;
        switch (item) {
          case "Today":
            // startDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
            // endDate = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();
            startDate = new Date(
              new Date(new Date().setHours(0, 0, 0, 0))
                .toString()
                .split("GMT")[0] + " UTC"
            ).toISOString();
            endDate = new Date(
              new Date(new Date().setHours(23, 59, 59, 999))
                .toString()
                .split("GMT")[0] + " UTC"
            ).toISOString();
            break;
          case "Last week":
            startDate = moment().subtract(7, "days");
            endDate = new Date().toISOString();
            break;
          case "Last month":
            startDate = moment().subtract(30, "days");
            endDate = new Date().toISOString();
            break;
          case "Last year":
            startDate = moment().subtract(365, "days");
            endDate = new Date().toISOString();
            break;
          // case "Custom":
          //   startDate = this.vault_filters.dueDate[0];
          //   endDate = this.vault_filters.dueDate[1];
          //   console.log(this.vault_filters.dueDate);
          //   break;
          default:
            startDate = null;
            endDate = null;
            break;
        }
        this.vault_filters.dueDate[0] = startDate
          ? new Date(startDate).toISOString()
          : null;
        this.vault_filters.dueDate[1] = endDate
          ? new Date(endDate).toISOString()
          : null;
        this.vault_filters = { ...this.vault_filters };
      },
      set_custom_time(dates) {
        this.selected_filter = "All Time";
        let startDate = dates[0];
        let endDate = dates[1];
        this.vault_filters.dueDate[0] = startDate
          ? new Date(startDate).toISOString()
          : null;
        this.vault_filters.dueDate[1] = endDate
          ? new Date(endDate).toISOString()
          : null;
        this.vault_filters = { ...this.vault_filters };
      },
      clear_filters() {
        this.vault_filters.dueDate = [];
        this.vault_filters = { ...this.vault_filters };
      }
    }
  };
</script>

<style lang="scss">
  .vault-filters-groupby {
    .dropdown .dropdown-menu .dropdown-content {
      border-radius: 1rem;
      box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px rgba(23, 30, 72, 0.1);
      background-color: #ffffff;
    }
    .dropdown-content {
      width: 20rem;
      .dropdown-item {
        font-size: 1.4rem !important;
      }
    }
  }
</style>

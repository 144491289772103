<script>
  export default {
    created() {
      if (navigator.serviceWorker)
        navigator.serviceWorker.addEventListener("message", this.swMessage);
    },
    beforeDestroy() {
      if (navigator.serviceWorker)
        navigator.serviceWorker.removeEventListener("message", this.swMessage);
    },
    methods: {
      async swMessage(event) {
        if (event.data && event.data.type == "networkSuccess") {
          this.staleWhileRevalidate(event);
        }
      }
    }
  };
</script>
